import React from 'react'
import { Button } from '@mui/material'
import { Download, Check } from 'react-feather'


const InstallButton = ({
	label,
	installed,
	handleSubmit,
	handleDelete,
}) => {


	return (
		<Button
			fullWidth
			variant="contained"
			color="primary"
			onClick={handleSubmit}
			startIcon={
				installed ? (
					<Check sx={ sx.icon } />
				) : (
					<Download sx={ sx.icon } />
				)
			}
		>
			{installed ? 'Update' : 'Install'} {label}
		</Button>
	)
}

export default InstallButton

const sx = {
	icon: {
		height: 20,
		width: 20,
	},
}
