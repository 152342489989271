import React from 'react'
import { Grid } from '@mui/material'
import { InstallButton, TextInput } from 'components'

const CloudinaryAddonForm = ({
	app,
	addon,
	installed,
	handleChange,
	handleSubmit,
}) => (
	<Grid container spacing={1}>
		<Grid item xs={12} sm={12}>
			<TextInput
				label="Cloud Name"
				value={addon?.credentials?.cloud_name}
				name="cloud_name"
				placeholder="Cloud Name"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				label="Cloudinary API Key"
				value={addon?.credentials?.api_key}
				name="api_key"
				placeholder="Cloudinary API Key"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				type="password"
				label="Cloudinary API Secret"
				value={addon?.credentials?.api_secret}
				name="api_secret"
				placeholder="Cloudinary API Secret"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<InstallButton
				label={app?.name}
				installed={installed}
				handleSubmit={handleSubmit}
			/>
		</Grid>
	</Grid>
)

export default CloudinaryAddonForm
