import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import queryString from 'query-string'

const useOAuth = ({ onComplete, provider }) => {
	const location = useLocation()
	const queryParams = queryString.parse(location.search)

	// Handle the login once the code is retrieved
	const handleEventListener = async (event) => {
		if (event.data) {
			const code = event.data
			await onComplete(code)
			window.close()
		}
	}
	// Listen for messages from popup window
	useEffect(() => {
		const eventId = window.addEventListener(
			'message',
			handleEventListener,
			false
		)
		return () => {
			if (eventId) {
				window.removeEventListener(eventId)
			}
			return
		}
	}, [])

	// Once the code is returned after a successful login,
	// post the message to the parent window
	useEffect(() => {
		if (queryParams?.code) {
			const { code } = queryParams
			const message = JSON.stringify({
				code: code,
				provider: provider,
			})
			window.opener.postMessage(message)
			window.close()
		}
	}, [queryParams?.code])
}

export default useOAuth
