import React, { createContext, useState } from 'react'
import AppContext from 'context/AppContext'

const AppProvider = ({ children }) => {
	const [authenticated, setAuthenticated] = useState()
	const [currentUser, setCurrentUser] = useState()
	const [token, setToken] = useState()

	const [alert, setAlert] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [isLoaded, setIsLoaded] = useState(false)

	const [isEditing, setIsEditing] = useState(false)

	const [showMenu, setShowMenu] = useState(false)

  const [openMobile, setOpenMobile] = useState(false)

  const [globalReload, setGlobalReload] = useState(true)
	const [showSideMenu, setShowSideMenu] = useState(true)
	const [activeTab, setActiveTab] = useState(false)
  
  const [viewsOpen, setViewsOpen] = useState(true)
  const [collectionsOpen, setCollectionsOpen] = useState(true)

	const toggleSideMenu = () => setShowSideMenu(!showSideMenu)
	const toggleEditing = () => setIsEditing(!isEditing)

	const value = {
		authenticated,
		setAuthenticated,

		currentUser,
		setCurrentUser,

		token,
		setToken,

		alert,
		setAlert,
    
		activeTab,
		setActiveTab,

		isLoading,
		isLoaded,
		setIsLoaded,
		setIsLoading,

		showMenu,
    setShowMenu,
    
		showSideMenu,
		setShowSideMenu,
		toggleSideMenu,

    globalReload,
    setGlobalReload,

		isEditing,
		setIsEditing,
		toggleEditing,

    openMobile,
    setOpenMobile,

    viewsOpen,
    setViewsOpen,

    collectionsOpen,
    setCollectionsOpen
	}

	return (
		<AppContext.Provider value={value}>
			{children}
		</AppContext.Provider>
	)
}

export default AppProvider
