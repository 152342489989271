import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { Label } from 'components'

import {
	Check,
	Download,
	BookOpen,
	ExternalLink,
  Trash,
  X
} from 'react-feather'
import AppHeader from 'containers/main/apps/AppHeader'

const AppDetails = ({
	app,
	installed,
	handleClick,
	handleDeleteClick,
	handleDocsClick,
	handleVisitClick,
	fullWidth,
}) => {


	return (
		<Box sx={ sx.root }>
			<AppHeader app={app} size={120} />
			<Box>
				<Box sx={ sx.description }>
					<Typography variant="h5">Description</Typography>
					<Typography variant="body2">
						{app.description}
					</Typography>
				</Box>
				<Button
					fullWidth={fullWidth}
					sx={ sx.button }
					color="primary"
					variant="contained"
					onClick={() => handleClick(app)}
					startIcon={
						installed ? (
							<Check sx={ sx.icon } />
						) : (
							<Download sx={ sx.icon } />
						)
					}
				>
					{installed ? 'Addon Installed' : 'Install Addon'}
				</Button>
				{installed && (
					<Box>
						<Button
              sx={ sx.button }
              variant='outlined'
							onClick={handleDeleteClick}
							color="secondary"
							startIcon={<Trash sx={ sx.icon} /> }
						>
							Uninstall
						</Button>
					</Box>
				)}
				<Box>
					<Button
						onClick={handleDocsClick}
						startIcon={
							<BookOpen style={ sx.icon } />
						}
					>
						View Documentation
					</Button>
				</Box>
				<Box>
					<Button
						onClick={handleVisitClick}
						startIcon={
							<ExternalLink sx={ sx.icon } />
						}
					>
						Visit Website
					</Button>
				</Box>
			</Box>
		</Box>
	)
}

export default AppDetails

const sx = {
	root: {},
	description: {
	  py: 2,
    px: 1,
	},
	button: {
		minWidth: '320px',
		my: 1,
    mx: 0,
    width: { xs: '100%', sm: '320px'}
	},
	icon: {
		height: '20px',
		width: '20px',
	},
}
