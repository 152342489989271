import React from 'react'
import { Grid, Container, Typography } from '@mui/material'
import { LandingLayout, Hero, Panel } from 'components'
import LegalTabs from 'containers/landing/Legal/LegalTabs'

export default function Privacy(){
  const activeStep = 2
	return (
		<LandingLayout>
			<Hero
				title="Privacy Policy"
				subtitle="Last updated 4/1/2022"
			/>
			<Container maxWidth="lg">
				<Grid container spacing={1}>
					<Grid item xs={12} sm={3}>
						<LegalTabs step={activeStep} />
					</Grid>
					<Grid item xs={12} sm={9}>
						<Panel p={4}>							
								<Typography variant="h6" mt={1}>
									What is GDPR?
								</Typography>

								<Typography variant="body1" mt={1}>
									The General Data Protection Regulation
									(GDPR) is a comprehensive European Union
									law on data privacy. It took effect on May
									25, 2018, creating new obligations for
									businesses and new rights for individuals
									in the EU.
								</Typography>

								<Typography variant="subtitle1" mt={1}>
                  What is our legal basis for processing
                  data?
								</Typography>

								<Typography variant="body1" mt={1}>
									The data we process has been made
									'manifestly public' (GDPR Article 9.2(e))
									by the data subject. We only crawl and
									index publicly available email addresses
									and phone numbers, such as those that are
									accessible from websites and social media.
									SkillHire has a 'legitimate interest'
									(GDPR Article 6.1(f)) in empowering
									recruiters to process employee data for
									operational, adminstrative and HR
									purposes.
								</Typography>

								<Typography variant="h6" mt={1}>
									Your rights
								</Typography>
								<Typography variant="subtitle1" mt={1}>
									Access
								</Typography>
								<Typography variant="body1" mt={1}>
									You may request access to a copy of your
									personal information including: purposes
									of processing; categories of data
									processed; recipient(s) of data; length of
									time data is stored; and information on
									data transfers.
								</Typography>

								<Typography variant="subtitle1" mt={1}>
									Erasure
								</Typography>

								<Typography variant="body1" mt={1}>
									Since we only deal with public data,
									information that is removed from a website
									will also be removed from our database.
									You may at any time request the removal of
									your personal information from our
									database.
								</Typography>

								<Typography variant="subtitle1" mt={1}>
									Portability
								</Typography>
								<Typography variant="body1" mt={1}>
									The GDPR gives users the right to download
									data that they have provided to a service.
								</Typography>

								<Typography variant="subtitle1" mt={1}>
									Rectification
								</Typography>

								<Typography variant="body1" mt={1}>
									You may request that we change, update or
									complete any of your personal information.
								</Typography>

								<Typography variant="h6" mt={1}>
									Security
								</Typography>

								<Typography variant="subtitle1" mt={1}>
									Data protection in the cloud
								</Typography>
								<Typography variant="body1" mt={1}>
									Our services run on Amazon Web Services
									(AWS), a provider with the highest levels
									of security. The physical safety of
									datacenters is guaranteed by 24/7
									surveillance teams while state-of-the-art
									software security techniques protect your
									data from unwanted access. AWS
									infrastructure is highly resilient,
									constantly available and thoroughly
									monitored. It satisfies many global
									security standards including ISO27001,
									SOC, PCI and FedRAMP.
								</Typography>

								<Typography variant="subtitle1" mt={1}>
									Data Encryption
								</Typography>
								<Typography variant="body1" mt={1}>
									SkillHire systematically uses HTTPS on
									skillhire.com and any of our subdomains.
									Any connection in HTTP gets redirected to
									its HTTPS counterpart. We also use the Key
									Management Service (KMS) through AWS,
									which employs industry best practices to
									ensure the safety of the keys used to
									encrypt your data. The bottom line is that
									you can be sure of your information's
									safety.
								</Typography>

								<Typography variant="subtitle1" mt={1}>
									Credit Card Information
								</Typography>

								<Typography variant="body1" mt={1}>
									SkillHire does not store any card
									information that can compromise your
									security. Stripe -the provider who handles
									all your card details- is PCI Service
									Provider Level 1 Certified, the highest
									security standard available in the
									payments industry.
								</Typography>

								<Typography variant="subtitle1" mt={1}>
									Password Hashing
								</Typography>
								<Typography variant="body1" mt={1}>
									We don't store passwords- we don't even
									see them. We store a cryptographic hash.
								</Typography>

								<Typography variant="subtitle1" mt={2}>
									Information we collect from Google
								</Typography>

								<Typography variant="body1" mt={1}>
									SkillHire allows you to connect your
									account with Google to provide access to
									data including your family name, given
									name, email, picture, username, and access
									tokens which provides online and offline
									access to your Google account.
								</Typography>

								<Typography variant="body1" mt={1}>
									SkillHire uses information collected from
									Google to recognize you and provide access
									to our platform hosted at
									www.skillhire.com
								</Typography>

								<Typography variant="body1" mt={1}>
									From time to time SkillHire sends
									promotional emails and we may use your
									Google data to verify and send marketing
									content to your email. You may opt-out of
									receiving marketing emails at any time.
								</Typography>

								<Typography variant="body1" mt={1}>
									The information we collect from Google is
									not shared with any 3rd parties affiliated
									with SkillHire.
								</Typography>
						</Panel>
					</Grid>
				</Grid>
			</Container>
		</LandingLayout>
	)
}
