import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from 'App'
import HttpsRedirect from 'react-https-redirect'
import {
	AppProvider,
	AuthProvider,
	ApiProvider,
	ProjectProvider,
} from 'context'
import { StyledEngineProvider } from '@mui/material/styles'
import { API_URL } from 'config/secrets'
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { MUI_GRID_PRO_LICENSE } from 'lib/constants'

LicenseInfo.setLicenseKey(MUI_GRID_PRO_LICENSE)

const rootElement = document.getElementById('root')

ReactDOM.render(
	<HttpsRedirect>
		<BrowserRouter>
			<AuthProvider>
				<AppProvider>
					<ApiProvider url={API_URL}>
						<ProjectProvider>
							<StyledEngineProvider injectFirst>
								<App />
							</StyledEngineProvider>
						</ProjectProvider>
					</ApiProvider>
				</AppProvider>
			</AuthProvider>
		</BrowserRouter>
	</HttpsRedirect>,
	rootElement
)
