import React from 'react'
import {
	ButtonLoader,
	ProgressLoader,
	Modal,
} from 'components'
import { Box, Button } from '@mui/material'

import CollectionForm from 'containers/main/collections/CollectionForm'

const CollectionModal = ({
	isLoading,
	open,
	collection,
	handleClose,
	handleChange,
	handleSubmit,
}) => {

	return (
		<Modal
			open={open}
			handleClose={handleClose}
			title={`Add Collection`}
			actions={
				<Button
					size="large"
					color="primary"
					variant="contained"
					onClick={handleSubmit}
					startIcon={isLoading && <ButtonLoader />}
				>
					Save
				</Button>
			}
		>
			<Box sx={ sx.content }>
				{isLoading ? (
					<ProgressLoader />
				) : (
					<CollectionForm
						collection={collection}
						handleChange={handleChange}
					/>
				)}
			</Box>
		</Modal>
	)
}

export default CollectionModal

const sx = {
	content: {
		p: 2,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}
