import React, { useState } from "react";
import { Container } from "@mui/material";
import { Section } from 'components'
import { useAlerts } from 'hooks'
import { SubscriptionPlanCard, SwitchInput } from 'components'
import { Box, Grid } from '@mui/material'

export default function SectionOne({ 
    handleClick, 
    subscriptionPlans,
    ...props
  }) {  
  
  const [hosting, setHosting] = useState('cloud')
  const handleSignupClick = () => {
    console.log("signup")    
  }
  
  return (
  <Section bgColor='common.section' styles={ sx.section }>
    <Container maxWidth="lg">
      <center>
        <Box sx={ sx.input }>            
          <SwitchInput
            placeholder={ hosting ? "Cloud-hosting (we host)" : "Self-hosting (you host)" }
            name='cloud_hosting'
            value={ hosting }
            handleChange={() => setHosting(!hosting) }
          />
        </Box>
      </center>
      <Grid container spacing={1}>
        { subscriptionPlans.map(plan => (
          <Grid item xs={12} sm={12} md={4} key={plan.id}> 
            <SubscriptionPlanCard
              key={plan.id}
              plan={ plan }
              popular={ plan.popular }
              hosting={ hosting ? 'cloud' : 'self_hosting' }
              handleClick={ handleSignupClick }
            />
          </Grid> 
        ))}
      </Grid>
    </Container>
  </Section>
  );
}

const sx = {
  root: {},
  input: {
    maxWidth: 200
  }
};
