import React, { useState, useEffect } from 'react'
import { 
  useTabs,
  useProjects,
  useActiveProject, 
	useHeroku,
	useHerokuOAuth,
	useOAuth,
} from 'hooks'
import { useHistory } from 'react-router-dom'
import {  
  OnboardingLayout,
  Autosuggest,
  ButtonLoader,
  ListItemButton,
	Panel,
	PanelHeader,
  ProgressLoader
} from 'components'
import { Button, Box, Typography, List } from '@mui/material'
import { isHerokuAuthenticated } from 'lib/helpers/heroku'
import HerokuOAuthButton from 'containers/main/auth/oauth/HerokuOAuthButton'
import { ChevronRight } from '@mui/icons-material'
import { UploadCloud, Database } from 'react-feather'
import ProjectSteps from 'containers/main/projects/onboarding/ProjectSteps'

const ProjectCloudHosting = ({ ...props }) => {
	useTabs('projects')

  const step = 1

	const history = useHistory()
	const { id: projectId } = props.match.params

  const { 
    isLoading: isProjectLoading, 
    updateProject, 
    deployProject 
  } = useProjects({
    id: activeProject?.id 
  })

	const { 
    activeProject, 
    setActiveProject,
    findActiveProject 
  } = useActiveProject(projectId)

  const [authorized, setAuthorized] = useState()
	const [activeTeam, setActiveTeam] = useState()

	const { 
    isLoading, 
    teams, 
    listTeams 
  } = useHeroku({
	  projectId: activeProject?.id
	})

	const { onHerokuComplete } = useHerokuOAuth()

	const handleHerokuComplete = async (message) => {
		let resp = await onHerokuComplete(message, {
			project_id: activeProject?.id,
		})
		if (resp?.id) {			
			await findActiveProject()
		}
	}

	useOAuth({
		provider: 'heroku',
		onComplete: handleHerokuComplete,
	})

  const handleChangeHosting = async (value) => {
    let updatedProject = await updateProject({
      ...activeProject,
      hosting: value 
    })
    setActiveProject(updatedProject)
  }

  const handleSubmit = async () => {
    if(activeProject.hosting === 'self_hosted'){
      await deployProject(activeProject?.id, activeTeam)
      history.push(`/projects/${activeProject?.id}/onboarding/step4`)    
    }else{
      history.push(`/projects/${activeProject?.id}/onboarding/step3`)    
    }
  }

	const handleTeamChange = (ev) => {
		setActiveTeam(null)
		const { value } = ev.target
		setActiveTeam(value)
	}


  const { hosting } = activeProject || {}

  const disabledButton = !(hosting === 'cloud') && !(hosting === 'self_hosted' && activeTeam && authorized)

  useEffect(() => {    
    if(hosting === 'self_hosted' && isHerokuAuthenticated(activeProject)) {      
      listTeams()
      setAuthorized(true)
    }    
    if(activeProject?.heroku_app_id){
      history.push(`/projects/${activeProject?.id}/onboarding/step3`)
    }
	}, [activeProject])

	return (
    <OnboardingLayout>
      <ProjectSteps step={step} />
      <PanelHeader 
        title="Project Hosting" 
        actions={          
          <Button   
            fullWidth
            sx={{ 
              ...sx.button, 
              ...(disabledButton && sx.disabledButton) 
            }}
            disabled={ disabledButton }
            variant="contained" 
            color="primary"
            onClick={ handleSubmit }
            startIcon={ isProjectLoading ? <ButtonLoader /> : null }
            endIcon={ <ChevronRight /> }
          >
            Next  
          </Button>  
        }
      />
      <Panel>
        <Typography variant="subtitle2">
          Select hosting
        </Typography>  
        <Typography variant="body1" color="textSecondary">
          Connect your Heroku account to deploy to your own server. You can also select Cloud Hosting 
          to host with Adminly.
        </Typography>
        <List>
          <ListItemButton
            icon={UploadCloud}
            onClick={() => handleChangeHosting('cloud')}
            primary="Cloud Hosting"
            secondary="Cloud-hosting plans start at $49/month."
            selected={hosting === 'cloud'}
          />
          <ListItemButton
            onClick={() => handleChangeHosting('self_hosted')}
            icon={Database}
            primary="Self-Hosting (Heroku)"
            secondary={
              "Connect and deploy to your Heroku account. Free for 5 users."
            }
            selected={hosting === 'self_hosted'}
          />
        </List>
      </Panel>      

      { hosting === 'self_hosted' && (
        <Panel>
          <Typography variant="subtitle2">
            Connect with Heroku 
          </Typography>  
          <Typography variant="body1" color="textSecondary">
            Connect your Heroku account to deploy to your own server. 
          </Typography>

          <Box mt={2}>
            { authorized && (
              <Box mb={2}>
                { teams ? (                    
                  <Autosuggest
                    value={activeTeam}
                    name="team_name"
                    placeholder="Heroku team"
                    options={teams?.map((team) => ({
                      value: team.name,
                      label: team.name,
                    }))}
                    handleChange={handleTeamChange}
                  />
                ) : (
                  <Box>
                    <ProgressLoader />
                  </Box>
                )}
              </Box>
            )}   
            <HerokuOAuthButton />
          </Box>  
        </Panel>
      )}
    </OnboardingLayout>
	)
}

export default ProjectCloudHosting

const sx = {
  heroku: {
    borderRadius: theme => theme.shape.borderRadius,
    p: 2,
    backgroundColor: 'common.gradient'
  },
  disabledButton: {
    opacity: 0.6,
    color: `common.white`
  }
}
