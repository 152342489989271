import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { AppContext } from 'context'
import { BackButton, ProgressLoader } from 'components'
import { Avatar, Box, Typography } from '@mui/material'


const PanelHeader = ({
	title,
	image,
	actions,
  primaryActions,
  enableBackButton=false
}) => {

	return (
		<Box sx={ sx.root }>
			<Box sx={ sx.toolbar }>        
        { enableBackButton && (
				  <BackButton />
        )}
        { primaryActions && primaryActions }
				<Box>
					{image && (
						<Avatar
							variant="rounded"
							sx={ sx.avatar }
						>
							<img
								src={image}
								alt={title}
								sx={ sx.image }
							/>
						</Avatar>
					)}
				</Box>
				<Box sx={ sx.titleBar }>
					<Typography
						variant="h4"
						sx={ sx.title }
					>
						{title}
					</Typography>
				</Box>
			</Box>
			{actions && (
				<Box sx={ sx.actions}>{actions }</Box>
			)}
		</Box>
	)
}

export default PanelHeader

const sx = {
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
    alignItems: 'center',
		minHeight: 60,    
		width: '100%',    
	},
	avatar: {
		mt: 2,
    mr: 0,
    mb: 2,
    ml: 2,
		backgroundColor: 'common.white',
		boxShadow: `rgb(0 0 0 / 10%) 0px 2px 4px !important`,
	},
	toolbar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
    height: '100%',
	},
	titleBar: {
    height: '100%',
    display: 'flex',
		flexDirection: 'row',
    alignItems: 'center',
    py: { xs: 2 },
    px: { xs: 0, sm: 0 }
	},
	title: {
		lineHeight: 1.0,
    fontSize: {
      sm: 26,
      sx: 18
    },
    wordBreak: 'break-word'
	},
	actions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		gap: '5px',
		mr: 0.5,
	},
	image: {
		height: 32,
		width: 32,
	},
}
