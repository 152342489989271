import React, { useState, useEffect } from 'react'
import { useTabs, useProjects, useAlerts, useAdminly } from 'hooks'
import { useHistory } from 'react-router-dom'
import {
	ProgressLoader,
} from 'components'
import { Button, Box, Typography } from '@mui/material'
import { useActiveProject } from 'hooks'

const ProjectDeployStatus = ({ projectId, ...props }) => {
	useTabs('projects')

	const POLL_INTERVAL = 3000

	const history = useHistory()
	const { activeProject } = useActiveProject(projectId)
	
	const [status, setStatus] = useState(false)
	const [statusText, setStatusText] = useState('Loading ...')
	const [intervalId, setIntervalId] = useState()
	const [loading, setLoading] = useState(false)

	const { checkDeployStatus, syncSchema } = useProjects({})

	const handleCancelClick = () => {
		history.push('/projects')
	}

	const handleSyncSchema = async () => {
		setStatusText('Synching schema ...')
		await syncSchema(activeProject?.id)
		setStatusText('Complete')
		setTimeout(() => history.push(`/projects/${activeProject.id}/onboarding/step5`), 2000)
	}

	const handleCheckDeploymentStatus = async () => {
		setLoading(true)
		let deployStatus = await checkDeployStatus(activeProject?.id)
    if(deployStatus?.deployed){
      setStatus(true)
		  setLoading(false)
    }				
	}

	const handlePollDeploymentStatus = async () => {
		let iid = setInterval(() => handleCheckDeploymentStatus(), POLL_INTERVAL)
		setIntervalId(iid)
	}

	useEffect(() => {
		if (activeProject?.id) {
			handlePollDeploymentStatus()
		}
	}, [activeProject?.id])

	useEffect(() => {
		if (status) {
			clearInterval(intervalId)
			handleSyncSchema()
		}
	}, [status])

  //Ensure to clear interval on unmount
  useEffect(() => {
    return () => intervalId && clearInterval(intervalId)
  }, [intervalId])
 
	return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        p={2}        
      >
        <Box width="80px" height="80px" m={2}>
          <ProgressLoader />
        </Box>
        <Typography variant="h4">
          {statusText}
        </Typography>        
        <Box maxWidth={400}>
          <Typography
            variant="body1"
            color="textSecondary"
          >
            This may take a few minutes. You will receive an email when the server is deployed. 
          </Typography>
        </Box>
      </Box>
	)
}

export default ProjectDeployStatus
