import React, { useContext, useState, useEffect } from 'react'
import { AppContext, ProjectContext } from 'context'
import PropTypes from 'prop-types'
import { useAlerts, useHeroku, useProjects } from 'hooks'
import { Box, Typography, Button } from '@mui/material'

import { 
  Panel, 
  PanelHeader, 
  PanelButton,
  ProgressLoader,
  DeleteWarningModal 
} from 'components'
import ProjectEnvForm from 'containers/main/projects/ProjectEnvForm'
import { useHistory } from 'react-router-dom'
import { Repeat, Database, Key } from 'react-feather'

const HerokuContainer = (props) => {

  const history = useHistory()

  const { setOpenMobile } = useContext(AppContext)
	const { activeProject } = useContext(ProjectContext)

  const { showAlertSuccess } = useAlerts()
  
  const [showEnvVars, setShowEnvVars] = useState(false)
  const [showRedeployModal, setShowRedeployModal] = useState(false)
  const [showRestartModal, setShowRestartModal] = useState(false)

  const { 
    isLoading,
    redeployProject,
    restartProject 
  } = useProjects({
    id: activeProject?.id
  })

	const { 
    env, 
    listEnv, 
    updateEnv, 
    handleChange 
  } = useHeroku({
	  projectId: activeProject?.id,
	})

  const handleRedeploy = async () => {
    await redeployProject(activeProject?.id)
    setShowRedeployModal(false) 
    showAlertSuccess("Project is being redeployed. This may take a few minutes.")       
  } 

  const handleRestart = async () => {
    await restartProject(activeProject?.id)
    setShowRestartModal(false)
    showAlertSuccess("Please wait for the servers to restart.")    
  } 

	const handleSubmit = async (name, value) => {
		await updateEnv({
			[name]: value,
		})
	}

	useEffect(() => {
		if(showEnvVars){
      listEnv()
    }
	}, [showEnvVars])

  useEffect(() => {
    setOpenMobile(true)
  }, [])

	return (
		<Box>
			<PanelHeader
        enableBackButton
				title="Heroku"
			/>
      <PanelButton title="Restart application"
        description="Restart your Heroku web application instances."
        actions={
          <Button             
            startIcon={ <Repeat size={20} sx={ sx.icon} /> }  
            variant="outlined"
            onClick={() => setShowRestartModal(true)}
          >
            Restart 
          </Button> 
        }
      />
      <PanelButton 
        title="Redeploy"
        description={"Redploy Adminly on your Heroku instance to get the latest updates. \
          This operation may take a few minutes to complete."
        } 
        actions={
          <Button 
            startIcon={ <Database size={20} sx={ sx.icon} /> }  
            variant="outlined"
            onClick={() => setShowRedeployModal(true)}
          >
            Redeploy 
          </Button>           
        }
      />         
      <Panel p={4} my={2}>
        <Box sx={ sx.panel }>
          <Box sx={ sx.panelContent  }>
            <Typography variant="h5">
              Advanced settings 
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Manually update your Heroku environment variables.              
            </Typography>
          </Box>
          <Box>
            <Button 
              startIcon={ <Key size={20} sx={ sx.icon} /> }  
              variant="outlined"
              onClick={() => setShowEnvVars(!showEnvVars)}
            >
              Reveal
            </Button> 
          </Box>
        </Box> 
        { showEnvVars && (
        <Box py={4}>
          {env ? (
            <ProjectEnvForm
              env={env}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          ):(
            <Box sx={ sx.container }>
              <ProgressLoader />
            </Box>
          )}
        </Box>
        )}
			</Panel>
      <DeleteWarningModal 
        isLoading={isLoading}
        open={showRedeployModal}
        onClose={() => setShowRedeployModal(false)}
        onConfirm={ handleRedeploy }
      />
       <DeleteWarningModal 
        isLoading={isLoading}
        open={showRestartModal}
        onClose={() => setShowRestartModal(false)}
        onConfirm={ handleRestart }
      />
		</Box>
	)
}

export default HerokuContainer

const sx = {
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
  panel: {
    display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
  },
  panelContent: {
    maxWidth: 540,
    pr: 2
  }
}
