import React, { useState } from 'react'
import {
	Panel,
	PanelHeader,
  OnboardingLayout,
  DeleteWarningModal
} from 'components'
import { Button } from '@mui/material'
import { useAlerts, useActiveProject, useProjects } from 'hooks'
import ProjectSteps from 'containers/main/projects/onboarding/ProjectSteps'
import ProjectDeployDetails from 'containers/main/projects/onboarding/ProjectDeployDetails'
import { Database } from 'react-feather'

const ProjectDeploy = ({ ...props }) => {
  const step = 3

  const { showAlertSuccess } = useAlerts()

  const { id: projectId } = props.match.params
  const { activeProject } = useActiveProject(projectId)

  const [showRedeployModal, setShowRedeployModal] = useState(false)

  const { 
    redeployProject,
  } = useProjects({
    id: activeProject?.id
  })

  const handleRedeploy = async () => {
    await redeployProject(activeProject?.id)
    setShowRedeployModal(false) 
    showAlertSuccess("Project is being redeployed. This may take a few minutes.")       
  } 
	
	return (
		<OnboardingLayout>			
      <ProjectSteps step={ step } />
      <PanelHeader         
        title="Deploy project"    
        actions={
          <Button 
            startIcon={ <Database size={20} sx={ sx.icon} /> }  
            variant="outlined"
            onClick={() => setShowRedeployModal(true)}
          >
            Redeploy 
          </Button>  
        }       
      />
      <Panel>
        <ProjectDeployDetails 
          projectId={ projectId}
        />
      </Panel>
      <DeleteWarningModal 
        open={showRedeployModal}
        onClose={() => setShowRedeployModal(false)}
        onConfirm={ handleRedeploy }
      />
		</OnboardingLayout>
	)
}

export default ProjectDeploy

const sx = {
}
