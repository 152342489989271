import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useAdminProjects, useAlerts } from 'hooks'
import {
	Box,
	Paper,
	Button,
	Typography,
	Container,
} from '@mui/material'

import { ImageUploader, Subheader } from 'components'
import { Check } from '@mui/icons-material'
import ProjectForm from 'containers/admin/projects/ProjectForm'

const ProjectEdit = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params

	const {
		projectId,
		isLoading,
		isLoaded,
		project,
		handleChange,
		updateProject,
		findProject,
		uploadFile,
		deleteFile,
	} = useAdminProjects({ id: id })

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleSaveClick = async () => {
		await updateProject(project)
		showAlertSuccess('Project has been updated')
		history.push('/projects')
	}

	const handleUploadImage = async (file) => {
		await uploadFile(file, 'image_url')
		findProject(id)
	}

	const handleDeleteImage = async (file) => {
		await deleteFile('image_url')
		findProject(id)
	}

	useEffect(() => {
		if (id) findProject(id)
	}, [])

	return (
		<>
			<Subheader
				title="Edit Project"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>,
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<ProjectForm
							project={project}
							handleChange={handleChange}
						/>
						<Box py={2}>
							<Button
								color="primary"
								variant="contained"
								startIcon={<Check />}
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	)
}

export default ProjectEdit
