import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'

import { PanelButton, PanelHeader, Panel, ButtonLoader } from 'components'
import { Check } from '@mui/icons-material'
import ProjectSettingsForm from 'containers/main/projects/ProjectSettingsForm'
import { Database } from 'react-feather'

const SettingsProjectDetails = ({
	isLoading,
	project,
	handleChange,
	handleSubmit,
	handleHerokuClick,
  handleSyncSchemaClick,
	...props
}) => {


	return (
		<Box>
			<PanelHeader
				title="Settings"
				enableBackButton
			/>
			<Panel>
				<ProjectSettingsForm
					project={project}
					handleChange={handleChange}
					handleHerokuClick={handleHerokuClick}
				/>
				<Box my={2}>
					<Button
						onClick={handleSubmit}
						color="primary"
						variant="contained"
						startIcon={
							isLoading ? <ButtonLoader /> : <Check />
						}
					>
						Save Project
					</Button>
				</Box>
			</Panel>
      <PanelButton 
        title="Update Schema"
        description="Sync Adminly to your latest database schema."
        actions={
          <Button 
            startIcon={ <Database size={20} sx={ sx.icon } /> }
            variant="outlined"
            onClick={ handleSyncSchemaClick }
          >
            Update Schema 
          </Button>
        }
      />
		</Box>
	)
}

SettingsProjectDetails.propTypes = {
	project: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
}

export default SettingsProjectDetails

const sx = {
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
  icon: {
    color: 'text.secondary'
  }
}

