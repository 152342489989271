import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMenu } from 'hooks'
import {
  Box,
	IconButton,
	Menu,
	MenuItem,
  Typography,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
  Switch
} from '@mui/material'

import { AvatarIcon, Label } from 'components'
import {
	MoreHoriz,
	DragIndicator,
} from '@mui/icons-material'
import { Edit, Trash } from 'react-feather'


const SortableField = ({
	item,
	onEdit,
  onDelete,
	onUpdate,
	className,
	iconClassName,
}) => {


  const [visible, setVisible] = useState(item.visible)

	const handleEditClick = (item) => {
		onEdit(item)
		closeMenu()
	}

  const handleDeleteClick = (item) => {
		onDelete(item)
		closeMenu()
	}


	const handleToggleVisibility = (item) => {    
		onUpdate({
			id: item.id,
			visible: !item.visible,
		})
		closeMenu()
	}

	const { 
    open, 
    anchorEl, 
    closeMenu, 
    toggleMenu 
  } = useMenu()

  const handleChangeVisibility = (ev) => {    
    setVisible(ev.target.checked)
    onUpdate({
			id: item.id,
			visible: ev.target.checked
		})
  }

	const renderMenu = () => (
		<Menu
			open={open}
			onClose={toggleMenu}
			anchorEl={anchorEl}
		>
			<MenuItem onClick={() => handleEditClick(item)}>
        <ListItemIcon>
          <Edit size={20} />
        </ListItemIcon>
        <ListItemText primary="Edit" />				
			</MenuItem>
      { onDelete && (
        <MenuItem onClick={() => handleDeleteClick(item)}>
          <ListItemIcon>
            <Trash size={20} />
          </ListItemIcon>
          <ListItemText primary="Delete" />				
        </MenuItem>      
      )}
		</Menu>
	)

	return (
		<ListItem
			sx={{ 
        ...sx.item, 
        ...(item.isDragging && sx.isDragging)
      }} 
		>
			<ListItemIcon>
				<DragIndicator
					sx={ sx.secondaryIcon }
				/>
			</ListItemIcon>
			<ListItemIcon>
				<AvatarIcon friendlyType={item?.friendly_type} />
			</ListItemIcon>
			<ListItemText
				primary={
          <Typography variant="body3">
            {item?.db_name} {item.is_required ? '*' : ''}
          </Typography>
        }
				secondary={
					<span>
						<Label label={item.db_type} />
						{item.required && <Label label={'required'} />}
					</span>
				}
			/>
			<ListItemSecondaryAction
				sx={ sx.secondaryActions }
			>
        <Switch          
          checked={visible}
          onChange={handleChangeVisibility}
          value={true}
        />
				<IconButton size="small" onClick={toggleMenu}>
					<MoreHoriz
						className={ sx.secondaryIcon }
					/>
				</IconButton>
				{renderMenu()}
			</ListItemSecondaryAction>
		</ListItem>
	)
}

SortableField.propTypes = {
	item: PropTypes.object.isRequired,
	onDelete: PropTypes.func,
	onEdit: PropTypes.func,
	className: PropTypes.string,
	iconClassName: PropTypes.string,
}

export default SortableField

const sx = {
	item: {
		p: 1,
		backgroundColor: 'common.white',
	},
	icon: {
		color: 'primary.main'
	},
	secondaryIcon: {
		color: 'secondary.main'
	},
	isDragging: {
		backgroundColor: 'common.primary',
		boxShadow: `0 0 10px 0 rgb(0,0,0,0.30)`,
	},
	visibilityIcon: {
		height: 20,
		width: 20,
		color: 'secondary.main'
	},
	secondaryActions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
	},
}
