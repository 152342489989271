import React, { useEffect, useContext } from 'react'
import { AuthContext } from 'context'
import { useAuth, useAlerts, useUsers } from 'hooks'
import { Box, Button } from '@mui/material'
import { Check } from '@mui/icons-material'
import {
  PanelLayout,
  LeftPanel,
  CenterPanel,
	ImageUploader,
	PanelHeader,
	Panel,
} from 'components'
import { useHistory } from 'react-router'
import AccountForm from 'containers/main/users/AccountForm'
import SettingsLeftPanel from 'containers/main/settings/SettingsLeftPanel'

const UserEdit = ({ ...props }) => {
	const { id } = props.match.params

	const {
		user,
		handleChange,
		fetchMe,
		updateMe,
		uploadAvatar,
		deleteAvatar,
	} = useAuth({ id: id })	

	const { showAlertSuccess } = useAlerts()

	const handleSaveClick = async () => {
		await updateMe(user)
		showAlertSuccess('User has been updated')
	}

	const handleUploadAvatar = async (file) => {
		await uploadAvatar(file)
		fetchMe()
	}

	const handleDeleteAvatar = async (file) => {
		await deleteAvatar()
		fetchMe()
	}

	const handleFindUser = async () => {
		const resp = await fetchMe()
	}

	useEffect(() => {
		if (id) {
			handleFindUser()
		}
	}, [id])

	return (
    <PanelLayout>
      <LeftPanel>
        <SettingsLeftPanel 
          activeTab={'my_account'} 
        />
      </LeftPanel>      
      <CenterPanel>
        <PanelHeader 
          enableBackButton
          title="My Account" 
          actions={            
            <Button
              color="primary"
              variant="contained"
              startIcon={<Check />}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          }
        />
        <Panel p={4}>
          <center>
            <ImageUploader
              imageUrl={user.avatar}
              handleUpload={handleUploadAvatar}
              handleDelete={handleDeleteAvatar}
            />
          </center>
          <AccountForm
            user={user}
            handleChange={handleChange}
          />
        </Panel>
      </CenterPanel>
    </PanelLayout>
	)
}

export default UserEdit
