import React from 'react'
import {
  FlashOn,
  OndemandVideoOutlined,
  BarChart,
  PhoneIphone,
  LockOutlined
} from "@mui/icons-material";
import { Send, Users, Database, Search } from 'react-feather'

export const HOME_FEATURES = [
  {
    title: "Easily connect to any relational database",
    description: "Built to work with popular relational databases",
    icon: Database,
  },
  {
    title: "No-code required",
    description: "Get setup in under 60 seconds without any coding.",
    icon: FlashOn 
  },
  {
    title: "Rich Media",
    description: "Upload, store and browse image and video.",
    icon: OndemandVideoOutlined 
  },
  {
    title: "Full-text search",
    description: "Search data in any table using native PostgreSQL search.",
    icon: Search 
  },
  {
    title: "Mobile responsive",
    description: "Easily manage your data on the go with your mobile device",
    icon: PhoneIphone
  },
  {
    title: "Analytics",
    description: "Gain quick insights using our analytics engine.",
    icon: BarChart 
  },
  {
    title: "Collaboration",
    description: "Invite team members and easily collaborate on your data.",
    icon: Users
  },
  {
    title: "Security",
    description: "Access control and read-write permission management.",
    icon: LockOutlined 
  },
  {
    title: "Sharing",
    description: "Create password-protected views to share data outside the organization.",
    icon: Send 
  },
]

export const PRICING_FAQ = [
  {
    question: "What is the difference between a free plan and a paid plan?",
    answer: "The key difference is that the paid plans are cloud-hosted on all tiers \
      and support all features. The free plan is a self-hosted plan that is only available \
      to the owner of the account. The free plan is ideal for personal use and small teams \
      that want to get started quickly.",
  },
  {
    question: "How do I upgrade my plan?",
    answer: "You can upgrade your plan at any time. You can upgrade to a paid plan \
      by clicking the upgrade button on the top right of the page. You can also upgrade to \
      a paid plan by contacting us at info@adminly.com.",
  },
  {
    question: "How do I downgrade my plan?",
    answer: "You can downgrade your plan at any time. You can downgrade to a free \
      plan by clicking the downgrade button on the top right of the page. You can also \
      downgrade to a free plan by contacting us at info@adminly.com.",      
  },
  {
    question: "How do I cancel my plan?",
    answer: "You can cancel your plan at any time. You can cancel your plan by \
      clicking the cancel button on the top right of the page. You can also cancel your \
      plan by contacting us at info@adminly.com.",
  },
  {
    question: "Is there a free trial for my plan?",
    answer: "Yes, there is a free trial period for all plans. You can cancel your \
      free trial at any time by clicking the cancel button on the top right of the page. \
      You can also cancel your free trial by contacting us at info@adminly.com.",
  },
  {
    question: "Does Adminly work with my existing database?",
    answer: "Yes, Adminly works with all popular relational databases, including PostgreSQL, MySQL and Maria DB. \
      You can connect to your existing database by clicking the connect button when you start \
      a new project. Adminly currently does not support NoSQL databases. "
  },
  {
    question: "Does Adminly support access-control?",
    answer: "Yes, Adminly supports access-control. You can manage access-control \
      from the team page from within project settings.",    
  }
]