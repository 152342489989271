import React from 'react'
import { Grid } from '@mui/material'
import { TextInput, NoSpaceInput } from 'components'

const ProjectForm = ({ folder, handleChange }) => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={12}>
				<NoSpaceInput
					label="Name"
					value={folder.name || ''}
					name="name"
					placeholder="Name"
					handleChange={handleChange}
				/>
			</Grid>
		</Grid>
	)
}

export default ProjectForm
