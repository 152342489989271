import React, { useState, useEffect } from 'react'
import { Box, Button } from '@mui/material'

const SocialLoginButton = ({
	icon,
	label = 'Sign In',
	authorizationUrl,
	provider,
	onComplete,
	styles,
  variant,
	height = 600,
	width = 600,
  fullWidth=false,
	...rest
}) => {


	const [popup, setPopup] = useState()

	// Post the code retrieved from OAuth back to the parent window
	const handleLoginClick = () => {
		let win = window.open(
			authorizationUrl,
			'targetWindow',
			`toolbar=no,
        location=no,
        left=400,
        top=100,
        toolbar=no,
        titlebar=no,
        status=no,
        menubar=no,
        scrollbars=yes,
        resizable=yes,
        width=${width},
        height=${height}`
		)
		win.document.title = label
		setPopup(win)
	}

	return (
		<Box sx={ sx.root }>
			<Button
        variant={variant}
        fullWidth={fullWidth} 
				onClick={handleLoginClick}
				sx={{ ...sx.button, ...styles }}
				startIcon={icon && icon}
			>
				{label}
			</Button>
		</Box>
	)
}

export default SocialLoginButton

const sx = {
	root: {},
	button: {
		'&&': {
			height: 36,
		},
	},
}
