import React from 'react'
import { Toolbar } from 'components'

const ProjectToolbar = ({
	totalSelected,
	selectedIds,
	selected,
	handleClear,
}) => (
	<Toolbar
		open={selectedIds?.length > 0}
		handleClose={handleClear}
	></Toolbar>
)

export default ProjectToolbar
