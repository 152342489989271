import React, { useContext, useEffect } from 'react'
import { ProjectContext, ApiContext } from 'context'
import { useAlerts, useLoaders, useResource } from 'hooks'

const DEFAULT_HEROKU_TEAM = 'adminly-cloud'

const useProjects = ({ id, ...props }) => {
	const { api } = useContext(ApiContext)

	const { showLoading, hideLoading } = useLoaders()
	const { showAlertError } = useAlerts()

  const { projects, setProjects } = useContext(ProjectContext)

	const {
		resourceId: projectId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: project,
		resources,
		findOne: findProject,
		findMany: findProjects,
		update: updateProject,
		create: createProject,
		save: saveProject,
		destroy: deleteProject,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reload: reloadProject,
		reloadMany: reloadProjects,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		setResource: setProject,
	} = useResource({
		id: id,
		url: '/api/v1/projects',
		name: 'project',
	})

	const syncSchema = async (projectId) => {
		try {
			showLoading()
			const resp = await api.post(`/api/v1/projects/${projectId}/sync_schema`)
			return resp.data
		} catch (e) {
			showAlertError(
				'There was an error synching the database'
			)
		} finally {
			hideLoading()
		}
	}

  const syncCollections = async (collectionNames) => {
		try {
			showLoading()
			const resp = await api.post(`/api/v1/projects/${id}/sync_collections`, {
        project: {
          collection_names: collectionNames
        }
      })
			return resp.data
		} catch (e) {
			showAlertError(
				'There was an error synching the database'
			)
		} finally {
			hideLoading()
		}
	}


	const rebuildSchema = async (projectId) => {
		try {
			showLoading()
			const resp = await api.post(
				`/api/v1/projects/${projectId}/rebuild_schema`
			)
			return resp.data
		} catch (e) {
			showAlertError(
				'There was an error rebuilding the database'
			)
		} finally {
			hideLoading()
		}
	}

  const deployProject = async (projectId, teamName=DEFAULT_HEROKU_TEAM) => {
		try {
      showLoading()
			const resp = await api.post(`/api/v1/projects/${projectId}/deploy`, {
        project: {
          team_name: teamName,
        }
      })
			return resp.data
		} catch (e) {
			showAlertError('There was an error deploying the heroku project')
		} finally {
      hideLoading()
    }
  }

	const checkSchemaStatus = async (projectId) => {
		try {
			showLoading()
			const resp = await api.get(
				`/api/v1/projects/${projectId}/schema_status`
			)
			return resp.data
		} catch (e) {
			showAlertError(
				'There was an error checking the schema status'
			)
		} finally {
			hideLoading()
		}
	}

  const checkDeployStatus = async (projectId, enableLoading=false) => {
		try {
			if(enableLoading) showLoading();
			const resp = await api.get(
				`/api/v1/projects/${projectId}/deploy_status`
			)
			return resp.data
		} catch (e) {
			showAlertError(
				'There was an error checking the deploy status'
			)
		} finally {
			if(enableLoading) hideLoading();
		}
	}

  const redeployProject = async (projectId) => {
		try {
			showLoading()
			const resp = await api.post(`/api/v1/projects/${projectId}/redeploy`)
			return resp.data
		} catch (e) {
			showAlertError('There was an error redeploying the project')
		} finally {
			hideLoading()
		}
	}

  const restartProject = async (projectId) => {
		try {
			showLoading()
			const resp = await api.post(`/api/v1/projects/${projectId}/restart`)
			return resp.data
		} catch (e) {
			showAlertError('There was an error restarting the project')
		} finally {
			hideLoading()
		}
	}

  useEffect(() => {    
    setProjects(resources)    
  }, [resources])

	return {
		projectId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		project,
		projects,
		findProject,
		findProjects,
		saveProject,
		updateProject,
		createProject,
		deleteProject,
		uploadFile,
		deleteFile,
    deployProject,
    redeployProject,
    restartProject,
    checkDeployStatus,
		checkSchemaStatus,
		handleChange,
		handleChangePage,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		reloadProject,
		reloadProjects,
		sortKey,
		sortDirection,
		handleSort,
		setProject,
		syncSchema,
    syncCollections,
		rebuildSchema,
	}
}

export default useProjects
