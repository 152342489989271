import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { 
  Panel,
  PanelHeader,
  ProgressLoader,
  ButtonLoader,
} from 'components'
import {
  useCollections,
  useAdminly,
  useAlerts,
  useMenu
} from 'hooks'
import { 
  Button, 
  Box, 
  Menu,
  MenuItem,
  ButtonGroup,
  IconButton } from '@mui/material'
import { 
  ExpandMore, 
  ChevronLeft,   
} from '@mui/icons-material'
import AdminlyForm from 'containers/main/adminly/AdminlyForm'
import { validateAdminlyResource } from 'validations/adminly'
import { useHistory  } from 'react-router-dom'

const AdminlyEditDetails = ({ 
  collectionId,
  projectId,
  resourceId,
  resourceIds,
  ...props
}) => {

  const history = useHistory()  
  
  const [title, setTitle] = useState(null)
  const [activeFields, setActiveFields] = useState(false)  
  const { showAlertError } = useAlerts()

  const {
    open,
    anchorEl,
    closeMenu,
    toggleMenu 
  } = useMenu()

  const {
    collection,
    findCollection 
  } = useCollections({
    projectId: projectId 
  })

  const {
    isLoading,
    resource,
    setResource,        
    findOne,    
    update,
    create,    
    updateMany,
    handleChange  
  } = useAdminly({
    collection: collection?.db_name 
  })

  const handleBackClick = () => {
    history.goBack()
  }

  const handleSubmit = async () => {
		let validate = validateAdminlyResource(resource, activeFields)
		let resp
		if (validate.isValid) {
      if(resourceIds) {                
        resp = await updateMany(resourceIds, resource)  
      } else if (resource?.id) {
        resp = await update(resource)
        findOne(resp.id)      
      }else{
        resp = await create(resource)
        findOne(resp.id)
      }
      return resp 
		} else {
			validate.messages.map((m) => showAlertError(m))
		}
	}

  const handleSubmitAndBack = async () => {
    let resp = await handleSubmit() 
    if(resp){
      history.goBack()
    }
  }

  const handleSubmitAndNew = async () => {
    let resp = await handleSubmit() 
    if(resp){
      window.location.reload()
    }
  }

  const handleSortFields = () => {    
    let sortedFields = collection.fields
        .filter(field => field.visible === true)
        .sort((a, b) => a.position - b.position)
    setActiveFields(sortedFields)
  }

  useEffect(() => {
    if(projectId && collectionId){
      findCollection(collectionId)
    }
  }, [projectId, collectionId])

  useEffect(() => {
    if(collection?.id){      
      handleSortFields()
    }
  }, [collection?.id])

  useEffect(() => {
    if(collection?.id){
      findOne(resourceId)
    }
  }, [collection?.id])

  useEffect(() => {
    if(!resourceId){
      setResource({})
    }
  }, [resourceId])

  const renderMenu = () => (
    <Menu
      open={ open }
      anchorEl={ anchorEl }
      onClose={ closeMenu }
    >
      <MenuItem onClick={handleSubmitAndBack}>
        Save and go back 
      </MenuItem>
      <MenuItem onClick={handleSubmitAndNew}>
        Save and new 
      </MenuItem>
    </Menu>
  )

  useEffect(() => {
    const { name_singular, name_plural } = collection || {}
    if(resourceId && name_singular){
      setTitle(`Edit ${name_singular}`)
    }else if(resourceIds && name_plural){
      setTitle(`Edit ${name_plural}`)
    }else if((!resourceId && !resourceIds) && name_singular){
      setTitle(`Add ${name_singular}`)
    }
  }, [resourceId, resourceIds, collection])

  return(
    <Box sx={ sx.root }>
      <PanelHeader 
        title={ title ? title : 
          <ProgressLoader styles={ sx.progressLoader } size={24} /> 
        }
        primaryActions={
          <Box sx={ sx.goBack }>				
            <IconButton
              size="small"
              onClick={handleBackClick}
              sx={ sx.backButton }
            >
              <ChevronLeft sx={ sx.icon } />
            </IconButton>				
          </Box>  
        }
        actions={ 
          <ButtonGroup>
            <Button 
              variant="contained"
              onClick={ handleSubmit }
            > 
              Save  
            </Button> 
            <Button 
              sx={ sx.expandMoreButton }
              variant="contained"
              onClick={ toggleMenu }
            >
              <ExpandMore /> 
            </Button>
            { renderMenu() }
          </ButtonGroup>
        }
      />      
      <Panel p={4}>
        { !isLoading ? (
          <Box sx={ sx.list  }>
            { resource && 
              collection && 
              activeFields && (
              <>
                <AdminlyForm
                  collection={collection}
                  resource={resource}
                  fields={activeFields}
                  handleChange={handleChange}
                  defaultOpen={ resourceIds ? false : true }
                />    
                <Button
                  sx={ sx.button }
                  color="primary"
                  startIcon={isLoading && <ButtonLoader />}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Save
                </Button>          
              </>
            )}        
          </Box>
        ):(
          <Box sx={ sx.loader }>
            <ProgressLoader />
          </Box>
        )}      
      </Panel>
    </Box>
  )
}

AdminlyEditDetails.prototype = {
  collectionId: PropTypes.string,  
  resourceId: PropTypes.string,
  projectId: PropTypes.string
}

export default AdminlyEditDetails 

const sx = {
  backButton: {
    p: 0
  },
  icon: {
    height: 36,
    width: 36
  } ,
  expandMoreButton: {
    width: 32
  }
}
