import React, { useContext, useEffect } from 'react'
import { AuthContext } from 'context'
import { useHistory } from 'react-router'
import { useUsers, useAlerts } from 'hooks'
import {
	Box,
	Paper,
	Button,
	Typography,
	Container,
} from '@mui/material'

import { Edit } from '@mui/icons-material'
import { Subheader } from 'components'
import UserDetails from 'containers/main/users/UserDetails'

const UserShow = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params

	const { currentUser } = useContext(AuthContext)

	const { userId, isLoading, isLoaded, user, findUser } =
		useUsers({ id: id })

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleEditClick = () =>
		history.push(`/users/${id}/edit`)

	useEffect(() => {
		if (id) findUser(id)
	}, [])

	return (
		<>
			<Subheader
				title={isLoading ? 'Loading...' : `${user.name}`}
				buttons={[
					currentUser?.id == id && (
						<Button
							variant="contained"
							onClick={handleEditClick}
							color="primary"
						>
							Edit
						</Button>
					),
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<UserDetails user={user} />
					</Box>
				</Paper>
			</Container>
		</>
	)
}

export default UserShow
