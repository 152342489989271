import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
	useAlerts,
	useMenu,
	useCollections,
	useProjects,
} from 'hooks'
import {
	Menu,
	MenuItem,
	Box,
	List,
} from '@mui/material'
import { ProjectContext } from 'context'

import {
  LeftPanelHeader,
	DeleteWarningModal,
	SubmenuItem,
} from 'components'
import { Database } from 'react-feather'
import CollectionModal from 'containers/main/collections/CollectionModal'
import { validateCollection } from 'validations/collections'
import { MoreHoriz } from '@mui/icons-material'

const SchemaLeftPanel = ({
	isLoading,
	collections,
	activeCollection,
	handleClick,
	handleReload,
	...props
}) => {


	const [showModal, setShowModal] = useState(false)	
	const [showResyncModal, setShowResyncModal] = useState(false)

	const { showAlertSuccess, showAlertError } = useAlerts()
	const { activeProject } = useContext(ProjectContext)
	
  const { 
    anchorEl, 
    open, 
    toggleMenu, 
    closeMenu 
  } = useMenu()

	const { isLoading: isProjectLoading, rebuildSchema } =
		useProjects({
			id: activeProject?.id,
		})

	const {
		isLoading: isCollectionLoading,
		collection,
		updateCollection,
		handleChange,
		setCollection,
	} = useCollections({ projectId: activeProject?.id })

	const handleShowEditModal = (collection) => {
		setCollection(collection)
		setShowModal(true)
	}

	const handleSaveCollection = async () => {
		let newCollection
		let validate = validateCollection(collection)
		if (validate.isValid) {
			newCollection = await updateCollection(collection)
			if (newCollection?.id) {
				setShowModal(false)
				showAlertSuccess('Collection successfully updated')
			} else {
				showAlertSuccess(
					'There was an error, please try again.'
				)
			}
			handleReload()
		} else {
			validate.messages.map((m) => showAlertError(m))
		}
	}

	const handleResyncSchema = async () => {
		await rebuildSchema(activeProject?.id)
		setShowResyncModal(false)
	}

	const renderMenu = () => (
		<Menu
			open={open}
			onClose={closeMenu}
			anchorEl={anchorEl}
		>
			<MenuItem onClick={() => setShowResyncModal(true)}>
				Resync Schema
			</MenuItem>
		</Menu>
	)

	return (
		<Box sx={ sx.root }>
      <LeftPanelHeader 
        title="Schema" 
        icon={ Database }
      />
			<List sx={ sx.list }>
				{collections.map((collection, i) => (
					<SubmenuItem
						key={i}
						showMenu
						label={collection?.name}
						value={collection?.id}
						selected={activeCollection?.id}
						handleClick={() => handleClick(collection)}
						handleEditClick={() =>
							handleShowEditModal(collection)
						}
					/>
				))}
			</List>
			<CollectionModal
				isLoading={isCollectionLoading}
				open={showModal}
				collection={collection}
				handleClose={() => setShowModal(false)}
				handleChange={handleChange}
				handleSubmit={handleSaveCollection}
			/>
			<DeleteWarningModal
				isLoading={isProjectLoading}
				open={showResyncModal}
				onClose={() => setShowResyncModal(false)}
				onConfirm={handleResyncSchema}
			/>
		</Box>
	)
}

SchemaLeftPanel.propTypes = {
	className: PropTypes.string,
	collections: PropTypes.array,
	activeCollection: PropTypes.object,
	handleAddCollection: PropTypes.func,
}

export default SchemaLeftPanel

const sx = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	button: {
		m: 1,
		color: 'text.secondary'
	},
	list: {
		width: '100%',
		p: 1,
	},
}
