import React, { useContext, useEffect, useState } from 'react'
import { ProjectContext } from 'context'
import { Box, Grid } from '@mui/material'
import { useCollections } from 'hooks'
import { orderBy } from 'lodash'
import { EXCLUDED_INPUT_FIELDS } from 'lib/constants'
import AdminlyInput from 'containers/main/adminly/AdminlyInput'
import CloudinaryBrowseModal from 'containers/apps/cloudinary/CloudinaryBrowseModal'
import AdminlyTableModal from 'containers/main/adminly/AdminlyTableModal'

const AdminlyForm = ({
	fields,
	resource,
	handleChange,
  defaultOpen,
}) => {

	const [mediaOpen, setCloudinaryOpen] = useState(false)
	const [showTableModal, setShowTableModal] = useState(false)

	const [activeFieldName, setActiveFieldName] = useState()
	const [defaultQuery, setDefaultQuery] = useState()

  const [visibleFields, setVisibleFields] = useState([])

	const { activeProject } = useContext(ProjectContext)

	const { collection: foreignCollection, findCollection } =
		useCollections({
			projectId: activeProject?.id,
		})

	const handleBrowseCloudinary = (fieldName) => {
		setActiveFieldName(fieldName)
		setCloudinaryOpen(!mediaOpen)
	}

	const handleBrowseDataTable = async ({
		name: field,
		value,
		foreignCollectionId,
	}) => {
		setActiveFieldName(field)
		// we assume all primary keys are 'id'
		const query = {
			filters: [
				{
					field: 'id',
					operator: 'eq',
					value: value,
				},
			],
		}
		setDefaultQuery(query)
		await findCollection(foreignCollectionId)
		setShowTableModal(!showTableModal)
	}

	const handleOnRowClick = (row) => {    
		handleChange({
			target: {
				name: activeFieldName,
				value: row?.id,
			},
		})
		setShowTableModal(false)
	}

	const handleCloudinaryClick = (item) => {
		handleChange({
			target: {
				name: activeFieldName,
				value: item?.url,
			},
		})
		setCloudinaryOpen(false)
	}

  useEffect(() => {
    if(fields){
      let filteredFields = fields
        .filter(f => f.field_type === 'column')
        .filter(f => !EXCLUDED_INPUT_FIELDS.includes(f.db_name))
      let sortedFields = filteredFields.sort((a, b) => a.position - b.position)      
      setVisibleFields(sortedFields)
    }
  }, [fields])

	return (
		<Box>
			<Grid container spacing={0}>
				{ visibleFields && 
          visibleFields.map((field, i) => {
						return (
							<Grid key={i} item xs={12} sm={12}>
								{resource && field && (
									<AdminlyInput
                    defaultOpen={ defaultOpen }
                    field={ field }
										value={resource[field?.db_name] || ''}
										placeholder={`${field.name} ${field.required ? '*' : ''}`}
										handleChange={handleChange}
										handleBrowseCloudinary={ handleBrowseCloudinary }
										handleBrowseDataTable={ handleBrowseDataTable }
									/>
								)}
							</Grid>
						)
					}
				)}
			</Grid>
			<CloudinaryBrowseModal
				open={mediaOpen}
				handleClose={() => setCloudinaryOpen(false)}
				handleClick={handleCloudinaryClick}
			/>
			<AdminlyTableModal
				open={showTableModal}
				collection={foreignCollection}
				handleClose={() => setShowTableModal(false)}
				onRowClick={handleOnRowClick}
				defaultQuery={defaultQuery}
			/>
		</Box>
	)
}

export default AdminlyForm
