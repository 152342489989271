import React from 'react'
import { IconButton, ProgressLoader } from 'components'
import { Box, CircularProgress, List } from '@mui/material'

import { ExpandMore } from '@mui/icons-material'
import FolderItem from 'containers/apps/cloudinary/Folders/FolderItem'
import {
	ArrowLeftCircle,
	ChevronLeft,
	FolderPlus,
} from 'react-feather'

const FolderList = ({
	isLoading,
	handleClick,
	handleAddClick,
	handleBackClick,
	handleDeleteClick,
	folders,
	findFolders,
	activeFolder,
	reloadFolders,
	loadMoreFolders,
	nextCursor,
	...props
}) => {


	return (
		<Box sx={ sx.root }>
			{folders && (
				<Box>
					<List disablePadding>
						{activeFolder && activeFolder?.name && (
							<FolderItem
								icon={ArrowLeftCircle}
								folder={{
									name: 'back',
									path: activeFolder?.path,
								}}
								handleClick={handleBackClick}
							/>
						)}
						{folders.map((folder, i) => (
							<FolderItem
								key={i}
								folder={folder}
								handleClick={handleClick}
								handleDeleteClick={handleDeleteClick}
							/>
						))}
						<FolderItem
							icon={FolderPlus}
							handleClick={handleAddClick}
							folder={{
								name: 'new folder',
								path: activeFolder?.path,
							}}
						/>
					</List>
					{nextCursor ? (
						<center>
							<Box my={2}>
								{isLoading ? (
									<CircularProgress 
                    disableShrink
                    color="secondary" 
                  />
								) : (
									<IconButton
										onClick={() =>
											loadMoreFolders(nextCursor)
										}
										icon={ExpandMore}
									/>
								)}
							</Box>
						</center>
					) : null}
				</Box>
			)}
		</Box>
	)
}

export default FolderList

const sx = {
	root: {},
}
