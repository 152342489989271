import React from 'react'
import { List } from '@mui/material'

import { LineItem } from 'components'

const ProjectDetails = ({ project }) => (
	<List>
		<LineItem label={'Title'} value={project.title} />
	</List>
)

export default ProjectDetails
