import { useContext, useEffect, useState } from 'react'
import { ApiContext } from 'context'
import { useAlerts, useLoaders } from 'hooks'

const useHeroku = ({ projectId, ...props }) => {
	const { api } = useContext(ApiContext)

	const { isLoading, showLoading, hideLoading } =
		useLoaders()
	const { showAlertError, showAlertSuccess } = useAlerts()

	const [addons, setAddons] = useState()
	const [env, setEnv] = useState()
	const [teams, setTeams] = useState()
	const [apps, setApps] = useState()

	const listApps = async (teamId) => {
		showLoading()
		try {
			const resp = await api.get(
				`/api/v1/apps/projects/${projectId}/heroku/list_apps`,
				{
					params: {
						team_id: teamId,
					},
				}
			)
			hideLoading()
			setApps(resp.data)
			return resp.data
		} catch (e) {
			showAlertError('There was an error listing apps.')
		} finally {
			hideLoading()
		}
	}

	const listTeams = async () => {
		try {
			const resp = await api.get(`/api/v1/heroku/list_teams`)
			setTeams(resp.data)
			return resp.data
		} catch (e) {
			showAlertError('There was an error listing teams.')
		} 
	}

	const listEnv = async () => {
		showLoading()
		try {
			const resp = await api.get(`/api/v1/projects/${projectId}/heroku/list_env`)
			setEnv(resp.data)
			return resp.data
		} catch (e) {
			showAlertError(
				'There was an error accessing the config vars.'
			)
		} finally {
			hideLoading()
		}
	}

	const updateEnv = async (config) => {
		showLoading()
		try {
			const resp = await api.post(`/api/v1/projects/${projectId}/heroku/update_env`, {
					config: config,
				}
			)
			return resp.data
		} catch (e) {
			showAlertError(
				'There was an error accessing the config vars.'
			)
		} finally {
			hideLoading()
		}
	}

	const listDbAddons = async () => {
		showLoading()
		try {
			const resp = await api.get(
				`/api/v1/apps/projects/${projectId}/heroku/list_db_addons`
			)
			setAddons(resp.data)
			return resp.data
		} catch (e) {
			showAlertError('There was an error listing addons.')
		} finally {
			hideLoading()
		}
	}

	const listAddons = async () => {
		showLoading()
		try {
			const resp = await api.get(
				`/api/v1/apps/projects/${projectId}/heroku/list_addons`
			)
			setAddons(resp.data)
			return resp.data
		} catch (e) {
			showAlertError('There was an error listing addons.')
		} finally {
			hideLoading()
		}
	}

	const createAddon = async (addon) => {
		showLoading()
		try {
			const resp = await api.post(
				`/api/v1/apps/projects/${projectId}/heroku/create_addon`,
				{
					addon_name: addon,
				}
			)
			return resp.data
		} catch (e) {
			showAlertError(
				'There was an error provisioning the addon.'
			)
		} finally {
			hideLoading()
		}
	}

	const removeAddon = async (addon) => {
		showLoading()
		try {
			const resp = await api.post(
				`/api/v1/projects/${projectId}/heroku/delete_addon`,
				{
					addon_name: addon?.addon_service?.name,
					addon_id: addon?.id,
				}
			)
			return resp.data
		} catch (e) {
			showAlertError(
				'There was an error removing the addon.'
			)
		} finally {
			hideLoading()
		}
	}

	const restartApp = async (addonId) => {
		showLoading()
		try {
			const resp = await api.get(
				`/api/v1/projects/${projectId}/heroku/restart`
			)
			return resp.data
		} catch (e) {
			showAlertError(
				'There was an error removing the addon.'
			)
		} finally {
			hideLoading()
		}
	}

	const handleChange = (ev) => {
		const { name, value } = ev.target
		setEnv({
			...env,
			[name]: value,
		})
	}

	return {
		addons,
		env,
		teams,
		apps,
		isLoading,
		listApps,
		listTeams,
		listEnv,
		updateEnv,
		listAddons,
		listDbAddons,
		createAddon,
		removeAddon,
		restartApp,
		handleChange,
		setEnv,
	}
}

export default useHeroku
