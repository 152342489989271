import { isEmpty } from 'validations'

export const validateAdminlyResource = (resource, fields) => {
	let isValid = true
	let messages = []

	fields.forEach((field, i) => {
		if (
			field.required == true &&
			isEmpty(resource[field.name])
		) {
			isValid = false
			messages.push(`${field.title} is required`)
		}
	})

	let validation = {
		isValid,
		messages,
	}

	return validation
}
