import React from 'react'
import PropTypes from 'prop-types'
import { Box, SvgIcon } from '@mui/material'
import {
	AlternateEmail,
	Image,
	AttachMoney,
	Code,
	Schedule,
	Link,  
	Title,
	PlusOne,
	List,
	Today,
	Place,
	ToggleOn,
	PlayArrow,
	StarBorderOutlined,	
	PhoneIphone,
	ViewHeadline,
  CompareArrows,
  ForkRight,
  TurnSlightRight
} from '@mui/icons-material'

const Icon = ({ friendlyType, size=24, ...props }) => {
	
	return (
		<>
      {friendlyType === 'email' && (
        <AlternateEmail sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'html' && (
        <Code sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'date' && (
        <Today sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'datetime' && (
        <Schedule sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'text' && (
        <Title sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'paragraph' && (
        <ViewHeadline sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'phone' && (
        <PhoneIphone sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'image' && (
        <Image sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'video' && (
        <PlayArrow sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'rating' && (
        <StarBorderOutlined sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'enumerable' && (
        <List sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'number' && (
        <PlusOne sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'float' && (
        <PlusOne sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'price' && (
        <AttachMoney sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'boolean' && (
        <ToggleOn sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'array' && (
        <List sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'location' && (
        <Place sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'link' && (
        <Link sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'json' && (
        <SvgIcon sx={{ ...sx.icon, width: size, height: size }}>
          <path d="M4,7v2c0,0.55-0.45,1-1,1H2v4h1c0.55,0,1,0.45,1,1v2c0,1.65,1.35,3,3,3h3v-2H7c-0.55,0-1-0.45-1-1v-2 c0-1.3-0.84-2.42-2-2.83v-0.34C5.16,11.42,6,10.3,6,9V7c0-0.55,0.45-1,1-1h3V4H7C5.35,4,4,5.35,4,7z" />
          <path d="M21,10c-0.55,0-1-0.45-1-1V7c0-1.65-1.35-3-3-3h-3v2h3c0.55,0,1,0.45,1,1v2c0,1.3,0.84,2.42,2,2.83v0.34 c-1.16,0.41-2,1.52-2,2.83v2c0,0.55-0.45,1-1,1h-3v2h3c1.65,0,3-1.35,3-3v-2c0-0.55,0.45-1,1-1h1v-4H21z" />
        </SvgIcon>
      )}

      {friendlyType === 'reference' && (
        <TurnSlightRight sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'has_many' && (
        <ForkRight sx={{ ...sx.icon, width: size, height: size }} />
      )}

      {friendlyType === 'habtm' && (
        <CompareArrows sx={{ ...sx.icon, width: size, height: size }} />
      )}
		</>
	)
}

Icon.propTypes = {
	friendlyType: PropTypes.string.isRequired,
}

export default Icon

const sx = {
	icon: {
		color: 'text.secondary'
	},
}
