import React from 'react'
import {
	PanelHeader,
  OnboardingLayout
} from 'components'
import { Button } from '@mui/material'
import { useProjects, useActiveProject, useAlerts } from 'hooks'
import SubscriptionPlans from 'containers/main/payments/SubscriptionPlans'
import { isPaid } from 'lib/helpers/utils'
import { useHistory } from 'react-router-dom'
import { ChevronRight } from '@mui/icons-material'
import ProjectSteps from 'containers/main/projects/onboarding/ProjectSteps'

const ProjectPayment = ({ ...props }) => {
  const step = 2

  const history = useHistory()

  const { showAlertError } = useAlerts()
  const { id: projectId } = props.match.params
  const { activeProject } = useActiveProject(projectId)  

  const { 
    deployProject 
  } = useProjects({
    id: activeProject?.id 
  })

  const handleNextClick = async () => {
    if(activeProject?.id && isPaid(activeProject)){
      await deployProject(activeProject?.id)
      history.push(`/projects/${projectId}/onboarding/step4`)
    }else{
      showAlertError("Please subscribe to continue.")
    }    
  } 

	return (
		<OnboardingLayout>
      <ProjectSteps step={ step } />
      <PanelHeader 
        title="Subscription plan"    
        actions={
          <Button               
            variant={ !isPaid(activeProject) ? 'outlined' : 'contained' }
            onClick={handleNextClick}            
            disabled={ !isPaid(activeProject) }
            endIcon={ <ChevronRight /> }
          >
            Next  
          </Button>  
        }       
      />
      <SubscriptionPlans 
        disableHosting 
        cloudHosted={activeProject?.hosting === 'cloud' ? true : false}
      /> 
		</OnboardingLayout>
	)
}

export default ProjectPayment

