import React, { useEffect, useState } from 'react'
import { Box, Typography, InputBase } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

const Autosuggest = ({
	value,
	options,
	label,
	name,
	placeholder = 'Select',
	multiselect = false,
	handleChange,
	handleInputChange,
	freeSolo = false,
	className,
	...props
}) => {
	const [selected, setSelected] = useState({
		label: '',
		value: null,
	})

	const handleOnChange = (event, newValue) => {
		setSelected(newValue)
		handleChange({
			target: {
				name: name,
				value: newValue?.value,
			},
		})
	}

	useEffect(() => {
		if (typeof value != 'object') {
			setSelected(options.find((o) => o.value == value))
		} else {
			setSelected(value)
		}
	}, [value])

	return (
		<Box sx={ sx.container }>
			<Typography
				variant="caption"
				color="textSecondary"
				sx={ sx.label }
			>
				{label}
			</Typography>
			<Autocomplete
				freeSolo={freeSolo}
				multiple={multiselect}
				disableCloseOnSelect={multiselect}
				sx={{
					paper: sx.paper,
					option: sx.option,
					popperDisablePortal: sx.popperDisablePortal,
				}}
				value={selected}
				onChange={(event, newValue) => {
					handleOnChange(event, newValue)
				}}
				onInputChange={(event, newInputValue) => {
					handleInputChange &&
						handleInputChange(newInputValue)
				}}
				noOptionsText="No options"
				clearOnBlur
				handleHomeEndKeys
				options={options}
				getOptionLabel={(option) => option?.label}
				getOptionSelected={(option, value) =>
					option?.label == value?.label
				}
				renderInput={(params) => (
					<InputBase
						placeholder={placeholder}
						ref={params.InputProps.ref}
						inputProps={{
							...params.inputProps,
							autoComplete: 'none',
						}}
						sx={ sx.inputBase }
					/>
				)}
			/>
		</Box>
	)
}

export default Autosuggest

const sx = {
  container: {
    zIndex: theme => theme.zIndex.modal + 3,
  },
	inputBase: {
		zIndex: theme => theme.zIndex.modal + 3,
		p: 0,
		width: '100%',
		'& input': {
			zIndex: theme => theme.zIndex.modal + 3,
			'-webkit-appearance': 'none',
			'-moz-appearance': 'none',
			appearance: 'none',
			p: 1,
			height: 26,
			borderRadius: theme => theme.shape.borderRadius,
			fontSize: theme => theme.typography.body3.fontSize,
			fontFamily: theme => theme.typography.body3.fontFamily,
			backgroundColor: 'common.white',
			transition: '0.5s',
      m: '1px',
      border: theme => `1px solid ${theme.palette.common.inputBorder}`,
      boxShadow: `0 1px 3px 0 rgb(120 120 175 / 20%)`,  
			'&:focus': {
        m: '0px',
        boxShadow: `none`,
        border: theme => `2px solid ${theme.palette.primary.light}`
			},
		},
	},
	paper: {
		backgroundColor: 'common.white',
		mt: 1,
		color: '#586069',
		fontSize: 15,
	},
	option: {
		zIndex: '999 !important',
		minHeight: 'auto',
		alignItems: 'flex-start',
		p: 8,
		'&[aria-selected="true"]': {
			backgroundColor: 'transparent',
		},
		'&[data-focus="true"]': {
			backgroundColor: 'action.hover'
		},
	},
	popperDisablePortal: {
		zIndex: theme => theme.zIndex.modal + 2,
		position: 'relative',
	},
	label: {
		mb: 0,
	},
}
