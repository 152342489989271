import React from 'react'
import {
	ButtonLoader,
	ProgressLoader,
	Drawer,
} from 'components'
import { Box, Button } from '@mui/material'

import AdminlyForm from 'containers/main/adminly/AdminlyForm'

const AdminlyEditModal = ({
	isLoading,
	open,
	fields,
	resource,
  selected,
	collection,
	handleClose,
	handleChange,
	handleSubmit,
}) => {


  let title = selected?.length > 0 ? `Edit selected (${selected?.length})` : `Add ${collection?.db_name_singular}`

	return (
		<Drawer
			open={open}
			handleClose={handleClose}
			title={ title }
		>
			<Box sx={ sx.root  }>
        <AdminlyForm
          collection={collection}
          resource={resource}
          fields={fields}
          handleChange={handleChange}
        />
			</Box>
      <Box sx={ sx.stickyButton  }>
        <Button
          fullWidth
          sx={ sx.button }
          color="primary"
          startIcon={isLoading && <ButtonLoader />}
          variant="contained"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Box>
		</Drawer>
	)
}

export default AdminlyEditModal

const sx = {
	root: {
    pb: '40px'
  },
	content: {
	  my: 1,
    mx: 0,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
  stickyButton: {    
    zIndex: theme => theme.zIndex.modal + 3,
    position: 'fixed',
    bottom: 0,
    right: 0,
    backgroundColor: 'common.white',
    opacity: 0.95,
    boxShadow: `rgb(0 0 0 / 5%) 0px -4px 4px !important`,
    p: 2,
    width:{ sm: '100vw', md: '580px' },
    transition: '0.2s',
    '&:hover': {
      boxShadow: `rgb(0 0 0 / 15%) 0px -4px 12px !important`,
    }
  }
}
