import { isEmpty } from 'validations'

export const validateCloudProject = (project) => {
	let isValid = true
	let messages = []

	if (isEmpty(project.name)) {
		isValid = false
		messages.push('Project name is required')
	}

	if (isEmpty(project.database_url)) {
		isValid = false
		messages.push('Database connection string is required')
	}

  if(project.hosting != 'cloud') {
		isValid = false
		messages.push('Hosting must be set to cloud')
	}

	let validation = {
		isValid,
		messages,
	}

	return validation
}


export const validateSelfHerokuProject = (project) => {
	let isValid = true
	let messages = []

	if (isEmpty(project.name)) {
		isValid = false
		messages.push('Project name is required')
	}

	if (isEmpty(project.database_url)) {
		isValid = false
		messages.push('Database connection string is required')
	}

  if(project.hosting != 'self_hosted') {
		isValid = false
		messages.push('Hosting must be set to self-hosted')
	}

	let validation = {
		isValid,
		messages,
	}

	return validation
}


export const validateSelfManualProject = (project) => {
	let isValid = true
	let messages = []
  
	if (isEmpty(project.name)) {
		isValid = false
		messages.push('Project name is required')
	}

	if (isEmpty(project.api_url)) {
		isValid = false
		messages.push('API URL is required')
	}

  if (isEmpty(project.jwt_secret)) {
		isValid = false
		messages.push('JWT secret is required')
	}

  if(project.hosting != 'self_hosted') {
		isValid = false
		messages.push('Hosting must be set to self-hosted')
	}

	let validation = {
		isValid,
		messages,
	}

	return validation
}
