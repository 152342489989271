import React from 'react'
import { useMenuDrawer } from 'hooks';
import { 
  Box, 
  Button, 
  Hidden, 
  IconButton, 
} from '@mui/material';
import {
  Menu,
} from '@mui/icons-material'
import { Header } from 'components'
import MenuDrawer from './MenuDrawer'
import { useHistory } from 'react-router'

const LandingHeader = ({ 
    logo, 
    menuItems, 
    styles, 
    ...props 
  }) => {

  const history = useHistory()

  const handleClick = (path) => {  
    switch(path){
      case '/docs': 
        window.open('https://docs.adminly.com/')
        break;
      default: 
        history.push(path)
        break;
    }      
  }

  const { open, toggleMenu } = useMenuDrawer()

  return(
    <>
      <Header>
        <Hidden smUp>
          <IconButton onClick={toggleMenu} edge='start' size="large">
            <Menu />
          </IconButton>
        </Hidden>
        <Box sx={ sx.logoContainer  }>
          <Button onClick={() => handleClick('/')}>
            <img src={ logo } style={ sx.logo } />
          </Button>
        </Box>
        <Hidden smDown>
          { menuItems && 
            menuItems.map((menu,i) => {
            let Icon = menu.icon   
            return(
            <Button
              key={i}
              onClick={() => handleClick(menu.value)}
              sx={ sx.menuButton  }                  
              startIcon={ <Icon size={20} /> }
            >
              { menu.label }
            </Button>
          )})}
        </Hidden>
      </Header>
      <MenuDrawer
        open={ open }   
        menuItems={ menuItems }     
        toggleMenu={ toggleMenu }
        handleClick={ handleClick }
      />
    </>
  )
}

export default LandingHeader


const sx = {
  root: {
    height: 50,
    flexGrow: 1,
    backgroundColor: 'common.white'
  },
  appbar: {
    backgroundColor: 'common.white'
  },
  appBarShadow: {
    boxShadow: '0 4px 15px rgb(0,0,0,.1)',
  },
  menuButton: {
    color: 'text.secondary'
  },
  logoContainer: {
    flexGrow: 1,
  },
  logo: {
    width: 120
  }
}
