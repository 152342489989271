import React from 'react'
import {
  Modal,
  ListItemButton,
} from 'components'
import {
  List
} from '@mui/material'

import ViewUserList from 'containers/main/views/ViewUserList'
import { useViews, useActiveProject, useAlerts } from 'hooks'
import { useEffect } from 'react'
import { Users, UserPlus } from 'react-feather'

const ViewShareModal = ({
  open,
  handleClose,
  view: propsView,
  ...props 
}) => {

  const { showAlertSuccess } = useAlerts()
  const { activeProject } = useActiveProject()

  const {
    view,    
    setView,
    updateView
  } = useViews({
    projectId: activeProject?.id 
  })

  const handleShareView = async (shared) => {
    let resp = await updateView({
      ...view,
      shared: shared 
    })
    setView(resp)
  }

  useEffect(() => {
    if(propsView){
      setView(propsView)
    }
  }, [propsView])

  return(
    <Modal 
      open={open}
      handleClose={handleClose}
      title="Share view with team members"
    >
      <List>
        <ListItemButton
          color='success'
          icon={Users}
          onClick={() => handleShareView(true)}
          primary="Team sharing"
          secondary="Share view with all team members"
          selected={view?.shared}
        />
        <ListItemButton
          color='warning'
          onClick={() => handleShareView(false)}
          icon={UserPlus}
          primary="Share with individuals"
          secondary="Invite individual team members"
          selected={!view?.shared}
        />
      </List>
      { open && view?.shared === false && (
        <ViewUserList 
          view={ view }
        />
     )}
    </Modal>
  )
}

export default ViewShareModal

const sx = {
  root: {},
}


