import React from 'react'
import { 
  GoogleLoginButton,
  AuthLayout,
  AuthScreen 
} from 'components'
import Divider from './Divider'
import { Box, Typography } from '@mui/material'
import LoginForm from 'containers/main/auth/LoginForm'
import { useAuth } from 'hooks'
import { GOOGLE_CLIENT_ID } from 'lib/constants'

const Login = ({ ...props }) => {
	const { user, handleChange, loginUser } = useAuth()

	const handleSubmit = async () => {
		await loginUser(user)
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') handleSubmit()
	}

	return (
    <AuthLayout>
      <AuthScreen
        title="Sign In"
        subtitle="Log in to your account"
      >
        <GoogleLoginButton 
          googleClientId={ GOOGLE_CLIENT_ID }
        />
        <Divider /> 
        <LoginForm
          user={user}
          handleChange={handleChange}
          handleKeyPress={handleKeyPress}
          handleSubmit={handleSubmit}
        />
      </AuthScreen>
    </AuthLayout>
	)
}

export default Login

const styles = {
  logo: {    
    width: 140
  }
}

const sx = {
  divider: {
    mt: 3,
    mb: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',    
    borderTop: theme => `1px solid ${theme.palette.common.inputBorder}`
  },
  caption: {
    color: 'text.secondary',
    marginTop: '-28px',
    backgroundColor: 'common.white',
    p: 2
  }
}