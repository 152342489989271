import React from 'react'
import { useResponsive } from 'hooks'
import { Box, Typography, withWidth } from '@mui/material'


const Subheader = ({
	title,
	buttons,
	width,
	mb = 2,
	...props
}) => {

	const { isMobile } = useResponsive()

	return (
		<Box sx={ sx.box} mb={mb }>
			<Box
				width="100%"
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
			>
				<Box ml={isMobile ? 6 : 3} mt={1.5}>
					<Typography color="textPrimary" variant="h4">
						{title}
					</Typography>
				</Box>
			</Box>
			<Box
				display="flex"
				display="flex"
				justifyContent="flex-end"
			>
				{buttons &&
					buttons.map((button, i) => (
						<Box m={1} key={i}>
							{button}
						</Box>
					))}
			</Box>
		</Box>
	)
}
export default Subheader

const sx = {
	box: {
		position: 'relative',
		height: 52,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		backgroundColor: 'common.white',
		borderBottom: theme => `1px solid ${theme.palette.common.border}`,
		boxShadow: theme => `0px 0px 4px 0px ${theme.palette.common.border}`,
		background: theme => `linear-gradient(#fff, ${theme.palette.common.gradient})`
	},
}
