import React from "react";
import { Hero } from 'components'

export default function Home() {

  return (    
    <Hero 
      title="Pricing"
      subtitle={
        "Our flexible pricing provides free plans for small teams and \
        scales for larger teams and enterprises. Questions? Contact us \
        to see how we can work"
      }
      caption="Flexible pricing"
    />
  )
}


const sx = {
  root: {},
  primaryButton: {
    py: 1.5,
  },
  header: {
    mt: 6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center'
  },
  subtitle: {
    maxWidth: 600
  }
};
