import React from 'react'
import { useResource } from 'hooks'

const useAuth0 = ({ id, projectId, ...props }) => {
	const {
		resourceId: userId,
		isLoading,
		isLoaded,
		resource: user,
		resources: users,
		findOne: findUser,
		findMany: findUsers,
		create: createUser,
		update: updateUser,
		destroy: deleteUser,
		handleChange,
		reloadMany: reloadUsers,
		setResource: setUser,
	} = useResource({
		id: id,
		url: `/api/v1/apps/projects/${projectId}/auth0`,
		name: 'user',
	})

	return {
		userId,
		isLoading,
		isLoaded,
		user,
		users,
		findUser,
		findUsers,
		createUser,
		updateUser,
		deleteUser,
		handleChange,
		reloadUsers,
		setUser,
	}
}

export default useAuth0
