import React from 'react'
import { useResource } from 'hooks'

const useAddons = ({ id, projectId, ...props }) => {
	const {
		resourceId: addonId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: addon,
		resources: addons,
		findOne: findAddon,
		findMany: findAddons,
		update: updateAddon,
		create: createAddon,
		save: saveAddon,
		destroy: deleteAddon,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reload: reloadAddon,
		reloadMany: reloadAddons,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		setResource: setAddon,
	} = useResource({
		id: id,
		url: `/api/v1/projects/${projectId}/addons`,
		name: 'addon',
	})

	return {
		addonId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		addon,
		addons,
		findAddon,
		findAddons,
		saveAddon,
		updateAddon,
		createAddon,
		deleteAddon,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		reloadAddon,
		reloadAddons,
		sortKey,
		sortDirection,
		handleSort,
		setAddon,
	}
}

export default useAddons
