import React, { createContext, useState } from 'react'
import AuthContext from 'context/AuthContext'

const AuthProvider = ({ children }) => {
	const [authenticated, setAuthenticated] = useState()
	const [currentUser, setCurrentUser] = useState()
	const [token, setToken] = useState()

	const value = {
		authenticated,
		setAuthenticated,
		currentUser,
		setCurrentUser,
		token,
		setToken,
	}

	return (
		<AuthContext.Provider value={value}>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthProvider
