import React from 'react'
import { Box } from '@mui/material'
import Footer from 'containers/landing/Footer'

const AuthLayout = ({ children, ...props }) => {

	return (
		<Box sx={ sx.root }>	
      <Box sx={ sx.content }>	        
			  {children}
      </Box> 
      <Footer />
		</Box>
	)
}

export default AuthLayout

const sx = {
  root: {    
    height: '100%'
  },
  content: {
    width: '100vw',
    minHeight: 'calc(100vh - 320px)',
  }
}