import React, { useContext } from 'react'
import { AppContext } from 'context'

const useMenuDrawer = (props) => {
  
  const { 
    showMenu,
    setShowMenu
  } = useContext(AppContext)

  const closeMenu = () => setShowMenu(false)    
  const toggleMenu = () => setShowMenu(!showMenu)
	
	return {
	  open: showMenu, 
    setOpen: setShowMenu,
    closeMenu,
    toggleMenu 
	}
}

export default useMenuDrawer
