export default {
	defaultProps: {
		disableRipple: true,
	},
	styleOverrides: {
		root: {      
      whiteSpace: 'nowrap',      
			textTransform: 'uppercase',
      borderRadius: '4px'
		},
    outlined: {      
      color: '#23263b',
      backgroundColor: `#fff`,
      backgroundImage: 'linear-gradient(-180deg,#fff,#fcfcfd)',
      border: '1px solid #d6d6e7',      
      boxShadow: `0 1px 0 0 rgb(35 38 59 / 5%)`,
      '&:hover': {
        backgroundImage: 'linear-gradient(-180deg,#fff,#f5f5fa)',
        borderColor: '#d6d6e7'
      },
      '&:active': {
        boxShadow: `inset 0 1px 4px 0 rgb(119 122 175 / 30%)`,
      },
    },
		contained: {
      color: '#ffffff',
      border: `1px solid #2b3cbb`,
      backgroundColor: `#3c4fe0`,
      backgroundImage: 'linear-gradient(-180deg,#3c4fe0,#2b3cbb)',			
      '&.Mui-disabled': {
        color: '#ffffff'
      },
      '&:active': {
        backgroundImage: 'linear-gradient(-180deg,#2b3cbb,#3c4fe0)',			
      },
      '&:focus': {
        boxShadow: `0 1px 0 0 rgb(35 38 59 / 5%)`,
      }      
		},    
	},
}
