import React from 'react'
import {
	Box,
	ListItem,
	List,
	SwipeableDrawer,
} from '@mui/material'
import { useMenuDrawer } from 'hooks'
import { MenuItem } from 'components'
import Logo from 'assets/logo-regular.svg'
import { useHistory } from 'react-router-dom'

const Drawer = ({ menuItems, ...props }) => {
	const history = useHistory()

  const { 
    open, 
    closeMenu, 
  } = useMenuDrawer()

	const handleClick = (path) => {
		closeMenu()
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
		history.push(path)
	}

	return (
		<SwipeableDrawer
			open={open}
			variant={'temporary'}
			onClose={closeMenu}
		>
			<Box
				display="flex"
				justifyContent="space-between"
				flexDirection="column"
				height="100%"        
			>
				<List 
          component="nav" 
          sx={{
            ...sx.list,
            width: 320
          }}>
					<ListItem 
            button 
            onClick={() => handleClick('/')}
            sx={ sx.logo }>            
						<img              
							style={{ 
                ...sx.image, 
                width: 140
              }}
							src={Logo}
						/>
					</ListItem>
					{ menuItems.map((menu, idx) => (
              <MenuItem
                key={idx}
                value={menu.value}
                label={menu.label}
                icon={menu.icon}
                image={menu.image}
                handleClick={() => handleClick(menu.value)}
              />
					))}
				</List>
			</Box>
		</SwipeableDrawer>
	)
}

export default Drawer

const sx = {
	root: {
		'& .MuiDrawer-paperAnchorDockedLeft': {
			borderRight: theme => `1px solid ${theme.palette.common.darkBorder}`,
		},
	},
	logo: {
		height: 66,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	list: {
		backgroundColor: 'background.dark',		
		height: '100%',
	},
	icon: {
		color: 'icon'
	},
	divider: {
		m: 2,
		opacity: 0.6,
		borderTop: theme => `1px solid ${theme.palette.common.darkBorder}`
	},
}
