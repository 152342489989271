import React from 'react'
import { useMenu } from 'hooks'
import {
	Hidden,
	Button,
	IconButton,
	TableRow,
	TableCell,
	Menu,
	MenuItem,
	Checkbox,
} from '@mui/material'

import { MoreHoriz } from '@mui/icons-material'

const ProjectItem = ({
	project,
	isSelectable,
	selectedIds = [],
	handleSelect,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, toggleMenu, handleClose } =
		useMenu()

	const renderMenu = () => (
		<Menu
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
		>
			<MenuItem
				onClick={(e) => handleEditClick(project, e)}
			>
				Edit
			</MenuItem>
			<MenuItem
				onClick={(e) => handleDeleteClick(project, e)}
			>
				Delete
			</MenuItem>
		</Menu>
	)

	const isSelected = selectedIds.includes(project.id)

	return (
		<TableRow>
			{isSelectable && (
				<TableCell>
					<Checkbox
						checked={isSelected ? true : false}
						onChange={() => handleSelect(project)}
						name="project"
						color="primary"
					/>
				</TableCell>
			)}
			<TableCell>
				<Button onClick={() => handleClick(project)}>
					{project.name}
				</Button>
			</TableCell>
			<TableCell>
				<Button onClick={() => handleClick(project)}>
					{project.user?.name}
				</Button>
			</TableCell>
			<TableCell align="right">
				<IconButton onClick={(e) => toggleMenu(e)}>
					<MoreHoriz color="primary" />
					{renderMenu()}
				</IconButton>
			</TableCell>
		</TableRow>
	)
}

export default ProjectItem
