import React from 'react'
import { useAlerts } from 'hooks'
import { CardElement } from '@stripe/react-stripe-js'
import { Box, Button } from '@mui/material'

import { Panel, ButtonLoader } from 'components'
import { muiTheme } from 'theme'
import { createTheme } from '@mui/material/styles'
const theme = createTheme(muiTheme)

const StripeCreditCardForm = ({
	isLoading,
	stripe,
	elements,
	onSubmit,
	...props
}) => {
	const { showAlertError } = useAlerts()


	const handleSubmit = async (event) => {
		event.preventDefault()

		if (elements == null) {
			return
		}
		const card = elements.getElement(CardElement)
		const { error, token } = await stripe.createToken(card)
		if (token?.id) {
			await onSubmit(token.id)
		} else {
			showAlertError(
				'Please check your credit card information'
			)
		}
	}

	return (
		<Box sx={ sx.root }>
			<Box sx={ sx.stripeCardContainer }>
				<CardElement />
			</Box>
			<Button
        fullWidth
				sx={ sx.button }
				onClick={handleSubmit}
				variant="contained"
				color="primary"
				type="submit"
				disabled={!stripe || !elements}
				startIcon={isLoading && <ButtonLoader />}
			>
				Add Credit Card
			</Button>
		</Box>
	)
}

export default StripeCreditCardForm

const sx = {
	root: {},
	stripeCardContainer: {		
		mt: 2,
		borderRadius: 4,
		p: 2,
		boxShadow: '0 2px 8px rgb(0,0,0,0.10)',
		backgroundColor: 'background.light',
    width: { sm: '280px', md: '520px' }    
	},
	button: {
		mt: 2,
	},
}
