import React from 'react'
import { Grid } from '@mui/material'
import { TextInput } from 'components'

const ProjectSelfHostedForm = ({ project, handleChange }) => {
	return (
		<Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
				<TextInput
					label="Name"
					value={project.name}
					name="name"
					placeholder="Project name"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>        
				<TextInput
					label="Adminly API URL"
					value={project.api_url}
					name="api_url"
					placeholder="Adminly API URL"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
          type='password'
					label="JWT secret"
					value={project.jwt_secret}
					name="jwt_secret"
					placeholder="JWT secret"
					handleChange={handleChange}
				/>
			</Grid>
		</Grid>
	)
}

export default ProjectSelfHostedForm
