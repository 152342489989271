import React from 'react'
import {
  Box,
  ButtonGroup,
  CircularProgress,
	Badge,
	Button,	
} from '@mui/material'
import { isClearableParams } from 'lib/helpers/adminly'
import { Clear, FilterList } from '@mui/icons-material'

const AdminlyFilterButton = ({
  isLoading,
	query,
	handleFilterClick,
	badgeCount,
  handleClearFilters,  
}) => {

	return (
    <Badge
      sx={ sx.badge }
      badgeContent={badgeCount}
      color="primary"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <ButtonGroup>
        <Button
          variant='outlined'
          sx={ sx.button }
          startIcon={
            isLoading ? 
            <CircularProgress 
              disableShrink
              color='primary' 
              size={20} 
            /> : 
            <FilterList sx={ sx.icon } />
          }
          onClick={handleFilterClick}
        >
          Filters
        </Button>    
        { isClearableParams(query) && (
          <Button
            variant='outlined'
            sx={ sx.secondaryButton  }                    
            onClick={ handleClearFilters }                                      
          >   
            <Clear sx={ sx.icon  } />               
          </Button>
        )} 
        </ButtonGroup>  
      </Badge>    
	)
}

export default AdminlyFilterButton

const sx = {
	button: {
		ml: 1,
		color: 'text.secondary'
	},
  secondaryButton: {
    height: 34,
    width: 34,
		color: 'text.secondary'
  },
  icon: {
    height: 20,
    width: 20,
    color: 'text.secondary',
  }
}
