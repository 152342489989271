import React, { useRef, useContext, useState } from 'react'
import { ProjectContext } from 'context'
import { Placeholder, Modal } from 'components'
import { Box, Button } from '@mui/material'
import CloudinaryBrowser from 'containers/apps/cloudinary/CloudinaryBrowser'
import { UploadCloud } from 'react-feather'
import { isCloudinary } from 'lib/helpers/utils'

const CloudinaryModal = ({
	open,
	handleClick,
	handleClose,
}) => {
	const [showUpload, setShowUpload] = useState(false)


	const { activeProject } = useContext(ProjectContext)

	const ref = useRef()

	const handleUploadClick = () => {
		setShowUpload(!showUpload)
		ref.current.scrollIntoView({
			behavior: 'smooth',
		})
	}

	return (
		<Modal
			open={open}
			handleClose={handleClose}
			title="Select Image or Video"
			maxWidth="md"
			p={0}
			actions={
				isCloudinary(activeProject) ? (
					<Button
						variant="outlined"
						color="secondary"
						onClick={handleUploadClick}
						startIcon={
							<UploadCloud sx={ sx.icon } />
						}
					>
						Upload
					</Button>
				) : null
			}
		>
			<Box sx={ sx.root} ref={ref }>
				{isCloudinary(activeProject) ? (
					<CloudinaryBrowser
						open={showUpload}
						handleClick={handleClick}
					/>
				) : (
					<Box sx={ sx.placeholder }>
						<Placeholder
							icon={
								<UploadCloud sx={ sx.icon } />
							}
							title="Cloudinary addon not installed."
							subtitle="To use Cloudinary assets, install the Cloudinary addon."
						/>
					</Box>
				)}
			</Box>
		</Modal>
	)
}

export default CloudinaryModal

const sx = {
	root: {},
	icon: {
		color: 'icon'
	},
}
