import React, { useState, useEffect } from 'react'
import {
  Box,
  Button 
} from '@mui/material'
import { Terminal, Play } from 'react-feather'
import { TextInput } from 'components'

const SqlForm = ({
  sql, 
  setSql,
  handleChange,
  handleSubmit,
  ...props 
}) => {

  return(
      <Box sx={ sx.root }>
        <TextInput 
          rows={6}
          multiline
          value={ sql }
          handleChange={ handleChange } 
          placeholder="Enter SQL query..."
          styles={ sx.textInput }
        />
        <Button           
          onClick={ handleSubmit }
          variant="contained"
          sx={ sx.button }
          endIcon={ <Play size={18} /> }
        >
          Run SQL
        </Button>
      </Box>
  )
}

export default SqlForm 

const sx = {
  root: {
    width: '100%',
  },
  button: {
    mt: 2
  },
  textInput: {
    fontFamily: 'IBM Plex Mono',
  }
}