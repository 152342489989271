import React from 'react'
import { Grid } from '@mui/material'
import { TextInput } from 'components'

const Auth0Form = ({ user, handleChange }) => {
	const isEditing = user?.user_id ? true : false

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={12}>
				<TextInput
					label="Name"
					value={user?.name}
					name="name"
					placeholder="Name"
					handleChange={handleChange}
				/>
			</Grid>
			{!isEditing && (
				<Grid item xs={12} sm={12}>
					<TextInput
						label="Email"
						value={user?.email}
						name="email"
						placeholder="Email"
						handleChange={handleChange}
					/>
				</Grid>
			)}
			{!isEditing && (
				<Grid item xs={12} sm={12}>
					<TextInput
						label="Password"
						value={user?.password}
						name="password"
						placeholder="Password"
						handleChange={handleChange}
					/>
				</Grid>
			)}
		</Grid>
	)
}

export default Auth0Form
