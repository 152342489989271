import React, {
	useContext,
	useState,
	useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { ProjectContext } from 'context'
import { useAlerts, useFields } from 'hooks'

import {
  Panel,	
	PanelHeader,
  MobileButton,
	SortableList,
  DeleteWarningModal
} from 'components'
import SortableField from 'containers/main/fields/SortableField'
import FieldModal from 'containers/main/fields/FieldModal'
import { validateField } from 'validations/fields'
import { Add } from '@mui/icons-material'
import { Box } from '@mui/material'
import { orderBy } from 'lodash'

const FieldList = ({
	collection,
	...props
}) => {
	const { showAlertError, showAlertSuccess } = useAlerts()

	const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
	const { activeFields, setActiveFields } = useContext(ProjectContext)

	const {
		isLoading,
		field,
		findFields,
		createField,
    deleteField,
		updateField,
		updateFieldPositions,
		handleChange,
		setField,
	} = useFields({
		projectId: collection?.project_id,
		collectionId: collection?.id,
	})

	const handleDrop = async (sorted) => {
		let sortedFields = sorted.map((field,index) => ({
      ...field,
      position: index
    }))        
    setActiveFields(sortedFields)
		updateFieldPositions(sortedFields)
	}

	const handleSaveField = async () => {
		let newField
		let validate = validateField(field)
		if (validate.isValid) {
			if (field?.id) {
				newField = await updateField(field)
			} else {
				newField = await createField(field)
			}
			if (newField?.id) {
				loadFields()
				setShowModal(false)
			}
			setField({})
		} else {
			validate.messages.map((m) => showAlertError(m))
		}
	}

	const handleAddField = () => {
    //Only add reference fields
		setField({
      field_type: 'reference',
      db_name: 'reference'
    })
		setShowModal(true)
	}

	const handleUpdateField = async (field) => {
		setShowModal(false)
		await updateField(field)
		loadFields()
	}

	const handleEditField = (field) => {
		setField(field)
		setShowModal(true)
	}

  const handleShowDeleteModal = (field) => {
    setField(field)
    setShowDeleteModal(true)
  }

  const handleDeleteField = async () => {
    await deleteField(field?.id)
    setShowDeleteModal(false)
    showAlertSuccess("Field has been deleted")
    loadFields()
  }

	const loadFields = async () => {
		let fields = await findFields()
		setActiveFields(fields)
	}

	const filterAndSortFields = (fields) => {		
    let sortedFields = orderBy(fields, ['position'], ['asc'])
		return sortedFields
	}

	useEffect(() => {
		if (activeFields?.length > 0) {
			loadFields()
		}
	}, [activeFields?.length])

	useEffect(() => {
		if (collection?.id && collection?.project_id) {
			loadFields()
		}
	}, [collection])

	return (
		<Box sx={ sx.root }>
			<PanelHeader
        enableBackButton
				actions={
          <MobileButton
            variant="outlined"
            color="secondary"
            startIcon={<Add sx={ sx.icon} /> }
            onClick={handleAddField}
          >
            Add Field
          </MobileButton>
				}
			/>
      <Panel>
			<Box sx={ sx.content }>
				{activeFields && (
					<SortableList
						droppableId={'sortable-field-list'}
						items={ filterAndSortFields(activeFields) }
						handleDrop={handleDrop}
						renderItem={(item) => (
							<SortableField
								item={item}
								onUpdate={handleUpdateField}
								onEdit={handleEditField}
                onDelete={item?.field_type === 'reference' && handleShowDeleteModal}
							/>
						)}
					/>
				)}
			</Box>
      </Panel>
			<FieldModal
				isLoading={isLoading}
				open={showModal}
				field={field}
				handleSubmit={handleSaveField}
				handleChange={handleChange}
				handleClose={() => setShowModal(false)}
			/>
      <DeleteWarningModal
        isLoading={isLoading}
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={ handleDeleteField }
      />
		</Box>
	)
}

FieldList.propTypes = {
	collection: PropTypes.object.isRequired,
}

export default FieldList

const sx = {
	root: {
		height: '100%',
	},
	title: {
		pt: 3,
    pr: 3,
    pb: 1,
    pl: 3,
		borderBottom: theme => `1px solid ${theme.palette.common.border}`,
	},
	icon: {
		color: 'icon'
	},
	content: {
		overflow: 'hidden',
	},
}
