import React, {
	useState,
	useEffect,
	useContext,
} from 'react'
import { AppContext } from 'context'
import { Box } from '@mui/material'
import {
	CenterPanel,
	LeftPanel,
	Panel,
	PanelLayout,
	Placeholder,
	PanelHeader
} from 'components'
import ViewLeftPanel from 'containers/main/views/ViewLeftPanel'
import {  
	useActiveProject,
	useCollections,
	useTabs,
} from 'hooks'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Search } from 'react-feather'
import CollectionTable from 'containers/main/collections/CollectionTable'
import FieldList from 'containers/main/fields/FieldList'
import { parseQueryFromURL } from 'lib/helpers/adminly'
import CollectionHeader from 'containers/main/collections/CollectionHeader'

const CollectionContainer = ({ ...props }) => {

  useTabs('collections')

	const {     
    activeProject,
  } = useActiveProject(projectId)

  const location = useLocation()

  const [query, setQuery] = useState()
	
  const { 
    id: projectId, 
    view_id: viewId, 
    collection_id: collectionId 
  } = props.match.params

  const {
    isLoading,
    collection,
    findCollection,
  } = useCollections({
    projectId: projectId
  })

	const { 
    globalReload,
    setOpenMobile, 
    showSideMenu,
    isEditing,
  } = useContext(AppContext)

  const handleParseQuery = () => {
    let parsedQuery = parseQueryFromURL(location.search)
    setQuery(parsedQuery)
  }
  
	useEffect(() => {
		if (collection?.id) {
			setOpenMobile(true)
		}
	}, [collection?.id])

  useEffect(() => {
		if (collectionId) {
			findCollection(collectionId)    
		}
	}, [collectionId, globalReload])

  useEffect(() => {
    if(location){
      handleParseQuery()
    }
  }, [location])

	return (
		<Box sx={ sx.root }>
			<PanelLayout>
				{showSideMenu && (
					<LeftPanel>
						<ViewLeftPanel 
              projectId={projectId} 
            />
					</LeftPanel>
				)}
				<CenterPanel>
					{(activeProject && collectionId) ? (
						<Box>
              <CollectionHeader 
                title={ collection?.name }
                viewId={viewId}
                projectId={projectId}
              />
              { !isEditing ? (
                <CollectionTable
                  title={ collection?.name }
                  defaultQuery={ query } 
                  enableSaveView={false}
                  collection={collection}
                  fields={collection?.fields}
                />
              ) : (
                <FieldList										
                  collection={collection}
                />
              )}
          </Box>
					) : (
            <Box>
								<Box>
									<PanelHeader
										title="Views"
									/>
									<Panel py={4}>
										<Placeholder
											icon={
												<Search sx={ sx.icon } />
											}
											title="Select a view or collection"
											subtitle="Nothing selected."
										/>
									</Panel>
								</Box>
						</Box>
					)}
				</CenterPanel>
			</PanelLayout>
		</Box>
	)
}

export default CollectionContainer

const sx = {
	root: {},
	iconButton: {
		color: 'primary.main'
	},
	icon: {
		color: 'text.secondary'
	},
}
