import {
  Book,
	Database,
	Settings,
  User,
	Home,
  CreditCard,
  PenTool,
  Code
} from 'react-feather'
import { Gavel } from '@mui/icons-material'
import Auth0 from 'assets/icons/auth0-icon.svg'
import Cloudinary from 'assets/icons/cloudinary.svg'

export const MENU_ITEMS = {
	authenticated: [
		{
			id: 'projects',
			value: '/projects',
			label: 'Projects',
			icon: Home,
			project: false,
		},
		{
			id: 'collections',
			value: 'collections',
			label: 'Views',
			icon: Database,
			project: true,
		},
    {
			id: 'sql',
			value: 'sql',
			label: 'SQL Query',
			icon: Code,
			project: true,
		},
		{
			id: 'settings',
			value: 'settings/details',
			label: 'Settings',
			icon: Settings,
			project: true,
		},
	],
	unauthenticated: [
		{
			id: 'login',
			value: '/login',
			label: 'Sign In',
			icon: User,
			project: false,
		},
	],
  public: [
    {
			id: 'docs',
			value: '/docs',
			label: 'Docs',
			icon: Book,			
		},
    {
			id: 'pricing',
			value: '/pricing',
			label: 'Pricing',
			icon: CreditCard,			
		},
    {
			id: 'login',
			value: '/login',
			label: 'Sign In',
			icon: User			
		}
  ],
  addons: {
    auth0: {
      id: 'auth0',
      value: 'auth0',
      label: 'Auth0',
      image: Auth0,
      project: true,
    },
    cloudinary: {
      id: 'cloudinary',
      value: 'cloudinary',
      label: 'Cloudinary',
      image: Cloudinary,
      project: true,
    },
  }
}


export const LEGAL_TABS = [
	{
		value: '/terms_of_service',
		step: 1,
		label: 'Terms of Service',
		subtitle: 'Terms and conditions',
		icon: Gavel,
	},
	{
		value: '/privacy_policy',
		step: 2,
		label: 'Privacy Policy',
		subtitle: 'Privacy policy',
		icon: PenTool,
	},
]
