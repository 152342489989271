import React from 'react'
import { AdminlyProvider } from 'context'
import { useActiveProject } from 'hooks'
import ViewLayout from 'containers/main/views/ViewLayout'
import AdminlyShowDetails from 'containers/main/adminly/AdminlyShowDetails'

const AdminlyShow = ({ ...props }) => {
	
	const { 
    id: projectId, 
    collection_id: collectionId, 
    resource_id: resourceId 
  } = props.match.params  

  const { activeProject } = useActiveProject()
	
	return (
    <ViewLayout projectId={ projectId }>
      { activeProject && (
				<AdminlyProvider
					token={activeProject?.jwt_token}
					url={activeProject?.api_url}
				>		
          <AdminlyShowDetails 
            collectionId={ collectionId } 
            projectId={ projectId }
            resourceId={ resourceId }
          />
        </AdminlyProvider>
      )}
    </ViewLayout>
	)
}

AdminlyShow.propTypes = {}

export default AdminlyShow
