
import React from 'react'
import PropTypes from 'prop-types'
import {
  Link,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { Section } from 'components'
import Logo from 'assets/logo-white.svg'

const FOOTER_LINKS = {
  "about": [
    {label: 'Contact Us', value: '/contact_us'},
    {label: 'Features', value: '/'},
    {label: 'Pricing', value: '/pricing'},
  ],
  "legal": [
    {label: 'Terms of service', value: '/terms_of_service'},
    {label: 'Privacy policy', value: '/privacy_policy'},
  ],
  "documentation": [    
    {label: 'Documentation', value: '/docs'},
    {label: 'Quick Start', value: '/'},    
  ],
  "register": [
    {label: 'Sign In', value: '/sign_in'},
    {label: 'Register', value: '/register'},
    {label: 'Forgot password', value: '/forgot_password'},
  ]
}

const Footer = ({
    className,
    ...props
  }) => {

  return(
    <Section bgColor='background.dark' styles={ sx.root }>
      <Container maxWidth='lg'>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={2}>
            <Button>
              <img src={ Logo } style={ styles.logo } />
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant='subtitle1' sx={ sx.header  }>
                      About
                    </Typography>
                  }
                />
              </ListItem>
              { FOOTER_LINKS["about"].map((link,i) => (
                <ListItem key={i}>
                  <Link sx={ sx.link } href={ link.value  }>
                    { link.label }
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid>               
          <Grid item xs={12} sm={12} md={2}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant='subtitle1' sx={ sx.header  }>
                      Developers
                    </Typography>
                  }
                />
              </ListItem>
              { FOOTER_LINKS["documentation"].map((link,i) => (
                <ListItem key={i}>
                  <Link sx={ sx.link } href={ link.value  }>
                    { link.label }
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant='subtitle1' sx={ sx.header  }>
                      Get Started
                    </Typography>
                  }
                />
              </ListItem>
              { FOOTER_LINKS["register"].map((link,i) => (
                <ListItem key={i}>
                  <Link sx={ sx.link } href={ link.value  }>
                    { link.label }
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant='subtitle1' sx={ sx.header  }>
                      Legal
                    </Typography>
                  }
                />
              </ListItem>
              { FOOTER_LINKS["legal"].map((link,i) => (
                <ListItem key={i}>
                  <Link sx={ sx.link } href={ link.value  }>
                    { link.label }
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid>

        </Grid>
      </Container>
    </Section>
  )
}

Footer.propTypes = {
  className: PropTypes.string
}

export default Footer

const styles = {
  logo: {
    width: 120,
    marginTop: '16px'
  }
}

const sx = {
  root: {
    mt: 10,
    py: 4,
    minHeight: 200
  },
  header: {
    color: 'primary.contrastText'
  },
  link: {
    color: 'common.white',
    opacity: 0.8,
    textDecoration: 'none'
  },
  button: {
    color: 'text.secondary'
  },
  listItemText: {
    color: 'common.white'
  },
  text: {
    color: 'common.white'
  }
}
