import React, { useState, useEffect } from 'react'
import { useActiveProject, useAlerts, usePayments } from 'hooks'
import { SubscriptionPlanCard, SwitchInput } from 'components'
import { SUBSCRIPTION_PLANS } from 'lib/constants/prices'
import { isStripe, isPaid } from 'lib/helpers/utils'
import { Box, Grid } from '@mui/material'
import StripeCreditCardModal from 'containers/main/payments/StripeCreditCardModal'

const SubscriptionPlans = ({ projectId, enableSwitchHosting=false, cloudHosted }) => {  

  const { activeProject, findActiveProject } = useActiveProject(projectId)
  const { showAlertError, showAlertSuccess } = useAlerts()
  
  const [cloudHosting, setCloudHosting] = useState(true)
  const [showStripeModal, setShowStripeModal] = useState(false)
  const [activePlan, setActivePlan] = useState(null)
  const [activePrice, setActivePrice] = useState(0)

  const {
		isLoading,
		subscribe,
	} = usePayments({
		projectId: activeProject?.id,
	})

  const handleSelectPlan = (plan) => {
    setActivePlan(plan)
    setShowStripeModal(true)
  }

	const handleSubscribe = async () => {    
    const { stripe_plan } = activePlan
		if (isStripe(activeProject)) {
			let resp = await subscribe(stripe_plan)			
      if(resp?.id){
        await findActiveProject()      
        showAlertSuccess('You have successfully subscribed')
      }else{
        showAlertError('There was an error subscribing')
      }      
		} else {
			showAlertError('Please add a credit card to your account')
		}
	}

  useEffect(() => {
    if(activePlan){      
      setActivePrice(
        activePlan.price[cloudHosting ? 'cloud' : 'self_hosting']
      )
    }  
  }, [activePlan])

  useEffect(() => {
    if(cloudHosted){
      setCloudHosting(cloudHosting)
    }
  }, [cloudHosted])

  return(
    <Box>
      { enableSwitchHosting && (
        <Box sx={ sx.root }>            
          <SwitchInput
            placeholder={ cloudHosting ? "Cloud hosting" : "Self-hosting" }
            name='cloud_hosting'
            value={ cloudHosting }
            handleChange={() => setCloudHosting(!cloudHosting) }
          />
        </Box>
      )}
      <Grid container spacing={1}>
        { SUBSCRIPTION_PLANS.map(plan => (
          <Grid item xs={12} sm={12} md={4} key={plan.id}> 
            <SubscriptionPlanCard
              key={plan.id}
              plan={ plan }
              hosting={ cloudHosting ? 'cloud' : 'self_hosting' }
              handleClick={() => handleSelectPlan(plan)}
              activeStripePlan={ activeProject?.payment_plan }          
            />
          </Grid> 
        ))}
      </Grid>
      <StripeCreditCardModal 
        isLoading={isLoading }        
        open={ showStripeModal }
        customer={ activeProject }
        handleClose={() => setShowStripeModal(false)}
        handleSubscribe={ handleSubscribe }
        plan={ activePlan }
        price={ activePrice }
      />
    </Box>
  )
}

export default SubscriptionPlans 

const sx = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}