import React, { useState } from 'react'
import {
	SearchInput,
	IconButton,
	ProgressLoader,
	Placeholder,
} from 'components'
import {
	Box,
	Button,
	List,
	Typography,
} from '@mui/material'

import {
	ExpandMore,
	Collections,
} from '@mui/icons-material'
import CloudinaryItem from 'containers/apps/cloudinary/CloudinaryItem'
import CloudinaryUploader from 'containers/apps/cloudinary/CloudinaryUploader'
import CloudinaryTabs from 'containers/apps/cloudinary/CloudinaryTabs'
import { Waypoint } from 'react-waypoint'

const CloudinaryList = ({
	open,
	folders,
	cloudName,
	isLoading,
	handleSearch,
	handleClick,
	handleDeleteClick,
	resources,
	findResources,
	reloadResources,
	loadMoreResources,
	nextCursor,
	resourceType,
	handleResourceTypeChange,
	...props
}) => {


	const handleTabChange = (event, value) => {
		handleResourceTypeChange(value)
	}

	return (
		<Box sx={ sx.root }>
			{open && (
				<Box sx={ sx.input }>
					<CloudinaryUploader
						onComplete={reloadResources}
					/>
				</Box>
			)}
			<Box sx={ sx.input }>
				<SearchInput handleSearch={handleSearch} />
			</Box>
			<Box sx={ sx.input }>
				<CloudinaryTabs
					activeTab={resourceType}
					handleChange={handleTabChange}
				/>
			</Box>
			{folders && (
				<Box sx={ sx.folders}>{folders }</Box>
			)}
			{!isLoading || resources?.length > 0 ? (
				<Box>
					<List disablePadding>
						{resources.map((resource, i) => (
							<CloudinaryItem
								key={i}
								item={resource}
								cloudName={cloudName}
								handleClick={handleClick}
								handleDeleteClick={handleDeleteClick}
							/>
						))}
					</List>
					{nextCursor && (
						<Waypoint
							key={nextCursor}
							onEnter={() => loadMoreResources(nextCursor)}
						>
							<center>
								{!isLoading && (
									<Box my={2}>
										<IconButton
											onClick={() =>
												loadMoreResources(nextCursor)
											}
											icon={ExpandMore}
										/>
									</Box>
								)}
							</center>
						</Waypoint>
					)}
				</Box>
			) : (
				<Placeholder
					icon={<Collections />}
					title="No Cloudinary files."
					subtitle="Upload to add files."
				/>
			)}

			{isLoading && (
				<Box sx={ sx.loader }>
					<ProgressLoader />
				</Box>
			)}
		</Box>
	)
}

export default CloudinaryList

const sx = {
	root: {
		overflowX: 'hidden',
		overflowY: 'scroll',
		height: 'calc(100vh - 76px)',
	},
	input: {
		p: 1,
	},
	button: {
		fontSize: 12,
		fontFamily: theme => theme.typography.body3.fontFamily,
		color: 'primary.main'
	},
	activeButton: {
		color: 'text.primary'
	},
	toolbar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
}
