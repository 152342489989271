import React, { useState, useEffect, useRef } from 'react' 
import MUIRichTextEditor from 'mui-rte'
import { Panel } from 'components'
import { Box, Typography } from '@mui/material'
import { HTML_TOOLBAR_CONTROLS } from 'lib/constants'
import { 
  convertToRaw,
  EditorState, 
  ContentState,
  convertFromHTML
} from 'draft-js'
import { stateToHTML } from "draft-js-export-html";

const HTMLInput = ({ 
    label, 
    value, 
    name, 
    handleChange, 
  }) => {
  

  const ref = useRef()
  const [defaultValue, setDefaultValue] = useState(null)
  const [debounceTimeoutId, setDebounceTimeoutId] = useState(null)
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );

  const handleEditorChange = (editorState) => {
    let htmlContent = stateToHTML(editorState.getCurrentContent())
    // hack to force line breaks
    htmlContent = htmlContent.replaceAll(/<Typography variant="body1" mt={1}><br><\/p>/g, '<Typography variant="body1" mt={1}>&#x200b;</Typography>')
    handleChange({
      target: {
        name,
        value: htmlContent
      }
    })
  } 

  const handleDebouncedChange = (editorState) => {
    clearTimeout(debounceTimeoutId)
    let timeoutId = setTimeout(() => handleEditorChange(editorState), 1000)
    setDebounceTimeoutId(timeoutId)
  }

  useEffect(() => {
   handleDebouncedChange(editorState)
   return () => clearTimeout(debounceTimeoutId)
  }, [editorState])

  useEffect(() => {
    if(value && !defaultValue){
      const blocksFromHTML = convertFromHTML(value);
      const defaultState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );    
      setDefaultValue(JSON.stringify(convertToRaw(defaultState)))      
    }
  }, [value])

  return (    
    <Box sx={ sx.root  }>
      { label && (
        <Typography variant="caption" color="textSecondary">
          { label }
        </Typography>
      )}
      <Panel p={1}>
        <Box sx={ sx.editor  }>          
          <MUIRichTextEditor 
            ref={ref}
            label={"…"}
            controls={HTML_TOOLBAR_CONTROLS}
            editorState={editorState} 
            onChange={setEditorState} 
            defaultValue={ defaultValue }
          />
        </Box>
      </Panel>    
    </Box>
  )
}

export default HTMLInput 

const sx = {
  root: {
    'pre:parent(div)': {
      backgroundColor: '#fff'
    }
  },
  editor: {
    minHeight: 80
  }
}

