import React from "react";
import { 
  Box, 
  Container, 
  Grid, 
  Typography,
} from "@mui/material";
import { Label } from 'components'

export default function Home({
  title,
  subtitle,
  caption,
  actions,
  maxWidth="lg",
  ...props 
}) {

  return (    
    <Container maxWidth={ maxWidth }>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>            
          <Box sx={ sx.root }>
            { caption && (
              <Label label={ caption } />
            )}
            { title && (
              <Typography variant="h2">{ title }</Typography>
            )}
            { subtitle && (
            <Typography variant="h5" color="textSecondary" sx={sx.subtitle}>
              { subtitle }
            </Typography>
            )}
            { actions && actions }
          </Box>              
        </Grid>
      </Grid>
    </Container>
  )
}

const sx = {
  root: {
    my: 6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center'
  },
  subtitle: {
    maxWidth: 600
  }
};
