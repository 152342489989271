import React from 'react'
import { Grid } from '@mui/material'
import { TextInput } from 'components'

const ViewForm = ({
	view,
	handleChange,
}) => (
	<Grid container spacing={2}>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={view.name || ''}
				name="name"
				placeholder="Name this view"
				handleChange={handleChange}
			/>
		</Grid>
	</Grid>
)

export default ViewForm
