import React, {
	useEffect,
} from 'react'
import {
  useApps, 
  useActiveProject, 
} from 'hooks'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { 
  PanelHeader, 
  PanelLayout,
  LeftPanel,
  CenterPanel,
  Placeholder 
} from 'components'
import AppList from 'containers/main/apps/AppList'
import SettingsLeftPanel from 'containers/main/settings/SettingsLeftPanel'
import { Search } from 'react-feather'
import { Box } from '@mui/material'

const AppContainer = (props) => {

  const history = useHistory()
	
  const { id: projectId, tab } = props.match.params
	const { activeProject } = useActiveProject(projectId)



	const { apps, findApps } = useApps({})

	const handleAppClick = (app) => {
		history.push(`/projects/${activeProject?.id}/addons/${app?.id}`)
	}

	useEffect(() => {
		findApps()
	}, [])

	return (
    <PanelLayout>
      <LeftPanel>
        <SettingsLeftPanel activeTab={'apps'} />
      </LeftPanel>
      <CenterPanel>
        <Box sx={ sx.root }>
          <PanelHeader
            enableBackButton
            title="Apps"
          />
          {apps ? (
            <AppList apps={apps} handleClick={handleAppClick} />
          ):(
            <Placeholder
              icon={<Search sx={ sx.icon} /> }
              title="No apps found"
              subtitle="Please try another search."
            />
          )}
        </Box>
      </CenterPanel>
    </PanelLayout>
	)
}

AppContainer.propTypes = {
	className: PropTypes.string,
}

export default AppContainer

const sx = {
	root: {},
	iconButton: {
		color: 'primary.main'
	},
	icon: {
		color: 'secondary.main'
	},
}
