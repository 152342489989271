import React, { useState, useEffect } from 'react'
import { useTabs, useProjects, useAlerts } from 'hooks'
import { useHistory } from 'react-router-dom'
import {
	ButtonLoader,
	Panel,
  OnboardingLayout,
	PanelHeader,
  ListItemButton,
} from 'components'
import { Button, List, Typography } from '@mui/material'
import { 
  validateCloudProject, 
  validateSelfHerokuProject, 
  validateSelfManualProject 
} from 'validations/projects'
import { useActiveProject } from 'hooks'
import { ChevronRight } from '@mui/icons-material'
import { Code, UploadCloud, Database } from 'react-feather'
import ProjectForm from 'containers/main/projects/ProjectForm'
import ProjectSelfHostedForm from 'containers/main/projects/onboarding/ProjectSelfHostedForm'
import ProjectSteps from 'containers/main/projects/onboarding/ProjectSteps'

const ProjectNew = ({ ...props }) => {
	useTabs('projects')
  const step = 0

	const history = useHistory()
	const { showAlertError } = useAlerts()

	const { setActiveProject } = useActiveProject()
  const [hosting, setHosting] = useState('cloud')

	const {
		isLoading,
		project,
    setProject,
		handleChange,
		createProject,
	} = useProjects({})

	const handleCancelClick = () => {
		history.push('/projects')
	}

	const handleSubmit = async () => {
    let validate
    if(hosting === 'cloud') {
		  validate = validateCloudProject(project)
    }else if(hosting === 'self_hosted'){
      validate = validateSelfHerokuProject(project)
    }else{
      validate = validateSelfManualProject(project)
    }
		if (validate.isValid) {
			let newProject = await createProject(project)
			if (newProject?.id) {
        if(hosting === 'manual') {
				  history.push(`/projects/${newProject?.id}/onboarding/step4`)
        }else{
          history.push(`/projects/${newProject?.id}/onboarding/step2`)          
        }
			} else {
				showAlertError(
					'There was an error saving the project.'
				)
			}
		} else {
			validate.messages.map((m) => showAlertError(m))
		}
	}

  const handleChangeHosting = async (value) => {    
    setHosting(value)
    setProject({
      ...project,
      hosting: value === 'manual' ? 'self_hosted' : value
    })
  }
  
  useEffect(() => {
    if(hosting){
      handleChangeHosting(hosting)
    }    		
	}, [hosting])  

	useEffect(() => {
    setProject({
      hosting: hosting || 'cloud'
    })
		setActiveProject()
	}, [])

	return (
		<OnboardingLayout>
      <ProjectSteps step={step} />
      <PanelHeader 
        title="Start a new project" 
        actions={
          <>
            <Button 
              variant="outlined"
              onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              startIcon={isLoading && <ButtonLoader />}
              endIcon={ <ChevronRight /> }
            >
              {isLoading ? 'Saving ...' : 'Save'}
            </Button>
          </>
        }
      />
      <Panel>
        <Typography variant="subtitle2" mt={2}>
          Select hosting
        </Typography>  
        <Typography variant="body1" color="textSecondary">
          You can start a new project or if you self-hosted Adminly you can connect to an existing application.          
        </Typography>
        <List>
          <ListItemButton
            color='success'
            icon={UploadCloud}
            onClick={() => setHosting('cloud')}
            primary="Cloud Hosting"
            secondary="Cloud-hosting plans starts at $49/month."
            selected={hosting === 'cloud'}
          />
          <ListItemButton
            color='warning'
            onClick={() => setHosting('self_hosted')}
            icon={Database}
            primary="Self-Hosting (Heroku)"
            secondary="Connect and deploy to your Heroku account. Free for 5 users."
            selected={hosting === 'self_hosted'}
          />
          <ListItemButton
            color='warning'
            onClick={() => setHosting('manual')}
            icon={Code}
            primary="Self-Hosting (Manual)"
            secondary="Manually setup Adminly with 1-click deploy. Free for 5 users."
            selected={hosting === 'manual'}
          />
        </List>
        {(hosting === 'cloud' || hosting === 'self_hosted') && (
          <ProjectForm
            project={project}
            handleChange={handleChange}
          />
        )}            
        { hosting === 'manual' && (              
          <ProjectSelfHostedForm 
            project={project}
            handleChange={handleChange}
          />              
        )}
      </Panel>     
		</OnboardingLayout>
	)
}

export default ProjectNew
