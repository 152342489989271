import React from 'react'
import PropTypes from 'prop-types'
import { 
  PanelLayout, 
  LeftPanel,
  CenterPanel 
} from 'components'
import { useActiveProject } from 'hooks'
import SettingsLeftPanel from 'containers/main/settings/SettingsLeftPanel'
import HerokuContainer from 'containers/main/heroku/HerokuContainer'


const SettingsHeroku = (props) => {
  const activeTab = "heroku"	
  const { id: projectId, tab } = props.match.params

	return (
    <PanelLayout>
      <LeftPanel>
        <SettingsLeftPanel 
          activeTab={ activeTab }
        />
      </LeftPanel>
      <CenterPanel>
        <HerokuContainer /> 
      </CenterPanel>
    </PanelLayout>
	)
}

SettingsHeroku.propTypes = {
}

export default SettingsHeroku

const sx = {
	root: {}
}
