import React from 'react'
import { Grid } from '@mui/material'
import { InstallButton, TextInput } from 'components'

const Auth0AddonForm = ({
	app,
	addon,
	installed,
	handleChange,
	handleSubmit,
}) => (
	<Grid container spacing={1}>
		<Grid item xs={12} sm={12}>
			<TextInput
				label="Auth0 App Name"
				value={addon?.api_name}
				name="api_name"
				placeholder="Auth0 App Name"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				label="Auth0 Domain"
				value={addon?.api_url}
				name="api_url"
				placeholder="Auth0 Domain"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				label="Auth0 Client ID"
				value={addon?.api_key}
				name="api_key"
				placeholder="Auth0 Client ID"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				type="password"
				label="Auth0 Client Secret"
				value={addon?.api_secret}
				name="api_secret"
				placeholder="Auth0 Client Secret"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<InstallButton
				label={app?.name}
				installed={installed}
				handleSubmit={handleSubmit}
			/>
		</Grid>
	</Grid>
)

export default Auth0AddonForm
