import React from 'react'
import {
	Box,
	Button,
	Grid,
	Link,
	Typography,
} from '@mui/material'

import { ButtonLoader, TextInput } from 'components'
import { Link as RouterLink } from 'react-router-dom'

const SignupForm = ({
  isLoading,
	user,
	handleKeyPress,
	handleChange,
	handleSubmit,
}) => (
	<Grid container spacing={2}>
    <Grid item xs={12} sm={6}>
			<TextInput
				required
				name="first_name"
				value={user.first_name}
				onKeyPress={handleKeyPress}
				placeholder="First name"
				handleChange={handleChange}
			/>
		</Grid>
    <Grid item xs={12} sm={6}>
			<TextInput
				required
				name="last_name"
				value={user.last_name}
				onKeyPress={handleKeyPress}
				placeholder="Last name"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				required
				name="email"
				value={user.email}
				onKeyPress={handleKeyPress}
				placeholder="Email"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				required
				name="password"
				value={user.password}
				type="password"
				onKeyPress={handleKeyPress}
				placeholder="Password"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<Button
				fullWidth
				color="primary"
				onClick={handleSubmit}
				variant="contained"
        startIcon={ isLoading && <ButtonLoader /> }
			>
				Register
			</Button>
		</Grid>
		<Box my={4} />
		<Grid item xs={12} sm={12}>
			<Typography variant="body2">
				<Link component={RouterLink} to="/login">
					Already have an account? Sign in
				</Link>
			</Typography>
		</Grid>
	</Grid>
)

export default SignupForm
