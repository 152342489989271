import React, { useEffect, useState, useContext } from 'react'
import { 
  AppContext, 
  ProjectContext 
} from 'context'
import PropTypes from 'prop-types'
import {
  useAuth,
  useAlerts,
	useActiveProject,
	useViews,
  useCollections
} from 'hooks'

import { 
  alpha,
  Box, 
  Typography,
} from '@mui/material'
import { Database } from 'react-feather'
import {
  DeleteWarningModal,
  LeftPanelHeader,
  ListExpandable,
	TextInput,
	ToggleSideMenu,
	SubmenuItem,
  SortableList
} from 'components'
import ViewModal from 'containers/main/views/ViewModal'
import { validateView } from 'validations/views'
import { useHistory } from 'react-router-dom'
import { buildQuery } from 'lib/helpers/adminly'

const ViewLeftPanel = ({ projectId, ...props }) => {
  const { 
    isEditing,
    collectionsOpen,
    setCollectionsOpen,
    viewsOpen,
    setViewsOpen 
  } = useContext(AppContext)

  const history = useHistory()
  const {showAlertError} = useAlerts()

  const { 
    activeCollection,
    setActiveCollection,

    activeView, 
    activeViews,

    setActiveViews,
    setActiveView,

    setTabIndex,
  } = useContext(ProjectContext)

  const { activeProject } = useActiveProject(projectId) 

  const [showModal, setShowModal] = useState(false)  
  const [showDeleteModal, setShowDeleteModal] = useState(false)  
  
	const [keywords, setKeywords] = useState('')

  const [myViews, setMyViews] = useState()
  const [visibleViews, setVisibleViews] = useState()
  const [visibleCollections, setVisibleCollections] = useState()

  const { currentUser, fetchMe } = useAuth()

	const { 
    isLoading,
    view,
    setView,
    views, 
    findViews,
    createView,
    updateView,
    deleteView,
    handleChange,
    updateViewPositions,
    reloadViews
  } = useViews({
		projectId: activeProject?.id,
	})  

  const { 
    isLoading: isLoadingCollection,
    collections, 
    findCollections    
  } = useCollections({
		projectId: activeProject?.id,
	})  

	const handleViewClick = (view) => {  
    setActiveView(view)    
    setActiveCollection(null)
    let query = buildQuery(view?.query)        
    let queryParams = decodeURIComponent(new URLSearchParams(query).toString())    
    let path = `/projects/${activeProject?.id}/views/${view.id}/collections/${view?.db_name}?${queryParams}`
    history.push(path)
	}

  const handleCollectionClick = (collection) => {  
    setActiveView(null)    
    setActiveCollection(collection)
    let path = `/projects/${activeProject?.id}/collections/${collection.db_name}`
    history.push(path)
	}

	const handleKeywordChange = (ev) => {
		let { value } = ev.target
		setKeywords(value)
		let filteredViews = activeViews.filter((c) => c?.name?.toLowerCase().indexOf(value.toLowerCase()) != -1)
		setVisibleViews(filteredViews)    

    let filteredCollections = collections
      .filter((c) => c?.name?.toLowerCase().indexOf(value.toLowerCase()) != -1)
    setVisibleCollections(filteredCollections)
	}

  const handleEditClick = (view) => {
    setView(view)
    setShowModal(true)
  }

  const handleDeleteClick = (view) => {
    setView(view)
    setShowDeleteModal(true)
  }

  const handleDeleteView = async () => {
    await deleteView(view?.id)
    setShowDeleteModal(false)
    reloadViews()
  }

  const handleSaveView = async () => {
    let validate = validateView(view)
    if(validate.isValid){
      let newView 
      if(view?.id){
        newView = await updateView(view)
      }else{
        newView = await createView(view)
      }
      setShowModal(false)
      reloadViews()      
    }else{
      validate.messages.map(m => showAlertError(m))
    }
  }

  const handleOnDropMine = async (views) => {
    updateViewPositions(views)  
    setMyViews(views)
  }

  const handleOnDropShared = async (views) => {
    updateViewPositions(views)    
    setVisibleViews(views)
  }

	useEffect(() => {
		if (activeProject?.id && !isEditing) {
			findViews()
      findCollections()
		}
	}, [activeProject?.id, isEditing])

	useEffect(() => {
		setActiveViews(views)
	}, [views])

  useEffect(() => {
    setVisibleCollections(collections)
  }, [collections])

  useEffect(()  => {
    if(activeViews){
      let visible = activeViews.sort((a,b) => a.position - b.position)      
      setVisibleViews(visible)
    }    
  }, [activeViews])

  const handleSelectView = (view) => {
    setTabIndex(null)
    handleViewClick(view)
  }

  const handleSelectCollection = (collection) => {    
    setTabIndex(null)
    handleCollectionClick(collection)
  }

  useEffect(() => {    
    if(!currentUser?.id){
      fetchMe()
    }
  }, [currentUser])

	return (
		<Box sx={ sx.root }>
      <LeftPanelHeader 
        title="Views"
        icon={ Database }
        actions={
          <ToggleSideMenu />
        }
      />
			<Box sx={ sx.header }>
        <TextInput
          value={keywords}
          handleChange={handleKeywordChange}
          placeholder={'Search...'}
          styles={ sx.searchInput }
        />
			</Box>
        { !isEditing && (  
          <>
            <ListExpandable 
              label={'Views'}
              open={ viewsOpen }
              handleToggle={() => setViewsOpen(!viewsOpen)}
            >
              { visibleViews?.length > 0 ? 
                visibleViews.map((view, i) => (
                <SubmenuItem
                  key={i}
                  label={view?.name}
                  value={view?.id}
                  selected={ activeView?.id }
                  handleClick={() => handleSelectView(view)}
                />
              )):(
                <Box sx={ sx.empty  }>
                  <Typography variant="body2" sx={ sx.emptyText }>
                    No views yet. 
                  </Typography>
                </Box>
              )}
            </ListExpandable> 

            <ListExpandable 
              label={'Collections'}
              open={ collectionsOpen }
              handleToggle={() => setCollectionsOpen(!collectionsOpen)}
            >
              { visibleCollections?.length > 0 ? 
                visibleCollections.map((collection, i) => (
                <SubmenuItem
                  key={i}
                  label={collection?.db_name}
                  value={collection?.id}
                  selected={ activeCollection?.id }
                  handleClick={() => handleSelectCollection(collection)}
                />
              )):(
                <Box sx={ sx.empty  }>
                  <Typography variant="body2" sx={ sx.emptyText }>
                    No collections. 
                  </Typography>
                </Box>
              )}
            </ListExpandable> 
          </>
        )}        

      { isEditing && ( 
        <>
        <Typography variant="body2" sx={ sx.caption }>
          Views
        </Typography>
        <SortableList
          droppableId={'draggable-views'}
          items={visibleViews}
          handleDrop={ handleOnDropShared }
          renderItem={(view) => (
            <SubmenuItem
              draggable       
              showDragIcon               
              label={view?.name}
              value={view?.id}              
              selected={ activeView?.id }

              showMenu
              handleClick={() => handleSelectView(view)}
              handleEditClick={() => handleEditClick(view) }
              handleDeleteClick={() => handleDeleteClick(view) }
            />
          )
        }        
        />
        </>
      )}  
      <ViewModal 
        isLoading={isLoading}
        view={view}
        open={showModal}        
        handleSubmit={ handleSaveView }
        handleClose={() => setShowModal(false)}
        handleChange={handleChange}
      />
      <DeleteWarningModal
        isLoading={isLoading}
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteView}        
      />
		</Box>
	)
}

export default ViewLeftPanel

const sx = {
	root: {
    width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
    overflowY: 'hidden',
    backgroundColor: theme => theme.palette.background.dark
	},
  searchInput: {
    '& input, & textarea': {
      borderRadius: 0,
      backgroundColor: '#16304B', 
      border: 'none',   
      color: 'common.white',
      m: 0,
      '&::placeholder': {       
        color: 'common.white',
      },
      '&:focus': {
        border: 'none'
      }    
    }
  },
	header: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	avatar: {
		backgroundColor: 'text.secondary'
	},
  caption: {
    mt: 2,
    mr: 2,
    mb: 0,
    ml: 2,    
    fontWeight: 500,
    fontSize: 12,
    color: 'common.white',
    textTransform: 'uppercase'
  },
  button: {
    color: 'text.secondary',
    mt: 1
  },
  empty: {    
    p: 1,
    my: 0,
    mx: 1,    
    borderRadius: theme => theme.shape.borderRadius
  },
  emptyText: {
    color: theme => alpha(theme.palette.common.white, 0.6)
  }
}

