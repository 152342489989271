import React, { useState } from 'react'
import {  
	ButtonLoader,
	Modal,
	ProgressLoader,
} from 'components'
import { Box, Button, List, Tabs, Tab } from '@mui/material'

import FieldForm from 'containers/main/fields/FieldForm'
import FieldListItem from 'containers/main/fields/FieldListItem'
import { COLUMN_TYPES, REFERENCE_TYPES } from 'lib/constants'
import { useEffect } from 'react'

const FieldModal = ({
	isLoading,
	open,
	field,
	handleClose,
	handleChange,
	handleSubmit
}) => {

	const isEditing = field && field.id

  const [friendlyTypes, setFriendlyTypes] = useState(COLUMN_TYPES)
  
  const [tab, setTab] = useState(0)
  const handleTabChange = (ev, newValue) => {
    setTab(newValue)
  }

	const handleFieldTypeClick = (field) => {
    handleChange({
      target: {
        name: 'friendly_type',
        value: field.value        
      },
    })
	}

  const handleSetFriendlyTypes = (field) => {
    let filteredTypes = COLUMN_TYPES 
    switch(field?.field_type){
      case 'column':
        filteredTypes = COLUMN_TYPES.filter(f => f.db_type === field.db_type)    
        break
      case 'reference': 
        filteredTypes = REFERENCE_TYPES.filter(f => f.field_type === field.field_type)    
        break
    }
    setFriendlyTypes(filteredTypes)
  }

  useEffect(() => {
    if(Object.keys(field || {})?.length > 0) {
      handleSetFriendlyTypes(field)
    }
  }, [field])

	return (
		<Modal
			open={open}
			handleClose={handleClose}
			title={isEditing ? "Edit Field" : "Add Field"}
			actions={
				<Button
					color="primary"
					variant="contained"
					onClick={handleSubmit}
					startIcon={isLoading && <ButtonLoader />}
				>
					{isEditing ? 'Update' : 'Save'}
				</Button>
			}
      p={0}
		>               
			<Box>
        { isLoading ? (
					<ProgressLoader />
				):(        
        <Box>
          <Tabs fullWidth color="primary" value={tab} onChange={ handleTabChange }>
            <Tab variant="fullWidth" label="FIELD" value={0} />
            <Tab  variant="fullWidth" label="SETTINGS" value={1} />
          </Tabs>   
          <Box width='100%' sx={ sx.content }>
            { tab === 0 && (
              <List sx={ sx.list }>
                {friendlyTypes.map((friendlyType, i) => (
                  <FieldListItem
                    key={i}
                    field={friendlyType}
                    selected={field?.friendly_type === friendlyType.value}
                    handleClick={() => handleFieldTypeClick(friendlyType)}
                  />
                  )
                )}
              </List>
            )}
            <FieldForm
              tab={tab}
              isEditing={isEditing}
              field={field}
              handleChange={handleChange}
            />
          </Box>
        </Box>
        )}
      </Box>
		</Modal>
	)
}

export default FieldModal

const sx = {
	content: {
		p: 2,
		display: 'flex',
		flexDirection: 'column',
		flexScale: 1,
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
	},
	fieldType: {
		width: '100%',
		mb: 2,
	},
	list: {
		width: '100%',
	},
}
