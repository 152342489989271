import React, { useState, useEffect, useContext } from 'react'
import { useAlerts, useLoaders } from 'hooks'
import { AdminlyContext } from 'context'

const useSQL = ({ projectId }) => {

  const { 
    isLoading, 
    showLoading, 
    hideLoading 
  } = useLoaders()
  
  const { api } = useContext(AdminlyContext)
  const { showAlertError } = useAlerts()

  const [sql, setSql] = useState()
  const [data, setData] = useState()
  
  const [rows, setRows] = useState()
  const [headers, setHeaders] = useState()

  const handleChange = (e) => {
    setSql(e.target.value)
  }

  const execute = async (sql) => {
    try {
      showLoading()
      const resp = await api.post('/query', { 
        query: {
          sql: sql 
        }
      })
      if(resp?.data){
        setData(resp.data)
      }
      return resp?.data 
    }catch(e){   
      showAlertError(e?.data?.error)   
      console.log(e)
    }finally{
      hideLoading()
    }
  }

  useEffect(() => {
    if(data && data.length > 0){ 
      let dataHeaders = Object.keys(data[0])
      let dataRows = data.map(row => Object.values(row))
      setHeaders(dataHeaders)
      setRows(dataRows)
    }       
  }, [data])

  return {
    isLoading,
    sql,
    setSql,
    data,
    rows,
    headers,
    handleChange,
    execute
  }
}

export default useSQL 