import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'components'
import { Box } from '@mui/material'
import AdminlyTable from 'containers/main/adminly/AdminlyTable'

const AdminlyTableModal = ({
	open,
	collection,
	handleClose,
	onRowClick,
	defaultQuery,
	...props
}) => {

	return (
		<Modal
			fullScreen
			open={open}
			handleClose={handleClose}
			title={'Browse'}      
		>
      <Box mt={4}>
        <AdminlyTable
          disableEditing
          disableNavigation
          handleClose={handleClose}
          collection={collection}
          onRowClick={onRowClick}
          defaultQuery={defaultQuery}
        />
      </Box>
		</Modal>
	)
}

AdminlyTableModal.propTypes = {
	collection: PropTypes.object.isRequired,
}

export default AdminlyTableModal

