import React, { useContext } from 'react'
import { ProjectContext } from 'context'
import PropTypes from 'prop-types'
import { 
  Panel, 
  PanelLayout, 
  PanelHeader, 
  ProgressLoader, 
  LeftPanel,
  CenterPanel 
} from 'components'
import { Box } from '@mui/material'
import StripeSubscription from 'containers/main/payments/StripeSubscription'
import SettingsLeftPanel from 'containers/main/settings/SettingsLeftPanel'
import { useActiveProject } from 'hooks'
import SubscriptionPlans from 'containers/main/payments/SubscriptionPlans'

const SettingsPayments = (props) => {
  const activeTab = "payments"
  const { id: projectId, tab } = props.match.params
	const { activeProject } = useActiveProject(projectId)

	return (
    <PanelLayout>
      <LeftPanel>
        <SettingsLeftPanel 
          activeTab={ activeTab }
        />
      </LeftPanel>
      <CenterPanel>
        <Box>
          <PanelHeader
            enableBackButton
            title="Billing & Payments"
          />
          <Box>
            {activeProject ? (
              <Box>
                <SubscriptionPlans                   
                  projectId={ projectId }                  
                  cloudHosted={ activeProject.hosting === 'cloud' ? true : false}
                />            
                <Panel>
                  <StripeSubscription />
                </Panel>
              </Box>
            ) : (
              <Panel>
              <Box sx={ sx.container }>
                <ProgressLoader />
              </Box>
              </Panel>
            )}
         
          </Box>
        </Box>
      </CenterPanel>
    </PanelLayout>
	)
}

SettingsPayments.propTypes = {
}

export default SettingsPayments

const sx = {
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	divider: {
		borderColor: 'common.border'
	},
}
