import React, { useContext } from 'react'
import { ProjectContext } from 'context'
import { AdminlyProvider } from 'context'
import { Box } from '@mui/material'
import AdminlyTable from 'containers/main/adminly/AdminlyTable'

const CollectionTable = ({
  defaultQuery,
	collection,
  handleUpdateView,
  enableSaveView,
	...props
}) => {

	const { 
    activeProject 
  } = useContext(ProjectContext)  

	return (
		<Box>
			{ activeProject && (
				<AdminlyProvider
					token={activeProject?.jwt_token}
					url={activeProject?.api_url}
				>
					<AdminlyTable
            enableSaveView={enableSaveView}
            collection={collection}
            defaultQuery={defaultQuery}
            handleUpdateView={handleUpdateView}				
					/>
				</AdminlyProvider>
			)}
		</Box>
	)
}

export default CollectionTable
