import React from 'react'
import { Box } from '@mui/material'

const Section = ({ 
    children, 
    bgColor='white', 
    p=4, 
    styles={},
    ...props 
  }) => {

return(
  <Box 
    sx={{ 
      backgroundColor: bgColor,
      p: p,
      ...styles
    }}
  >
    { children }
  </Box>
  )
}

export default Section 