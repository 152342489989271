import React, { useState, useEffect } from 'react'
import {
	useTabs,
	useActiveAddon,
	useActiveProject,
	useAuth0,
	useAlerts,
} from 'hooks'
import { useHistory } from 'react-router-dom'
import {
	Panel,
	PanelHeader,
	CenterPanel,
	Placeholder,
	ProgressLoader,
} from 'components'
import { Box, Button, List } from '@mui/material'
import Auth0User from 'containers/apps/auth0/Auth0User'
import Auth0Icon from 'assets/icons/auth0.svg'
import Auth0Modal from 'containers/apps/auth0/Auth0Modal'
import { UserPlus } from 'react-feather'

const Auth0Container = ({ ...props }) => {
	useTabs('auth0')

	const history = useHistory()


	const { showAlertError, showAlertSuccess } = useAlerts()
	const [showModal, setShowModal] = useState(false)
	const { id: projectId } = props.match.params
	const { activeProject } = useActiveProject(projectId)

	const { addon, installed } = useActiveAddon({
		provider: 'auth0',
	})

	const {
		isLoading,
		user,
		users,
		findUser,
		findUsers,
		createUser,
		updateUser,
		deleteUser,
		setUser,
		reloadUsers,
		handleChange,
	} = useAuth0({
		projectId: projectId,
	})

	const handleAddonsClick = () => {
		history.push(`/projects/${activeProject?.id}/apps/all`)
	}

	const handleSubmit = async () => {
		let resp
		if (user.user_id) {
			resp = await updateUser({
				id: user?.user_id,
				name: user?.name,
				nickname: user?.nickname,
			})
		} else {
			resp = await createUser(user)
		}
		if (resp?.user_id) {
			setShowModal(false)
			await reloadUsers()
			showAlertSuccess('User saved successfully')
		} else {
			showAlertError('There was an error saving the user')
		}
	}

	const handleAddClick = () => {
		setUser({})
		setShowModal(true)
	}

	const handleEditClick = (user) => {
		setUser(user)
		setShowModal(true)
	}

	const handleDeleteClick = async (user) => {
		await deleteUser(user?.user_id)
		await reloadUsers()
		setShowModal(false)
	}

	useEffect(() => {
		if (projectId) {
			findUsers()
		}
	}, [projectId])

	return (
		<Box>
			<CenterPanel>
				<Panel p={0}>
					<PanelHeader
						title={activeProject ? 'Auth0' : 'Loading...'}
						subtitle={'Manage your Auth0 users'}
						image={addon?.app?.icon_url}
						actions={
							<Button
								startIcon={
									<UserPlus
										sx={ sx.standardIcon }
									/>
								}
								variant="outlined"
								color="secondary"
								onClick={handleAddClick}
							>
								Add User
							</Button>
						}
					/>

					<Box>
						{!isLoading ? (
							<Box>
								{users?.length > 0 ? (
									<List>
										{users.map((user, idx) => (
											<Auth0User
												key={idx}
												user={user}
												handleEditClick={handleEditClick}
												handleDeleteClick={
													handleDeleteClick
												}
											/>
										))}
									</List>
								) : (
									<Placeholder
										icon={
											<img
												src={addon?.app?.icon_url}
												width={32}
												height="auto"
											/>
										}
										title="No users"
										subtitle="Please try another search"
									/>
								)}
							</Box>
						) : (
							<ProgressLoader />
						)}
					</Box>

					{!installed && !isLoading && (
						<Box sx={ sx.placeholder }>
							<Placeholder
								icon={
									<img
										src={Auth0Icon}
										sx={ sx.icon }
									/>
								}
								title="Auth0 addon not installed."
								subtitle="To use Auth0, install the Auth0 addon."
								actions={
									<Button
										onClick={handleAddonsClick}
										variant="outlined"
										color="primary"
									>
										Install App
									</Button>
								}
							/>
						</Box>
					)}
				</Panel>
			</CenterPanel>
			<Auth0Modal
				open={showModal}
				handleClose={() => setShowModal(false)}
				user={user}
				handleChange={handleChange}
				handleSubmit={handleSubmit}
			/>
		</Box>
	)
}

export default Auth0Container

const sx = {
	icon: {
		height: 32,
		width: 32,
		color: 'secondary.main'
	},
	standardIcon: {
		height: 20,
		width: 20,
	},
}
