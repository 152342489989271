import React from 'react'
import { Box, Chip, TextField, Typography } from '@mui/material'

import Autocomplete from '@mui/material/Autocomplete'

const MultiAutosuggest = ({
	value,
	options,
	label,
	name,
  freeSolo=false,
	placeholder = 'Select',
	handleChange,
	handleInputChange,
	...props
}) => {


	const handleOnChange = (event, values) => {
		handleChange({
			target: {
				name: name,
				value: values,
			},
		})
	}

	return (
		<Box sx={ sx.container }>      
			<Typography
				variant="caption"
				color="textSecondary"
				sx={ sx.label }
			>
				{label}
			</Typography>
			{value && (
				<Autocomplete
					multiple
          freeSolo={freeSolo}
					sx={{ inputRoot: sx.inputRoot }}
					defaultValue={value}
					onChange={handleOnChange}
					options={options}
					getOptionLabel={(option) => option}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip 
                label={option} 
                sx={ sx.chip  }
                {...getTagProps({ index })} 
              />
						))
					}
					renderInput={(params) => {
						return (
							<TextField
								{...params}
								variant="outlined"
								placeholder={placeholder}
							/>
						)
					}}
				/>
			)}
		</Box>
	)
}

export default MultiAutosuggest

const sx = {
	root: {},
	inputRoot: {
		backgroundColor: 'common.white',
		p: '5px'
	},
	label: {
		mb: 0,
	},
  chip: {
    backgroundColor: 'primary.light'
  }
}
