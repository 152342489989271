import React, { useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import { Accordian, QuestionAnswer, Section } from 'components'

export default function SectionTwo ({ 
    faqs,
    ...props
  }) {  

  return (
  <Section bgColor='common.white'>
    <Container maxWidth="lg">      
      <center> 
        <Typography variant="h2" mb={2}>
          FAQ 
        </Typography>
      </center>
      { faqs.map((faq, i) => (
        <Accordian 
          key={i}
          title={ faq.question }
          description={ faq.answer }
        />
      ))}      
    </Container>
  </Section>
  );
}

const sx = {
  root: {},
};
