import React from 'react'
import { Route } from 'react-router'
import { Switch } from 'react-router-dom'
import { AuthRoute } from 'components'

//auth
import Login from 'containers/main/auth/Login'
import Signup from 'containers/main/auth/Signup'
import ForgotPassword from 'containers/main/auth/ForgotPassword'
import ResetPassword from 'containers/main/auth/ResetPassword'

// OAuth
import HerokuAuthorize from 'containers/main/auth/oauth/HerokuAuthorize'

//routes
import AddonContainer from 'containers/main/addons/AddonContainer'
import CollectionContainer from 'containers/main/collections/CollectionContainer'
import ProjectList from 'containers/main/projects/ProjectList'
import ProjectNew from 'containers/main/projects/onboarding/Step1-ProjectNew'
import ProjectHosting from 'containers/main/projects/onboarding/Step2-ProjectCloudHosting'
import ProjectPayment from 'containers/main/projects/onboarding/Step5-ProjectPayment'
import ProjectDeploy from 'containers/main/projects/onboarding/Step4-ProjectDeploy'
import ProjectInvite from 'containers/main/projects/onboarding/Step5-ProjectInvite'
import SchemaContainer from 'containers/main/schema/SchemaContainer'
import SqlContainer from 'containers/main/sql/SqlContainer'

import AdminlyShow from 'containers/main/adminly/AdminlyShow'
import AdminlyEdit from 'containers/main/adminly/AdminlyEdit'

//Settings 
import SettingsApps from 'containers/main/settings/SettingsApps'
import SettingsProject from 'containers/main/settings/SettingsProject'
import SettingsUsers from 'containers/main/settings/SettingsUsers'
import SettingsPayments from 'containers/main/settings/SettingsPayments'
import SettingsHeroku from 'containers/main/settings/SettingsHeroku'

// Landing 
import Home from 'containers/landing/Home'
import Pricing from 'containers/landing/Pricing'
import TermsOfService from 'containers/landing/Legal/TermsOfService'
import PrivacyPolicy from 'containers/landing/Legal/PrivacyPolicy'

// Apps
import Auth0Container from 'containers/apps/auth0/Auth0Container'
import CloudinaryContainer from 'containers/apps/cloudinary/CloudinaryContainer'
import HerokuContainer from 'containers/main/heroku/HerokuContainer'

// Users 
import UserNew from 'containers/main/users/UserNew'
import UserShow from 'containers/main/users/UserShow'
import UserEdit from 'containers/main/users/UserEdit'
import UserList from 'containers/main/users/UserList'

//Admin
import AdminProjectNew from 'containers/admin/projects/ProjectNew'
import AdminProjectShow from 'containers/admin/projects/ProjectShow'
import AdminProjectEdit from 'containers/admin/projects/ProjectEdit'
import AdminProjectList from 'containers/admin/projects/ProjectList'

const Routes = () => (
	<Switch>
		<Route path="/" component={Home} exact />
    <Route path="/pricing" component={Pricing} exact />
    <Route path="/terms_of_service" component={TermsOfService} exact />
    <Route path="/privacy_policy" component={PrivacyPolicy} exact />

		<Route path="/login/:token" component={Login} />
		<Route path="/login" component={Login} />
    <Route path="/register" component={Signup} />
		<Route
			path="/forgot_password"
			component={ForgotPassword}
			exact
		/>
		<Route
			path="/reset_password/:token"
			component={ResetPassword}
			exact
		/>
		<Route
			path="/users/passwords/edit"
			component={ResetPassword}
			exact
		/>

		<Route
			path="/heroku/authorize"
			component={HerokuAuthorize}
			exact
		/>
		<AuthRoute
			path="/projects"
			component={ProjectList}
			exact
		/>
		
    <AuthRoute path="/projects/onboarding/step1" component={ProjectNew} exact />
    <AuthRoute path="/projects/:id/onboarding/step2" component={ProjectHosting} exact />
    <AuthRoute path="/projects/:id/onboarding/step3" component={ProjectPayment} exact />
		<AuthRoute path="/projects/:id/onboarding/step4" component={ProjectDeploy} exact />
		<AuthRoute path="/projects/:id/onboarding/step5" component={ProjectInvite} exact />

		<AuthRoute
			path="/projects/:id"
			component={ProjectList}
			exact
		/>

		<AuthRoute
			path="/projects/:id/auth0"
			component={Auth0Container}
			exact
		/>

		<AuthRoute
			path="/projects/:id/addons/:app_id"
			component={AddonContainer}
			exact
		/>

    <AuthRoute path="/projects/:id/sql" component={SqlContainer} exact />


		<AuthRoute path="/projects/:id/collections" component={CollectionContainer} exact />
		<AuthRoute path="/projects/:id/collections/:collection_id" component={CollectionContainer} exact />
		<AuthRoute path="/projects/:id/views/:view_id/collections/:collection_id" component={CollectionContainer} exact />
        
    <AuthRoute path="/projects/:id/collections/:collection_id/resources/new" component={AdminlyEdit} exact />
    <AuthRoute path="/projects/:id/collections/:collection_id/resources/edit" component={AdminlyEdit} exact />
    <AuthRoute path="/projects/:id/collections/:collection_id/resources/:resource_id" component={AdminlyShow} exact />
    <AuthRoute path="/projects/:id/collections/:collection_id/resources/:resource_id/edit" component={AdminlyEdit} exact />        

		<AuthRoute
			path="/projects/:id/schema"
			component={SchemaContainer}
			exact
		/>

		<AuthRoute
			path="/projects/:id/schema/:collection_id"
			component={SchemaContainer}
			exact
		/>

		<AuthRoute
			path="/projects/:id/cloudinary"
			component={CloudinaryContainer}
			exact
		/>
		<AuthRoute
			path="/projects/:id/cloudinary/upload"
			component={CloudinaryContainer}
			exact
		/>

		<AuthRoute
			path="/projects/:id/heroku"
			component={HerokuContainer}
			exact
		/>
		<AuthRoute
			path="/projects/:id/heroku/:tab"
			component={HerokuContainer}
			exact
		/>

    <AuthRoute path="/projects/:id/settings/apps" component={SettingsApps} exact />
		<AuthRoute path="/projects/:id/settings/details" component={SettingsProject} exact />
    <AuthRoute path="/projects/:id/settings/heroku" component={SettingsHeroku} exact />
		<AuthRoute path="/projects/:id/settings/payments" component={SettingsPayments} exact />    
		<AuthRoute path="/projects/:id/settings/users" component={SettingsUsers} exact />

		<AuthRoute
			path="/projects/:id/users"
			component={UserList}
			exact
		/>
		<AuthRoute
			path="/projects/:id/users/new"
			component={UserNew}
			exact
		/>
		<AuthRoute
			path="/projects/:id/users/:user_id"
			component={UserShow}
			exact
		/>

		<AuthRoute
			path="/users/:id/edit"
			component={UserEdit}
			exact
		/>

		<AuthRoute
			path="/admin/projects/new"
			component={AdminProjectNew}
			exact
		/>
		<AuthRoute
			path="/admin/projects/:id/edit"
			component={AdminProjectEdit}
			exact
		/>
		<AuthRoute
			path="/admin/projects"
			component={AdminProjectList}
			exact
		/>
		<AuthRoute
			path="/admin/projects/:id"
			component={AdminProjectShow}
			exact
		/>
	</Switch>
)

export default Routes
