import { colors } from '@mui/material'

export default {
	primary: {		
		main: '#2A66E4',
    contrastText: '#ffffff',
	},
	secondary: {
		main: '#26A69A',
	},
  success: {
    main: '#5FEB9E',
  },
	error: {
    dark: '#AB1325',
    light: '#FEBDC5',
		main: '#FEBDC5',
		contrastText: '#ffffff',
	},
	warning: {
    dark: '#BF470A',
		main: '#FFE9C3',
		light: '#FFE9C3',
		contrastText: '#ffffff',
	},
	text: {
		primary: '#23263B',
		secondary: '#737F8F',
		link: '#6146EA',
	},
	heroku: {
		dark: '#61467F',
		main: '#734B84',
		light: '#85518C',
	},
	link: '#23263B',
	icon: '#5A5E9A',
	background: {
		main: '#F5F7F9',    
		default: '#FCFCFD',
		dark: '#021E3B',
		paper: '#FFFFFF',
    section: '#F6F9FC'    
	},
	common: {		
		border: '#E5E5F1',
    darkBorder: '#033467',		
		errorBorder: colors.pink[300],
    gradient: '#F5F7FE',		
    grey: colors.grey[400],
    heroku: '#430097',		
		highlight: '#EEF2FD',
    iconDark: '#0B132B',
    inputBorder: '#d6d6e7',
		skeleton: '#E9ECF8'
	},
	divider: '#0B132B',
}
