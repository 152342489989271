import React from 'react'
import { useResource } from 'hooks'

const useApps = ({ id, ...props }) => {
	const {
		resourceId: appId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: app,
		resources: apps,
		findOne: findApp,
		findMany: findApps,
		update: updateApp,
		create: createApp,
		save: saveApp,
		destroy: deleteApp,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reload: reloadApp,
		reloadMany: reloadApps,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		setResource: setApp,
	} = useResource({
		id: id,
		url: '/api/v1/public/apps',
		name: 'app',
	})

	return {
		appId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		app,
		apps,
		findApp,
		findApps,
		saveApp,
		updateApp,
		createApp,
		deleteApp,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		reloadApp,
		reloadApps,
		sortKey,
		sortDirection,
		handleSort,
		setApp,
	}
}

export default useApps
