import palette from './palette'

export default {
	h1: {
		fontFamily: 'Inter',
		fontWeight: 700,
		fontSize: 64,
		lineHeight: '1.1em',
		'@media (max-width:600px)': {
			fontSize: 52,
		},
	},
	h2: {
		fontFamily: 'Inter',
		fontWeight: 700,
		fontSize: 48,
		'@media (max-width:600px)': {
			fontSize: 36,
		},
		lineHeight: '1.3em',
	},
	h3: {
		fontFamily: 'Inter',
		fontWeight: 700,
		textIndent: 0,
		fontSize: 40,
    '@media (max-width:600px)': {
			fontSize: 32,
		},
		lineHeight: '1.3em',
	},
	h4: {
		fontFamily: 'Inter',
		fontWeight: 600,
		fontSize: 18,
		lineHeight: '1.2em',
	},
	h5: {
		fontFamily: 'Inter',
		fontWeight: 700,
		fontSize: 16,
		lineHeight: '2.0em',
	},
	h6: {
		fontFamily: 'Inter',
		fontWeight: 700,
		fontSize: '18px',
		lineHeight: '2.0em',
	},
	subtitle1: {
    fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 600,
		lineHeight: '1.5em',
	},
	subtitle2: {
    fontFamily: 'Inter',
		fontWeight: 500,
		fontSize: 15,
		lineHeight: '2.0em',
	},
	body1: {
		fontWeight: 400,		
		fontSize: 14,
		lineHeight: '1.5em',
	},
	body2: {
		fontFamily: 'Roboto',		
		fontSize: 14,
		fontWeight: 400,
		lineHeight: '1.5em',
		textDecoration: 'none',
	},
	body3: {
		fontFamily: 'Roboto',
		fontSize: 14,
		fontWeight: 400,
		textDecoration: 'none',
	},
	button: {
		textTransform: 'none',
		fontWeight: 500,
		fontFamily: 'Roboto',
		fontSize: 12,
	},
	caption: {
		fontFamily: 'Roboto',
		fontSize: 14,
		fontWeight: 500,		
	},
	overline: {
		fontFamily: 'Mohave',
		color: palette.text.secondary,
		fontSize: 14,
		fontWeight: 500,		
		textTransform: 'uppercase'
	},
}
