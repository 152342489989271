import React, {
	useState,
	useEffect
} from 'react'
import {
	Panel,
} from 'components'
import {
  useAlerts,
  useCollections,
  useChatGPT,  
  useSQL
} from 'hooks'
import SqlForm from 'containers/main/sql/SqlForm'
import { 
  Box,
} from '@mui/material'
import ChatGPTForm from 'containers/main/sql/ChatGPTForm'
import ChatTableDrawer from 'containers/main/sql/ChatTableDrawer'
import SqlTable from 'containers/main/sql/SqlTable'

const SqlQuery = ({ projectId, ...props }) => {

  const { showAlertError } = useAlerts()

  const [openTable, setOpenTable] = useState(false)
  const [tableNames, setTableNames] = useState([])
  const [activeTables, setActiveTables] = useState([])

  const {
    isLoading: isChatLoading,
    prompt,
    sql: sqlText,
    query, 
    handleChange: handleChatChange,
    execute: executePrompt,
  } = useChatGPT({
    projectId: projectId
  })	  

  const {
    isLoading,
    sql,
    setSql,
    headers,
    rows,
    handleChange,
    execute: executeSql
  } = useSQL({
    projectId: projectId
  })	  

  const { 
    isLoading: isCollectionsLoading,
    collections,
    findCollections
  } = useCollections({
    projectId: projectId 
  })
  
  const handleTableSelect = (table) => {
		if (activeTables.find((t) => t === table)) {
			setActiveTables(activeTables.filter((tt) => tt != table))			
		} else {
			setActiveTables(activeTables.concat(table))			
		}
	}

  const handleChatSubmit = async () => {
    if(!activeTables.length > 0){
      showAlertError("Please select at least one collection to query.")
      return null 
    }else{
      let resp = await executePrompt({
        prompt: prompt,
        tables: activeTables
      })
      if(resp){
        setSql(resp)
      }    
    }    
  }

  const handleSubmit = async () => {
    await executeSql(sql)
  }

  useEffect(() => {
    if(collections){
      setTableNames(collections.map((c) => c.db_name))
    }
  }, [collections])

  useEffect(() => {
    if(projectId){
      findCollections({
        per_page: 100
      })
    }
  }, [projectId])

  useEffect(() => {
    if (sqlText) {
      setSql(sqlText)
    }
  }, [sqlText])

	return (
      <Box sx={ sx.root }>
        <Panel>
          <Box sx={ sx.forms }>
            <ChatGPTForm               
              query={query}
              prompt={ prompt }
              handleChange={ handleChatChange }
              handleSubmit={ handleChatSubmit }
              handleOpenDrawer={() => setOpenTable(true)}
            />
            <SqlForm 
              isLoading={ isLoading || isChatLoading }
              sql={ sql }
              handleChange={ handleChange }
              handleSubmit={ handleSubmit }
            />
          </Box>
        </Panel> 
        <SqlTable 
          query={ query }
          headers={ headers }
          rows={ rows }
        />            
        <ChatTableDrawer 
          open={ openTable }
          handleClose={() => setOpenTable(false)}                                
          tables={ tableNames }
          activeTables={ activeTables }
          handleClick={ handleTableSelect }
        />
    </Box>
	)
}

export default SqlQuery

const sx = {
	root: {
    width: { 
      xs: 'calc(100vw - 30px)',
      sm: '100%',
    },
	},
  table: {
    py: 0.5,
    px: 0,
    maxHeight: 'calc(100vh - 370px)',
    width: '100%',
    maxWidth: { 
      sm: 'calc(100vw - 350px)',
      xs: 'calc(100vw - 30px)',
    },
    overflowX: 'scroll',
  },
  forms: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px'
  },
	iconButton: {
		color: 'primary.main'
	},
	icon: {
		color: 'text.secondary'
	},
  loader: {
    height: '100%',
    width: '100%',
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}
