import React from 'react'
import { useAlerts, useAuth } from 'hooks'
import {
	Box,
	Button,
} from '@mui/material'
import GoogleIcon from 'assets/icons/google.svg'
import { GoogleLogin } from 'react-google-login'
import { useHistory } from 'react-router'

const GoogleLoginButton = ({
	label = 'Sign In with Google',
  googleClientId, 
	...props
}) => {
	
	const history = useHistory()
	const { showAlertError } = useAlerts()

	const { googleLogin } = useAuth()

	const handleSuccess = async (auth) => {
		await googleLogin({
      access_token: auth.accessToken,
    })		
	}

	const handleFailure = (ev) => {
		console.log(ev)
	}

	return (
		<Box py={1}>
			<GoogleLogin
				clientId={googleClientId}
				buttonText={label}
				onSuccess={handleSuccess}
				onFailure={handleFailure}
				scopes={['email', 'profile']}
				render={(renderProps) => (
					<Button
						fullWidth
            size="large"
						variant="outlined"
						sx={ sx.button }
						onClick={renderProps.onClick}
						disabled={renderProps.disabled}
					>
						<img
							src={GoogleIcon}
							style={ sx.icon }
						/>
						{label}
					</Button>
				)}
				cookiePolicy={'single_host_origin'}
			/>
		</Box>
	)
}

export default GoogleLoginButton

const sx = {
	icon: {
		height: 28,
		width: 28,
		marginRight: '16px',
	},
	button: {
		width: '100%',
		fontSize: 14,
    textTransform: 'none',
		backgroundColor: 'white',
		color: 'text.primary',
		'&&': {
			backgroundColor: 'white',
		},
	},
}
