import React from 'react'
import { Drawer } from 'components'
import AdminlyFilterForm from 'containers/main/adminly/AdminlyFilterForm'

const AdminlyFilterDrawer = ({
	open,
  query,
	handleClose,
	fields,
	collection,
	handleSearch,	
	handleChange,
	onKeyPress,
  handleSaveView,
  handleClearFilters,
  enableSaveView
}) => {
  
	return (
		<Drawer
			open={open}
			handleClose={handleClose}
			title="Search"
      sx={ sx.drawer  }
		>
			<AdminlyFilterForm
        query={query}
				fields={fields}
				collection={collection}
				handleSearch={handleSearch}
        onKeyPress={onKeyPress}
        handleChange={handleChange}
        handleSaveView={handleSaveView}
        handleClearFilters={handleClearFilters}
        enableSaveView={enableSaveView}
			/>     
		</Drawer>
	)
}

export default AdminlyFilterDrawer

const sx = {
  drawer: {
    zIndex: theme => theme.zIndex.modal
  }
}

