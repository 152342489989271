export const isPaid = (resource) => {
	return resource?.paid == true ? true : false
}

export const isStripe = (resource) => {
	return resource?.stripe_customer_id?.length > 0 ? true : false
}

export const isCloudinary = (resource) => {
	return findAddon(resource, 'cloudinary') ? true : false
}

export const findAddon = (project, provider) => {
	return project?.addons.find(
		(a) => a.provider === provider
	)
}

export const buildOptions = (items, key, value) => {
	if (!items) return null
	let options = []
	let list = []
	if (items.data) {
		list = items.data
	} else {
		list = items
	}
	list.forEach((item, idx) => {
		let label = item[value]
		if (Array.isArray(value)) {
			label = value
				.map((v) => eval(`item.${v}`))
				.join(' - ')
		}
		return options.push({ value: item[key], label: label })
	})
	return options
}

export const truncate = (str, length = 60) => {
  if(!str) return '';
  if (str.length > length) {
    return str.substring(0, length) + '...'
  }
  return str
}

export const syntheticEvent = (
	value,
	name,
	type = 'text'
) => {
	let ev = {
		target: {
			value,
			name,
			type,
		},
	}
	return ev
}
