import React from 'react'
import { useAlerts, useLoaders, useResource } from 'hooks'

const useUsers = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders()
	const { showAlertError, showAlertSuccess } = useAlerts()

	const {
		resourceId: userId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: user,
		resources: users,
		findOne: findUser,
		findMany: findUsers,
		update: updateUser,
		create: createUser,
		save: saveUser,
		destroy: deleteUser,
		handleChange,
		handleChangePage,
		reload: reloadUser,
		reloadMany: reloadUsers,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
	} = useResource({
		id: id,
		url: '/api/v1/users',
		name: 'user',
	})

	return {
		userId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		user,
		users,
		findUser,
		findUsers,
		saveUser,
		updateUser,
		createUser,
		deleteUser,
		handleChange,
		handleChangePage,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		reloadUser,
		reloadUsers,
		sortKey,
		sortDirection,
		handleSort,
	}
}

export default useUsers
