import React from 'react'
import { Grid } from '@mui/material'
import { TextInput, NoSpaceInput } from 'components'

const CollectionForm = ({
	isEditing,
	collection,
	handleChange,
}) => (
	<Grid container spacing={2}>
		<Grid item xs={12} sm={12}>
			<TextInput
				label="Collection name"
				value={collection.name || ''}
				name="name"
				placeholder="Name"
				handleChange={handleChange}
			/>
		</Grid>
	</Grid>
)

export default CollectionForm
