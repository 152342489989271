import React from 'react'
import { Box, Button } from '@mui/material'
import { Repeat } from '@mui/icons-material'

const FieldReference = ({
	value,
  displayValue,
	headerName,
  handleClick,
	...props
}) => {

	return (
		<Box sx={ sx.cell }>
			<Button
				color="secondary"   
        variant="outlined"     
				sx={ sx.button }
				endIcon={<Repeat />}
				onClick={ handleClick }
			>
				{displayValue ? displayValue : `${headerName} ${value}` }
			</Button>
		</Box>
	)
}

export default FieldReference

const sx = {
  cell: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start'
  },
	avatar: {
		backgroundColor: 'common.white',
	},
	button: {
		textTransform: 'none',
		fontFamily: theme => theme.typography.body3.fontFamily,
		letterSpacing: 0,
	},
}
