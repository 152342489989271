import React from 'react'
import { STRIPE_PUBLISHABLE_KEY } from 'lib/constants'
import { Box, Typography } from '@mui/material'
import StripeCreditCardForm from 'containers/main/payments/StripeCreditCardForm'
import { loadStripe } from '@stripe/stripe-js'
import {
	Elements,
	ElementsConsumer,
} from '@stripe/react-stripe-js'


const StripeCreditCardInput = ({ handleSubmit, isLoading, ...props }) => {
	const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)
  
	return (
    <Box>
      <Typography
        variant="subtitle1"
        color="textPrimary"
      >
        Add a Payment Method
      </Typography>
      <Typography variant="body2" color="textSecondary">
        We accept all major credit cards.
      </Typography>
      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <StripeCreditCardForm
              stripe={stripe}
              elements={elements}
              onSubmit={handleSubmit}
              isLoading={isLoading}
            />
          )}
        </ElementsConsumer>
      </Elements>
    </Box>
	)
}

export default StripeCreditCardInput

const sx = {
	root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
		p: 3,
	},
  content: {
  }
}
