import React from 'react'
import { Grid } from '@mui/material'
import { EditableTextInput } from 'components'

const ProjectEnvForm = ({
	env,
	isLoading,
	handleChange,
	handleSubmit,
}) => {
	return (
		<Grid container spacing={2}>
			{Object.keys(env).map((key, i) => (
				<Grid item xs={12} key={i}>
					<EditableTextInput
						label={key}
						value={env[key]}
						name={key}
						placeholder={key}
						handleChange={handleChange}
						handleSubmit={handleSubmit}
						isLoading={isLoading}
					/>
				</Grid>
			))}
		</Grid>
	)
}

export default ProjectEnvForm
