import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useUsers, useAlerts } from 'hooks'
import {
	Box,
	Paper,
	Button,
	Typography,
	Container,
} from '@mui/material'

import { Check } from '@mui/icons-material'
import { Subheader } from 'components'
import UserForm from 'containers/main/users/UserForm'

const UserNew = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params

	const {
		userId,
		isLoading,
		isLoaded,
		user,
		handleChange,
		createUser,
		findUser,
	} = useUsers({ id: id })

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleSaveClick = async () => {
		await createUser(user)
		showAlertSuccess('User has been created')
		history.push('/users')
	}

	useEffect(() => {
		if (id) findUser(id)
	}, [])

	return (
		<>
			<Subheader
				title="Add Users"
				buttons={[
					<Button
						color="primary"
						variant="contained"
						startIcon={<Check />}
						onClick={handleSaveClick}
					>
						Save
					</Button>,
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<UserForm
							user={user}
							handleChange={handleChange}
						/>
						<Box py={2}>
							<Button
								startIcon={<Check />}
								color="primary"
								variant="contained"
								onClick={handleSaveClick}
							>
								Save
							</Button>
						</Box>
					</Box>
				</Paper>
			</Container>
		</>
	)
}

export default UserNew
