import React from 'react'
import {
  Panel
} from 'components'
import {
  Box,
  Typography
} from '@mui/material'


const PanelButton = ({ title, description, actions }) => {

 

  return(
    <Panel p={4} my={2}>
      <Box sx={ sx.panel }>
        <Box sx={ sx.panelContent  }>
          <Typography variant="h5">
            { title }
          </Typography>
          <Typography variant="body2" color="textSecondary">
            { description }
          </Typography>
        </Box>
        <Box>
          { actions }
        </Box>
      </Box> 
    </Panel>
  )
}

export default PanelButton 

const sx = {
  panel: {
    display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
  },
  panelContent: {
    maxWidth: 540,
    pr: 2
  }
}