import React from 'react'
import {
	Box,
	SwipeableDrawer,
	Typography,
} from '@mui/material'
import { IconButton } from '@mui/material'
import { ChevronLeft } from 'react-feather'

const Drawer = ({
	open,
	title,
	anchor = 'right',
	subtitle,
	handleClose,
	children,
	width = 520,
	buttons,
  className,
	...props
}) => {

	return (
		<SwipeableDrawer
			open={open}
			variant="temporary"
			anchor={anchor}
			onOpen={handleClose}
			onClose={handleClose}
			PaperProps={{ sx: sx.paper }}
		>
			<Box sx={ sx.container }>
				<Box sx={ sx.header }>
					<Box sx={ sx.iconBar }>
						<IconButton onClick={handleClose}>
							<ChevronLeft />
						</IconButton>
					</Box>
					<Box>
						<Typography
							color="textSecondary"
							variant="caption"
							sx={ sx.title }
						>
							{title}
						</Typography>
					</Box>
					<Box sx={ sx.iconBar }></Box>
				</Box>
				<Box sx={ sx.content }>
					{children}
					<Box sx={ sx.buttons}>{buttons }</Box>
				</Box>
			</Box>
		</SwipeableDrawer>
	)
}

export default Drawer

const sx = {
	icon: {
		color: 'secondary.light'
	},
	header: {
		display: 'flex',
		flexDirecton: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: 50,		
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(-180deg,#fff,#fcfcfd)',
    borderBottom: '1px solid #d6d6e7',
	},
	button: {
		border: theme => `1px solid ${theme.palette.common.inputBorder}`,
		boxShadow: `rgb(0 0 0 / 5%) 0px 2px 4px !important`,
	},
	buttons: {
				my: 2,
    mx: 0,
	},
	paper: {		
    zIndex: `9999 !important`,
		backgroundColor: 'background.main'
	},
	title: {},
	iconBar: {
		width: 60,
	},
	container: {
		width: { sm: '100vw', md: '580px' }
	},
	content: {
		p: 2,
	},
}
