import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useScrollPosition } from 'hooks';
import { 
  AppBar, 
  Box, 
  Toolbar, 
} from '@mui/material';

const Header = ({ children, styles, ...props }) => {

  const { position } = useScrollPosition()
  const [shadow, setShadow] = useState(false)

  useEffect(() => {
    if(position.y > 20){
      setShadow(true)
    }else{
      setShadow(false)
    }
  }, [position?.y])

  return(
      <Box sx={{ ...sx.root, ...styles}}>
        <AppBar
          color='inherit'
          sx={{ 
            ...sx.appbar,
            ...(shadow && sx.appBarShadow) 
          }}
          position='fixed'
          elevation={0}
        >
          <Toolbar>
            { children }
          </Toolbar>
        </AppBar>
      </Box>
  )
}

Header.propTypes = {
  className: PropTypes.string
}

export default Header


const sx = {
  root: {
    height: 50,
    flexGrow: 1,
    backgroundColor: 'common.white'
  },
  appbar: {
    backgroundColor: 'common.white'
  },
  appBarShadow: {
    boxShadow: '0 4px 15px rgb(0,0,0,.1)',
  }
}
