import React from 'react'
import { useActiveProject, useResponsive } from 'hooks'
import { Panel } from 'components'
import { Stepper, Step, StepButton } from '@mui/material'
import { PROJECT_STEPS } from 'lib/constants'
import { useHistory } from 'react-router-dom'

const ProjectSteps = ({ step }) => {
  const history = useHistory()
  const { isMobile } = useResponsive()
  const { activeProject } = useActiveProject()

  const handleStepClick = (nextStep) => {
    if(nextStep == 0){
      history.push('/projects/new')
    }else if(nextStep <= step){
      let currentStep = PROJECT_STEPS.find(s => s.value === step )
      history.push(`/projects/${activeProject?.id}${currentStep.path}`)
    }
	}

	return (
    <Panel>
			<Stepper
				nonLinear
				activeStep={step}
				orientation={ isMobile ? 'vertical': 'horizontal'}
			>
				{PROJECT_STEPS.map((step, index) => (
					<Step key={index} sx={ sx.step }>
						<StepButton
							onClick={() => handleStepClick(step.value)}
						>
							{step.label}
						</StepButton>
					</Step>
				))}
			</Stepper>
    </Panel>
	)
}

export default ProjectSteps

const sx = {
	step: {},
}
