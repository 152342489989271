import React, { useState, useEffect } from 'react'
import { TextInput } from 'components'
import { XCircle, Edit, CheckCircle } from 'react-feather'
import { Box, IconButton, CircularProgress } from '@mui/material'


const EditableTextInput = ({
	value,
	name,
	label,
	handleChange,
	handleSubmit,
	isLoading,
	placeholder,
	...props
}) => {

	const [editing, setEditing] = useState(false)

	const handleSubmitInput = (name, value) => {
		setEditing(false)
		handleSubmit(name, value)
	}

	return (
		<Box sx={ sx.root }>
			<TextInput
				disabled={!editing}
				value={value}
				label={label}
				name={name}
				handleChange={handleChange}
				placeholder={placeholder}
			/>
			<Box sx={ sx.icons }>
				{isLoading ? (
					<CircularProgress
            disableShrink
						size={24}
						sx={ sx.icon }
					/>
				) : (
					<React.Fragment>
						{editing ? (
							<IconButton
								size="small"
								onClick={() => setEditing(false)}
								sx={ sx.iconButton }
							>
								<XCircle sx={ sx.icon } />
							</IconButton>
						) : (
							<IconButton
								size="small"
								onClick={() => setEditing(true)}
								sx={ sx.iconButton }
							>
								<Edit sx={ sx.icon } />
							</IconButton>
						)}
						{editing && (
							<IconButton
								size="small"
								onClick={() =>
									handleSubmitInput(name, value)
								}
								sx={ sx.iconButton }
							>
								<CheckCircle sx={ sx.icon } />
							</IconButton>
						)}
					</React.Fragment>
				)}
			</Box>
		</Box>
	)
}

export default EditableTextInput

const sx = {
	root: {
		display: 'flex',
		flexDirection: 'row',
		height: '100%',
		alignItems: 'center',
	},
	icons: {
		mt: 2,
		display: 'flex',
		flexDirection: 'row',
		height: '100%',
		alignItems: 'center',
	},
	icon: {
		height: 20,
		width: 20,
		color: 'secondary.main'
	},
	iconButton: {},
}
