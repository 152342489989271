import React from 'react'
import {
	SearchInput,
} from 'components'
import {
  Box,
	IconButton,
	Hidden,
	Badge,
	Button,	
} from '@mui/material'
import { FilterList } from '@mui/icons-material'
import { ArrowUpRight, Edit, Plus, Trash, X } from 'react-feather'
import AdminlyToolbarMenu from 'containers/main/adminly/AdminlyToolbarMenu'
import AdminlyFilterButton from 'containers/main/adminly/AdminlyFilterButton'

const AdminlyTableHeader = ({
  isLoading,
	disableEditing=false,
	query,
  fields,
	handleChange,
	handleSearch,
	handleFilterClick,
	badgeCount,
	resource,
  selected,
	handleAddClick,
	handleEditClick,
  handleExportClick,
	handleDeleteClick,
  handleNavigateClick,
  handleClearFilters,  
  handleSort,
  handleToggleSort,
  handleUpdateView,
  handleSaveView
}) => {

	return (
		<Box sx={ sx.root }>
			<Box sx={ sx.searchBar }>
					<Box sx={ sx.searchField }>
						<SearchInput
              expandable
							name="keywords"
              query={query}
							value={query?.keywords}
							handleChange={handleChange}
							handleSearch={handleSearch}                            
						/>
					</Box>
          <Hidden mdDown>            
					<Box sx={ sx.toolbar }>
            <AdminlyFilterButton 
              isLoading={isLoading}
              query={query}
              handleFilterClick={handleFilterClick}
              badgeCount={badgeCount}
              handleClearFilters={handleClearFilters}
            />
					</Box>
				</Hidden>
			</Box>			
				<Box sx={ sx.actions }>
        {!disableEditing ? (
					<Box sx={ sx.toolbar }>
						<Hidden mdDown>
							{(resource?.id || selected?.length > 0) && (
                <>
                  <Button
                    sx={{ mr: '5px'}}
                    startIcon={ <Trash sx={ sx.icon } size={20} />  }
                    variant="outlined"									
                    onClick={handleDeleteClick}
                  >
                    Delete
                  </Button>
                  <Button
                    sx={{ mr: '5px'}}
                    startIcon={ <Edit sx={ sx.icon } size={20} />  }
                    variant="outlined"
                    onClick={handleEditClick}
                  >
                    Edit
                  </Button>                 
                </>
							)}
							<Button
								variant="contained"								
								onClick={handleAddClick}
								startIcon={<Plus  size={20} />}
							>
								Add
							</Button>
              <AdminlyToolbarMenu 
                handleExportClick={ handleExportClick }
                handleSaveView={handleSaveView}
                handleUpdateView={handleUpdateView}
              />              
						</Hidden>
						<Hidden mdUp>
							<Box sx={ sx.toolbar }>
								<Badge
									sx={ sx.badge }
									badgeContent={badgeCount}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
								>
									<IconButton
										onClick={handleFilterClick}
									>
										<FilterList sx={ sx.icon } />
									</IconButton>
								</Badge>
								{resource && (
									<IconButton
										color="secondary"
										onClick={handleDeleteClick}
									>
										<Trash sx={ sx.icon } />
									</IconButton>
								)}
								{resource && (
									<IconButton
										color="secondary"
										onClick={handleEditClick}
									>
										<Edit sx={ sx.icon } />
									</IconButton>
								)}
								<IconButton
									variant="outlined"
									color="secondary"
									onClick={handleAddClick}
								>
									<Plus sx={ sx.icon } />
								</IconButton>
							</Box>
						</Hidden>
					</Box>
          ):(
            <Button
              sx={ sx.navigateBtn  }
              endIcon={ <ArrowUpRight sx={ sx.icon } size={20} />  }
              variant="outlined"
              onClick={handleNavigateClick}
            >
              View
            </Button>
          )}
			</Box>
		</Box>
	)
}

export default AdminlyTableHeader

const sx = {
	root: {
		height: 74,
    borderRadius: '4px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderBottom: theme => `1px solid ${theme.palette.common.border}`,
		background: 'common.white'
	},
  pl: { sm: '6px' },	
	toolbar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		mr: 1,
	},
	titleBar: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		pl: 2,
    maxWidth: { 
      sm: '140px' 
    }
	},
	searchBar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
    width: { sm: '100%' },
    ml: { 
      xs: 2, 
      sm: 0 
    }
	},
	searchField: {
    pl: 2,    
	},  
  navigateBtn: {
    mr: 2,
  },
	toolbar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
    alignItems: 'center'
	},
	filterButton: {
		ml: 1,
		color: 'text.secondary'
	},
  clearFilterButton: {		
    height: 34,
		color: 'text.secondary'
	},
	title: {
		lineHeight: 1.2,
	},
	actions: {
		width: 240,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	icon: {
		color: 'text.secondary'
	},
  sortIcon: {
    height: 20,
    width: 20,
    color: 'text.secondary',
  }
}
