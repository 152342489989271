import React from 'react'
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import { Drawer } from 'components'
import { 
  Square,
  CheckSquare,
} from 'react-feather'

const ChatTableDrawer = ({
  open,
  handleClose,
  tables,
  activeTables,
  handleClick,
  ...props
}) => {

  return(
  <Drawer
    open={open}
    handleClose={handleClose}
    title="Data sources"
    sx={ sx.drawer  }
  >
    <List>
      { tables.map((table, index) => {
        const selected = activeTables.includes(table)
        return(
        <ListItem 
          key={index}
          disablePadding
        >
          <ListItemButton 
            onClick={() => handleClick(table)}
          >
            <ListItemIcon>
              { selected ? 
                <CheckSquare size={20} /> :
                <Square size={20} />
              }
            </ListItemIcon>
            <ListItemText 
              primary={table} 
            />
          </ListItemButton>          
        </ListItem> 
        )}
      )}
    </List> 
  </Drawer>
  )
}

export default ChatTableDrawer 

const sx = {
  drawer: {}
}