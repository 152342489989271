import React, { useState, useEffect } from 'react'
import { 
  Box,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,   
} from '@mui/material'
import { Icon, Divider } from 'components'
import { ExpandMore, ExpandLess} from '@mui/icons-material'

import { Settings, ToggleLeft } from 'react-feather'
import { FIELD_TYPES } from 'lib/constants'

const CellHeaderMenu = ({ 
    open,
    anchorEl,
    toggleMenu,
    closeMenu,
    handleUpdateClick,
    handleSortClick,
    handleEditClick,
    handleHideClick,
    ...params 
  }) => {  

  
  const { id, propsField: field } = params.colDef || {} 

  const [activeFieldTypes, setActiveFieldTypes] = useState([])

  const handleSortDesc = () => {    
    closeMenu(); 
    handleSortClick(field?.db_name, 'desc')      
  }

  const handleSortAsc = () => {    
    closeMenu(); 
    handleSortClick(field?.db_name, 'asc')      
  }

  const handleEditField = (params) => {    
    closeMenu(); 
    handleEditClick(id, params)
  }

  const handleUpdateField = (id, friendlyType) => {
    closeMenu(); 
    handleUpdateClick(id, friendlyType)
  }

  const handleHideField = () => {
    closeMenu(); 
    handleHideClick(id)
  }

  const handleMenuClick = (fieldType) => {
    if(fieldType.require_menu){ 
      handleEditField(id) 
    }else{
      handleUpdateField(id, fieldType.value) 
    }
  }

  useEffect(() => {
    if(field?.friendly_type) {
      setActiveFieldTypes(FIELD_TYPES.filter(f => f.db_type === field?.db_type))
    }
  }, [field?.friendly_type])

  return(
    <Menu 
      open={ open }
      anchorEl={ anchorEl }
      onClose={ closeMenu }
    >
      <MenuItem
        onClick={handleSortAsc}
      >
        <ListItemIcon>
          <ExpandLess sx={ sx.icon  } />
        </ListItemIcon>
        <ListItemText 
          primary={
            <Typography
              variant="body3"
              color="textPrimary"
            >          
              Sort increasing 
            </Typography>
            } 
          /> 
      </MenuItem>
      <MenuItem onClick={ handleSortDesc }>
        <ListItemIcon>
          <ExpandMore sx={ sx.icon  } />
        </ListItemIcon>
        <ListItemText 
          primary={
            <Typography
              variant="body3"
              color="textPrimary"
            >          
              Sort decreasing 
            </Typography>
          }
        /> 
      </MenuItem>
      <Divider />
      { activeFieldTypes.map((fieldType, index)=> (
        <MenuItem 
          key={index}
          selected={fieldType.value == field?.friendly_type}
          onClick={() => handleMenuClick(fieldType) }>
          <ListItemIcon>
            <Icon 
              size={20}
              friendlyType={ fieldType.value }
            />
          </ListItemIcon>
          <ListItemText 
              primary={
              <Typography
                variant="body3"
                color="textPrimary"
              >          
                { fieldType.label } 
              </Typography>
            }
          /> 
        </MenuItem>
      ))}
      <Divider />
      <MenuItem onClick={ handleHideField }>
        <ListItemIcon>
          <ToggleLeft size={18} sx={ sx.settingsIcon  } />
        </ListItemIcon>
        <ListItemText 
            primary={
            <Typography
              variant="body3"
              color="textPrimary"
            >          
              Hide column 
            </Typography>
          }
        /> 
      </MenuItem>
      <MenuItem onClick={ handleEditField }>
        <ListItemIcon>
          <Settings size={18} sx={ sx.settingsIcon  } />
        </ListItemIcon>
        <ListItemText 
            primary={
            <Typography
              variant="body3"
              color="textPrimary"
            >          
              Settings 
            </Typography>
          }
        /> 
      </MenuItem>
    </Menu>
  )
}

export default CellHeaderMenu 

const sx = {
  root: {},
  icon: {
    color: 'text.secondary'    
  },
  settingsIcon: {
    color: 'text.secondary',
    ml: 0.5
  }
}
