import React, { useState } from 'react'
import {
	Avatar,
	ListItem,
	ListItemText,
	ListItemIcon,
	ListItemSecondaryAction,
} from '@mui/material'
import { useTheme } from '@mui/styles';

const ListItemButton = ({
	onClick,
	image,
	icon: Icon,
	primary,
	secondary,
	secondaryAction,
  selected=false
}) => {

  const theme = useTheme();

	return (
		<ListItem      			
			sx={{ 
        ...sx.listItem, 
        ...(selected && sx.selected)
        }
      }
			onClick={onClick}
		>
			<ListItemIcon>
				{Icon && (
					<Avatar
						variant="rounded"
						sx={ sx.avatar }
					>
						<Icon style={{
              ...sx.icon,
              color: theme.palette.text.secondary 
              }} 
            />
					</Avatar>
				)}
				{image && (
					<img src={image} style={ styles.image } />
				)}
			</ListItemIcon>
			<ListItemText
				primary={primary}
				secondary={secondary}
			/>
			{secondaryAction && (
				<ListItemSecondaryAction>
					{secondaryAction}
				</ListItemSecondaryAction>
			)}
		</ListItem>
	)
}

export default ListItemButton

const styles = {
  image: {
		width: 32,
		height: 'auto',
	}
}

const sx = {
	icon: {
		color: 'text.secondary'
	},
	avatar: {
		border: theme => `2px solid ${theme.palette.common.white}`,
		backgroundColor: 'common.gradient'
	},
  selected: {
    border: theme =>  `2px solid ${theme.palette.primary.light} !important`,
    '&:hover': {
      border: theme => `2px solid ${theme.palette.primary.light}`,
    },
    '&:focus': {
      border: theme => `2px solid ${theme.palette.primary.light}`,
    },
    '&:active': {
      border: theme => `2px solid ${theme.palette.primary.light}`,
    }
  },
	listItem: {
    my: 1,
    cursor: 'pointer',
    borderRadius: theme => theme.shape.borderRadius,
    mx: 0,
    color: '#23263b',
    backgroundColor: `#fff`,
    border: '1px solid #d6d6e7',
    backgroundImage: 'linear-gradient(-180deg,#fff,#fcfcfd)',
    boxShadow: `0 1px 0 0 rgb(35 38 59 / 5%)`,
    '&:hover': {
      backgroundImage: 'linear-gradient(-180deg,#fff,#f5f5fa)',
    },
    '&:active': {      
      border: theme => `2px solid ${theme.palette.primary.light}`,
      boxShadow: `inset 0 1px 4px 0 rgb(119 122 175 / 30%)`,
    },    
	},
}
