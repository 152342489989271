import React, {
	useState,
	useEffect,
	useContext,
} from 'react'
import { AppContext, ProjectContext } from 'context'
import {
	useTabs,
	useProjects,
	useSelected,
} from 'hooks'
import { useHistory } from 'react-router-dom'
import {
  Panel,
  Placeholder,
  DeleteWarningModal,
  PanelLayout,	
	PanelHeader,
	CenterPanel,
} from 'components'
import { Button, Box, Grid } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useTheme } from '@mui/styles'
import { Database } from 'react-feather'
import ProjectItem from 'containers/main/projects/ProjectItem'

const ProjectList = ({ ...props }) => {
	useTabs('projects')

  const theme = useTheme()

	const history = useHistory()
	const [showSyncModal, setShowSyncModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	const { id: projectId } = props.match.params
	const { activeProject, setActiveProject } = useContext(ProjectContext)
  const { setOpenMobile } = useContext(AppContext)

	const {
		isLoading,
		isLoaded,
    isEmpty,
		project,
		projects,
		findProject,
		findProjects,
		deleteProject,
		syncSchema,
		checkSchemaStatus,
		setProject,
		reloadProjects,
	} = useProjects({})

	const { handleSelect } = useSelected()

	const handleSyncSchema = async () => {
		await syncSchema(activeProject?.id)
		setShowSyncModal(false)
		history.push(`/projects/${activeProject?.id}/collections`)
	}

	const handleClick = async (project) => {
		setActiveProject(project)
    const { status } = project || {} 
    switch(status){
      case 'pending':
        history.push(`/projects/${project?.id}/onboarding/step2`)
        break
      case 'deployed':
        history.push(`/projects/${project?.id}/onboarding/step4`)
        break 
      case 'out_of_sync':        
      case 'ready':
        let schemaStatus = await checkSchemaStatus(project.id)
        let { in_sync } = schemaStatus?.table || {}
        if (in_sync == true) {
          history.push(`/projects/${project?.id}/collections`)
        } else if (in_sync == false) {
          setShowSyncModal(true)
        }    
      break                 
    }
	}

	const handleAddProject = () => {
		history.push(`/projects/onboarding/step1`)
	}

	const handleEditClick = (project) => {
		history.push(`/projects/${project?.id}/settings/details`)
	}

	const handleShowDeleteModal = (project) => {
		setProject(project)
		setShowDeleteModal(true)
	}

	const handleDeleteClick = async () => {
		await deleteProject(project?.id)
		setShowDeleteModal(false)
		reloadProjects()
	}

	useEffect(() => {
		findProjects()
	}, [])

	const handleLoadProject = async () => {
		let project = await findProject(projectId)
		setActiveProject(project)
	}

	useEffect(() => {
		if (projectId && !activeProject?.id) {
			handleLoadProject()
		}
	}, [projectId, activeProject])

  // Disable mobile translate effect 
  // when there is no side layout panel
  useEffect(() => {
    setOpenMobile(false)
  }, [projectId])

	return (
    <PanelLayout>
      <CenterPanel>
        <PanelHeader 
          title={`My Projects (${projects?.length || 0})`}
          actions={
            <Button 
              variant="contained"
              onClick={ handleAddProject }
              startIcon={ <Add /> }
            >
              Add Project
            </Button>
          }
        />
        <Box py={2}>
          {isLoaded && (
            !isEmpty ? (
              <Grid container spacing={2}>
                { projects && 
                  projects.map((project, idx) => (
                  <Grid
                    key={idx}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                  >
                    <ProjectItem
                      project={project}
                      handleSelect={handleSelect}
                      handleClick={handleClick}
                      handleDeleteClick={
                        handleShowDeleteModal
                      }
                      handleEditClick={handleEditClick}
                    />
                  </Grid>
                ))}
              </Grid>
            ):(
              <Panel>
              <Box sx={ sx.placeholder }>
                <Placeholder
                  icon={
                  <Database 
                    size={24} 
                    style={{
                      color: theme.palette.icon 
                    }} 
                    />
                  }
                  title="No projects yet."
                  subtitle="Get started with a new project."
                  actions={                    
                    <Button
                      sx={ sx.button }                      
                      variant="contained"
                      onClick={ handleAddProject }
                      startIcon={ <Add /> }
                    >
                      Add Project
                    </Button>
                  }
                />
                </Box>
              </Panel>
            )
          )}
        </Box>
        <DeleteWarningModal
          isLoading={isLoading}
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteClick}
        />
        <DeleteWarningModal
          title={
            'Your project is not in sync. Would you like to update it now?'
          }
          subtitle={
            'Adminly will update your project to match the database.'
          }
          isLoading={isLoading}
          open={showSyncModal}
          onClose={() => setShowSyncModal(false)}
          onConfirm={handleSyncSchema}
        />
      </CenterPanel>
    </PanelLayout>
	)
}

export default ProjectList

const sx = {
  button: {
    my: 2
  },
  placeholder: {
    my: 4
  }
}