
import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { Sidebar } from 'components'

const DESKTOP_WIDTH = '60px'

const PanelLayout = ({ children, ...props }) => {

	return(
    <Box sx={ sx.root }>
      <Sidebar />
      <Box sx={ sx.content }>
        {children }
      </Box>
    </Box>
  )
}

PanelLayout.propTypes = {
	children: PropTypes.any.isRequired,
}

export default PanelLayout

const sx = {
  root: {
		paddingLeft: { 
      xs: 0, 
      sm: DESKTOP_WIDTH 
    }
	},
	content: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		width: '100%',
		flexGrow: 1,
		height: 'calc(100vh)',
		overflow: 'none',
		zIndex: 0,
	},  
}
