import React from 'react'
import { useMenu } from 'hooks'
import {
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemSecondaryAction,
	Menu,
	MenuItem,
} from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'
import { UserAvatar, Label } from 'components'

const UserItem = ({
	user,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {
	const { open, anchorEl, toggleMenu, closeMenu } =
		useMenu()

	// Name and nickname cannot be edited for social Auth0 identities
	const editable = user?.identities.find(
		(i) => i.isSocial === false
	)

	const renderMenu = () => (
		<Menu
			open={open}
			onClose={closeMenu}
			anchorEl={anchorEl}
		>
			{editable && (
				<MenuItem
					onClick={() => {
						closeMenu()
						handleEditClick(user)
					}}
				>
					Edit
				</MenuItem>
			)}
			<MenuItem
				onClick={() => {
					closeMenu()
					handleDeleteClick(user)
				}}
			>
				Delete
			</MenuItem>
		</Menu>
	)

	return (
		<ListItem>
			<ListItemIcon>
				<UserAvatar src={user.picture} variant="circle" />
			</ListItemIcon>
			<ListItemText
				primary={user.name}
				secondary={user.email}
			/>
			<ListItemSecondaryAction>
				<Label label={'Auth0'} />
				<IconButton onClick={toggleMenu}>
					<MoreHoriz />
				</IconButton>
				{renderMenu()}
			</ListItemSecondaryAction>
		</ListItem>
	)
}

export default UserItem
