import React, { useContext } from 'react'
import { Badge, Box, Menu, MenuItem } from '@mui/material'
import { AuthContext } from 'context'

const UserMenu = ({
	open,
	anchorEl,
	toggleMenu,
	handleLogoutClick,
	handleClick,
	...props
}) => {
	const { currentUser } = useContext(AuthContext)

	return (
		<Menu
			open={open}
			onClose={toggleMenu}
			anchorEl={anchorEl}
		>
			<MenuItem
				onClick={(e) =>
					handleClick(`/users/${currentUser.id}/edit`)
				}
			>
				My Account
			</MenuItem>
			<MenuItem onClick={handleLogoutClick}>
				<Badge
          color="success"
					variant="dot"
					classes={{ 
            dot: sx.badgeOnline 
          }}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					<Box mx={1} />
				</Badge>
				Sign Out
			</MenuItem>
		</Menu>
	)
}

export default UserMenu

const sx = {
	badgeOnline: {
		height: 10,
		width: 10,
	},
}
