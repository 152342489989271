import React from 'react'
import PropTypes from 'prop-types'
import { Box, Hidden, Typography } from '@mui/material'

const LeftPanelHeader = ({
	title,
  icon: Icon,
	actions,
}) => {


	return (
		<Box sx={ sx.root }>
			<Box sx={ sx.toolbar }>
        <Hidden smDown>
          {Icon && (            
            <Icon size={18} style={ sx.icon  } />
          )}
        </Hidden>
        <Typography
          variant="overline"
          sx={ sx.title }
        >
          {title}
        </Typography>
			</Box>
			{actions && (
				<Box sx={ sx.actions }>
          {actions}
        </Box>
			)}
		</Box>
	)
}

export default LeftPanelHeader

const sx = {
	root: {
    width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
    alignItems: 'center',
		height: 74,
		width: '100%',
    borderBottom: theme => `1px solid ${theme.palette.common.darkBorder}`,
	},
	toolbar: {
    width: '100%',
    height: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
    pl: { xs: '60px', sm: 0 }   
	},
	titleBar: {
    width: '100%',
    display: 'flex',
		flexDirection: 'row',
    alignItems: 'center',
    height: '100%',    
		pt: 2,
    pr: 2,
    pb: 0,
    pl: 0.5
	},
	title: {
    fontSize: 16,
    color: 'common.white',
		lineHeight: 1.0,
	},
  icon: {
    color: 'white',
    margin: '0 8px 0 8px'    
  },
	actions: {    
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		gap: '5px',
		mr: '10px',
	},
}
