import React, { useContext } from 'react'
import { useEffect, useState } from 'react'
import { AdminlyContext } from 'context'
import { buildQuery } from 'lib/helpers/adminly'

const emptyQuery = {
  keywords: null,
  page: 1,
  per_page: 20,
  filters: [],
  order: 'id:desc',
}

const useAdminly = ({
	collection,
	defaultQuery = emptyQuery,
	...props
}) => {

  if(Object.keys(defaultQuery).length === 0) {
    defaultQuery = emptyQuery
  }

	const { api, baseURL, jwtToken } = useContext(AdminlyContext) || {}

	let url = `/${collection}`

	const [query, setQuery] = useState({})
	const [activeFilters, setActiveFilters] = useState([
		defaultQuery.filters,
	])
	const [isLoaded, setIsLoaded] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isEmpty, setIsEmpty] = useState(false)
	const [id, setId] = useState(props.id)
	const [resource, setResource] = useState({ id: props.id })
	const [resources, setResources] = useState([])
  const [schema, setSchema] = useState()
	const [meta, setMeta] = useState({})
	const [params, setParams] = useState({})
	const [page, setPage] = useState(1)
	const [perPage, setPerPage] = useState(20)
	const [numPages, setNumPages] = useState(1)
	const [sortBy, setSortBy] = useState(`id`)
	const [sortDirection, setSortDirection] = useState('desc')
	const [totalCount, setTotalCount] = useState(0)

	const showLoading = () => setIsLoading(true)
	const hideLoading = () => setIsLoading(false)

  const findSchema = async () => {
    try{
      showLoading()
		  setIsLoaded(false)
		  const res = await api.get(`/schema`)
      if(res?.data){
        setSchema(res.data)
        setIsLoaded(true)
      }
      return res?.data       
    } catch(e) {
      console.log(e)
    } finally {
		  hideLoading()
    }		
  }

	const findOne = async (id) => {
		if (!id) return null
		showLoading()
		setIsLoaded(false)
		setId(id)
		const res = await api.get(`${url}/${id}`)
		setResource(res.data)
		setMeta(res.meta)
		setIsLoaded(true)
		hideLoading()
		return res.data
	}

	const findMany = async (query, loadMore=false) => {
    if(!collection) return;
    try{
      showLoading()
      const queryParams = buildQuery(query)
      setQuery(query)
      setParams(params)
      if (!loadMore) {
        setIsLoaded(false)
      }
      const res = await api.get(url, {
        params: queryParams,
      })
      if (res.data) {
        if (!loadMore) {
          setResources(res.data)
        } else {
          setResources([...resources, ...res.data])
        }
        if (res.meta) {     
          setMeta(res.meta)
          setPage(res.meta.page)
          setPerPage(res.meta.per_page)
          setTotalCount(res.meta.total_count)
          setNumPages(Math.floor(res.meta.total_count / res.meta.per_page) + 1)
        }
        setIsEmpty(res?.data?.length > 0 ? false : true)
        setIsLoaded(true)
        return res.data
      }
    } catch(e) {
      console.log(e)
    }finally{
      hideLoading()
    }
	}

	const loadMore = () => {
		let nextPage = page + 1
		let loadMoreResults = true
		findMany({ 
      ...query, 
      page: nextPage 
    }, loadMoreResults)
	}


	const save = (resource, showLoaders = false) => {
		if (resource?.id) {
			return update(resource, showLoaders)
		} else {
			return create(resource, showLoaders)
		}
	}

	const create = async (resource) => {
    try{
      showLoading()
      const res = await api.post(url, {
        [collection]: resource,
      })
      if (res.data && res.data.id) {
        setResource(res.data)
        setIsLoaded(true)
        setId(res.data.id)    
      }
      return res.data
    }catch(e){
      console.log(e)
    }finally{
      hideLoading()
    }				
	}

	const update = async (resource) => {
    try{
      setId(resource.id)
      showLoading()
      const res = await api.put(`${url}/${resource.id}`, {
        [collection]: resource,
      })
      return res.data
    }catch(e){
      console.log(e)
    }finally{
      hideLoading()
    }				
	}

	const destroy = async (id) => {
    try{
		  showLoading()
		  const res = await api.delete(`${url}/${id}`)      
		  setResource({ data: {} })
      return res 
    }catch(e){
      console.log(e)
    }finally{
      hideLoading()
    }		
	}

	const updateMany = async (ids, resource) => {
    try{
      showLoading()
      const res = await api.post(`${url}/update_many`, {
        ids: ids,
        [collection]: resource,
      })
      return res.data
    }catch(e){
      console.log(e)
    }finally{
      hideLoading()
    }				
	}

	const deleteMany = async (ids) => {
    try{
      showLoading()
      const res = await api.post(`${url}/delete_many`, {
        ids: ids,
      })
      return res.data
    }catch(e){
      console.log(e)
    } finally {
      hideLoading()
    }    
	}

  const exportCsv = async (query) => {
    let queryParams = buildQuery(query)
    let queryString = new URLSearchParams(queryParams).toString()
    let exportCSVUrl = `${baseURL}${url}.csv?${queryString}&token=${jwtToken}`    
    window.open(exportCSVUrl, '_self')
  }

	const paginate = (page) => {
		findMany({ 
      ...query, 
      page: page 
    })
	}

	const handleChange = (ev) => {
		const { name } = ev.target
		const value = ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value
		setResource({
			...resource,
			[name]: value,
		})
	}

	const reloadOne = () => {
		findOne(id)
	}

	const reloadMany = () => {
		findMany(query)
	}

	const sortAll = ({ sortBy, sortDirection }) => {
		setSortBy(sortBy)
		setSortDirection(sortDirection)
		findMany({
			...query,
			sort_by: sortBy,
			sort_direction: sortDirection,
		})
	}  
  
	useEffect(() => {
		if (props.id) setId(props.id)
	}, [props.id])

  let startIndex = (perPage * (page - 1)) + 1;
  let endIndex = Math.min(perPage * page, totalCount || 0)

	return {
		id,
		isLoading,
		isLoaded,
		isEmpty,
		resource,
		resources,
		setResource,
		setResources,
		meta,    
    schema,
		findOne,
		findMany,
    findSchema,
		save,
		update,
		create,
		destroy,
		updateMany,
		deleteMany,
    exportCsv,
		paginate,
		loadMore,
		handleChange,
		activeFilters,
		setActiveFilters,
		query,
    setQuery,
		params,
		page,
		perPage,
		numPages,
		totalCount,
		reloadOne,
		reloadMany,
		sortAll,
		sortBy,
		sortDirection,
		startIndex,
		endIndex,
	}
}

export default useAdminly
