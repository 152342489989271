import React from 'react'
import {
	Box,
	Button,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material'
import { Check, CheckCircle } from 'react-feather'
import { Panel, Label } from 'components'

const SubscriptionPlanCard = ({ 
    plan, 
    popular=false,
    hosting='cloud', 
    activeStripePlan, 
    handleClick,
    ...props  
  }) => {
    
    const selected = activeStripePlan === plan?.stripe_plan
	
    return (
		<Panel 
      styles={{ 
        ...(popular && sx.active),
      }}>
      <Label label={ plan.subtitle } />
			<Typography variant="h6">
        {plan.title} - { plan.price[hosting] }
      </Typography>
			<Box>
      {handleClick && (
				<Box my={2}>
					<Button
						onClick={ handleClick}
						fullWidth
						variant={(popular || selected) ? "contained" : "outlined"}
						color="primary"
            startIcon={ 
              selected && <CheckCircle />
            } 
					>
						{ selected ? "Subscribed" : "Get Started" } 
					</Button>
				</Box>
			)}
      <List dense>
        {plan.features.map((f, i) => (
          <ListItem
            disableRipple
            sx={ sx.listItem  }
            key={i}
            button
          >
            <ListItemIcon>
              <Check size={20} sx={ sx.icon  } />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  color="textSecondary"
                >
                  {f.text}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
			</Box>
		
		</Panel>
	)
}

export default SubscriptionPlanCard

const sx = {
	listItem: {
		borderBottom: theme => `1px solid ${theme.palette.common.divider}`,
	},
	caption: {
		fontWeight: 500,
	},
  active: {
    border: theme => `2px solid ${theme.palette.primary.main}`,
  }
}
