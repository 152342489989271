import React from 'react'
import {
  useMenu
} from 'hooks'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'

import { Trash, Edit } from 'react-feather'

const ViewActionMenu = ({
  handleEditClick,
  handleDeleteClick,
  ...props
}) => {


  const { 
    open, 
    anchorEl, 
    closeMenu, 
    toggleMenu 
  } = useMenu()

  return(
    <Box>
      <IconButton size="small" onClick={toggleMenu}>
        <MoreHoriz sx={ sx.icon  } />
      </IconButton>
      <Menu
        open={open}
        onClose={toggleMenu}
        anchorEl={anchorEl}
      >
        <MenuItem
          onClick={() => {
            handleEditClick()
            closeMenu()
          }}
        >
          <ListItemIcon>
            <Edit size={18} sx={ sx.icon  } />
          </ListItemIcon>
          <ListItemText primary="Edit" />				  
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDeleteClick()
            closeMenu()
          }}
        >
          <ListItemIcon>
            <Trash size={18} sx={ sx.icon  } />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
    </Box>  
  )
}

export default ViewActionMenu 

const sx = {
  icon: {
    color: 'text.secondary'
  }
}
