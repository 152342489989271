import React from 'react'
import { Grid } from '@mui/material'
import { TextInput } from 'components'

const ProjectForm = ({ project, handleChange }) => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={12}>
				<TextInput
					label="Name"
					value={project.name}
					name="name"
					placeholder="Project name"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					label="Database URI"
					value={project.database_url}
					name="database_url"
					placeholder="postgres://user:pass@host:port/database"
					handleChange={handleChange}
				/>
			</Grid>
		</Grid>
	)
}

export default ProjectForm
