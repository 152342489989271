import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'
import {
	ButtonLoader,
	ProgressLoader,
	Placeholder,
	Modal,
} from 'components'
import { AlertCircle } from 'react-feather'

const DeleteWarningModal = ({
	isLoading,
	title = 'Please confirm or cancel this action.',
	subtitle = 'This action is not reversable.',
	open,
	onClose,
	onConfirm,
}) => {

	return (
		<Modal
			open={open}
			title="Are you sure?"
			actions={
				<Button
          variant='outlined'
					color="secondary"
					onClick={onConfirm}
					startIcon={isLoading && <ButtonLoader />}
				>
					Confirm
				</Button>
			}
			handleClose={onClose}
		>
			{!isLoading ? (
				<Placeholder
					icon={<AlertCircle sx={ sx.icon} /> }
					title={title}
					subtitle={subtitle}
				/>
			) : (
				<Box sx={ sx.content }>
					<ProgressLoader />
				</Box>
			)}
		</Modal>
	)
}

DeleteWarningModal.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
}

export default DeleteWarningModal

const sx = {
	content: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	icon: {
		color: 'icon',
		height: 32,
		width: 32,
	},
}
