import React from "react";
import { Button, Container } from "@mui/material";
import { ArrowRightAlt } from "@mui/icons-material";
import { LeftImageText, Section } from 'components'
import Image from "assets/images/adminly-search.png";

export default function SectionOne({ handleClick, ...props}) {  

  return (
  <Section bgColor='common.section' styles={ sx.section }>
    <Container maxWidth="lg">
      <LeftImageText 
        caption={'Powered by PostgreSQL'}
        title={'Full-text search over your database.'}
        description={
          'Take advantage of the native search capabilities of PostgreSQL \
          with our built-in search engine. Find data from any table in your database. '
        }
        image={ Image }
        actions={
          <Button
            size="large"
            color="secondary"
            variant="contained"
            sx={ sx.button }
            onClick={handleClick}
            endIcon={<ArrowRightAlt />}
          >
            Learn More
          </Button>
        }
      />
    </Container>
  </Section>
  );
}


const sx = {
  root: {}
};
