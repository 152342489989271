import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import { Box, IconButton, Typography } from '@mui/material'
import { DeleteOutlined } from '@mui/icons-material'
import { ProgressLoader } from 'components'
import {
	UploadCloud,
	DownloadCloud,
	CheckCircle,
} from 'react-feather'
import { useTheme } from '@mui/styles'

const IMAGE_WIDTH = 140

const ImageUploader = ({
	circle = true,
	handleUpload,
	label = 'Upload image',
	size = IMAGE_WIDTH,
	imageUrl,
	handleDelete,
	...props
}) => {

  const theme = useTheme() 

  const [isEditing, setIsEditing] = useState(false)
	const [file, setFile] = useState()
	const [files, setFiles] = useState([])
	const [isUploading, setIsUploading] = useState(false)
	const [isUploaded, setIsUploaded] = useState(false)
	const [dropzoneActive, setDropzoneActive] =
		useState(false)

	const handleEditClick = () => setIsEditing(!isEditing)

	const onDrop = async (files) => {
		setIsUploading(true)
		setIsUploaded(false)
		await handleUpload(files[0])
		setIsEditing(false)
		setIsUploading(false)
		setIsUploaded(true)
		setFiles([])
		setFile(null)
	}

	const onDragEnter = (files) => {
		setFiles(files)
		setDropzoneActive(true)
	}

	const onDragLeave = (files) => {
		setFiles(files)
		setDropzoneActive(false)
	}

	return (
		<Box>
			{imageUrl && !isEditing && (
				<img
          style={{
            ...sx.image,
            height: size,
            width: size 
          }}
					size={size}
					src={imageUrl}
				/>
			)}

			{(isEditing || !imageUrl) && (
				<Dropzone
          style={{ 
            ...sx.dropZone,
            border: `1px solid ${theme.palette.common.inputBorder}`,
            marginBottom: theme.spacing(2), 
          }}
					disableClick={false}
					onDrop={onDrop}
					onDragEnter={onDragEnter}
					onDragLeave={onDragLeave}
					multiple={true}
				>
					{!isUploaded && !isUploading && !dropzoneActive && (
						<Box>
							<UploadCloud 
                style={{ 
                  ...sx.icon, 
                  color: theme.palette.icon 
                }}  
              />
							<Typography variant="body2" color="textSecondary">
								{label}
							</Typography>
						</Box>
					)}

					{isUploaded && (
						<Box>
							<CheckCircle 
                style={{ 
                  ...sx.icon, 
                  color: theme.palette.icon 
                }} 
              />
              <Typography variant="body2" color="textSecondary">
								Drop file to upload.
							</Typography>
						</Box>
					)}

					{!isUploaded && !isUploading && dropzoneActive && (
						<Box>
							<DownloadCloud 
                style={{ 
                  ...sx.icon, 
                  color: theme.palette.icon 
                }} 
              />
							<Typography variant="body2" color="textSecondary">
								Drop file to upload.
							</Typography>
						</Box>
					)}

					{!isUploaded && isUploading && (
						<ProgressLoader />
					)}
				</Dropzone>
			)}

			{imageUrl && handleDelete && (
				<IconButton
					sx={ sx.iconButton }
					size="small"
					onClick={handleDelete}
				>
					<DeleteOutlined />
				</IconButton>
			)}
		</Box>
	)
}

export default ImageUploader

const sx = {
	image: {
		p: 2,
		mr: 1,
    borderRadius: '100px'
	},
	circle: {
		borderRadius: 100,
	},
	dropZone: {
		m: 0,
		height: 140,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '4px',
		boxShadow: `rgb(0 0 0 / 5%) 0px 2px 4px !important`,
		backgroundColor: 'white',
		textAlign: 'center',
	},
	icon: {
		height: 44,
		width: 44,
	},
	iconButton: {
		fontSize: 11,
		top: 0,
		left: -48,
		color: 'text.secondary',
		'&& ': {
			backgroundColor: 'common.white',
		},
	},
}
