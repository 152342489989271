import React, {
	useContext, useEffect,
} from 'react'
import { AppContext } from 'context'
import { AdminlyProvider } from 'context'
import { Box } from '@mui/material'
import {
	CenterPanel,
	LeftPanel,
	PanelLayout,
} from 'components'
import {  
	useActiveProject,
	useTabs  
} from 'hooks'
import SqlQuery from './SqlQuery'

const SqlContainer = ({ ...props }) => {

  useTabs('sql') 

  const { 
    id: projectId 
  } = props.match.params

	const {     
    activeProject,
  } = useActiveProject(projectId)

  const { 
    setOpenMobile, 
    showSideMenu,
  } = useContext(AppContext)


	return (
		<Box sx={ sx.root }>
			<PanelLayout>
				{showSideMenu && (
					<LeftPanel>
					</LeftPanel>
				)}
				<CenterPanel>
					{activeProject && (
						<Box>
              <AdminlyProvider
                token={activeProject?.jwt_token}
                url={activeProject?.api_url}
              >
                <SqlQuery                   
                  projectId={ activeProject?.id }
                />
              </AdminlyProvider>
            </Box>
					)}
				</CenterPanel>
			</PanelLayout>
		</Box>
	)
}

export default SqlContainer

const sx = {
	root: {},
	iconButton: {
		color: 'primary.main'
	},
	icon: {
		color: 'text.secondary'
	},
}
