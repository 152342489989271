import React from 'react'
import { CircularProgress } from '@mui/material'


function ButtonLoader() {


	return (
		<CircularProgress
			size={20}
      disableShrink
			sx={ sx.loader }
		/>
	)
}

export default ButtonLoader

const sx = {
	loader: {
		color: 'common.white',
	},
}
