import React from 'react'
import {
  LandingLayout    
} from 'components'
import SectionHero from './SectionHero'
import SectionOne from './SectionOne'
import SectionTwo from './SectionTwo'
import { SUBSCRIPTION_PLANS } from 'lib/constants/prices'
import { PRICING_FAQ } from 'lib/constants/landing'

const Home = (props) => {

  return(
    <LandingLayout>      
      <SectionHero />
      <SectionOne 
        subscriptionPlans={ SUBSCRIPTION_PLANS }
      />
      <SectionTwo 
        faqs={ PRICING_FAQ }
      />
    </LandingLayout>
  )
}

export default Home 

const sx = {
  root: {} 
}
