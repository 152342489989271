import React from 'react'

import { File as FileIcon } from 'react-feather'
import { resize, imageFromVideoUrl } from 'lib/helpers/cloudinary'
import {
	VIDEO_FORMATS,
	IMAGE_OR_VIDEO_FORMATS,
} from 'lib/constants'

const CloudinaryImage = ({ src, height, width }) => {

	let fileExtension = src.split('.').pop()	
  let imageUrl = src
	if (VIDEO_FORMATS.includes(fileExtension)) {
		imageUrl = imageFromVideoUrl(src)
	}else{
    // convert all images to jpg to ensure that they render correctly    
    imageUrl = src.substr(0, src.lastIndexOf(".")) + ".jpg"  
  }

	const resizedImageUrl = resize(imageUrl, {
		width: width,
		height: height,
	})

	const isImageOrVideo = IMAGE_OR_VIDEO_FORMATS.includes(fileExtension)

	return isImageOrVideo ? (
		<img src={resizedImageUrl} height={height} width={width} />
	) : (
		<FileIcon sx={ sx.icon } />
	)
}

export default CloudinaryImage

const sx = {
	icon: {
		height: 20,
		width: 20,
		color: 'text.secondary'
	},
}
