import { isEmpty } from 'validations'

export const validateField = (field) => {
	let isValid = true
	let messages = []

	if (isEmpty(field.name)) {
		isValid = false
		messages.push('API name is required')
	}

	if (isEmpty(field.friendly_type)) {
		isValid = false
		messages.push('Database type is required')
	}

  if(field.friendly_type === 'reference') {
    if(isEmpty(field.foreign_field_id)) {
      isValid = false
      messages.push('Display field is required')
    }

    if(isEmpty(field.foreign_collection_id)) {
      isValid = false
      messages.push('Collection is required')
    }
  }

  if(field.friendly_type === 'enumerable') {
    if(isEmpty(field.options)) {
      isValid = false
      messages.push('You should have at least one option')      
    }
  }

	let validation = {
		isValid,
		messages,
	}

	return validation
}
