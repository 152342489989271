import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Label } from 'components'

export default function LeftImageText ({ 
  title,
  description,
  caption, 
  actions,
  handleClick, 
  spacing=1,
  image: Image,
  ...props 
}) {  

  return (
    <Grid container spacing={spacing}>
      <Grid item xs={12} sm={6} md={6}>
        <Box sx={ sx.content }>
          <Label label={ caption } />                  
          <Typography variant="h3">
            { title }
          </Typography>
          <Box my={2} />
          <Typography variant="subtitle1" color="textSecondary">
            { description }
          </Typography>
          <Box my={4}>
            { actions }
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Box sx={ sx.image }>
          <img src={Image} style={styles.image} />        
        </Box>
      </Grid>
    </Grid>
  );
}

const styles = {
  image: {
    width: "100%",
    height: 'auto',
  }
}

const sx = {
  image: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
};
