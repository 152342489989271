import React from 'react'
import { Box, Typography } from '@mui/material'

const Divider = ({ ...props }) => {
	return (
    <Box sx={ sx.divider }>
      <Typography variant='caption' sx={ sx.caption }>        
        or 
      </Typography>
    </Box>
	)
}

export default Divider

const sx = {
  divider: {
    mt: 3,
    mb: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',    
    borderTop: theme => `1px solid ${theme.palette.common.inputBorder}`
  },
  caption: {
    color: 'text.secondary',
    marginTop: '-28px',
    backgroundColor: 'common.white',
    p: 2
  }
}