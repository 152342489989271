import React from 'react'
import { Box } from '@mui/material'
import CircularProgress, {
	circularProgressClasses,
} from '@mui/material/CircularProgress'

const ProgressLoader = ({ size = 48, styles={}, borderWidth=4, ...props }) => {
	return (
		<Box sx={{ ...sx.root, ...styles }}>
			<Box sx={sx.box}>
				<CircularProgress
					variant="determinate"
					sx={sx.circularProgressBackground}
					size={size}
					thickness={borderWidth}
					{...props}
					value={100}
				/>
				<CircularProgress
					variant="indeterminate"
					disableShrink
					sx={sx.circularProgress}
					size={size}
					thickness={borderWidth}
					{...props}
				/>
			</Box>
		</Box>
	)
}

export default ProgressLoader

const sx = {
	root: {
		p: 2,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	box: {
		position: 'relative',
	},
	circularProgress: {
		color: 'primary.main',
		animationDuration: '550ms',
		position: 'absolute',
		left: 0,
		[`& .${circularProgressClasses.circle}`]: {
			strokeLinecap: 'round',
		},
	},
	circularProgressBackground: {
		color: 'common.skeleton'
	},
}
