import React, { useContext } from 'react'
import { AppContext } from 'context'

import { Button, Hidden } from '@mui/material'
import {
  FirstPage,
  LastPage,
} from '@mui/icons-material'

const SideMenuToggle = () => {


	const { showSideMenu, toggleSideMenu } = useContext(AppContext)

	return (
    <Hidden smDown>
      <Button
        onClick={toggleSideMenu}
        sx={ sx.button }
      >
        {showSideMenu ? 
          <FirstPage sx={ sx.icon  } /> : 
          <LastPage sx={ sx.icon  } />
        }
      </Button>
    </Hidden>
	)
}

export default SideMenuToggle

const sx = {
	button: {
    minWidth: 32,
    boxShadow: `0 1px 0 0 rgb(35 38 59 / 5%)`,
	},
  icon: {
    color: 'common.white',
    '&:hover': {
      color: 'primary.light'
    }
  }
}
