import React, {
	useContext,
	useEffect,
	useState,
} from 'react'
import { ApiContext } from 'context'
import { Autosuggest } from 'components'

import { buildOptions } from 'lib/helpers/utils'
import { get } from 'lodash'

const AsyncAutoselect = ({
	value,
	label,
	name,
	remoteUrl,
	query,
	displayField,
	handleChange,
	position,
	placeholder = 'Search',
	...props
}) => {
	const { api } = useContext(ApiContext)

	const [option, setOption] = useState()
	const [options, setOptions] = useState([])

	const findValue = async (value) => {
		let resp = await api.get(`${remoteUrl}/${value}`)
		setOption({
			value: resp.data.id,
			label: get(resp.data, displayField),
		})
	}

	const findOptions = async (keywords) => {
		let resp = await api.get(remoteUrl, {
			params: {
				keywords: keywords,
				...query,
			},
		})
		let results = buildOptions(
			resp.data,
			'id',
			displayField
		)
		setOptions(results)
	}

	const handleInputChange = (newValue) => {
		findOptions(newValue)
	}

	useEffect(() => {
		findOptions()
	}, [])

	useEffect(() => {
		if (value) {
			findValue(value)
		}
	}, [value])

	return (
		<Autosuggest
			label={label}
			name={name}
			value={option}
			options={options}
			placeholder={placeholder}
			handleChange={handleChange}
			handleInputChange={handleInputChange}
		/>
	)
}

export default AsyncAutoselect

const sx = {
	root: {},
}
