import React, {
	useState,
	useEffect,
} from 'react'
import {
	useActiveProject,
	useProjectUsers,
	useAlerts,
} from 'hooks'
import { useHistory } from 'react-router-dom'
import {
  DeleteWarningModal,
	Panel,
	PanelHeader,
	Placeholder,
	SearchInput,
} from 'components'
import { Box, Button, List } from '@mui/material'
import {
	Search as SearchIcon,
} from '@mui/icons-material'
import UserItem from 'containers/main/users/UserItem'
import UserModal from 'containers/main/users/UserModal'
import PaymentModal from 'containers/main/payments/PaymentModal'
import { PROJECT_PLAN_MAX_USERS } from 'lib/constants'
import { validateInviteUser } from 'validations/users'
import { UserPlus } from 'react-feather'

const UserList = ({ projectId, enableSkip, enableBackButton=false, ...props }) => {	
	const history = useHistory()

  const [activeUser, setActiveUser] = useState(null)
	const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
	const { activeProject } = useActiveProject(projectId)

	const {
		isLoading,
		users,
		user,
		listUsers,
		inviteUser,
		updateUser,
		removeUser,
		handleChange,
		setUser,
	} = useProjectUsers({
		projectId: projectId,
	})

	const { showAlertError, showAlertSuccess } = useAlerts()

	const handleEditClick = (user) => {
		setUser(user)
		setShowModal(true)
	}

  const handlePaymentClick = () => {
    history.push(`/projects/${projectId}/settings/payments`)
  }

	const handleAddClick = () => {
    const { payment_plan } = activeProject || {} 
    if(users?.length >= PROJECT_PLAN_MAX_USERS[payment_plan]){
      setShowPaymentModal(true)
    }else{
      setUser({})
      setShowModal(true)
    }		
	}

	const handleSearch = (keywords) => {
		listUsers({
			keywords: keywords,
		})
	}

	const handleSubmit = async () => {
		let valid = validateInviteUser(user)
		if (valid.isValid) {
			if (user?.id) {
				await updateUser(user)
				showAlertSuccess('User has been updated')
				setShowModal(false)
			} else {
				await inviteUser(user)
				showAlertSuccess('User has been invited')
				setShowModal(false)
			}
			reloadUsers()
		} else {
			valid.messages.map((m) => showAlertError(m))
		}
	}

	const reloadUsers = async () => {
		await listUsers()
	}

  const handleSkipClick = () => {
    history.push(`/projects/${projectId}/collections`)
  }

  const handleDeleteClick = async (user) => {
		setActiveUser(user)
    setShowDeleteModal(true)
	}

	const handleRemoveUser = async () => {
		await removeUser(activeUser)		
		reloadUsers()
    setActiveUser(null)
    setShowDeleteModal(false)
	}

	useEffect(() => {
		if (activeProject?.id) {
			listUsers()
		}
	}, [activeProject?.id])

	return (
		<Box>
      <PanelHeader
        enableBackButton={enableBackButton}
        title={activeProject ? 'Team' : 'Loading...'}        
        actions={
          <>
          { enableSkip && (
            <Button
              variant="outlined"
              onClick={handleSkipClick}								
            >
              Continue
            </Button>
          )}
          <Button
            variant="contained"
            startIcon={
              <UserPlus size={20} sx={ sx.icon } />
            }
            onClick={handleAddClick}								
          >
            Invite user
          </Button>
          </>
        }
      />
      <Panel>
        <Box sx={ sx.searchInput }>
          <SearchInput handleSearch={handleSearch} />
        </Box>
        {users?.length > 0 ? (
          <List>
            {users.map((user, idx) => (
              <UserItem
                key={idx}
                user={user}
                projectId={ projectId }                
                isAdmin={activeProject?.current_role === 'admin' ? true : false}
                handleDeleteClick={handleDeleteClick}
                handleEditClick={handleEditClick}
              />
            ))}
          </List>
        ) : (
          <Placeholder
            icon={<SearchIcon />}
            title="No users"
            subtitle="Please try another search"
          />
        )}
      </Panel>
      <DeleteWarningModal 
        isLoading={ isLoading }
        open={ showDeleteModal }
        onClose={() => setShowDeleteModal(false)}
        onConfirm={ handleRemoveUser }
      />
      <PaymentModal 
        open={ showPaymentModal }
        handleClose={() => setShowPaymentModal(false)} 
        handleClick={ handlePaymentClick }
      />
			<UserModal
				open={showModal}
				handleClose={() => setShowModal(false)}
				isLoading={isLoading}
				user={user}
				handleChange={handleChange}
				handleSubmit={handleSubmit}
			/>
		</Box>
	)
}

export default UserList



const sx = {
	icon: {
		height: 20,
		width: 20,
		color: 'icon'
	},
	searchInput: {
		p: 2,
	},
}
