import React, { useState } from 'react' 
import {
  ProgressLoader,
  CheckboxInput,
  Modal, 
  Placeholder 
} from 'components'
import { Button, Box, Typography } from '@mui/material'

import StripeCreditCard from 'containers/main/payments/StripeCreditCard'
import { useAlerts } from 'hooks'
import { isStripe } from 'lib/helpers/utils'
import { CreditCard } from 'react-feather'

const StripeCreditCardModal = ({
  isLoading,
  open,
  handleClose,
  handleSubscribe,
  customer,
  plan,
  price,
  ...props 
}) => {
 
  const { showAlertError } = useAlerts()

  const [acceptTOS, setAcceptTOS] = useState(false)

  const handleSubscribeClick = async () => {
    if(acceptTOS){
      await handleSubscribe()
      handleClose()
    }else{
      showAlertError("Please accept our Terms of Service to complete payment.")
    }
  }  

  return (
    <Modal 
      open={open}
      handleClose={handleClose}
      title={ `${plan?.title} - ${price}` }
    >
      { isLoading ? (
          <ProgressLoader />  
        ):(
          <Box>
            { isStripe(customer) && (
            <Placeholder 
              icon={ <CreditCard sx={ sx.icon } />  }
              title="Confirm your subscription."
              subtitle="All plans include a 14-day free trial. Cancel anytime."
            /> 
            )}
              <StripeCreditCard 
                customer={customer}
              /> 
            { isStripe(customer) && (
            <Box>
              <Box my={2}>
                <CheckboxInput 
                  disableBorder
                  name="acceptTOS"
                  value={ acceptTOS }            
                  handleChange={() => setAcceptTOS(!acceptTOS)}
                  placeholder={"I agree to the Adminly Terms of Service"}
                />
              </Box>
              <Button
                fullWidth 
                variant="contained"
                onClick={ handleSubscribeClick }
              >
                Subscribe
              </Button>
              <Box sx={ sx.caption  }>
                <Typography variant="body2" color="textSecondary"> 
                  All plans include a 14-day free trial. Cancel anytime.
                </Typography>
              </Box> 
            </Box>
          )}
      </Box>
      )}
    </Modal>
  )
}

export default StripeCreditCardModal

const sx = {
  root: {},
  icon: {
    color: 'icon'
  },
  caption: {
    mt: 1,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 12
  }
}

