import React, { useState } from 'react'

import { TextInput } from 'components'

const NoSpaceInput = ({
	value,
	name,
	label,
	placeholder,
	handleChange,
	disabled,
	...props
}) => {


	const [error, setError] = useState(false)

	const formatChange = (ev) => {
		let { value } = ev.target
		value = value.replace(' ', '_').toLowerCase()

		handleChange({
			target: {
				name,
				value,
			},
		})
	}

	return (
		<TextInput
			error={error}
			value={value}
			disbaled={disabled}
			placeholder={placeholder}
			handleChange={formatChange}
			name={name}
			label={label}
		/>
	)
}

export default NoSpaceInput

const sx = {
	root: {},
}
