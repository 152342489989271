import React, {
	useState,
	useEffect,
	useContext,
} from 'react'
import { AppContext } from 'context'
import { useAlerts } from 'hooks'
import {
	Avatar,
	Fade,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Paper,
	Slide,
	Box,
	Typography,
} from '@mui/material'

import {
	Clear,
} from '@mui/icons-material'

const Alert = () => {
	const [open, setOpen] = useState(false)

	const { alert, setAlert } = useContext(AppContext)

	const handleClose = () => {
		setOpen(false)
		setAlert()
	}

	useEffect(() => {
		if (alert && alert?.message) {
			setOpen(true)
		}
	}, [alert])

	useEffect(() => {
		if (open) setTimeout(() => setOpen(false), 2500)
	}, [open])

	return (
		<Fade in={open}>
			<Slide direction="down" in={open}>
				<Box width="100%" p={2} sx={ sx.root }>
					<Paper elevation={4} sx={ sx.alert }>
						<List disablePadding>
							<ListItem button onClick={handleClose}>
								<ListItemText
									primary={
										<Typography
											variant="body1"
											sx={ sx.text }
										>
											{alert?.message || ''}
										</Typography>
									}
								/>
							</ListItem>
							<ListItemSecondaryAction>
								<IconButton
									size="small"
									onClick={handleClose}
								>
									<Clear sx={ sx.icon } />
								</IconButton>
							</ListItemSecondaryAction>
						</List>
					</Paper>
				</Box>
			</Slide>
		</Fade>
	)
}

export default Alert

const sx = {
	root: {
		position: 'fixed',
		zIndex: 2000,
		width: '100%',
		top: 0,
		left: 0,
	},
	alert: {
		maxWidth: 500,
		m: '0 auto',
		backgroundColor: 'primary.main',
		color: 'common.white',
	},
	text: {
		color: 'common.white',
	},
	icon: {
		color: 'common.white',
	},
}
