import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, InputBase } from '@mui/material'
import { sx } from 'components/inputs/TextInput/sx'

const SearchInput = ({
	name,
	label,
  value,
  query,
	placeholder = 'Search...',	
  handleChange,
  handleSearch,
  expandable=false,
  styles={},
	...props
}) => {

  const handleKeyPress = (ev) => {
    if(ev.code === 'Enter'){
      handleSearch(value)
    }
  }

	return (
		<Box width="100%">
			{label && (
				<Typography variant="body2" color="textSecondary">
					{label}
				</Typography>
			)}
			<InputBase
				sx={{ 
          ...sx.inputBase, 
          ...(expandable && sx.inputExpand),
          ...styles 
        }}
				type="text"
				fullWidth
				name={name}
				variant={'outlined'}
				placeholder={placeholder}
				autoComplete="off"
				onChange={handleChange}				
				value={value}
        onKeyPress={ handleKeyPress }
			/>
		</Box>
	)
}

SearchInput.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	placeholder: PropTypes.string,
}

export default SearchInput
