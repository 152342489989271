import React, { useContext } from 'react'
import { ProjectContext } from 'context'
import { isPaid, isStripe } from 'lib/helpers/utils'
import { Box, Typography } from '@mui/material'
import { Label } from 'components'
import StripeSubscriptionActions from 'containers/main/payments/StripeSubscriptionActions'


const StripeSubscription = ({ ...props }) => {

	const { activeProject } = useContext(ProjectContext)

	return (
		<Box sx={ sx.root }>
			<Box>
        <Box display='flex' flexDirection='row'>
          <Typography variant="subtitle1" color="textPrimary">
            Current subscription          
          </Typography>
          <Box ml={1}>
            { activeProject?.payment_plan && (
              <Label label={ activeProject?.payment_plan } />
            )}
          </Box>
        </Box>
				<Typography variant="body2" color="textSecondary">
					Manage your subscription. Cancel anytime.
				</Typography>
			</Box>
      <Box>
        {activeProject && isStripe(activeProject) && (
					<StripeSubscriptionActions />
				)}
      </Box>
		</Box>
	)
}

export default StripeSubscription

const sx = {
	root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
		p: 3,
	},
	label: {
		pl: 1,
	},
}
