import React from 'react'
import { Box, Typography } from '@mui/material'
import { Label } from 'components'

const AppHeader = ({ app, size = 100 }) => {
	
	return (
		<Box sx={ sx.header }>
			<Box sx={{ 
        ...sx.imageContainer, 
        width: size,
        height: size,
      }}>
				<img 
          src={app.icon_url} 
          style={{ 
            ...styles.image,
            width: (size * 2) / 3, 
          }} 
        />
			</Box>
			<Box>
				<Typography variant="h4">{app.name}</Typography>
				<Label label={app.category} />
			</Box>
		</Box>
	)
}

export default AppHeader

const styles = {
  image: {    
    height: 'auto',
  }
}

const sx = {
	header: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	imageContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'common.white',
		borderRadius: 8,
		m: 1,
		p: 1,
		boxShadow: '0 2px 8px rgb(0,0,0,0.05)',
	},
}
