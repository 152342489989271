import React from 'react'
import { useActiveProject, useMenu } from 'hooks'
import {
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemSecondaryAction,
	Menu,
	MenuItem,
} from '@mui/material'
import { UserAvatar, Label } from 'components'
import { MoreHoriz } from '@mui/icons-material'


const UserItem = ({
	user,
	isOwner,
	isAdmin,
	handleEditClick,
	handleDeleteClick,
  projectId,
	...props
}) => {

  const { activeProject } = useActiveProject(projectId)
	
  const { 
    open, 
    anchorEl, 
    toggleMenu, 
    handleClose 
  } = useMenu()

	const renderMenu = () => (
		<Menu
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
		>
      <MenuItem onClick={() => handleEditClick(user)}>
        Edit
      </MenuItem>
      <MenuItem onClick={() => handleDeleteClick(user)}>
        Remove
      </MenuItem>
		</Menu>
	)

	return (
		<ListItem>
			<ListItemIcon>
				<UserAvatar src={user.avatar} variant="circle" />
			</ListItemIcon>
			<ListItemText
				primary={user.name}
				secondary={user.email}
			/>
      {(isAdmin && !(activeProject?.user_id === user?.id)) && (
        <ListItemSecondaryAction>				
          <Label label={'Admin'} />
          <IconButton onClick={(e) => toggleMenu(e)}>
            <MoreHoriz color="secondary" />
            {renderMenu()}
          </IconButton>
        </ListItemSecondaryAction>
      )}
		</ListItem>
	)
}

export default UserItem
