import React, {
	useState,
	useEffect,
	useContext,
} from 'react'
import { ProjectContext } from 'context'
import { muiTheme } from 'theme'
import { createTheme } from '@mui/material/styles'
const theme = createTheme(muiTheme)

const useCloudinaryWidget = ({ onUploadComplete }) => {
	const { activeProject } = useContext(ProjectContext)

	const [cloudinary, setCloudinary] = useState()

	const setupCloudinary = async () => {
		//let credentials = await fetchCloudinaryToken('image')
		//const { api_key, cloud_name, signature } = credentials
		const widget = window.cloudinary.createUploadWidget(
			{
				api_key: activeProject.cloudinary_api_key,
				cloudName: activeProject.cloudinary_cloud_name,
				uploadPreset: 'my_preset',
				cropping: 'true',
				croppingCoordinatesMode: 'custom',
				showPoweredBy: false,
				showAdvancedOptions: true,
				showUploadMoreButton: true,
				styles: styles,
			},
			(error, result) => {
				if (
					!error &&
					result &&
					result.event === 'success'
				) {
					onUploadComplete()
				}
			}
		)
		setCloudinary(widget)
	}

	const openCloudinary = () => {
		if (cloudinary) {
			cloudinary.open()
		}
	}

	useEffect(() => {
		if (activeProject?.id) {
			setupCloudinary()
		}
	}, [activeProject])

	return {
		openCloudinary,
	}
}

export default useCloudinaryWidget

const styles = {
	palette: {
		window: 'common.white',
		windowBorder: 'text.secondary',
		tabIcon: 'text.secondary',
		menuIcons: 'text.secondary',
		textDark: 'text.secondary',
		textLight: 'common.white',
		link: 'text.secondary',
		action: 'text.secondary',
		inactiveTabIcon: 'text.secondary',
		error: 'text.secondary',
		inProgress: 'text.secondary',
		complete: 'text.secondary',
		sourceBg: 'common.white'
	},
	fonts: {
		Roboto:
			'https://fonts.googleapis.com/css?family=Roboto:400,500,600,700,800|Inter:400,500,600,700,800',
	},
}
