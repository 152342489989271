import React, { useState, useEffect } from 'react'
import { useAlerts } from 'hooks'
import {
  Box,
  Button 
} from '@mui/material'
import { Grid, Terminal, MessageSquare } from 'react-feather'
import { TextInput } from 'components'
import copy from 'copy-to-clipboard'

const ChatGPTForm = ({
  prompt, 
  setPrompt,
  query,
  handleChange,
  handleSubmit,
  handleOpenDrawer,
  ...props 
}) => {

  const { showAlertSuccess } = useAlerts()

  const handleCopyClick = () => {
    copy(query || '')
    showAlertSuccess('Copied to clipboard')
    setTimeout(() => window.open('https://chat.openai.com', '_blank'), 200)
  }

  return(
      <Box sx={ sx.root }>
        <TextInput 
          rows={6}
          multiline
          value={ prompt }
          handleChange={ handleChange } 
          placeholder="A query to..."
          styles={ sx.textInput }
        />
        <Box sx={ sx.buttons }>
          <Button           
            onClick={ handleSubmit }
            variant="contained"
            sx={ sx.button }
            endIcon={ 
              <MessageSquare size={20} /> 
            }
          >
            Convert to SQL
          </Button>
          <Button   
            variant="outlined"        
            onClick={ handleOpenDrawer }
            sx={ sx.button }
            endIcon={ 
              <Grid size={20} /> 
            }
          >
            Tables   
          </Button>
          { query && (
            <Button           
              onClick={ handleCopyClick }
              sx={ sx.button }
              endIcon={ 
                <Terminal size={20} /> 
              }
            >
              OpenAI  
            </Button>
          )}
        </Box>
      </Box>
  )
}

export default ChatGPTForm 

const sx = {
  root: {
    width: '100%',
  },
  button: {
    mt: 2
  },
  textInput: {
    fontFamily: 'IBM Plex Mono',
  },
  buttons: {
    display: 'flex',
    gap: 1,
  }
}