import React, { useContext, useEffect } from 'react'
import { ProjectContext } from 'context'
import { useActiveProject, useMenu, useProjects, useResponsive } from 'hooks'
import { 
  Avatar,
  Box,
  Button,     
  ListItemIcon,
  ListItemText, 
  Menu, 
  MenuItem,
  Typography,   
} from '@mui/material'
import { getInitials } from 'lib/helpers/adminly'
import { Divider } from 'components'
import ProjectIcon from 'containers/main/projects/ProjectIcon'
import { UnfoldMore } from '@mui/icons-material' 
import { Plus } from 'react-feather'
import { useHistory } from 'react-router-dom'
import { alpha } from '@mui/material'
import { COLORS } from 'lib/constants'

const ActiveProjectMenu = ({
  ...props
}) => {

  const history = useHistory()

  const { activeProject, setActiveProject } = useActiveProject()
  const { projects } = useContext(ProjectContext)
  const { isMobile } = useResponsive()
  const { 
    isLoading, 
    findProjects, 
    checkSchemaStatus 
  } = useProjects({})

  const { 
    anchorEl,
    open,
    closeMenu,
    toggleMenu,
  } = useMenu()

  const handleToggleMenu = async (ev) => {       
    toggleMenu(ev)
    await findProjects()
  }

  const handleAddProject = () => {
    history.push('/projects/onboarding/step1')
  }

  const handleSwitchProject = async (project) => {
    closeMenu()
    setActiveProject(project)
    history.push(`/projects/${project?.id}/collections`)    
  }

  useEffect(() => {   
    if(!projects){
      findProjects()
    } 
  }, [projects])  

	return (
    <Box sx={ sx.root }>
      <Button        
        onClick={ handleToggleMenu }
        startIcon={
          <ProjectIcon project={ activeProject } />
        }
        sx={ sx.button  }
        endIcon={ <UnfoldMore sx={ sx.icon } />  }
      >
        { activeProject?.name ? (
          <span>
            { isMobile ? getInitials(activeProject?.name) : activeProject?.name }
          </span>
        ):(
          <span>select</span>
        )}      
      </Button>    
      <Menu
        open={open}
        onClose={toggleMenu}
        anchorEl={anchorEl}
      >     
      {  projects && 
          projects?.filter(p => p.status === 'ready').map((project, index) => (
          <MenuItem
            key={index}
            onClick={() => handleSwitchProject(project)}
          >
              <ListItemIcon>
                <ProjectIcon 
                  project={project} 
                />
              </ListItemIcon>            
              <ListItemText 
                primary={
                  <Typography variant="body2">
                    { project.name }
                  </Typography>
                }
              />                    
          </MenuItem>
        ))}
      <Divider />
      <MenuItem
        onClick={ handleAddProject }
      >
        <ListItemIcon>
          <Avatar
            variant={'rounded'}
            sx={{
              ...sx.avatar,
              color: COLORS['teal'],
              backgroundColor: alpha(COLORS['teal'], 0.2),
            }}
          >
            <Plus size={20} />
          </Avatar>
        </ListItemIcon>
        <ListItemText
          secondary={'Add Project'}
        />
      </MenuItem>
    </Menu>  
    </Box>
	)
}

export default ActiveProjectMenu

const sx = {
  avatar: {		
    height: 28,
    width: 28,
		boxShadow: `rgb(0 0 0 / 10%) 0px 2px 4px !important`,
	},
  caption: {
    fontSize: 10
  },
	toolbar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
    height: '100%',
    pl: { sm: '36px' }
	},
	root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
		pl: 1,    
	},
  icon: {
    color: 'text.secondary'
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    color: 'text.primary',
    fontSize: 14,
    textTransform: 'none'
  },
  unfoldMoreButton: {
    minWidth: 32,
    color: 'secondary.main'
  }	
}
