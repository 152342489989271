import React from 'react'
import {
  Box,
  List,
  ListItemText,
  ListItemButton,
  Typography,
  Collapse 
} from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'

const ListExpandable = ({ label, open, handleToggle, children }) => {

  return(
    <Box>
    <List 
      dense
      sx={ sx.list }
    >      
      <ListItemButton
        sx={ sx.listButton  }
        onClick={ handleToggle }
      >
        <ListItemText
          primary={
            <Typography variant="body2" sx={ sx.caption }>
              { label } 
            </Typography>
          }
        />
          <KeyboardArrowDown
            sx={{
              ...sx.icon,
              ...(open && sx.rotateIcon)
            }}
          />
      </ListItemButton>
      <Collapse in={open}>
        { children }
      </Collapse>
    </List>
    </Box>
  )
}

export default ListExpandable 

const sx = {
	list: {
    px: 1,
		width: '100%',
    borderRadius: 0,
	},
  listButton: {
    cursor: 'pointer',
    borderRadius: '4px',
    '&:hover': {      
      backgroundColor: 'primary.main'
    },
  },
  caption: {
    fontWeight: 500,
    fontSize: 12,
    transition: '0.2s',
    color: 'common.white',
    textTransform: 'uppercase'
  },
  icon: {    
    mr: -1,
    color: 'white',
    transition: '0.2s',    
    transform: 'rotate(0)'
  },
  rotateIcon: {
    transform: 'rotate(-180deg)' 
  }
}


