import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useAdminProjects, useAlerts } from 'hooks'
import {
	Box,
	Paper,
	Button,
	Typography,
	Container,
} from '@mui/material'

import { Edit } from '@mui/icons-material'
import { Subheader } from 'components'
import ProjectDetails from 'containers/admin/projects/ProjectDetails'

const ProjectShow = ({ ...props }) => {
	const history = useHistory()
	const { id } = props.match.params

	const {
		projectId,
		isLoading,
		isLoaded,
		project,
		findProject,
	} = useAdminProjects({ id: id })

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleEditClick = () =>
		history.push(`/projects/${id}/edit`)

	useEffect(() => {
		if (id) findProject(id)
	}, [])

	return (
		<>
			<Subheader
				title={
					isLoading ? 'Loading...' : `${project.title}`
				}
				buttons={[
					<Button
						variant="contained"
						onClick={handleEditClick}
						color="primary"
					>
						Edit
					</Button>,
				]}
			/>
			<Container>
				<Paper>
					<Box p={4}>
						<ProjectDetails project={project} />
					</Box>
				</Paper>
			</Container>
		</>
	)
}

export default ProjectShow
