import React from 'react'
import {
  OnboardingLayout
} from 'components'
import UserList from 'containers/main/users/UserList'
import ProjectSteps from 'containers/main/projects/onboarding/ProjectSteps'

const ProjectInvite = ({ ...props }) => {
  const step = 4
  const { id: projectId } = props.match.params
	
	return (
    <OnboardingLayout>
      <ProjectSteps step={ step } />
      <UserList 
        enableSkip
        projectId={ projectId } 
      /> 			
		</OnboardingLayout>
	)
}

export default ProjectInvite
