import { useContext, useEffect, useState } from 'react'
import { ApiContext } from 'context'
import { useLoaders, useResource } from 'hooks'

const useFields = ({
	id,
	projectId,
	collectionId,
	...props
}) => {
	const { api } = useContext(ApiContext)

	const { showLoading, hideLoading } = useLoaders()

	const {
		resourceId: fieldId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: field,
		resources: fields,
		findOne: findField,
		findMany: findFields,
		update: updateField,
		create: createField,
		save: saveField,
		destroy: deleteField,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reload: reloadField,
		reloadMany: reloadFields,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		setResource: setField,
		setResources: setFields,
	} = useResource({
		id: id,
		url: `/api/v1/projects/${projectId}/collections/${collectionId}/fields`,
		name: 'field',
	})

	const updateFieldPositions = async (sortedFields) => {		
		try {
      showLoading()
			const resp = await api.post(`/api/v1/projects/${projectId}/collections/${collectionId}/fields/update_positions`,{
        ids: sortedFields.map((field) => field.id),
        positions: sortedFields.map((field, index) => index),
      }
			)
			setFields(resp?.data)
		} catch (e) {
			console.log(e)
		}finally{
      hideLoading()
    }	
	}

	return {
		fieldId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		field,
		fields,
		findField,
		findFields,
		saveField,
		updateField,
		createField,
		deleteField,
		updateFieldPositions,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		reloadField,
		reloadFields,
		sortKey,
		sortDirection,
		handleSort,
		setField,
		setFields,
	}
}

export default useFields
