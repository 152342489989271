import React from 'react'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	Slide,
	IconButton,
} from '@mui/material'
import { useResponsive } from 'hooks'

import { Close } from '@mui/icons-material'

const Modal = ({
	open,
	handleClose,
	title,
	subtitle,
	actions,
	children,
	maxWidth,
	secondaryActions,
	p = 2,
	fullScreen,
}) => {

  const { isMobile } = useResponsive()

	return (
		<Dialog
			sx={ sx.root }
			fullWidth
			maxWidth={maxWidth || 'sm'}
			fullScreen={
				isMobile || fullScreen === true ? true : false
			}
			open={open}
			onClose={handleClose}
			transition={Slide}
		>
			<DialogTitle
				sx={ sx.dialogTitle }
				onClose={handleClose}
			>
				<Box display="flex" justifyContent="space-between">
					<Box display="flex" flexDirection="row">
						<Typography
							variant="h6"
							color="textPrimary"
							sx={ sx.title }
						>
							{title}
						</Typography>
					</Box>
					<Box sx={ sx.secondaryActions }>
						{secondaryActions && secondaryActions}
						<IconButton
							sx={ sx.closeButton }
							onClick={handleClose}
						>
							<Close />
						</IconButton>
					</Box>
				</Box>
			</DialogTitle>
			<DialogContent sx={{ ...sx.dialogContent, p: p }}>
				{subtitle && (
					<Typography variant="body1" mt={1}>
						{subtitle}
					</Typography>
				)}
				<Box>{children}</Box>
			</DialogContent>
			<DialogActions sx={ sx.dialogActions }>
				<Button
          variant='outlined'
					onClick={handleClose}
					sx={ sx.button }
				>
					Close
				</Button>
        { actions && actions }
			</DialogActions>      
		</Dialog>
	)
}

export default Modal

const sx = {
	root: {
  },
	title: {
		mt: 1,
	},
	dialogTitle: {
		color: 'text.primary',
		pt: 1,
    pr: 1.5,
    pb: 1,
    pl: 2,
		width: '100%',
		backgroundColor: 'common.white',
		borderBottom: theme => `1px solid ${theme.palette.common.border}`,
	},
	dialogContent: {
		backgroundColor: 'background.paper',
	},
	dialogActions: {
		backgroundColor: 'background.main'
	},
	secondaryActions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	closeButton: {
		color: 'text.secondary'
	},
}
