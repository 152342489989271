import React, { useContext } from 'react'
import { AppContext } from 'context'
import { Button } from '@mui/material'
import { LockOpenOutlined, LockOutlined } from '@mui/icons-material'

const ToggleEditing = ({ ...props }) => {

	const { isEditing, toggleEditing } = useContext(AppContext)

	return (
		<Button
			onClick={toggleEditing}
			sx={{ 
        ...sx.button, 
        ...(isEditing && sx.editing)
      }}
		>
			{isEditing ? <LockOpenOutlined /> : <LockOutlined />}
		</Button>
	)
}

export default ToggleEditing

const sx = {
	button: {
		minWidth: '32px',
		color: 'text.secondary'
	},
	editing: {
		color: 'warning.dark',
		backgroundColor: 'error.light',
		'&:hover': {
			backgroundColor: 'error.light'
		},
	},
}
