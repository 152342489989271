import { useContext, useEffect, useState } from 'react'
import { ApiContext } from 'context'
import { useAlerts, useResource, useLoaders } from 'hooks'

const useCloudinaryFolders = ({
	id,
	projectId,
	...props
}) => {
	const { api } = useContext(ApiContext)

	const { showLoading, hideLoading } = useLoaders()
	const [nextCursor, setNextCursor] = useState()
	const { showAlertError } = useAlerts()

	const {
		folderId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: folder,
		resources: folders,
		findOne: findFolder,
		findMany: findFolders,
		reload: reloadFolder,
		reloadMany: reloadFolders,
		query,
		meta,
		setResource: setFolder,
		setResources: setFolders,
	} = useResource({
		id: id,
		url: `/api/v1/apps/projects/${projectId}/cloudinary_folders`,
		name: 'folder',
	})

	const createFolder = async (path) => {
		try {
			const resp = await api.post(
				`/api/v1/apps/projects/${projectId}/cloudinary_folders/create_folder`,
				{
					folder: {
						path: path,
					},
				}
			)
			return resp?.data
		} catch (e) {
			console.log(e)
		} finally {
			hideLoading()
		}
	}

	const deleteFolder = async (path) => {
		try {
			const resp = await api.post(
				`/api/v1/apps/projects/${projectId}/cloudinary_folders/delete`,
				{
					folder: {
						path: path,
					},
				}
			)
			return resp?.data
		} catch (e) {
			showAlertError('Folder is not empty')
			console.log(e)
		} finally {
			hideLoading()
		}
	}

	const loadMoreFolders = async (nextCursor) => {
		let nextFolders = await findFolders({
			next_cursor: nextCursor,
		})
		if (nextFolders.length > 0) {
			setFolders([...folders, ...nextFolders])
		}
	}

	useEffect(() => {
		setNextCursor(meta?.next_cursor)
	}, [meta])

	return {
		folderId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		folder,
		folders,
		findFolder,
		findFolders,
		createFolder,
		deleteFolder,
		reloadFolder,
		reloadFolders,
		loadMoreFolders,
		query,
		nextCursor,
		setFolder,
		setFolders,
	}
}

export default useCloudinaryFolders
