import palette from 'theme/palette'

export default {
	styleOverrides: {
		root: {
      width: '100%',
			backgroundColor: palette.common.white,
		},
	},
}
