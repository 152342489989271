import React from 'react'
import { Grid } from '@mui/material'
import {
  ArrayAutosuggest,
	CollectionAutosuggest,
  FieldAutosuggest,
	TextInput,
	SwitchInput,
} from 'components'

const FieldForm = ({ field, tab=0, handleChange }) => {
  
  const { friendly_type, field_type } = field || {} 
  return (       
    tab == 0 ? (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextInput
            label="Name"
            value={field.name || ''}
            name="name"
            placeholder="Name"
            handleChange={handleChange}
          />
        </Grid>

        {(friendly_type === 'reference' || field_type === 'reference') && (
          <Grid item xs={12} sm={12}>
            <CollectionAutosuggest
              label="Reference collection"
              value={field.foreign_collection_id || ''}
              name="foreign_collection_id"
              placeholder="Reference collection"
              handleChange={handleChange}
            />
          </Grid>
        )}

        {(friendly_type === 'habtm') && (
          <Grid item xs={12} sm={12}>
            <CollectionAutosuggest
              label="Join collection"
              value={field.foreign_join_collection_id || ''}
              name="foreign_join_collection_id"
              placeholder="Join collection"
              handleChange={handleChange}
            />
          </Grid>
        )}

        {field.foreign_collection_id && 
        (friendly_type === 'reference' || friendly_type === 'has_many') && (
          <Grid item xs={12} sm={12}>
            <FieldAutosuggest
              label="Reference field"
              value={field.foreign_field_id || ''}
              collectionId={ field.foreign_collection_id }
              name="foreign_field_id"
              placeholder="Reference field"
              handleChange={handleChange}
            />
          </Grid>
        )}


        { friendly_type === 'enumerable' && (
          <Grid item xs={12} sm={12}>
            <ArrayAutosuggest
              freeSolo
              label="Options"
              value={field.options || []}          
              name="options"
              options={[]}
              placeholder="select"
              handleChange={handleChange}
            />
          </Grid>
        )}
    </Grid>
    ):(
    <Grid container spacing={2}>
      { field.db_name !== 'id' && (
        <Grid item xs={12} sm={12}>
          <SwitchInput
            value={field.visible != null ? field.visible : true}
            label="Visible"
            name="visible"
            placeholder="This field is visible"
            handleChange={handleChange}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12}>
        <SwitchInput
          value={field.editable != null ? field.editable : true}
          label="Editable"
          name="editable"
          placeholder="This field is editable"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <SwitchInput
          value={field.required || false}
          name="required"
          label="Required"
          placeholder="This field is required"
          handleChange={handleChange}
        />
      </Grid>
    </Grid>
    )
)}

export default FieldForm
