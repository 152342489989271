import React, { useState } from 'react'
import { Box, Hidden, IconButton } from '@mui/material'
import { Close, Menu } from '@mui/icons-material'
import Drawer from 'containers/main/navigation/Drawer'

const Sidebar = (props) => {

	const [open, setOpen] = useState(false)
	const toggleMenu = () => setOpen(!open)
  const closeMenu = () => setOpen(false)

	return (
		<Box width={'100%'}>
			<Hidden mdUp>
				<Box sx={ sx.box }>
					<IconButton
						sx={ sx.iconButton }
						size="small"
						onClick={toggleMenu}
						color="secondary"
					>
						{open ? (
							<Close sx={ sx.icon } />
						) : (
							<Menu sx={ sx.icon } />
						)}
					</IconButton>
				</Box>
			</Hidden>
			<Drawer 
        open={open} 
        closeMenu={closeMenu}         
      />
		</Box>
	)
}

export default Sidebar

const sx = {
	box: {
		zIndex: 99,
		position: 'fixed',
		top: 20,
		left: 10,
	},
	icon: {
		color: 'text.secondary'
	},
	iconButton: {
		backgroundColor: 'background.main',
		opacity: 0.8,
	},
}
