import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
	useActiveProject,
	useTabs,
	useMenu,
	useProjects,
	useCollections,
} from 'hooks'
import {
	Panel,
	PanelHeader,
	Placeholder,
	PanelLayout,
	LeftPanel,
	CenterPanel,
} from 'components'
import { Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import FieldList from 'containers/main/fields/FieldList'
import SchemaLeftPanel from 'containers/main/schema/SchemaLeftPanel'
import { Search } from 'react-feather'

const CollectionContainer = ({ ...props }) => {
	useTabs('schema')

	const history = useHistory()
	const { id: projectId, collection_id: collectionId } = props.match.params

	const [activeCollection, setActiveCollection] = useState()
	const [openMobile, setOpenMobile] = useState(false)

	const { isLoading } = useProjects({})

	const { activeProject } = useActiveProject(projectId)

	const { 
    collections, 
    findCollection, 
    findCollections 
  } = useCollections({
		projectId: projectId,
	})

	const handleCollectionClick = (collection) => {
		history.push(
			`/projects/${projectId}/schema/${collection?.id}`
		)
	}

	const handleLoadCollections = async () => {
		await findCollections()
	}

	const handleLoadCollection = async () => {
		const resp = await findCollection(collectionId)
		setActiveCollection(resp)
	}

	useEffect(() => {
		if (!projectId && activeProject) {
			history.push(`/projects/${activeProject.id}/schema`)
		}
	}, [projectId, activeProject])

	useEffect(() => {
		if (projectId) {
			handleLoadCollections()
		}

		if (collectionId) {
			setActiveCollection({ id: collectionId })
		}

		if (projectId && collectionId) {
			handleLoadCollection()
		}
	}, [projectId, collectionId])

	useEffect(() => {
		if (activeCollection?.id && activeProject?.id) {
			setOpenMobile(true)
		}
	}, [activeCollection?.id, activeProject?.id])

	return (
		<Box sx={ sx.root }>
			<PanelLayout>
				<LeftPanel isLoading={isLoading} open={openMobile}>
					{collections && (
						<SchemaLeftPanel
							collections={collections}
							activeCollection={activeCollection}
							handleClick={handleCollectionClick}
							handleReload={handleLoadCollections}
						/>
					)}
				</LeftPanel>
				<CenterPanel open={openMobile}>
					{activeProject?.id && (
						<Box>
							{activeCollection?.id ? (
                <FieldList
                  collection={activeCollection}
                />
							) : (
								<Box>
									<Panel py={4}>
										<Placeholder
											icon={
												<Search sx={ sx.icon } />
											}
											title="Select a Collection"
											subtitle="No collections."
										/>
									</Panel>
								</Box>
							)}
						</Box>
					)}
				</CenterPanel>
			</PanelLayout>
		</Box>
	)
}

CollectionContainer.propTypes = {
	className: PropTypes.string,
}

export default CollectionContainer

const sx = {
	root: {},
	iconButton: {
		color: 'primary.main'
	},
	icon: {
		color: 'secondary.main'
	},
}
