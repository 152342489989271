import React, { useContext, useEffect } from 'react'
import { useProjects } from 'hooks'
import { ProjectContext } from 'context'

const useActiveProject = (projectId) => {
	const { activeProject, setActiveProject } = useContext(ProjectContext)

	const { findProject } = useProjects({ id: projectId })

	const findActiveProject = async () => {
		let proj = await findProject(projectId)
		setActiveProject(proj)
	}

	useEffect(() => {
    console.log(projectId, activeProject)
		if (projectId && !activeProject?.id) {  
      console.log("here")
			findActiveProject()
		}
	}, [projectId, activeProject?.id])

	return {
		projectId,
		activeProject,
		setActiveProject,
		findActiveProject,
	}
}

export default useActiveProject
