import React from 'react'
import { GoogleLoginButton, AuthLayout, AuthScreen } from 'components'
import SignupForm from 'containers/main/auth/SignupForm'
import Divider from './Divider'
import { useAlerts, useAuth } from 'hooks'
import { validateUser } from 'validations/users'
import { GOOGLE_CLIENT_ID } from 'lib/constants'

const Login = ({ ...props }) => {

  const { 
    isLoading,
    user, 
    handleChange, 
    signupUser 
  } = useAuth()

  const { showAlertError } = useAlerts()

	const handleSubmit = async () => {
    let valid = validateUser(user)
    if(valid.isValid){
      await signupUser(user)
    }else{
      valid.messages.map(m => showAlertError(m))
    }		
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') handleSubmit()
	}

	return (
    <AuthLayout>
      <AuthScreen
        title="Sign up"
        subtitle="Register your account"
      >
        <GoogleLoginButton 
          googleClientId={ GOOGLE_CLIENT_ID }
        />
        <Divider /> 
        <SignupForm
          isLoading={isLoading}
          user={user}
          handleChange={handleChange}
          handleKeyPress={handleKeyPress}
          handleSubmit={handleSubmit}
        />
      </AuthScreen>
    </AuthLayout>
	)
}

export default Login
