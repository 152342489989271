import React from 'react'
import { Box } from '@mui/material'

const Panel = ({
	children,
	maxWidth = 'xl',
	p=2,
  my=1,
  styles={},
	...props
}) => {

	return (
		<Box p={p} my={my} sx={ { ...sx.root, ...styles }}>
			{children}
		</Box>
	)
}

export default Panel

const sx = {
	root: {
		width: '100%',
    borderRadius: theme => theme.shape.borderRadius,
		backgroundColor: 'common.white',
    boxShadow: `0 0 0 1px rgb(35 38 59 / 5%), 0 1px 3px 0 rgb(35 38 59 / 15%)`
	},
}
