import React from 'react'
import {
	Avatar,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material'

const VerticalTab = ({
	icon,
	step,
	value,
	primary,
	secondary,
	handleClick,
	dense = false,
	disableBorder = false,
	...props
}) => {

	return (
		<ListItem
			button
			dense
			onClick={() => handleClick(value)}
			sx={
        step === value ? 
        { ...sx.listItem, ...sx.activeListItem } : 
        sx.listItem } 
		>
			<ListItemIcon>
				<Avatar
					sx={
            step === value ? 
            { ...sx.avatar, ...sx.active } : 
            sx.avatar 
          }
				>
					{icon}
				</Avatar>
			</ListItemIcon>
			<ListItemText
				primary={
					<Typography
						variant="body1"
						sx={
              step === value ? 
              { ...sx.text, ...sx.activeText } : 
              sx.text 
            }
					>
						{primary}
					</Typography>
				}
				secondary={
					<Typography variant="body2" color="textSecondary">
						{secondary}
					</Typography>
				}
			/>
		</ListItem>
	)
}

export default VerticalTab

const sx = {
	avatar: {
		mr: 2,
		height: 50,
		width: 50,
	},
	active: {
		backgroundColor: 'primary.main',
	},
	listItem: {
		backgroundColor: 'common.white',
		borderRight: `4px solid transparent`,
	},
	activeListItem: {
		borderRight: theme => `4px solid ${theme.palette.primary.main}`,
	},
	text: {
		fontWeight: 500,
	},
	activeText: {
		color: 'primary.main',
		fontWeight: 500,
	},
}
