import React from 'react'
import { AppContext } from 'context'
import PropTypes from 'prop-types'
import {
	useActiveProject,
	useAlerts,
	useProjects,
} from 'hooks'
import {
	PanelLayout,
	LeftPanel,
	CenterPanel,
} from 'components'
import { Box } from '@mui/material'
import SettingsProjectDetails from 'containers/main/settings/SettingsProjectDetails'
import SettingsLeftPanel from 'containers/main/settings/SettingsLeftPanel'

const SettingsProject = ({ ...props }) => {
	  
	const { id: projectId, tab } = props.match.params

	const { showAlertSuccess } = useAlerts()

	const { 
    activeProject, 
    setActiveProject 
  } = useActiveProject(projectId)

	const { 
    isLoading, 
    syncSchema, 
    updateProject 
  } = useProjects({
		id: activeProject?.id,
	})

	const handleChange = (ev) => {
		const { name, value } = ev.target
		setActiveProject({
			...activeProject,
			[name]: value,
		})
	}

	const handleSaveProject = async () => {
		await updateProject(activeProject)
		showAlertSuccess('Project saved')
	}

  const handleSyncSchema = async () => {    
    await syncSchema(activeProject?.id)
    showAlertSuccess("Your project's schema has been synced with the database.")
  }

	return (
		<Box sx={ sx.root }>
			<PanelLayout>
				<LeftPanel>
					<SettingsLeftPanel 
            activeTab={'details'}
          />
				</LeftPanel>
				<CenterPanel>
					{activeProject && (
            <SettingsProjectDetails
              isLoading={isLoading}
              project={activeProject}
              handleChange={handleChange}
              handleSubmit={handleSaveProject}
              handleSyncSchemaClick={handleSyncSchema}              
            />
          )}
				</CenterPanel>
			</PanelLayout>
		</Box>
	)
}

SettingsProject.propTypes = {
	className: PropTypes.string,
}

export default SettingsProject

const sx = {
	root: {},
}
