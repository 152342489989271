import React from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	ListItem,
	ListItemText,
	ListItemIcon,
	ListItemSecondaryAction,
} from '@mui/material'
import { Label, ListItemButton } from 'components'

import { Check } from 'react-feather'

const AppItem = ({ app, addon, handleClick, ...props }) => {

	return (
		<ListItemButton
			image={app.icon_url}
			primary={app.name}
			secondary={
				<Label label={app.category} />
			}
			secondaryAction={
				addon?.id && (
					<Button
						color="primary"
						startIcon={
							<Check sx={ sx.checkIcon } />
						}
					>
						Installed
					</Button>
				)
			}
			onClick={() => handleClick(app)}
		/>
	)
}

AppItem.propTypes = {
	app: PropTypes.object,
	handleClick: PropTypes.func,
}

export default AppItem

const sx = {
	listItem: {
		width: '100%',
		mb: 1,
		backgroundColor: 'common.white',
		'&:hover': {
			backgroundColor: 'common.highlight'
		},
	},
	icon: {
		width: 32,
		height: 32,
	},
	checkIcon: {
		width: 20,
		height: 20,
	},
}
