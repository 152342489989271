import React from 'react'
import { Box, Button, Container } from '@mui/material'
import Logo from 'assets/logo-dark.svg'
import { Header } from 'components'
import { useHistory } from 'react-router-dom'

const Layout = ({ children, ...props }) => {
  
  const history = useHistory()

  const handleClick = () => history.push('/projects')

	return (
		<Box sx={ sx.root }>	
      <Header>
        <Box sx={ sx.header }>	
          <Button onClick={() => handleClick('/')}>
            <img src={ Logo } style={ styles.logo } />
          </Button>
        </Box>
      </Header>
      <Container maxWidth="md">
        <Box mx={2}>	        
          {children}
        </Box> 
      </Container>
		</Box>
	)
}

export default Layout

const styles = {
  logo: {
    maxWidth: 110,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
}

const sx = {  
  root: {
    py: {
      sm: 6,
      xs: 4
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
}