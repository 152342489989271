import React, { useState } from 'react'
import {  
	TextInput,
	DatePicker,
  HTMLInput,
	JSONInput,
	RatingInput,
  CheckboxInput,
	ArrayInput,
  Autosuggest,
  Panel,
  Label,
  CellVideo,
  CellImageThumbnail,
} from 'components'
import { 
  Box, 
  IconButton, 
  Button, 
  Typography, 
  Collapse
} from '@mui/material'
import { KeyboardArrowDown, ViewHeadline } from '@mui/icons-material'
import { Search } from 'react-feather'

const AdminlyInput = ({
  field,
	value,
  placeholder,
	handleChange,
	handleBrowseCloudinary,
	handleBrowseDataTable,
  defaultOpen=true,
}) => {

  const {
    friendly_type,
    name,
    db_name,
    array,
    required,   
    options, 
    foreign_collection_id
  } = field || {}

	const stringFields = ['text', 'link', 'email', 'phone']
	const numberFields = ['number', 'price', 'float']
	const dateFields = ['date', 'datetime']

  const [open, setOpen] = useState(defaultOpen)

	return (
    <Box sx={ sx.root }>          
      <Box>
        <IconButton 
          size={'small'}
          onClick={() => setOpen(!open)}
          sx={ sx.iconButton }
          >
          <KeyboardArrowDown
            sx={{
              ...sx.icon,
              ...(!open && sx.rotateIcon)
            }}
          />
          </IconButton>
      </Box>
      <Box sx={ sx.inputField }> 
        <Box sx={ sx.inputLabel }>       
          <Typography variant="caption" color="text.primary">
            { name }
          </Typography>
          <Label label={ friendly_type } />
        </Box>

      <Collapse in={open}>
        
        {friendly_type == 'boolean' && (
          <CheckboxInput
            name={db_name}
            placeholder={placeholder}
            value={value}
            handleChange={handleChange}
          />
        )}

        {friendly_type === 'enumerable' && (
          <Autosuggest
            name={db_name}
            placeholder={placeholder}
            value={value}
            options={ options.map(opt => ({
              value: opt,
              label: opt
            })) || [] }
            handleChange={handleChange}										
          />
        )}

        {!array ? (
          <React.Fragment>
            {stringFields.includes(friendly_type) && (
              <TextInput
                name={db_name}
                placeholder={placeholder}
                value={value}
                handleChange={handleChange}
                startIcon={<ViewHeadline />}
                required={required}
              />
            )}

            {numberFields.includes(friendly_type) && (
              <TextInput
                type="number"
                name={db_name}
                placeholder={placeholder}
                value={value}
                handleChange={handleChange}
                required={required}
              />
            )}
          </React.Fragment>
        ) : (
          <ArrayInput
            type="number"
            name={db_name}
            freeSolo
            placeholder={placeholder}
            value={value}
            handleChange={handleChange}
            required={required}
          />
        )}

        {dateFields.includes(friendly_type) && (
          <DatePicker
            name={db_name}
            placeholder={placeholder}
            value={value}
            handleChange={handleChange}
            required={required}
          />
        )}

        {friendly_type == 'html' && (
          <HTMLInput 
            name={db_name}
            placeholder={placeholder}
            value={value}
            handleChange={handleChange}        
            required={required}
          />
        )}

        {friendly_type === 'paragraph' && (
          <TextInput
            multiline
            rows={5}
            name={db_name}
            placeholder={placeholder}
            value={value}
            handleChange={handleChange}
            startIcon={<ViewHeadline />}
            required={required}
          />
        )}

        {friendly_type == 'rating' && (
          <RatingInput
            name={db_name}
            placeholder={placeholder}
            value={value}
            handleChange={handleChange}
          />
        )}

        {friendly_type == 'json' && (
          <JSONInput
            name={db_name}
            value={value}
            handleChange={handleChange}
          />
        )}

        {friendly_type == 'reference' && (
          <Box>
            <TextInput
              type="number"
              name={db_name}
              placeholder={placeholder}
              value={value}
              handleChange={handleChange}
              required={required}
            />
            <Button
              sx={ sx.button }
              size="small"
              variant="outlined"
              onClick={() =>
                handleBrowseDataTable({
                  name: db_name,
                  value: value,
                  foreignCollectionId: foreign_collection_id,
                })
              }
              startIcon={<Search sx={ sx.icon} /> }
            >
              Browse
            </Button>
          </Box>
        )}

        {friendly_type == 'image' && (          
          <Box>
            <CellImageThumbnail
              value={ value }
            />
            <TextInput
              name={db_name}
              placeholder={placeholder}
              value={value}
              handleChange={handleChange}
            />
            <Button
              sx={ sx.button }
              size="small"
              variant="outlined"
              onClick={() => handleBrowseCloudinary(name)}
              startIcon={<Search sx={ sx.icon} /> }
            >
              Browse
            </Button>
          </Box>
        )}

        {friendly_type == 'video' && (          
          <Box>
            <CellVideo value={ value } />
            <TextInput
              name={db_name}
              placeholder={placeholder}
              value={value}
              handleChange={handleChange}
            />
            <Button
              sx={ sx.button }
              size="small"
              variant="outlined"
              onClick={() => handleBrowseCloudinary(name)}
              startIcon={<Search sx={ sx.icon} /> }
            >
              Browse
            </Button>
          </Box>
        )}

        </Collapse>
      </Box>
		</Box>
	)
}

export default AdminlyInput

const sx = {
  root: {
    mb: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  inputLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
	inputField: {
    flexGrow: {
      xs: 1,
      sm: 0.5
    },
    width: '100%',
    pr: 0,
    pl: 1,
    borderLeft: theme => `2px solid ${theme.palette.common.highlight}`,
    mb: 1
	},
  icon: {    
    height: 20,
    width: 20,
    borderRadius: 2,
    color: 'text.secondary',
    backgroundColor: 'common.highlight',
    transition: '0.2s',    
    transform: 'rotate(0)'
  },
  rotateIcon: {
    transform: 'rotate(-90deg)' 
  },
  button: {
    color: 'text.secondary',
    mt: 0.5
  }
}
