import React from 'react'
import { Button } from '@mui/material'

const CellBoolean = ({ value, ...props }) => {

	return (
		<Button
			size="small"
			color="secondary"
      variant="outlined"
			sx={ sx.button }
		>
			{value === true && 'true'}
			{value === false && 'false'}
		</Button>
	)
}

export default CellBoolean

const sx = {
	button: {
		textTransform: 'none',
		fontFamily: theme => theme.typography.body3.fontFamily,
		letterSpacing: 0,
	}  
}
