import palette from '../palette'

export default {
	styleOverrides: {
		root: {},
		elevation1: {
			backgroundColor: palette.common.white,
			boxShadow: `rgb(0 0 0 / 5%) 0px 2px 4px !important`,
		},
	},
}
