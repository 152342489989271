import React from "react";
import { 
  Box, 
  Button, 
  Container, 
  Grid, 
  Typography,
} from "@mui/material";
import { ArrowRight } from "@mui/icons-material";
import { Label } from 'components'
import Desktop from "assets/images/adminly-desktop-fade.png";
import { useHistory } from "react-router-dom";

export default function Home() {

  const history = useHistory()
  
  const handleClick = () => {
    history.push('/login')
  }

  return (    
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>            
            <Box sx={ sx.header }>
              <Label label={'No-code platform'} />
              <Typography variant="h1">Instant, no-code Admin.</Typography>
              <Typography variant="h5" color="textSecondary" sx={sx.subtitle}>
                Adminly is an instant, no-code admin that creates a CMS from your relational database.
              </Typography>
            </Box>              
            <Box my={4}  display="flex" width="100%" justifyContent="center">
              <Button
                size="large"
                color="primary"
                variant="contained"
                sx={ sx.primaryButton }
                onClick={handleClick}
                endIcon={<ArrowRight />}
              >
                Get started for free
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <img src={Desktop} style={styles.image} />
          </Grid>
        </Grid>
      </Container>
  );
}

const styles = {
  image: {
    maxWidth: 1200,
    padding: '32px',
    width: "100%",
    height: 'auto',
  },
}

const sx = {
  root: {},
  primaryButton: {
    py: 1.5,
  },
  header: {
    mt: 6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center'
  },
  subtitle: {
    maxWidth: 600
  }
};
