import React, {
	useContext,
	useEffect,
	useState,
} from 'react'
import { ApiContext } from 'context'
import { useAlerts, useLoaders } from 'hooks'
import { AuthContext } from 'context'

const useAuth = (props) => {
	const { api } = useContext(ApiContext)

	const { showLoading, hideLoading } = useLoaders()

	const { showAlertError, showAlertSuccess } = useAlerts()

	const {
		authenticated,
		setAuthenticated,
		currentUser,
		setCurrentUser,
		setToken,
	} = useContext(AuthContext)

	const [user, setUser] = useState({})

	const handleChange = (ev) => {
		const { name } = ev.target
		let value =
			ev.target.type === 'checkbox'
				? ev.target.checked
				: ev.target.value
		setUser({
			...user,
			[name]: value,
		})
	}

	const fetchMe = async () => {
		try {
			let resp = await api.get('/api/v1/users/me')
			if (resp && resp.data) {
				setUser(resp.data)
				setCurrentUser(resp.data)
			}
			return resp.data
		} catch (e) {
			console.log(e)
		}
	}

	const updateMe = async (user) => {
		showLoading()
		let resp = await api.put(`/api/v1/users/${user.id}`, {
			user: user,
		})
		setCurrentUser(resp.data)
		hideLoading()
		return resp.data
	}

	const loginUser = async (user) => {
		try {
			showLoading()
			let resp = await api.post('/api/v1/login', {
				user: user,
			})
			if (resp.data) {
				setCurrentUser(resp.data)
				setAuthenticated(true)
				await setTokens(resp.data)
				window.location.href = '/projects'
				return resp.data
			} else {
				return false
			}
			hideLoading()
		} catch (e) {
			showAlertError('Your email or password is incorrect')
			hideLoading()
		}
	}

	const signupUser = async (user) => {
		try {
			showLoading()
			let resp = await api.post('/api/v1/signup', {
				user: user,
			})
			hideLoading()
			if (resp.data) {
				setCurrentUser(resp.data)
				setAuthenticated(true)
				setTokens(resp.data)
        window.location.href = '/projects'
				return resp.data
			} else {
				return false
			}
		} catch (e) {
			showAlertError(
				'A user with this email may already exist'
			)
			hideLoading()
		}
	}

	const resetPassword = async ({
		password,
		resetToken,
	}) => {
		try {
			showLoading()
			let resp = await api.post('/api/v1/reset_password', {
				user: {
					password: password,
					password_confirmation: password,
					change_password_token: resetToken,
				},
			})
			hideLoading()
			if (resp.data) {
				setCurrentUser(resp.data)
				setAuthenticated(true)
				setTokens(resp.data)
				return resp.data
			} else {
				return false
			}
		} catch (e) {
			showAlertError(
				'There was an error resetting your password'
			)
			hideLoading()
		}
	}

	const forgotPassword = async (email) => {
		try {
			showLoading()
			let resp = await api.post('/api/v1/forgot_password', {
				user: {
					email,
				},
			})
			setCurrentUser(resp.data)
			return resp.data
		} catch (e) {
			showAlertError(
				'A user with that email could not be found'
			)
		} finally {
			hideLoading()
		}
	}

	const logoutUser = () => {
		setCurrentUser(null)
		setAuthenticated(null)
		localStorage.removeItem('token')
		window.location.href = '/'
	}

  const googleLogin = async (user) => {
		try {
			showLoading()
			let resp = await api.post('/api/v1/google/login', {
				user,
			})
			if (resp.data) {
        await setTokens(resp.data)
				window.location.href = '/projects'				
				return resp.data
			} else {
				return false
			}
		} catch (e) {
			console.log(e)
		} finally {
			hideLoading()
		}
	}


	const authenticateFromToken = (token) => {
		if (token) {
			setAuthenticated(true)
			setToken(token)
		} else {
			return false
		}
	}

	const setTokens = (user) => {
		setToken(user.token)
		localStorage.setItem('token', user.token)
	}

	const uploadAvatar = async (file) => {
		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		}
		let formData = new FormData()
		formData.append(`user[avatar]`, file)
		let resp = await api.post('/api/v1/users/upload_avatar',formData,config)
		return resp?.data
	}

	const deleteAvatar = async () => {
		showLoading()
		await api.post('/api/v1/users/delete_avatar')
		hideLoading()
	}

	useEffect(() => {
		if (currentUser && !authenticated) {
			setTokens(currentUser)
			setAuthenticated(true)
		}
	}, [currentUser])

	return {
		user,
    currentUser,
		handleChange,
		fetchMe,
		updateMe,
		loginUser,
		signupUser,
		logoutUser,
		resetPassword,
		forgotPassword,
    googleLogin,
		authenticateFromToken,
		uploadAvatar,
		deleteAvatar,
	}
}

export default useAuth
