import React from 'react'
import { useMenu } from 'hooks'
import { 
  Box,
  Typography,
} from '@mui/material'
import { CellHeaderMenu } from 'components'

import { ExpandMore } from '@mui/icons-material'

const CellHeader = ({ 
    query,
    id, 
    field,
    handleSortClick,
    handleUpdateClick,
    handleEditClick,
    handleHideClick,
    ...params 
  }) => {  

  const { 
    open,
    anchorEl,
    closeMenu,
    toggleMenu
  } = useMenu()

  return(
    <Box sx={ sx.root }>
      <Box 
        onClick={ toggleMenu }
        sx={ sx.button }
      >
        <Typography
          variant="body2"
          sx={ sx.headerName  }
        >
          { field?.db_name }        
        </Typography>
        <ExpandMore sx={ sx.icon  } />
      </Box>
      <CellHeaderMenu 
        { ...params }
        open={open}
        anchorEl={anchorEl}
        closeMenu={closeMenu}
        toggleMenu={toggleMenu}        
        handleSortClick={ handleSortClick }
        handleUpdateClick={handleUpdateClick}
        handleEditClick={ handleEditClick }
        handleHideClick={ handleHideClick }
      />    
    </Box>
  )
}

export default CellHeader 

const sx = {
  root: { },
  button: {
    py: 0,
    px: 1,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    cursor: 'pointer',
    '&:hover $headerName': {
      color: 'primary.main'
    },
    '&:hover $icon': {
      visibility: 'visible'
    }   
  },
  headerName: {      
    textTransform: 'none',
    fontWeight: 400,    
    color: 'text.secondary'
  },
  icon: {
    visibility: 'hidden',
    color: 'primary.main',   
    height: 20,
    width: 20
  },
  settingsIcon: {
    color: 'text.secondary',
    ml: 0.5
  }
}
