import { isEmpty } from 'validations'

export const validateView = (view) => {
	let isValid = true
	let messages = []

	if (isEmpty(view.name)) {
		isValid = false
		messages.push('View name is required')
	}

  if (isEmpty(view.view_type)) {
		isValid = false
		messages.push('View type is required')
	}

  if (isEmpty(view.collection_id)) {
		isValid = false
		messages.push('Collection is required')
	}

	let validation = {
		isValid,
		messages,
	}

	return validation
}
