import React from 'react'
import { Box, Typography } from '@mui/material';
import { Fade } from '@mui/material';

export default function Feature ({
  title,
  description,
  icon
}) {

  return(
    <Box
      p={3}
      sx={sx.box}
    >
      { icon && icon }
      <Typography variant='h5' sx={ sx.title  }>
        { title }
      </Typography>
      <Typography variant='body1' color='textSecondary' my={1}>
        { description }
      </Typography>
    </Box>
  )
}

const sx = {
  title: {},
  box: {
    minHeight: 240,
    margin: 1,
    borderRadius: '6px',
    cursor: 'pointer',
    backgroundColor: 'common.white',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      boxShadow: `0 4px 10px 0 rgb(119 122 175 / 30%)`,
    }
  },
  icon: {
    fontSize: 48,
    color: 'secondary.main',
    my: 2
  },
}
