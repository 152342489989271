import React from 'react'
import { useAlerts, useLoaders, useResource } from 'hooks'

const useAdminProjects = ({ id, ...props }) => {
	const { showLoading, hideLoading } = useLoaders()
	const { showAlertError, showAlertSuccess } = useAlerts()

	const {
		resourceId: projectId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: project,
		resources: projects,
		findOne: findProject,
		findMany: findProjects,
		update: updateProject,
		create: createProject,
		save: saveProject,
		destroy: deleteProject,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reload: reloadProject,
		reloadMany: reloadProjects,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
	} = useResource({
		id: id,
		url: '/api/v1/admin/projects',
		name: 'project',
	})

	return {
		projectId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		project,
		projects,
		findProject,
		findProjects,
		saveProject,
		updateProject,
		createProject,
		deleteProject,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		reloadProject,
		reloadProjects,
		sortKey,
		sortDirection,
		handleSort,
	}
}

export default useAdminProjects
