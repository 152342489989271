import React, { useContext } from 'react'
import { ProjectContext } from 'context'
import { useCloudinary } from 'hooks'
import { ImageUploader } from 'components'
import { Box } from '@mui/material'

const CloudinaryUploader = ({
	onComplete,
	path,
	...props
}) => {


	const { activeProject } = useContext(ProjectContext)
	const { signedUpload } = useCloudinary({
		projectId: activeProject?.id,
	})

	const handleUpload = async (file) => {
		await signedUpload(file, path)
		onComplete()
	}

	return (
		<Box sx={ sx.root }>
			<ImageUploader
				label="Drag-and-drop file to upload."
				handleUpload={handleUpload}
			/>
		</Box>
	)
}

export default CloudinaryUploader

const sx = {
	root: {
		mt: 1,
	},
}
