import React, { useContext } from 'react'
import { AppContext } from 'context'
import { Paper, Skeleton } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const SkeletonTable = ({
	numRows=20,
	...props
}) => {	
  
  let rows = new Array(numRows).fill(0)
  let cells = new Array(1).fill(0)

	return (
      <TableContainer component={ Paper } sx={ sx.root }>
        <Table>
          <TableHead>
            <TableRow sx={ sx.row  }>
              { cells.map((cell,i) => (
                <TableCell key={i}>
                  <Skeleton
                    height={14}
                    sx={ sx.skeleton }
                    variant="rectangular"
                    animation="wave"
                  /> 
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          { rows.map((row,i) => (
            <TableRow key={i} sx={ sx.row  }>
              { cells.map((cell,j) => (
                <TableCell sx={ sx.tableCell } key={j}>
                  <Skeleton
                    height={14}
                    sx={ sx.skeleton }
                    variant="rectangular"
                    animation="wave"
                  /> 
                </TableCell>
              ))}
            </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  )		
}

export default SkeletonTable

const sx = {
  root: {
    width: '100%',
		height: { 
      xs: 'calc(100vh - 340px)', 
      sm:  'calc(100vh - 250px)' 
    }
  },
  tableCell: {
    p: 1
  },
	skeleton: {
    m: 0.5,
    opacity: 0.1,
		borderRadius: theme => theme.shape.borderRadius,
		backgroundColor: 'text.secondary'    
	},
}
