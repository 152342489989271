import React from 'react'
import { List } from '@mui/material'
import { Panel } from 'components'

const VerticalTabs = ({ children, ...rest }) => {	

	return (
		<Panel>
			<List disablePadding sx={ sx.list }>
				{children}
			</List>
		</Panel>
	)
}

export default VerticalTabs

const sx = {
	list: {
		backgroundColor: 'common.white',
	},
}
