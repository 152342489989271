import React, { useContext } from 'react'
import { AsyncAutosuggest } from 'components'
import { ProjectContext } from 'context'

const FieldAutosuggest = ({
	value,
	name='field_id',
  collectionId,
	label,
	position,
	handleChange,
	...props
}) => {

	const { activeProject } = useContext(ProjectContext)

	return (
    (activeProject && collectionId) ? 
		<AsyncAutosuggest
			name={name}
			label={label}
			value={value}
			position={position}
			displayField="db_name"
			remoteUrl={`/api/v1/projects/${activeProject?.id}/collections/${collectionId}/fields`}
			placeholder="Select collection"
			handleChange={handleChange}
		/> : null 
	)
}

export default FieldAutosuggest
