import React from 'react'
import AdminlyContext from 'context/AdminlyContext'
import axios from 'axios'

const AdminlyProvider = ({ token: jwtToken, url: baseURL, children }) => {
	 
  const api = axios.create({
		baseURL: baseURL,
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + jwtToken,
		},
		timeout: 30000,
	})

	api.interceptors.response.use(
		(resp) => Promise.resolve(resp.data),
		(error) => Promise.reject(error.response)
	)

	const value = {
		api,
    jwtToken,
    baseURL
	}

	return (
		<AdminlyContext.Provider value={value}>
			{children}
		</AdminlyContext.Provider>
	)
}

export default AdminlyProvider
