import React from 'react'
import { Typography } from '@mui/material'


const FieldText = ({ value, ...props }) => {
  


	return (
    <Typography variant="body3" sx={ sx.text  }>
      {value}
    </Typography>
  )
}

export default FieldText

const sx = {
	root: {},
  text : {
    whiteSpace: 'pre-wrap',
  }
}
