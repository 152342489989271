import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
	useAlerts,
	useTabs,
	useActiveAddon,
	useActiveProject,
	useCloudinary,
	useCloudinaryFolders,
} from 'hooks'
import {
	ProgressLoader,
	Panel,
	PanelLayout,
	PanelHeader,
	Placeholder,
	LeftPanel,
	CenterPanel,
	DeleteWarningModal,
} from 'components'
import { Button, Box } from '@mui/material'

import CloudinaryList from 'containers/apps/cloudinary/CloudinaryList'
import FolderList from 'containers/apps/cloudinary/Folders/FolderList'
import { UploadCloud } from 'react-feather'
import copy from 'copy-to-clipboard'
import { useHistory } from 'react-router'
import CloudinaryUploadModal from 'containers/apps/cloudinary/CloudinaryUploadModal'
import CloudinaryViewerModal from 'containers/apps/cloudinary/CloudinaryViewerModal'
import FolderModal from 'containers/apps/cloudinary/Folders/FolderModal'
import { findAddon } from 'lib/helpers/utils'

const CloudinaryContainer = ({ ...props }) => {
	useTabs('cloudinary')

	const { addon, installed } = useActiveAddon({
		provider: 'cloudinary',
	})

	const { showAlertSuccess, showAlertError } = useAlerts()
	const { id: projectId } = props.match.params

	const [open, setOpen] = useState(false)
	const [activeItem, setActiveItem] = useState()

	const [resourceType, setResourceType] = useState('image')

	const defaultFolder = { name: '', path: '' }
	const [folder, setFolder] = useState(defaultFolder)
	const [activeFolder, setActiveFolder] = useState()
	const [activeDeleteFolder, setActiveDeleteFolder] =
		useState()
	const [showFolderModal, setShowFolderModal] =
		useState(false)
	const [showDeleteFolderModal, setShowDeleteFolderModal] =
		useState(false)

	const [showCloudinaryViewer, setShowCloudinaryViewer] =
		useState(false)
	const [openMobile, setOpenMobile] = useState(false)

	const history = useHistory()

	const {
		isLoading,
		resources,
		findResources,
		deleteResource,
		reloadResources,
		loadMoreResources,
		nextCursor,
	} = useCloudinary({ projectId: projectId })

	const {
		isLoading: isFoldersLoading,
		folders,
		createFolder,
		deleteFolder,
		findFolders,
		reloadFolders,
		loadMoreFolders,
		nextCursor: nextFolderCursor,
	} = useCloudinaryFolders({ projectId: projectId })

	const handleFolderClick = (folder) => {
		setActiveFolder(folder)
	}

	const handleFolderBackClick = () => {
		let paths = activeFolder.path.split('/')
		let newPath = paths.slice(0, paths.length - 1)
		handleFolderClick({
			name: newPath[newPath.length - 1],
			path: newPath.join('/'),
		})
	}

	const handleDeleteFolderClick = (folder) => {
		setActiveDeleteFolder(folder)
		setShowDeleteFolderModal(true)
	}

	const handleDeleteFolder = async () => {
		await deleteFolder(activeDeleteFolder?.path)
		setShowDeleteFolderModal(false)
		reloadFolders()
	}

	const handleAddFolderClick = () => {
		setFolder(defaultFolder)
		setShowFolderModal(true)
	}

	const handleAddFolder = async () => {
		setShowFolderModal(true)
		await createFolder(folder?.path)
		reloadFolders()
		setShowFolderModal(false)
	}

	const handleFolderChange = (ev) => {
		const { name, value } = ev.target
		setFolder({
			name: value,
			path: activeFolder
				? `${activeFolder.path}/${value}`
				: value,
		})
	}

	const handleResourceTypeChange = (type) => {
		setResourceType(type)
	}

	const handleUploadComplete = async () => {
		setOpen(false)
		await reloadResources()
	}

	const { activeProject } = useActiveProject(projectId)

	const handleCloudinaryItemClick = (item) => {
		setActiveItem(item)
		setShowCloudinaryViewer(true)
	}

	const handleCloseCloudinaryViewer = () => {
		setShowCloudinaryViewer(false)
		setActiveItem()
	}

	const handleSearch = (keywords) => {
		findResources({
			keywords: keywords,
			resourceType: resourceType,
		})
	}

	const handleCopyURLClick = (item) => {
		copy(item?.url)
		showAlertSuccess('Asset URL copied to clipboard.')
		setActiveItem(item)
	}

	const handleClose = () => {
		setOpen(false)
		setActiveItem()
	}

	const handleDeleteClick = async (item) => {
		await deleteResource(
			item?.public_id,
			item?.resource_type
		)
		reloadResources()
		showAlertSuccess('Asset successfully deleted.')
	}

	const handleAddonsClick = () => {
		history.push(`/projects/${activeProject?.id}/apps/all`)
	}

	useEffect(() => {
		if (projectId) {
			findResources()
			findFolders()
		}
	}, [projectId])

	useEffect(() => {
		if (resourceType) {
			findResources({
				path: activeFolder?.path,
				resource_type: resourceType,
			})
		}
	}, [resourceType])

	useEffect(() => {
		if (activeFolder) {
			findResources({
				path: activeFolder.path,
				resource_type: resourceType,
			})
			findFolders({
				path: activeFolder.path,
			})
			setOpenMobile(true)
		}
	}, [activeFolder])

	return (
		<Box sx={ sx.root }>
			<PanelLayout>
				<CenterPanel open={openMobile}>
					<Panel p={0}>
						<PanelHeader
              enableBackButton
							title="Cloudinary"							
							image={addon?.app?.icon_url}
							actions={
								installed && (
									<Button
										startIcon={<UploadCloud />}
										variant="outlined"
										color="secondary"
										onClick={() => setOpen(true)}
									>
										Upload
									</Button>
								)
							}
						/>
						{installed ? (
							<Box>
								{resources ? (
									<CloudinaryList
										isLoading={isLoading}
										project={activeProject}
										resources={resources}
										handleSearch={handleSearch}
										findResources={findResources}
										cloudName={
											activeProject.cloudinary_cloud_name
										}
										handleDeleteClick={handleDeleteClick}
										handleClick={handleCloudinaryItemClick}
										reloadResources={reloadResources}
										loadMoreResources={loadMoreResources}
										nextCursor={nextCursor}
										resourceType={resourceType}
										handleResourceTypeChange={
											handleResourceTypeChange
										}
										folders={
											folders && (
												<FolderList
													folders={folders}
													isLoading={isFoldersLoading}
													activeFolder={activeFolder}
													handleClick={handleFolderClick}
													handleDeleteClick={
														handleDeleteFolderClick
													}
													handleAddClick={
														handleAddFolderClick
													}
													handleBackClick={
														handleFolderBackClick
													}
													nextCursor={nextFolderCursor}
													loadMoreFolders={loadMoreFolders}
												/>
											)
										}
									/>
								) : (
									<Box sx={ sx.loader }>
										<ProgressLoader />
									</Box>
								)}
							</Box>
						) : (
							<Box sx={ sx.placeholder }>
								<Placeholder
									icon={
										<UploadCloud sx={ sx.icon } />
									}
									title="Cloudinary addon not installed."
									subtitle="To use Cloudinary assets, install the Cloudinary addon."
									actions={
										<Button
											onClick={handleAddonsClick}
											variant="outlined"
											color="primary"
										>
											Add Add-ons
										</Button>
									}
								/>
							</Box>
						)}
					</Panel>
				</CenterPanel>
			</PanelLayout>
			<CloudinaryUploadModal
				open={open}
				path={activeFolder?.path}
				handleClose={handleClose}
				onComplete={handleUploadComplete}
			/>
			<CloudinaryViewerModal
				open={showCloudinaryViewer}
				item={activeItem}
				handleClose={handleCloseCloudinaryViewer}
			/>
			<FolderModal
				open={showFolderModal}
				handleClose={() => setShowFolderModal(false)}
				handleSubmit={handleAddFolder}
				folder={folder}
				handleChange={handleFolderChange}
			/>
			<DeleteWarningModal
				open={showDeleteFolderModal}
				onClose={() => setShowDeleteFolderModal(false)}
				onConfirm={handleDeleteFolder}
			/>
		</Box>
	)
}

CloudinaryContainer.propTypes = {
	className: PropTypes.string,
}

export default CloudinaryContainer

const sx = {
	root: {},
	iconButton: {
		color: 'primary.main'
	},
	loader: {
		height: 500,
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	icon: {
		color: 'secondary.main'
	},
}
