export default {
	styleOverrides: {
		root: {
			fontSize: '14px',
		},
		notchedOutline: {
			borderColor: 'rgba(0,0,0,0.15)',
		},
	},
}
