import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
	ListItem,
	ListItemText,
	ListItemIcon,
} from '@mui/material'
import { Icon } from 'components'

const FieldListItem = ({
	field,
	handleClick,
  selected=false,
	...props
}) => {

	return (
		<ListItem
			sx={{ 
        ...sx.listItem, 
        ...(selected && sx.selected) 
      }}
			button
			onClick={() => handleClick(field)}
		>
			<ListItemIcon>
				<Icon friendlyType={field?.value} />
			</ListItemIcon>
			<ListItemText
				primary={field?.label}
				secondary={field?.description}
			/>
		</ListItem>
	)
}

FieldListItem.propTypes = {
	field: PropTypes.object,
	handleClick: PropTypes.func,
}

export default FieldListItem

const sx = {
  selected: {
    border: theme => `2px solid ${theme.palette.primary.main} !important`,
  },
	listItem: {
    borderRadius: theme => theme.shape.borderRadius,
		width: '100%',
		mb: 1,
		border: theme => `1px solid ${theme.palette.common.border}`,
		backgroundColor: 'common.white',
		'&:hover': {
			backgroundColor: 'common.highlight'
		},
	},
}
