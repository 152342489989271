import React from 'react'
import { useMenu } from 'hooks'
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
} from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'

const AdminlyToolbarMenu = ({
  handleUpdateView,
  handleSaveView,
  handleExportClick,
  ...props 
}) => {

  const onUpdateView = () => {
    closeMenu() 
    handleUpdateView()
  }

  const onSaveView = () => {
    closeMenu() 
    handleSaveView()
  }
  const onExportClick = () => {
    closeMenu() 
    handleExportClick()
  }

  const {
    open,
    anchorEl,
    toggleMenu,
    closeMenu
  } = useMenu()

  return(
    <>
      <IconButton  
        variant="outlined"
        onClick={ toggleMenu }
        sx={ sx.button  }              
      >
        <MoreHoriz sx={ sx.icon } />
      </IconButton>    
      <Menu 
        open={open}
        anchorEl={ anchorEl }
        onClose={ closeMenu }
      >
        { handleUpdateView && (
          <MenuItem onClick={ onUpdateView }>      
            <ListItemText 
              sx={ sx.listItem  }      
              primary="Update view" />
          </MenuItem>        
        )}
        <MenuItem onClick={ onSaveView }>      
          <ListItemText 
            sx={ sx.listItem  }      
            primary="Save as view..." 
          />
        </MenuItem>
        <MenuItem onClick={ onExportClick }>      
          <ListItemText 
            sx={ sx.listItem  }      
            primary="Export CSV..." 
          />
        </MenuItem>
      </Menu>
    </>      
  )
}

export default AdminlyToolbarMenu 

const sx = {
  button: {
    color: 'text.secondary',
    ml: 1
  },
  listItem: {
    color: 'text.secondary'
  },
  icon: {
    color: 'text.secondary'
  }
}