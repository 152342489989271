import components from 'theme/components'
import palette from 'theme/palette'
import typography from 'theme/typography'

export const muiTheme = {
	palette,
	components,
	typography,
  shape: {
    borderRadius: 2
  }
}
