export const SUBSCRIPTION_PLANS = [
	{
		id: 'starter',
    stripe_plan: 'starter',
		title: 'Starter',
    subtitle: 'Small teams.',
		price: {
      cloud: '$50/month',
      self_hosting: 'FREE'
    },
		features: [
      { text: 'Instant, no-code admin for any database.', included: true },
			{ text: 'Modern, collaboration CMS.', included: true },
			{ text: 'Upto 5 users.', included: true },
      { text: 'Sharing and collaboration.', included: true },
			{ text: 'REST API access.', included: true },
			{ text: 'Access Control Levels.', included: true },
      { text: 'Security and authorization.', included: true },			
			{ text: 'Customizable dashboard.', included: true },
		],
	},
	{
		id: 'standard',
    stripe_plan: 'standard',
		title: 'Standard',
		subtitle: 'Grow your team.',
    popular: true,
    price: {
      cloud: '$199/month',
      self_hosting: '$199/month',
    },
		features: [
			{ text: 'Instant, no-code admin for any database.', included: true },
			{ text: 'Modern, collaboration CMS.', included: true },
			{ text: 'Upto 20 users.', included: true },
      { text: 'Sharing and collaboration.', included: true },
			{ text: 'REST API access.', included: true },
			{ text: 'Access Control Levels.', included: true },
      { text: 'Security and authorization.', included: true },			
			{ text: 'Customizable dashboard.', included: true }
		],
	},
	{
		id: 'premium',
    stripe_plan: 'premium',
		title: 'Premium',
		subtitle: 'Scale your startup.',
    price: {
      cloud: '$299/month',
      self_hosting: '$299/month',
    },
		features: [
			{ text: 'Instant, no-code admin for any database.', included: true },
			{ text: 'Modern, collaboration CMS.', included: true },
			{ text: 'Upto 50 users.', included: true },
      { text: 'Sharing and collaboration.', included: true },
			{ text: 'REST API access.', included: true },
			{ text: 'Access Control Levels.', included: true },
      { text: 'Security and authorization.', included: true },			
			{ text: 'Customizable dashboard.', included: true }
		],
	},
]
