import React, { useState, useEffect } from 'react'
import {
  Modal,
  ProgressLoader  
} from 'components'
import {
  Box, 
  List, 
  Typography 
} from '@mui/material'
import {   
  useViews,
  useProjectUsers  
} from 'hooks'
import { useActiveProject } from 'hooks'

import ViewUserItem from 'containers/main/views/ViewUserItem'
import { isAdmin } from 'lib/helpers/projects'

const ViewUserList = ({
  view,
  ...props 
}) => {


  const { activeProject } = useActiveProject()

  const [users, setUsers] = useState()
  const [projectMembers, setProjectMembers] = useState()
  
  const {
    isLoading,
    listUsers, 
    addUser,
    removeUser 
  } = useViews({
    projectId: activeProject?.id, 
    id: view?.id
  })

	const {
		isLoading: isProjectLoading,
    users: projectUsers,
		listUsers: listProjectUsers,
	} = useProjectUsers({
		projectId: activeProject?.id,
	})

  const handleRemoveClick = async (user) => {
    await removeUser(view?.id, user?.id)    
    handleFindUsers()
  }

  const handleAddClick = async (user) => {    
    await addUser(view?.id, user?.id)
    handleFindUsers()    
  }

  const handleFindUsers = async () => {    
    let resp = await listUsers(view?.id)
    setUsers(resp)
  }

  useEffect(() => {
    if(view?.id){  
      listProjectUsers()    
      handleFindUsers()      
    }
  }, [view?.id])

  useEffect(() => {
    if(users && projectUsers){
      let filteredUsers = projectUsers
        .filter(user => !users.find(u => u.id === user.id))
      setProjectMembers(filteredUsers)
    }
  }, [users, projectUsers])

  return(
    <Box>
      { !isLoading && !isProjectLoading ? (
        <Box my={2}> 
          <Typography color="textSecondary" variant="caption" sx={ sx.subtitle  }>
            Shared
          </Typography>         
          <List>
            { users && 
              users.map(user => (
              <ViewUserItem 
                key={ user.id }
                user={ user }
                handleRemoveClick={ handleRemoveClick }
              />
            ))}
          </List>
          <Box>            
            { projectMembers?.length > 0 && (
              <Box>
                <Typography color="textSecondary" variant="caption">
                  Invite members 
                </Typography>
                <List>
                  { projectMembers && 
                    projectMembers.map(user => (
                    <ViewUserItem 
                      key={ user.id }
                      user={ user }
                      handleAddClick={ handleAddClick }
                    />
                  ))}
                </List>
              </Box>
            )}
          </Box>
          </Box>
        ):(
          <ProgressLoader />
        )}

    </Box>
  )
}

export default ViewUserList

const sx = {
  root: {},
  subtitle: {
    mt: 2
  },
  icon: {
    color: 'text.secondary'
  }
}


