import React, { useContext, useEffect, useState } from 'react'
import { AppContext, ProjectContext } from 'context'
import PropTypes from 'prop-types'
import { Panel, DeleteWarningModal } from 'components'
import {
  useAlerts,
  useAdminlyTabs,
  useCollections,
  useFields,
  useLoaders,
  useViews,
  useSelected 
 } from 'hooks'
import { useAdminly } from 'hooks'
import { validateAdminlyResource } from 'validations/adminly'
import {
	Cell,
  CellHeader,
  Icon,
	Pagination,
  ImageModal,
  VideoModal,
  TextModal,
  Table,
  TableCell,
  TableRow  
} from 'components'
import AdminlyEditModal from 'containers/main/adminly/AdminlyEditModal'
import AdminlyFilterModal from 'containers/main/adminly/AdminlyFilterModal'
import AdminlyTableHeader from 'containers/main/adminly/AdminlyTableHeader'
import FieldModal from 'containers/main/fields/FieldModal'
import ViewModal from 'containers/main/views/ViewModal'
import { validateField } from 'validations/fields'
import { validateView } from 'validations/views'
import { useHistory, useLocation } from 'react-router-dom'
import { 
  validFilter,
  buildQuery,
  buildQueryFromFields,   
  buildQueryParams,  
  parseQueryFromURL  
} from 'lib/helpers/adminly'
import copy from 'copy-to-clipboard'
import { 
  ExpandMore, 
  ExpandLess, 
  EditOutlined 
} from '@mui/icons-material'
import SkeletonTable from 'components/SkeletonTable'
import { 
  Box, 
  Button, 
  Checkbox, 
  IconButton,   
} from '@mui/material'
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';


const AdminlyTable = ({
	collection,
	onRowClick,
	defaultQuery,
  handleUpdateView,
  enableSaveView,
  handleClose,
  disableEditing=false,
  disableNavigation=false,
	...props
}) => {

  const { showLoading, hideLoading } = useLoaders()
  const { 
    globalReload, 
    setGlobalReload, 
    showSideMenu,
    isEditing 
  } = useContext(AppContext)

  const history = useHistory()
  const location = useLocation()

	const { fields } = collection || {}

  const { handleAddTab } = useAdminlyTabs()

  const { 
    selected,
    selectedIds,
    setSelected,
    setSelectedIds,
    handleSelect,
    handleClear,
  } = useSelected()

  const handleSelectAll = () => {
    if(selected?.length === resources?.length){
      setSelected([])
      setSelectedIds([])
    }else{
      setSelected(resources)
      setSelectedIds(resources.map(r => r.id))
    }
  }

	const { 
    setActiveViews,
    activeProject 
  } = useContext(ProjectContext)

  const {
    isLoading: isViewLoading,
    view,    
    createView,
    findViews, 
    handleChange: handleViewChange
  } = useViews({
    projectId: activeProject?.id
  })


	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [open, setOpen] = useState(false)

  const [activeSrc, setActiveSrc] = useState()
  
  const [activeText, setActiveText] = useState()
  const [showTextModal, setShowTextModal ] = useState(false)
  const [showImageModal, setShowImageModal ] = useState(false)
  const [showVideoModal, setShowVideoModal] = useState(false)

  const [showViewModal, setShowViewModal] = useState(false)

	const [showFilters, setShowFilters] = useState(false)
  const [showFieldModal, setShowFieldModal] = useState(false)

  const [sortedFields, setSortedFields] = useState(
    fields?.sort((a, b) => a.position - b.position)
  )

	const {
    findCollection
  } = useCollections({
		projectId: activeProject.id,
	})

  const {
    isLoading: isFieldLoading,
    field,
    setField,
    handleChange: handleFieldChange,
    updateField,
  } = useFields({
    collectionId: collection?.id,
    projectId: activeProject?.id
  })

	const {
		query,
    setQuery,
		isLoading,
		resource,
		resources,
		findMany,    
		update: updateResource,
		destroy: deleteResource,
		create: createResource,
		updateMany,
		deleteMany,
		reloadMany,
		handleChange,
		setResource,
		setResources,
		sortAll,
    exportCsv,
		totalCount,
    paginate,
    page,
    numPages,
		startIndex,
		endIndex
	} = useAdminly({
		collection: collection?.db_name,
		defaultQuery: defaultQuery,
	})

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleAddClick = () => {
		history.push(`/projects/${activeProject.id}/collections/${collection.id}/resources/new`)
	}

	const handleDeleteClick = () => {
		setShowDeleteModal(true)
	}

	const handleEditClick = () => {
    history.push(`/projects/${activeProject?.id}/collections/${collection.db_name}/resources/edit?ids=${selectedIds.join(',')}`)
	}

  const handleEditRowClick = (row) => {
    history.push(`/projects/${activeProject?.id}/collections/${collection.db_name}/resources/${row.id}/edit`)
  }

  const handleExportClick = async () => {
    await exportCsv({
      ...query,
      page: 1,
      per_page: 10000
    })
  }

	const handleSubmit = async () => {
		let validate = validateAdminlyResource(resource, fields)		
		if (validate.isValid) {
			if (selected?.length <= 1) {
				if (resource?.id) {
					await updateResource(resource)
				} else {
					await createResource(resource)
				}
			} else if (selected?.length > 1) {
				const selectedIds = selected.map((row) => row.id)
				await updateMany(selectedIds, resource)
			}			
      await reloadMany()
			setOpen(false)
		} else {
			validate.messages.map((m) => showAlertError(m))
		}
	}

	const handleDeleteRow = async () => {
		if (selected?.length === 1) {
			await deleteResource(selected[0].id)			      
		} else if (selected?.length > 1) {
			const selectedIds = selected.map((row) => row.id)
			await deleteMany(selectedIds)      
		}
    setSelected(null)
		setShowDeleteModal(false)
		reloadMany()
	}

  const handlePaginate = async (ev, page) => {
    await paginate(page)
  }

  const handleEditFieldClick = async (fieldId) => {
    let activeField = fields.find(f => f.id === fieldId)
    setField(activeField)
    setShowFieldModal(true)
  }

  const handleHideField = async (fieldId) => {
    showLoading()
		let selectedField = fields.find(f => f.id === fieldId)
    let newField = await updateField({
      ...selectedField,
      visible: false
    })
    if (newField?.id) {
      await handleRefreshFields()
    }
    setField({})
    hideLoading()
	}

  const handleUpdateFieldType = async (fieldId, friendlyType) => {    
		let selectedField = fields.find(f => f.id === fieldId)
			let newField = await updateField({
        ...selectedField,
        friendly_type: friendlyType
      })
			if (newField?.id) {
        await handleRefreshFields()
			}
			setField()
	}

	const handleSaveField = async () => {
		let newField
		let validate = validateField(field)
		if (validate.isValid) {            
			newField = await updateField(field)
      const { field_type, friendly_type } = newField
			if (newField?.id) {
        await handleRefreshFields()        
        if(field_type === 'reference' || friendly_type === "reference") {
          await findManyWithAssociations(query)        
        }
        setGlobalReload(!globalReload)
        setShowFieldModal(false)
        setField({})              
			}			
		} else {
			validate.messages.map((m) => showAlertError(m))
		}
	}

  const handleRefreshFields = async () => {
    let newCollection = await findCollection(collection.id)
    let { fields: newFields } = newCollection
    setShowFieldModal(false)
    return newFields
  }

  const handleSortClick = (sortBy, sortDirection) => {
		sortAll({ sortBy, sortDirection })
  }

  const handleNavigateClick = () => {
    handleClose()
    history.push(`/projects/${activeProject?.id}/collections/${collection.id}`)
  }

  const handleCellClick = ({ row, field, value }) => {
    let rowId = row?.id
    switch(field.friendly_type) {
      case 'reference':
        handleCellReferenceClick({ 
          label: field?.foreign_collection?.db_name, 
          value: value, 
          collectionId: field?.foreign_collection_id
        })
        break
      case 'has_many':
        handleCellHasManyClick({
          label: field?.foreign_collection?.db_name, 
          value: rowId, 
          field: field?.foreign_field?.db_name, 
          collectionId: field?.foreign_collection_id
        })
        break  
      case 'habtm':
        let rowIds = [-1] //ensure that the results return empty set if row value is empty
        if(row[field.foreign_collection.db_name]?.length > 0){
          rowIds = row[field.foreign_collection.db_name].map(r => r.id)
        }        
        handleCellHABTMClick({
          label: field.foreign_collection.db_name, 
          value: rowIds, 
          collectionId: field?.foreign_collection_id
        })
        break    
      case 'link':
        handleCellLinkClick(value)
        break;
      case 'image':
        handleCellImageClick(value)
        break;
      case 'video':
        handleCellVideoClick(value)
        break
      case 'paragraph':
        handleCellTextClick(value)
        break
  
    }
  }

  const handleCellImageClick = (value) => {
    if(value) {
      setShowImageModal(true)
      setActiveSrc(value)
    }
  }

  const handleCellVideoClick = (value) => {
    if(value) {
      setShowVideoModal(true)
      setActiveSrc(value)
    }
  }

  const handleCellTextClick = (value) => {
    if(value) {
      setShowTextModal(true)
      setActiveText(value)
    }
  }


	const handleCellReferenceClick = async (params) => {
    const { label, value, collectionId } = params || {}
		const foreignQuery = {
			filters: [
				{
					field: 'id',
					operator: 'eq',
					value: value,
				},
			],
		}    
    handleTabClick({
      label, 
      collectionId, 
      query: foreignQuery
    })		
	}

  const handleCellHasManyClick = async (params) => {
    const { label, value, field, collectionId } = params || {}
		const foreignQuery = {
			filters: [
				{
					field: field,
					operator: 'eq',
					value: value,
				},
			],
		}
    handleTabClick({
      label, 
      collectionId, 
      query: foreignQuery, 
    })		
	}

  const handleCellHABTMClick = async (params) => {
    const { label, value, collectionId } = params || {}
		const foreignQuery = {
			filters: [
				{
					field: `id`,
					operator: 'in',
					value: value
				},
			]
		}
    handleTabClick({
      label, 
      collectionId, 
      query: foreignQuery, 
    })		
	}

  const handleTabClick = async (params) => {    
    const { label, collectionId, query: foreignQuery } = params || {}
    handleAddTab({ 
      value: collectionId, 
      label: label || 'Tab', 
      query: foreignQuery 
    })
  }

	const handleQueryChange = (ev) => {
		const { value, name } = ev.target
		setQuery({
      ...query,
      [name]: value
    })
	}

	const handleKeyPress = (ev) => {
		if (ev.key === 'Enter') {
			handleKeywordSearch(query?.keywords)
		}
	}

	const handleKeywordSearch = (searchTerm) => {
		let query = {
			...query,
			keywords: searchTerm,
		}
		handleSearch(query)
	}

	const handleSearch = (searchQuery) => {       
		setShowFilters(false)    		
    if(!disableNavigation){
      let query = buildQuery({
        ...searchQuery,
        page: 1,
        per_page: 20
      }, fields)  
      let queryString = buildQueryParams(query)    
      history.push(`/projects/${activeProject?.id}/collections/${collection.id}?${queryString}`)
    }else{
      findManyWithAssociations(searchQuery)
    }    
	}

  const handleClearFilters = () => {
    setShowFilters(false)
    if(!disableNavigation){
      let queryParams = parseQueryFromURL(location.search)
      let query = buildQuery({
        ...queryParams,
        filters: [],
        keywords: ''
      }, fields)
      let queryString = buildQueryParams(query)
      history.push(`/projects/${activeProject?.id}/collections/${collection.id}?${queryString}`)
    }else{
      let clearQuery = {
        ...query,
        filters: [],
        keywords: ''
      }
      findManyWithAssociations(clearQuery)
    }
  }

  const handleCreateView = async () => {    
    let viewQuery = parseQueryFromURL(location.search)
    let newView = {
      ...view,
      view_type: 'query',
      query: viewQuery,
      collection_id: collection?.id,
    }
    let validate = validateView(newView)
    if(validate.isValid){
      let savedView = await createView(newView)  
      let newViews = await findViews()      
      setActiveViews(newViews)   
      setShowViewModal(false)   
      history.push(`/projects/${activeProject?.id}/views/${savedView.id}/collections/${collection.id}`)
    }else{
      validate.messages.map(m => showAlertError(m))
    }
  }

  const handleToggleSortByField = (field) => {
    let sortBy = query?.sort_by || "id"
    let sortDirection = query?.sort_direction || 'desc'
    if(field?.db_name === sortBy){
      sortDirection = sortDirection === 'desc' ? 'asc' : 'desc'
    }
    sortBy = field?.db_name 

    let newQuery = buildQuery({
      ...query,
      sort_by: sortBy,
      sort_direction: sortDirection
    }, fields)
    let queryString = buildQueryParams(newQuery)
    history.push(`/projects/${activeProject?.id}/collections/${collection.id}?${queryString}`)
  }

  const handleToggleSort = () => {
    let sortDirection = query.sort_direction === 'asc' ? 'desc' : 'asc'
    let newQuery = buildQuery({
      ...query,
      sort_direction: sortDirection
    }, fields)
    let queryString = buildQueryParams(newQuery)
    history.push(`/projects/${activeProject?.id}/collections/${collection.id}?${queryString}`)
  }

	const handleCellLinkClick = async (e, value) => {
		e.preventDefault()
		copy(value)
		showAlertSuccess('Link copied to clipboard')
	}

  const findManyWithAssociations = async (searchQuery) => {
    let associationQuery = buildQueryFromFields(fields)        
    await findMany({
      ...searchQuery,
      ...associationQuery
    })    
  }

	useEffect(() => {    
		if (fields && defaultQuery && !isEditing) {
      setQuery({})
      setResources(null)
      setResource(null)
      findManyWithAssociations(defaultQuery)
    }
	}, [fields, defaultQuery, isEditing])  

  useEffect(() => {
    if(fields){
      setSortedFields(fields?.sort((a,b) => a.position - b.position))
    }
  }, [fields])
  
	return (
		<Box sx={ sx.root }>            
			<Panel p={0} my={0}>        
				<AdminlyTableHeader
					isLoading={resources && isLoading}
					resource={resource}
          selected={selected}
					query={query}
          fields={fields}          
					handleChange={handleQueryChange}
					handleSearch={handleKeywordSearch}
					handleFilterClick={() => setShowFilters(true)}
          handleToggleSort={ handleToggleSort }
					handleAddClick={handleAddClick}
					handleEditClick={handleEditClick}
          handleExportClick={handleExportClick}
					handleDeleteClick={handleDeleteClick}
          handleNavigateClick={handleNavigateClick}
					badgeCount={query?.filters?.filter(validFilter).length}
          handleClearFilters={ handleClearFilters }
          handleSaveView={() => setShowViewModal(true)}
          handleUpdateView={handleUpdateView}
					disableEditing={disableEditing}
				/>
				{query && resources && sortedFields ? (
          <Table
            showSideMenu={ showSideMenu}
            disableEditing={ disableEditing }
          >                                
              <TableHead>
                <TableRow> 
                  <TableCell 
                    header 
                    stickyHeader 
                  >
                    <Checkbox                      
                      checked={ selected?.length === resources?.length ? true : false}
                      onChange={handleSelectAll}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>                    
                  </TableCell>
                  { sortedFields.map((field, i) => {
                    let active = query?.sort_by === field.db_name                    
                    return (
                    <TableCell key={i}>
                        <Button 
                          disableRipple
                          fullWidth 
                          sx={ sx.sortButton }
                          onClick={() => handleToggleSortByField(field)}
                          endIcon={ 
                            active && (
                              <>
                                {query?.sort_direction === 'asc' && (
                                  <ExpandLess sx={ sx.sortIcon } /> 
                                )}
                                {query?.sort_direction === 'desc' && (
                                  <ExpandMore sx={ sx.sortIcon } /> 
                                )}
                              </> 
                            )
                          }
                        >
                          <CellHeader 
                            id={ field?.id } 
                            field={ field }
                            handleEditClick={ handleEditFieldClick }
                            handleSortClick={ handleSortClick }
                            handleUpdateClick={ handleUpdateFieldType }
                            handleHideClick={ handleHideField }                
                          />
                        </Button>                    
                    </TableCell> 
                  )})}
                </TableRow>
              </TableHead>
              <TableBody>
              { resources.map((row, index) => {
                const selected = selectedIds?.includes(row?.id) ? true : false
                return (
                <TableRow 
                  hover
                  selected={ selected }
                  onClick={ onRowClick ? () => onRowClick(row) : null }
                  key={index}>
                   <TableCell 
                    align={'center'}
                    sticky
                  >
                    <Checkbox                      
                      checked={ selected }
                      onChange={() => handleSelect(row)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell 
                    align='center'
                  >
                    <IconButton 
                      onClick={() => handleEditRowClick(row) }
                      sx={ sx.editIconButton } size="small">
                      <EditOutlined sx={ sx.editIcon } color="textPrimary" />
                    </IconButton>
                  </TableCell>
                  { fields.map((field, index) => {                  
                    const value = row[field?.db_name]                                         
                    return (
                    <TableCell 
                      sx={sx.tableCell}
                      key={index}
                    >
                      <Cell 
                        row={ row }
                        field={ field }
                        value={ value }
                        handleClick={() => handleCellClick({ row, field, value }) }
                      />
                    </TableCell>
                  )})}
                </TableRow>
              )})}
            </TableBody>            
          </Table>
				) : (
          <SkeletonTable />
				)}
				<Pagination
          page={page}
          numPages={numPages}
					totalCount={totalCount}
					isLoading={resources && isLoading}
					startIndex={startIndex}
					endIndex={endIndex}
          handlePaginate={handlePaginate}
				/>
			</Panel>
			<AdminlyFilterModal
      	open={showFilters}
				isLoading={isLoading}
				handleClose={() => setShowFilters(false)}
				fields={fields}
				handleSearch={handleSearch}
				query={query}
				handleChange={ handleQueryChange }
        handleClearFilters={ handleClearFilters }
				onKeyPress={handleKeyPress}
			/>
			<AdminlyEditModal
				isLoading={isLoading}
				open={open}
				fields={fields}
				resource={resource}
        selected={ selected }
				collection={collection}
				handleClose={() => setOpen(false)}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
			/>
      <TextModal
        open={ showTextModal }
        text={ activeText }
        handleClose={() => setShowTextModal(false)}
      />
      <ImageModal
        open={ showImageModal }
        src={ activeSrc }
        handleClose={() => setShowImageModal(false)}
      />
      <VideoModal
        open={ showVideoModal }
        src={ activeSrc }
        handleClose={() => setShowVideoModal(false)}
      />
      <FieldModal
        isLoading={ isFieldLoading }
        field={ field }
        open={ showFieldModal }
        handleClose={() => setShowFieldModal(false)}
        handleSubmit={ handleSaveField }
        handleChange={ handleFieldChange }        
      />
      <ViewModal 
        isLoading={ isViewLoading }
        open={ showViewModal }
        view={ view }
        handleChange={ handleViewChange }
        handleClose={() => setShowViewModal(false)}
        handleSubmit={ handleCreateView }
      />
      <DeleteWarningModal
				open={showDeleteModal}
				onClose={() => setShowDeleteModal(false)}
				onConfirm={handleDeleteRow}
			/>
		</Box>
	)
}

AdminlyTable.propTypes = {
	rows: PropTypes.array,
	headers: PropTypes.array,
}

export default AdminlyTable

const sx = {
	root: {
    width: { 
      xs: 'calc(100vw - 30px)',
      sm: '100%',
    },
	},
	loader: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},  
  sortIcon: {
    height: 20,
    width: 20,
    color: 'text.secondary'
  },
  icon: {
    color: 'text.secondary'
  },
  editIconButton: {
    mx: 2
  },
  editIcon: {
    height: '20px',
    width: '20px',
  },
  sortButtonGroup: {
    backgroundColor: 'white',
    border: "none",
    borderColor: 'transparent'
  },
  sortIconButton: {
    backgroundColor: 'white',
    borderRight: '0px solid white !important',        
  },
  sortButton: {    
    borderRight: '0px solid white !important',        
  }
}
