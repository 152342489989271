import React from 'react'
import { Tab, Tabs } from '@mui/material'
import { withStyles } from '@mui/styles'

const CloudinaryTabs = ({
	activeTab,
	handleChange,
	...props
}) => {
	return (
		<StyledTabs
			value={activeTab}
			onChange={handleChange}
			color="primary"
		>
			<StyledTab label="Images" value={'image'} />
			<StyledTab label="Videos" value={'video'} />
		</StyledTabs>
	)
}

export default CloudinaryTabs

const StyledTab = withStyles((theme) => ({
	root: {
		textTransform: 'uppercase',
		fontSize: 12,
		minWidth: 72,
		mr: 4,
		color: 'secondary.main',
		'&:hover': {
			opacity: 1,
		},
		'&$selected': {},
		'&:focus': {},
	},
	selected: {},
}))((props) => <Tab {...props} />)

const StyledTabs = withStyles((theme) => ({
	indicator: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
		'& > span': {
			maxWidth: 100,
			width: '100%',
			backgroundColor: 'primary.main'
		},
	},
}))((props) => (
	<Tabs
		{...props}
		TabIndicatorProps={{ children: <span /> }}
	/>
))

const sx = {
	tabs: {},
}
