import { isEmpty } from 'validations'

let addonValidations = {	
	'cloudinary': {
		cloud_name: true,
		api_key: true,
		api_secret: true
	}
}

export const validateAddon = (addon, appHandle) => {
	let isValid = true
	let messages = []

	let validations = addonValidations[appHandle]

	if (isEmpty(addon.app_id)) {
		isValid = false
		messages.push('App is required')
	}

  Object.keys(validations).forEach((key, i) => {
    if(!addon?.credentials || isEmpty(addon?.credentials[key])){
      isValid = false
      messages.push(`${key} is required`)
    }
  })

	let validation = {
		isValid,
		messages,
	}

	return validation
}
