import React from 'react'
import { ExpandSideMenu } from 'components'
import { Box } from '@mui/material'
import OnlineUserMenu from 'containers/main/navigation/OnlineUserMenu'
import ActiveProjectMenu from 'containers/main/navigation/ActiveProjectMenu'

const Header = ({
  ...props
}) => {

	return (
		<Box sx={ sx.root }>
			<Box sx={ sx.toolbar }>				
        <ExpandSideMenu />        
        <ActiveProjectMenu />
			</Box>	
				<Box sx={ sx.actions }>
          <OnlineUserMenu />
        </Box>
		</Box>
	)
}

Header.propTypes = {
}

export default Header

const sx = {
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
    alignItems: 'center',
		height: {
      xs: 72,
      sm: 40
    },
		width: '100%',    
    backgroundColor: 'background.main',
	},  
	toolbar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
    height: '100%',
    pl: { xs: '36px', sm: 0}
	},
	actions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		gap: '5px',
		mr: '10px',
	},
}
