import React from 'react'
import {
  Box,
  Button,
} from '@mui/material'
import { Modal, Placeholder } from 'components'
import { CreditCard } from '@mui/icons-material'


const PaymentModal = ({ 
  open, 
  handleClose, 
  handleClick 
}) => {


  return(
    <Modal 
      open={open}
      handleClose={handleClose}
      title="Upgrade your plan"
      actions={
        <Button 
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          Explore Plans 
        </Button>
      }
    >
      <Box sx={ sx.root }>
        <Placeholder 
          icon={ <CreditCard /> }
          title="Upgrade your plan"
          subtitle="Upgrade your plan to invite more team members."
        />        
      </Box>
    </Modal>
  )
}

export default PaymentModal 

const sx = {
}