import React from 'react'
import { Image } from '@mui/icons-material'
import { Avatar } from '@mui/material'

const EmptyImage = ({ size=64 }) => {

  return(
    <Avatar 
      variant='rounded' 
      sx={{
        ...sx.avatar,
        height: size,
        width: size,
      }}
    >
      <Image sx={ sx.icon } />
    </Avatar>
  )
}

export default EmptyImage 

const sx = {
	avatar: {
		border: theme => `2px solid ${theme.palette.common.white}`,
		backgroundColor: 'common.gradient'
	},
  icon: {
    color: 'icon'
  }
}