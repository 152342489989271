import React from 'react'
import { Box } from '@mui/material'
import {
  CellArray,
	CellBoolean,
	CellDate,
  CellHasMany,
  CellHABTM,
	CellImage,
	CellJSON,
	CellLink,
	CellText,
  CellParagraph,
	CellRating,
	CellReference,
  CellVideo
} from 'components'
import { displayValueFromField } from 'lib/helpers/adminly'

const Cell = ({
  field, 
  row,
  value,
  label,
  handleClick,
  ...params
}) => {
    
  let displayValue = displayValueFromField({ field, value, row })

	const RENDER_TYPES = [
		'boolean',
		'date',
		'datetime',
		'rating',
		'reference',
		'image',
		'json',
		'link',
		'paragraph',
    'text',
    'video',
    'has_many',
    'habtm'
	]

	if ((value === undefined || value === null) && field.field_type === 'column'){
    return <Box></Box>
  }

	return (
		<Box sx={ sx.root  }>
			{field.friendly_type === 'boolean' && (
				<CellBoolean value={value} />
			)}

			{field.friendly_type === 'date' && (
				<CellDate value={value} />
			)}

			{field.friendly_type === 'datetime' && (
				<CellDate value={value} />
			)}

			{field.friendly_type === 'image' && (
				<CellImage 
          value={value} 
          handleClick={ handleClick }
        />
			)}

      {field.friendly_type === 'video' && (
				<CellVideo 
          value={value} 
          handleClick={ handleClick }
        />
			)}

			{field.friendly_type === 'json' && (
				<CellJSON value={value} />
			)}

			{field.friendly_type === 'link' && (
				<CellLink
					value={value}
					handleClick={handleClick}
				/>
			)}

			{field.friendly_type === 'rating' && (
				<CellRating value={value} />
			)}

			{field.friendly_type === 'paragraph' && (
				<CellParagraph
					value={value}
          handleClick={ handleClick }
				/>
			)}


      {field.friendly_type === 'has_many' && (
				<CellHasMany
					value={value}
          displayValue={displayValue}  
					headerName={field?.name}
          handleClick={ handleClick }        
				/>
			)}

      {field.friendly_type === 'habtm' && (
				<CellHABTM
					value={value}
          displayValue={displayValue}  
					headerName={field?.name}
          handleClick={ handleClick }        
				/>
			)}

      { !field.array ? (
        <>
          {field.friendly_type === 'text' && (
            <CellText
              value={value}
              
            />
          )}
      
          {!RENDER_TYPES.includes(field.friendly_type) && (
            <CellText
              value={value}
              
            />
          )}
        </>
      ):(
        <CellArray
          value={value}
          
        />
      )}


			{field.friendly_type === 'reference' && (
				<CellReference
					value={value}
					headerName={field?.name}
          displayValue={displayValue}  
          handleClick={ handleClick }        
				/>
			)}

		</Box>
	)
}

export default Cell

const sx = {
	root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
	default: {
		fontFamily: 'Roboto Mono',
	},
}
