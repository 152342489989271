import React from 'react'
import { useMenu } from 'hooks'
import {
	Avatar,
	Typography,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemSecondaryAction,
	Menu,
	MenuItem,
} from '@mui/material'

import { MoreHoriz } from '@mui/icons-material'
import { Folder as FolderIcon } from 'react-feather'

const FolderItem = ({
	icon: Icon,
	folder,
	handleClick,
	handleDeleteClick,
	...props
}) => {


	const { open, anchorEl, toggleMenu, handleClose } =
		useMenu()

	const renderMenu = () => (
		<Menu
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
		>
			<MenuItem onClick={() => handleDeleteClick(folder)}>
				Delete
			</MenuItem>
		</Menu>
	)

	return (
		<ListItem
			disableRipple
			button
			onClick={() => handleClick(folder)}
			sx={ sx.root }
		>
			<ListItemIcon>
				<Avatar variant="square" sx={ sx.avatar }>
					<IconButton
						size="small"
						onClick={() => handleClick(folder)}
					>
						{Icon ? (
							<Icon sx={ sx.icon } />
						) : (
							<FolderIcon sx={ sx.icon } />
						)}
					</IconButton>
				</Avatar>
			</ListItemIcon>
			<ListItemText
				primary={
					<Typography variant="body2">
						{folder?.name}
					</Typography>
				}
			/>
			{handleDeleteClick && (
				<ListItemSecondaryAction>
					<IconButton onClick={toggleMenu}>
						<MoreHoriz sx={ sx.icon } />
						{renderMenu()}
					</IconButton>
				</ListItemSecondaryAction>
			)}
		</ListItem>
	)
}

export default FolderItem

const sx = {
	root: {
		width: '100%',
		borderBottom: theme => `1px solid ${theme.palette.common.border}`,
		'&:hover': {
			backgroundColor: 'common.highlight'
		},
	},
	avatar: {
		m: 1,
		backgroundColor: 'common.white',
	},
	icon: {
		height: 20,
		width: 20,
		color: 'text.secondary'
	},
	listItemIcon: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
}
