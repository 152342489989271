import React, { useEffect } from 'react'
import {
	useAdminProjects,
	useAlerts,
	useNavigation,
	useSelected,
} from 'hooks'
import { useHistory } from 'react-router-dom'
import {
	SortableTableHeader,
	Subheader,
	Placeholder,
	Search,
} from 'components'
import Pagination from '@mui/material/Pagination'
import {
	Checkbox,
	Container,
	Box,
	Paper,
	Button,
	Table,
	TableHead,
	TableContainer,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material'
import {
	Add,
	Search as SearchIcon,
} from '@mui/icons-material'
import ProjectItem from 'containers/admin/projects/ProjectItem'
import ProjectToolbar from 'containers/admin/projects/ProjectToolbar'

const TABLE_HEADERS = [
	{
		value: 'title',
		label: 'Title',
		sortable: true,
		hidden: false,
	},
	{
		value: 'user.name',
		label: 'Created by',
		sortable: false,
		hidden: false,
	},
]

const ProjectList = ({ ...props }) => {
	const history = useHistory()

	const {
		isLoading,
		isLoaded,
		isEmpty,
		projects,
		findProjects,
		deleteProject,
		paginate,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		reloadProjects,
		sortKey,
		sortDirection,
		handleSort,
	} = useAdminProjects({})

	const { handleClick, handleEditClick, handleAddClick } =
		useNavigation({
			history,
			url: '/admin/projects',
		})

	const {
		selected,
		selectedIds,
		handleSelect,
		handleClear,
		handleSelectAll,
		totalSelected,
	} = useSelected()

	const { showAlertSuccess, showAlertError } = useAlerts()

	const handleDeleteClick = async (project) => {
		await deleteProject(project?.id)
		showAlertSuccess('Project has been deleted')
		reloadProjects()
	}

	useEffect(() => {
		findProjects({
				...query,
				sort_key: sortKey,
				sort_direction: sortDirection,
        page: page 
		})
	}, [query, page, sortKey, sortDirection])

	const isSelectable = false

	return (
		<>
			<Subheader
				title="Projects"
				buttons={[
					<Button
						variant="contained"
						startIcon={<Add />}
						onClick={handleAddClick}
						color="primary"
					>
						Project
					</Button>,
				]}
			/>
			<Container maxWidth="lg">
				{isLoaded && (
					<>
						<Paper>
							<Search
								isLoading={isLoading}
								query={query}
								handleSearch={findProjects}
							/>
							{!isEmpty ? (
								<TableContainer
									component={Paper}
									elevation={0}
								>
									<Table>
										<TableHead>
											<TableRow>
												{isSelectable && (
													<TableCell>
														<Checkbox
															checked={
																selectedIds?.length ===
																projects?.length
															}
															onChange={() =>
																handleSelectAll(projects)
															}
															color="primary"
														/>
													</TableCell>
												)}
												{TABLE_HEADERS.filter(
													(h) => !h.hidden
												).map((header, idx) => (
													<SortableTableHeader
														key={idx}
														handleSort={handleSort}
														value={`projects.${header.value}`}
														sortable={header.sortable}
														sortKey={sortKey}
														sortDirection={sortDirection}
													>
														{header.label}
													</SortableTableHeader>
												))}
												<TableCell></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{projects.map((project, idx) => (
												<ProjectItem
													key={idx}
													project={project}
													isSelectable={isSelectable}
													selectedIds={selectedIds}
													handleSelect={handleSelect}
													handleClick={handleClick}
													handleDeleteClick={
														handleDeleteClick
													}
													handleEditClick={handleEditClick}
												/>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							) : (
								<Placeholder
									icon={<SearchIcon />}
									title="No projects"
									subtitle="Please try another search"
								/>
							)}
						</Paper>
						{!isEmpty && (
							<Box my={1}>
								<Pagination
									onChange={(ev, page) => paginate(page)}
									color="primary"
									size="small"
									shape="rounded"
									page={page}
									count={numPages}
								/>
							</Box>
						)}
					</>
				)}
				<ProjectToolbar
					selected={selected}
					selectedIds={selectedIds}
					handleClear={handleClear}
					totalSelected={totalSelected}
				/>
			</Container>
		</>
	)
}

export default ProjectList
