import React from 'react'
import { 
  Panel,
  Table, 
  TableRow,
  TableCell,
  Placeholder 
} from 'components'
import { 
  Box,
  Typography,
  CircularProgress,
  TableHead, 
  TableBody 
} from '@mui/material'
import { Code } from 'react-feather'
import { truncate } from 'lib/helpers/utils'

const SqlTable = ({ 
    isLoading, 
    query,
    headers, 
    rows, 
    ...props 
  }) => {

	return (
    <Panel styles={ sx.table }>
      { isLoading? (
        <Box sx={ sx.loader }>
          <CircularProgress 
            disableShrink
            sx={ sx.progress }
          />
        </Box>
        ):(
      <>
      { headers && rows ? (                
        <Table>
          <TableHead>
            <TableRow>
              { headers.map((header, idx) => (
              <TableCell header key={idx}>
                  { header }
                </TableCell> 
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            { rows.map((row, i) => (
              <TableRow key={i}>
                { row.map((value, j) => (
                  <TableCell key={j}>
                    { truncate(value) }
                  </TableCell>  
                ))}
              </TableRow>
            ))}
          </TableBody> 
        </Table>          
        ):(
        <Box>
          <Placeholder
            icon={
              <Code sx={ sx.icon } />
            }
            title="No results"
            subtitle="Trying another SQL query."
          />
        </Box>
        )}
      </>
      )}
    </Panel>
	)
}

export default SqlTable

const sx = {
	root: {
    width: { 
      xs: 'calc(100vw - 30px)',
      sm: '100%',
    },
	},
  table: {
    py: 0.5,
    px: 0,
    maxHeight: 'calc(100vh - 300px)',
    width: '100%',
    maxWidth: { 
      sm: 'calc(100vw - 350px)',
      xs: 'calc(100vw - 30px)',
    },
    overflowX: 'scroll',
  },
  textContainer: {
    p: 4
  },
  text: {
    whiteSpace: 'pre-wrap',
  }
}
