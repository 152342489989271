import React from 'react'
import { useActiveProject } from 'hooks'
import { List } from '@mui/material'

import AppItem from 'containers/main/apps/AppItem'

const AppList = ({
	isLoaded,
	apps,
	handleClick,
	...props
}) => {


	const { activeProject } = useActiveProject()

	return (
		<List sx={ sx.list }>
			{apps.map((app, idx) => {
				const addon = activeProject?.addons.find(
					(a) => a.provider === app.handle
				)
				return (
					<AppItem
						key={idx}
						app={app}
						addon={addon}
						handleClick={handleClick}
					/>
				)
			})}
		</List>
	)
}

export default AppList

const sx = {
	list: {},
	container: {
		p: 2,
		backgroundColor: 'common.white',
	},
}
