import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { Feature, Label, Section } from 'components'
import { useTheme } from '@mui/styles'


export default function SectionFour ({ 
  features,
  handleClick, 
  ...props
}) {  
  
  const theme = useTheme()

  return (
    <Section bgColor='common.section' styles={ sx.section }>
      <Container maxWidth={"lg"}>          
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <center>
                <Label label={'Join the no-code revolution'} />
                <Typography variant="h3">Next Generation Admin platform</Typography>
              </center>
            </Grid>
              { features.map(({ title, description, icon: Icon}, i) => (
                <Grid item xs={12} sm={6} md={4} key={i}>
                <Feature
                  icon={
                    <Icon 
                      style={{ 
                        height: 40,
                        width: 40,
                        color: theme.palette.primary.main 
                      }} 
                    /> 
                  }
                  title={ title }
                  description={ description }
                />
              </Grid>
            ))}            
          </Grid>
        </Container>  
      </Section>
  );
}


const sx = {
  root: {},
  icon: {
    height: 48,
    width: 48,
    color: 'secondary.main'
  },
};
