import React from 'react'
import { Grid } from '@mui/material'
import { TextInput } from 'components'

const ProjectForm = ({
	project,
	isEditing,
	handleChange,
}) => (
	<Grid container spacing={1}>
		<Grid item xs={12} sm={12}>
			<TextInput
				label="Title"
				value={project.title}
				label="Title"
				name="title"
				placeholder="Title"
				handleChange={handleChange}
			/>
		</Grid>
	</Grid>
)

export default ProjectForm
