import React from 'react'
import { Button } from '@mui/material'

import {
	CellImageThumbnail
} from 'components'

const CellImage = ({ value, handleClick, ...props }) => {


	return (
		<Button sx={ sx.button } onClick={ handleClick  }>
      <CellImageThumbnail value={value} />		
    </Button>
	)
}

export default CellImage

const sx = {
	root: {},
  button: {
    cursor: 'zoom-in',
    p: 0
  }
}
