import React from 'react'
import { Avatar } from '@mui/material'
import { CloudinaryImage } from 'components'


const FieldImageThumbnail = ({ value, ...props }) => {

	let isCloudinary = /(cloudinary)/i.test(value)

	return (
    isCloudinary ? (
      <CloudinaryImage
        src={value}
        height={240}
        width={240}
      />
    ) : (
      <img src={value} sx={ sx.image } />
    )
	)
}

export default FieldImageThumbnail

const sx = {
	avatar: {
		backgroundColor: 'common.white',
	},
	image: {
		objectFit: 'cover',
		height: 240,
		width: 240,
	},
}
