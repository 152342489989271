import React, { useContext } from 'react' 
import { AppContext } from 'context'
import { Box, IconButton, Hidden } from '@mui/material'
import { FirstPage } from '@mui/icons-material'

const BackButton = (props) => {

  const { openMobile, setOpenMobile } = useContext(AppContext)
  const onClick = () => setOpenMobile(false)

	return (
		<Hidden smUp>
			<Box sx={ sx.goBack }>				
        <IconButton
          onClick={onClick}
          sx={ sx.backButton }
        >
          <FirstPage />
        </IconButton>				
			</Box>
		</Hidden>
	)
}

export default BackButton

const sx = {
	goBack: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	backButton: {
		color: 'icon'
	},
}
