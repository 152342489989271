import React from 'react'

import {
	FieldText,
} from 'components'

const FieldJSON = ({ value, ...props }) => {

  let formattedValue = value
  if (typeof value === 'object') {
    formattedValue = JSON.stringify(value)
  }

	return (
		<FieldText value={JSON.stringify(value, null, 2)} />
	)
}

export default FieldJSON

const sx = {
	root: {},
}
