import React from "react";
import { Button, Container } from "@mui/material";
import { ArrowRightAlt } from "@mui/icons-material";
import { RightImageText, Section } from 'components'
import Image from "assets/images/adminly-filters.png";
export default function SectionOne({ handleClick, ...props }) {  

  return (
      <Section styles={ sx.section }>
        <Container maxWidth={"lg"}>
          <RightImageText 
            caption={'No SQL required.'}
            title={'Advanced search and filtering. '}
            description={
              'Adminly supports a built-in, powerful and express query language \
              to allow you to easily search, sort, and filter your data. Easy collaboration \
              features allow you to save filtered data and share with team members.'
            }
            image={ Image }
            actions={ 
              <Button
                size="large"
                color="secondary"
                variant="contained"
                sx={ sx.button }
                onClick={handleClick}
                endIcon={<ArrowRightAlt />}
              >
                Learn More
              </Button>
            }
          />
        </Container>
    </Section>
  );
}


const sx = {
  root: {},
};
