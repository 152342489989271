import typography from 'theme/typography'

export default {
	styleOverrides: {
		root: {
			borderRadius: '4px',
			fontFamily: typography.body3.fontFamily,
		},
		deletable: {
			'&:focus': {},
		},
	},
}
