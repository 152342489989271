import React, { useEffect, useState } from 'react'
import { useMenu } from 'hooks'
import {
	Avatar,
  Box,
	Typography,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemSecondaryAction,
	Menu,
	MenuItem,
	Button,
} from '@mui/material'

import { MoreHoriz } from '@mui/icons-material'
import { Link2 } from 'react-feather'
import { Label, CloudinaryImage } from 'components'

const CloudinaryItem = ({
	item,
	cloudName,
	width = 80,
	height = 80,
	handleClick,
	handleDeleteClick,
	...props
}) => {


	const { open, anchorEl, toggleMenu, handleClose } =
		useMenu()

	const renderMenu = () => (
		<Menu
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
		>
			<MenuItem onClick={() => handleDeleteClick(item)}>
				Delete
			</MenuItem>
		</Menu>
	)

	return (
		<ListItem
			disableRipple
			button
			onClick={() =>
				handleClick ? handleClick(item) : null
			}
			sx={ sx.root }
		>
			<ListItemIcon>
				<Avatar
					variant="rounded"
					sx={ sx.avatar }
				>
					<CloudinaryImage
						src={item.secure_url}
						height={50}
						width={50}
					/>
				</Avatar>
			</ListItemIcon>
			<ListItemText
				primary={
					<Typography variant="body2">
						{item?.public_id}.{item?.format}
					</Typography>
				}
			/>
			<ListItemSecondaryAction>
				<Label label={`${item.width}x${item.height}`} />
				<Label label={item.resource_type} />
				<Label label={item.format} />
				<IconButton onClick={toggleMenu}>
					<MoreHoriz sx={ sx.icon } />
					{renderMenu()}
				</IconButton>
			</ListItemSecondaryAction>
		</ListItem>
	)
}

export default CloudinaryItem

const sx = {
	root: {
		width: '100%',
		borderBottom: theme => `1px solid ${theme.palette.common.border}`,
		'&:hover': {
			backgroundColor: 'common.highlight'
		},
	},
	avatar: {
		m: 1,
		backgroundColor: 'common.white',
	},
	image: {
		borderRadius: 4,
		width: '100%',
		height: 'auto',
		mr: 2,
	},
	button: {
		p: 0,
	},
	toolbar: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	icon: {
		height: 20,
		width: 20,
		color: 'text.secondary'
	},
	listItemIcon: {
		display: 'flex',
		height: 50,
		flexDirection: 'column',
		justifyContent: 'center',
	},
	title: {
		width: '70%',
		overflowWrap: 'break-word',
	},
	loader: {
		width: '100%',
		height: 160,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
}
