import React, { useState, useEffect } from 'react'
import { useActiveProject, useAddons } from 'hooks'
import { Drawer } from 'components'

import AppHeader from 'containers/main/apps/AppHeader'
import Auth0AddonForm from 'containers/apps/auth0/Auth0AddonForm'
import CloudinaryAddonForm from 'containers/apps/cloudinary/CloudinaryAddonForm'

const AddonModal = ({
	open,
	app,
	addon,
	setAddon,
	handleClose,
	handleChange,
	handleSubmit,
}) => {

	const { activeProject } = useActiveProject()

	const handleLoadAddon = async () => {
		if (addon?.id) {
			const resp = await findAddon(addon?.id)
			setAddon(resp)
		}
	}

	const { findAddon } = useAddons({
		projectId: activeProject?.id,
	})

	useEffect(() => {
		if (open) {
			handleLoadAddon()
		}
	}, [open])

	return (
		<Drawer
			open={open}
			handleClose={handleClose}
			title={app?.name}
		>
			<AppHeader app={app} size={100} />

			{app?.handle === 'auth0' && (
				<Auth0AddonForm
					app={app}
					addon={addon}
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					installed={addon?.id}
				/>
			)}

			{app?.handle === 'cloudinary' && (
				<CloudinaryAddonForm
					app={app}
					addon={addon}
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					installed={addon?.id}
				/>
			)}
			
		</Drawer>
	)
}

export default AddonModal

const sx = {
	root: {},
	actions: {
				my: 2,
    mx: 0,
	},
}
