import React from 'react'
import { 
  PanelLayout, 
  LeftPanel,
  CenterPanel 
} from 'components'
import SettingsLeftPanel from 'containers/main/settings/SettingsLeftPanel'
import UserList from 'containers/main/users/UserList'


const SettingsUsers = (props) => {
  const activeTab = "users"
  const { id: projectId } = props.match.params 
  
	return (
    <PanelLayout>
      <LeftPanel>
        <SettingsLeftPanel 
          activeTab={ activeTab }
        />
      </LeftPanel>
      <CenterPanel>
        <UserList 
          enableBackButton
          projectId={ projectId } 
        /> 
      </CenterPanel>
    </PanelLayout>
	)
}

export default SettingsUsers

