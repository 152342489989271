import React from 'react'
import { IconButton } from '@mui/material'
import { Box } from '@mui/material'

import PropTypes from 'prop-types'


const ButtonIcon = ({
	onClick,
	icon: Icon,
	size = 24,
	disabled = false,
	...props
}) => {

	return (
		<Box sx={ sx.root }>
			<IconButton
				sx={ sx.button }
				onClick={onClick}
				disabled={disabled}
			>
				<Icon 
          sx={{
          	color: disabled ? 'common.grey' : 'text.secondary',
            height: size,
            width: size,
          }} 
        />
			</IconButton>
		</Box>
	)
}

ButtonIcon.propTypes = {
	icon: PropTypes.object.isRequired,
	handleClick: PropTypes.func,
}

export default ButtonIcon

const sx = {
	button: {
		p: 12		
	}
}
