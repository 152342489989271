import React, { useContext } from 'react' 
import { ProjectContext } from 'context'
import { 
  useActiveProject 
} from 'hooks'
import { buildQuery } from 'lib/helpers/adminly'
import { useHistory } from 'react-router-dom'

const useAdminlyTabs = ({ ...props }) => {

  const history = useHistory()
  
  const { 
    activeView,
    activeProject 
  } = useActiveProject()
  
  const { 
    tabIndex,
    setTabIndex,
    activeTabs,
    setActiveTabs 
  } = useContext(ProjectContext)


  const handleTabClick = (tabIdx) => {
    setTabIndex(tabIdx)
    let tab = activeTabs[tabIdx]
    handleLoadTab(tab)
  }

  const handleLoadTab = (tab) => {
    let query = buildQuery(tab?.query)        
    let queryParams = decodeURIComponent(new URLSearchParams(query).toString())    
    history.push(`/projects/${activeProject?.id}/collections/${tab.value}?${queryParams}`)
  }

  const handleAddTab = ({ label, value, query }) => {
    let tab = { label, value, query }
    let newTabs = [...activeTabs, tab]
    setActiveTabs(newTabs)     
    setTabIndex(newTabs?.length - 1)   
    handleLoadTab(tab)
  }

  const handleRemoveTab = (tabIdx) => {
    let newTabs = handleDeleteTab(tabIdx)
    if(newTabs?.length === 0 && activeView?.collection) {            
      history.push(`/projects/${activeProject?.id}/views/${activeView?.id}`)
    }else if(newTabs?.length > 0){
      let lastIndex = newTabs.length-1
      handleTabClick(lastIndex)
    }
  }

  const handleDeleteTab = (index) => {
    let newTabs = activeTabs.filter((_, i) => i !== index)
    setActiveTabs(newTabs)
    return newTabs
  }

  return {
    tabIndex,
    activeTabs,
    setActiveTabs,
    handleAddTab,
    handleRemoveTab,
    handleTabClick    
  }
}

export default useAdminlyTabs 
