import React from 'react'
import {
	Box,
	Container,
	Grid,
	IconButton,
	Paper,
	Slide,
} from '@mui/material'

import { Close } from '@mui/icons-material'

const Toolbar = ({
	open,
	handleClose,
	children,
	...props
}) => {


	return (
		<Slide in={open} direction="down">
			<Paper sx={ sx.paper} square elevation={2 }>
				<Container sx={ sx.paper } maxWidth="xl">
					<Grid container spacing={1}>
						<Grid item xs={1} />
						<Grid item xs={10}>
							<Box
								display="flex"
								mt={1}
								justifyContent="center"
							>
								{children}
							</Box>
						</Grid>
						<Grid item xs={1}>
							<Box
								display="flex"
								width="100%"
								justifyContent="flex-end"
							>
								<IconButton
									sx={ sx.button }
									onClick={handleClose}
								>
									<Close />
								</IconButton>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Paper>
		</Slide>
	)
}

export default Toolbar

const sx = {
	paper: {
		zIndex: 2000,
		backgroundColor: 'common.white',
		top: 0,
		left: 0,
		position: 'fixed',
		height: 64,
		width: '100%',
	},
	container: {
		pt: 10,
	},
	button: {
		mt: 4,
	},
}
