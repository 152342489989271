import React from 'react'
import { AuthScreen, ProgressLoader } from 'components'
import { useOAuth } from 'hooks'

const HerokuAuthorize = ({ ...props }) => {
	
  useOAuth({ provider: 'heroku' })

	return (
		<AuthScreen
			title="Heroku"
			subtitle="Heroku authorization"
		>
			<ProgressLoader />
		</AuthScreen>
	)
}

export default HerokuAuthorize
