import React from 'react'
import { useAlerts } from 'hooks'
import { Modal } from 'components'
import { Box, Button } from '@mui/material'
import { Link, Download } from 'react-feather'
import copy from 'copy-to-clipboard'

const ImageModal = ({
	open,
	src,
  title='',
	handleClose,
}) => {

	const { showAlertSuccess } = useAlerts()

	const handleCopyUrlClick = () => {
		copy(src)
		showAlertSuccess('Asset URL copied to clipboard')
	}

	const handleDownloadClick = () => {
		window.open(src, '_blank')
	}

	return (
		<Modal
			open={open}
			handleClose={handleClose}
			title={title}
			maxWidth="md"
			p={0}
			actions={
				<>
					<Button
            variant="outlined"
						sx={ sx.button }
						startIcon={<Link />}
						onClick={handleCopyUrlClick}
					>
						Copy URL
					</Button>
					<Button
            variant="outlined"
						sx={ sx.button }
						startIcon={<Download />}
						onClick={handleDownloadClick}
					>
						Download
					</Button>
				</>
			}
		>
			<Box sx={ sx.root }>
				<img src={src} sx={ sx.image } />
			</Box>
		</Modal>
	)
}

export default ImageModal

const sx = {
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	image: {
		width: '100%',
		height: 'auto',
		maxHeight: '100%',
	},
	button: {
		color: 'text.secondary'
	},
}
