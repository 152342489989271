import React, { useContext } from 'react'
import { AsyncAutosuggest } from 'components'
import { ProjectContext } from 'context'

const CollectionAutosuggest = ({
	value,
	name = 'collection_id',
	label,
	position,
	handleChange,
	...props
}) => {
	const { activeProject } = useContext(ProjectContext)

	return (
		<AsyncAutosuggest
			name={name}
			label={label}
			value={value}
			position={position}
			displayField="name"
			remoteUrl={`/api/v1/projects/${activeProject?.id}/collections`}
			placeholder="Select collection"
			handleChange={handleChange}
		/>
	)
}

export default CollectionAutosuggest
