import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { 
  Panel,
  PanelHeader,
  ProgressLoader,
  Field 
} from 'components'
import {
  useCollections,
  useAdminly
} from 'hooks'
import {
  Box,
  Typography
} from '@mui/material'

const AdminlyShowDetails = ({ 
  collectionId,
  projectId,
  resourceId,
  ...props
}) => {


  const [activeFields, setActiveFields] = useState(false)

  const {
    isLoading: isLoadingCollection,
    collection,
    findCollection 
  } = useCollections({
    projectId: projectId 
  })

  const {
    isLoading,
    resource,
    findOne 
  } = useAdminly({
    collection: collection?.db_name 
  })

  const handleSortFields = () => {    
    let sortedFields = collection.fields
        .filter(field => field.visible === true)
        .sort((a, b) => a.position - b.position)

    setActiveFields(sortedFields)
  }

  useEffect(() => {
    if(projectId && collectionId){
      findCollection(collectionId)
    }
  }, [projectId, collectionId])

  useEffect(() => {
    if(collection?.id){
      findOne(resourceId)
      handleSortFields()
    }
  }, [collection?.id, resourceId])
  
  return(
    <Box sx={ sx.root }>
      <PanelHeader 
        title={`Resource ${resourceId}`}
      />      
      <Panel p={4}>
        { !isLoading ? (
          <Box sx={ sx.list  }>
            { resource && 
              activeFields &&  
              activeFields.map((field, i) =>  (
              <Box sx={ sx.row} key={i }>
                <Box sx={ sx.field  }>
                  <Typography color="textSecondary" variant="caption">
                    {field.db_name}
                  </Typography>
                </Box>
                <Box sx={ sx.value  }>
                  <Field field={field} resource={resource} />
                </Box>                  
              </Box>
            ))}
          </Box>
        ):(
          <Box sx={ sx.loader }>
            <ProgressLoader />
          </Box>
        )}
      </Panel>
    </Box>
  )
}

AdminlyShowDetails.prototype = {
  collectionId: PropTypes.string,  
  resourceId: PropTypes.string,
  projectId: PropTypes.string
}

export default AdminlyShowDetails 

const sx = {
  root: {},
  row: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderLeft: theme => `3px solid ${theme.palette.common.border}`,
    mb: 2,
    p: 1,
    pl: 2,
    '&:hover': {
      borderLeft: theme => `3px solid ${theme.palette.primary.main}`,
      backgroundColor: 'common.highlight'
    }
  },
  field: {
    width: 240,    
    height: '100% !important',
    display: 'flex',
    justifyContent: 'flex-start',
    color: 'text.secondary'
  },
  value: {
    width: '100%',    
  }
}