import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { AppContext } from 'context'
import { Box } from '@mui/material'

const LeftPanel = ({
	title,
	subtitle,
	children,
	actions,
	...props
}) => {

  const { openMobile } = useContext(AppContext)

	return (
		<Box 
      sx={{ 
        ...sx.root, 
        ...(openMobile && sx.open)
      }}>
			<Box sx={sx.container}>
        {children}
      </Box>
		</Box>
	)
}

LeftPanel.propTypes = {
	children: PropTypes.any.isRequired,
}

export default LeftPanel

const sx = {
	root: {
		height: '100%',
		backgroundColor: 'background.dark',
    p: 0,
    overflow: 'scroll',
    height: '100%',
    flexBasis: {
      xs: '100%',
      sm: 'auto'
    },
    width: { xs: '100%', sm: '260px' },
    transition: 'transform .5s ease',
    transform: { xs: 'translateX(0)', sm: 'auto' },
    ml: { xs: 0, sm: 'auto' },
		maxWidth: { xs: 'none', sm: 'none' },
    flexShrink: { xs: '0', sm: 'auto' },
    '&::-webkit-scrollbar': {
      display: 'none',
      msOverflowStyle: 'none',  //IE and Edge
      scrollbarWidth: 'none'  // Firefox 
    }		
	},
	open: {		
    transform: {
      xs: 'translateX(-100%)',
      sm: 'translateX(0)'
		}
	},
	container: {
		width: '100%',
		overflowY: 'scroll',
		overflowX: 'none',
	},
}
