import React from 'react'
import { Box, List } from '@mui/material'

import { LineItem, UserAvatar } from 'components'

const UserDetails = ({ user }) => (
	<Box>
		<center>
			<UserAvatar src={user.image_url} size={100} />
		</center>
		<List>
			<LineItem label="Name" value={user.name} />
			<LineItem label="Email" value={user.email} />
			<LineItem label="Role" value={user.role} />
		</List>
	</Box>
)

export default UserDetails
