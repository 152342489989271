import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Box, List } from '@mui/material'
import { Settings } from '@mui/icons-material'
import { LeftPanelHeader, SubmenuItem } from 'components'
import { useAuth, useActiveProject, useTabs } from 'hooks'
import { isHerokuAuthenticated } from 'lib/helpers/heroku'
import { AppContext } from 'context'
import { useHistory } from 'react-router-dom'

const SettingsLeftPanel = ({
	activeTab,
	...props
}) => {

  useTabs('settings')


  const history = useHistory()

  const { currentUser, fetchMe } = useAuth()
  const { setOpenMobile } = useContext(AppContext)
  const { activeProject } = useActiveProject()

	const TABS = [
		{ label: 'Project', value: 'details' },
		{ label: 'Apps', value: 'apps' },
		{ label: 'Billing', value: 'payments' },
    { label: 'Team', value: 'users' },
	]

  const HEROKU_TABS = [
    { label: 'Heroku', value: 'heroku' },
  ]

	const handleClick = (tabValue) => {
		setOpenMobile(true)
    switch(tabValue){
      case 'my_account': 
        history.push(`/users/${currentUser?.id}/edit`)
        break
      case 'projects': 
        history.push(`/projects`)
        break        
      default: 
        history.push(`/projects/${activeProject?.id}/settings/${tabValue}`)
      break
    }		
	}


	return (
		<Box sx={ sx.root }>
			<LeftPanelHeader 
        title="Settings" 
        icon={ Settings }
      />
			<List disablePadding sx={ sx.list }>
        { activeProject && 
          activeProject.current_role === 'admin' && (
          <>
            {TABS.map((tab, i) => (
              <SubmenuItem
                key={i}
                value={tab.value}
                selected={activeTab}
                label={tab.label}
                handleClick={() => handleClick(tab.value)}
              />
            ))}
            { isHerokuAuthenticated(activeProject) && 
              HEROKU_TABS.map((tab, i) => (
                <SubmenuItem
                  key={i}
                  value={tab.value}
                  selected={activeTab}
                  label={tab.label}
                  handleClick={() => handleClick(tab.value)}
                />
            ))}
          </>
        )}

        <SubmenuItem
          value={'my_account'}
          selected={activeTab}
          label={'My Account'}
          handleClick={() => handleClick('my_account')}
        />
			</List>
		</Box>
	)
}

SettingsLeftPanel.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object,
}

export default SettingsLeftPanel

const sx = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	list: {
		width: '100%',
	},
}
