import React, { useState } from 'react'
import {
	Box,
	Button,
	Grid,
	Link,
	Typography,
} from '@mui/material'
import { AuthScreen, TextInput } from 'components'
import { useAlerts, useAuth } from 'hooks'
import {
	Link as RouterLink,
	useHistory,
} from 'react-router-dom'

const ResetPassword = ({ ...props }) => {
	const [email, setEmail] = useState()

	const { showAlertError, showAlertSuccess } = useAlerts()

	const history = useHistory()

	const { forgotPassword } = useAuth()

	const handleSubmit = async () => {
		try {
			await forgotPassword(email)
			history.push(`/login`)
			showAlertSuccess(
				'We have sent you password reset instructions'
			)
		} catch (e) {
			showAlertError("A user with that email doesn't exist")
		}
	}

	const handleChange = ({ target }) =>
		setEmail(target.value)

	return (
		<AuthScreen
			title="Reset Password"
			subtitle="Forgot password reset."
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextInput
						fullWidth
						required={true}
						name="email"
						value={email}
						placeholder="Enter your email"
						handleChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						onClick={handleSubmit}
					>
						Send Instructions
					</Button>
				</Grid>
			</Grid>
			<Box my={4} />
			<Typography variant="body2">
				<Link component={RouterLink} to="/login">
					Sign in
				</Link>
			</Typography>
		</AuthScreen>
	)
}

export default ResetPassword
