import React from 'react'
import { useHeroku } from 'hooks'
import { SocialLoginButton } from 'components'
import {
	HEROKU_CLIENT_ID,
	HEROKU_AUTH_URL,
	HEROKU_SCOPES,
} from 'lib/constants'
import HerokuIcon from 'assets/icons/heroku.svg'

const HerokuOAuthButton = () => {

	const authorizationUrl = `${HEROKU_AUTH_URL}?client_id=${HEROKU_CLIENT_ID}&response_type=code&scope=${HEROKU_SCOPES}`

	return (
		<SocialLoginButton
      fullWidth
			authorizationUrl={authorizationUrl}
			icon={
				<img src={HerokuIcon} style={ styles.icon } />
			}
			label={'Connect with Heroku'}
			provider="heroku"
			styles={ sx.button }
		/>
	)
}

export default HerokuOAuthButton

const styles = {
  icon: {
		height: 24,
		width: 24,
		color: 'white',
	}
}

const sx = {
	button: {
    color: 'common.white',
    backgroundColor: 'common.heroku',
		'&&': {
			backgroundColor: 'common.heroku'
		},
	},
}
