import React, { useContext } from 'react'
import { usePayments } from 'hooks'
import { ProjectContext } from 'context'
import { STRIPE_PUBLISHABLE_KEY } from 'lib/constants'
import { Typography, Button } from '@mui/material'
import StripeCreditCardForm from 'containers/main/payments/StripeCreditCardForm'
import { loadStripe } from '@stripe/stripe-js'
import {
	Elements,
	ElementsConsumer,
} from '@stripe/react-stripe-js'
import { ButtonLoader } from 'components'
import { isStripe } from 'lib/helpers/utils'
import { useHistory } from 'react-router'
import { Box } from '@mui/material'
import StripeCreditCardInput from 'containers/main/payments/StripeCreditCardInput'

const StripeCreditCard = ({ customer, ...props }) => {
	const history = useHistory()
	const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)


	const { 
    isLoading, 
    addPayment,
    removePayment 
  } = usePayments({
		id: customer?.id,
	})

	const handleRemovePayment = async () => {
		await removePayment()
	}

	const handleSubmit = async (stripeToken) => {
		await addPayment(stripeToken)
	}
  
	return (
		<Box sx={ sx.root }>
        { !isStripe(customer) ? (
          <Box sx={ sx.stripeCard  }>
            <StripeCreditCardInput 
              isLoading={isLoading}
              handleSubmit={handleSubmit}
            />
          </Box>
        ):(      
				<Box sx={ sx.content  }>
          <Box>
            <Typography
              variant="subtitle1"
              color="textPrimary"
            >
              Credit Card 
            </Typography>
            <Typography variant="body2" color="textSecondary">								
              XXXX XXXX XXXX {customer.stripe_last_4}
            </Typography>
          </Box>
          <Box>
            <Button						
              variant="outlined"
              sx={ sx.button }
              onClick={handleRemovePayment}        
              startIcon={isLoading && <ButtonLoader />}
            >
              REMOVE CARD
            </Button>
          </Box>
        </Box>
      )}
		</Box>
	)
}

export default StripeCreditCard

const sx = {
	root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
	},
  stripeCard: {
    p: 3
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
		p: 3,        
    borderRadius: theme => theme.shape.borderRadius,
    backgroundColor: 'common.white',
		border: theme => `1px solid ${theme.palette.common.inputBorder}`,
		boxShadow: `rgb(0 0 0 / 5%) 0px 2px 4px !important`,
  }
}
