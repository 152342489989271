import React, {
	useState,
	useEffect,
	useContext,
} from 'react'
import { ProjectContext } from 'context'
import { muiTheme } from 'theme'
import { createTheme } from '@mui/material/styles'
const theme = createTheme(muiTheme)

const useCloudinaryEditor = ({ onComplete }) => {
	const { activeProject } = useContext(ProjectContext)
	const [editor, setEditor] = useState()

	const setupEditor = () => {
		const myEditor = window.cloudinary.mediaEditor()
		myEditor.on('export', function (data) {
			onComplete(data)
		})
		setEditor(myEditor)
	}

	const openEditor = (publicId) => {
		if (editor) {
			editor.update({
				publicIds: [publicId],
				cloudName: activeProject?.cloudinary_cloud_name,
			})
			editor.show()
		}
	}

	useEffect(() => {
		if (activeProject?.id) {
			setupEditor()
		}
	}, [activeProject?.id])

	return {
		openEditor,
	}
}

export default useCloudinaryEditor

const styles = {
	palette: {
		window: 'background.main',
		windowBorder: 'text.secondary',
		tabIcon: 'text.secondary',
		menuIcons: 'text.secondary',
		textDark: 'text.secondary',
		textLight: 'common.white',
		link: 'text.secondary',
		action: 'text.secondary',
		inactiveTabIcon: 'text.secondary',
		error: 'text.secondary',
		inProgress: 'text.secondary',
		complete: 'text.secondary',
		sourceBg: 'background.main'
	},
	fonts: {
		Roboto:
			'https://fonts.googleapis.com/css?family=Roboto:400,500,600,700,800|Inter:400,500,600,700,800',
	},
}
