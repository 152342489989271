import React, { useState, useContext, useEffect } from 'react'
import {
	useActiveAddon,
	useAddons,
	useAlerts,
	useActiveProject,
	useApps,
} from 'hooks'
import PropTypes from 'prop-types'
import {
	CenterPanel,
	LeftPanel,
	Panel,
	PanelLayout,
	Placeholder,
	PanelHeader,
	DeleteWarningModal,
} from 'components'
import SettingsLeftPanel from 'containers/main/settings/SettingsLeftPanel'
import AppDetails from 'containers/main/apps/AppDetails'
import AddonModal from 'containers/main/addons/AddonModal'
import { Search } from 'react-feather'
import { validateAddon } from 'validations/addons'
import { Box } from '@mui/material'
import { findAddon } from 'lib/helpers/utils'
import { AppContext } from 'context'

const AppContainer = ({ ...props }) => {

	const { id: projectId, app_id: appId } = props.match.params


	const { showAlertError, showAlertSuccess } = useAlerts()

	const [showModal, setShowModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)

  const { setOpenMobile } = useContext(AppContext)
	const { activeProject } = useActiveProject(projectId)

	const { app, findApp } = useApps({})

	const { installed, setInstalled } = useActiveAddon({
		provider: app?.handle,
	})

	const {
		addon,
		setAddon,
		createAddon,
		updateAddon,
		deleteAddon,    
	} = useAddons({
		projectId: projectId,
	})

  const handleChange = (ev) => {
    let { name, value } = ev.target
    setAddon({
      ...addon,
      credentials: {
        ...addon.credentials,
        [name]: value,
      }
    })
  }

	const handleCloseAddonModal = () => {
		setShowModal(false)
	}

	const handleInstallClick = (app) => {
		setShowModal(true)
		setAddon({
			...addon,
			app_id: app?.id,
		})
	}

	const handleSaveAddon = async () => {
		let validate = validateAddon(addon, app?.handle)
		if (validate.isValid) {
			let resp
			if (addon?.id) {
				resp = await updateAddon(addon)
			} else {
				resp = await createAddon(addon)
			}
			if (resp?.id) {
				setShowModal(false)
				setInstalled(true)
				showAlertError('Addon was successfully saved')
			}else{
        showAlertError('There was an error saving the Addon')
      }
		} else {
			validate.messages.map((m) => showAlertError(m))
		}
	}

	const handleDeleteClick = async () => {
		setShowDeleteModal(true)
	}

	const handleDeleteAddon = async () => {
		await deleteAddon(addon?.id)
		setInstalled(false)
    setAddon({
      provider: app?.handle 
    })
		setShowDeleteModal(false)
		showAlertSuccess('Addon was successfully deleted')
	}

	const handleVisitClick = () => {
		window.open(app.url, '_blank')
	}

	const handleDocsClick = () => {
		window.open(app.documentation_url, '_blank')
	}

	useEffect(() => {
		if (appId) {
			findApp(appId)
		}
	}, [appId])

	useEffect(() => {
		if (app?.id) {
			setOpenMobile(true)
		}
	}, [app?.id])

	useEffect(() => {
		if (activeProject && app) {
			let installedAddon = findAddon(
				activeProject,
				app?.handle
			)
			if (installedAddon) {
				setAddon(installedAddon)
			}
		}
	}, [activeProject, app])

	return (
    <PanelLayout>
      <LeftPanel>
        <SettingsLeftPanel activeTab={'apps'} />
      </LeftPanel>
      <CenterPanel>
        <Box sx={ sx.root }>
          <PanelHeader title="Apps" />
          <Panel>
            {app ? (
              <AppDetails
                app={app}
                installed={installed}
                handleClick={handleInstallClick}
                handleDeleteClick={handleDeleteClick}
                handleVisitClick={handleVisitClick}
                handleDocsClick={handleDocsClick}
              />
            ) : (															
              <Placeholder
                icon={
                  <Search sx={ sx.icon } />
                }
                title="No apps found"
                subtitle="Please try another search."
              />							
            )}
          </Panel>
        </Box>
      </CenterPanel>
			<AddonModal
				open={showModal}
				app={app}
				addon={addon}
				setAddon={setAddon}
				handleClose={handleCloseAddonModal}
				handleChange={handleChange}
				handleSubmit={handleSaveAddon}
			/>
			<DeleteWarningModal
				open={showDeleteModal}
				onConfirm={handleDeleteAddon}
				onClose={() => setShowDeleteModal(false)}
			/>
		</PanelLayout>
	)
}

AppContainer.propTypes = {
	className: PropTypes.string,
}

export default AppContainer

const sx = {
	root: {},
	iconButton: {
		color: 'primary.main'
	},
	icon: {
		color: 'text.secondary'
	},
}
