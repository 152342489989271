import React, { useState } from 'react'
import { Modal } from 'components'

import CloudinaryUploader from 'containers/apps/cloudinary/CloudinaryUploader'

const CloudinaryModal = ({
	open,
	path,
	onComplete,
	handleClose,
}) => {
	return (
		<Modal
			open={open}
			handleClose={handleClose}
			title="Upload an Asset"
			maxWidth="md"
		>
			<CloudinaryUploader
				path={path}
				onComplete={onComplete}
			/>
		</Modal>
	)
}

export default CloudinaryModal

const sx = {
	root: {},
}
