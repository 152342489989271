export default {
	styleOverrides: {
		root: {
			backgroundColor: 'white',
      '&:before': {
        backgroundColor: 'white'
      }
		},
	},
}
