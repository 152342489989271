import React, { useState, useContext } from 'react'
import { ProjectContext } from 'context'
import { useAuth, useAlerts, usePayments } from 'hooks'
import { Box, Button, Typography } from '@mui/material'
import {
	DeleteWarningModal,
	ButtonLoader,
} from 'components'
import { isStripe, isPaid } from 'lib/helpers/utils'


const StripeManageCard = ({ last4, ...props }) => {
	const [open, setOpen] = useState(false)

	const { fetchMe } = useAuth()

	const { showAlertSuccess, showAlertError } = useAlerts()

	const { activeProject } = useContext(ProjectContext)

	const {
		isLoading,
		removePayment,
		subscribe,
		unsubscribe,
	} = usePayments({
		projectId: activeProject?.id,
	})

	const handleSubscribe = async () => {
		if (isStripe(activeProject)) {
			const resp = await subscribe()			
			showAlertSuccess('You have successfully subscribed')
		} else {
			showAlertError('There was an error subscribing')
		}
	}

	const handleUnsubscribe = async () => {
		await unsubscribe()
		showAlertSuccess('You have canceled your subscription')
		fetchMe()
		setOpen(false)
	}

	return (
		<Box sx={ sx.root }>
			<Box sx={ sx.creditCard }>
				{isPaid(activeProject) && (
					<Button
						sx={ sx.button }
						onClick={() => setOpen(true)}
						variant="outlined"
						color="primary"
						type="submit"
						startIcon={isLoading && <ButtonLoader />}
					>
						CANCEL SUBSCRIPTION
					</Button>
				)}
			</Box>
			<DeleteWarningModal
				open={open}
				isLoading={isLoading}
				title={'Please confirm you would like to unsubscribe.'}
				subtitle={'You will lose access to premium features.'}
				onClose={() => setOpen(false)}
				onConfirm={handleUnsubscribe}
			/>
		</Box>
	)
}

export default StripeManageCard

const sx = {
	root: {},
	button: {
		mt: 2,
		mr: 0.5,
	},
	subscription: {},
	creditCard: {},
}
