import { useContext, useState } from 'react'
import { ApiContext, ProjectContext } from 'context'
import { useLoaders, useAlerts } from 'hooks'

const usePayments = (props) => {
	const { api } = useContext(ApiContext)

	const { showAlertError } = useAlerts()

	const { isLoading, showLoading, hideLoading } = useLoaders()
	const { activeProject, setActiveProject } = useContext(ProjectContext)
	const projectId = activeProject?.id

	const [payment, setPayment] = useState({})

	const handleChange = (ev) => {
		const { name, value } = ev.target
		setPayment({
			...payment,
			[name]: value,
		})
	}

	const addPayment = async (stripeToken) => {
		showLoading()
		try {
			const resp = await api.post(
				`/api/v1/projects/${projectId}/payments/add_payment`,
				{
					payment: {
						stripe_token: stripeToken,
					},
				}
			)
			if (resp?.data) {
				const { stripe_customer_id, stripe_last_4 } =
					resp.data
				setActiveProject({
					...activeProject,
					stripe_customer_id: stripe_customer_id,
					stripe_last_4: stripe_last_4,
				})
			}
			hideLoading()
			return resp?.data
		} catch (e) {
			hideLoading()
			showAlertError(
				'There was an error adding your stripe credit card'
			)
		}
	}

	const removePayment = async () => {
		try {
			showLoading()
			const resp = await api.post(
				`/api/v1/projects/${projectId}/payments/remove_payment`
			)
			if (resp?.data) {
				const { stripe_customer_id, stripe_last_4 } =
					resp.data
				setActiveProject({
					...activeProject,
					stripe_customer_id: stripe_customer_id,
					stripe_last_4: stripe_last_4,
				})
			}
			hideLoading()
			return resp?.data
		} catch (e) {
			hideLoading()
			showAlertError('There was an error removing payment')
		}
	}

	const subscribe = async (stripePlan) => {
		try {
			showLoading()
			const resp = await api.post(`/api/v1/projects/${projectId}/payments/subscribe`, {
        payment: {
          stripe_plan: stripePlan
        }
      })
			if (resp?.data) {
				const { paid } = resp.data
				setActiveProject({
					...activeProject,
					paid: paid,
				})
			}
			hideLoading()
			return resp?.data
		} catch (e) {
			hideLoading()
			showAlertError('There was an error subscribing')
		}
	}

	const unsubscribe = async (stripeToken) => {
		try {
			showLoading()
			const resp = await api.post(
				`/api/v1/projects/${projectId}/payments/unsubscribe`
			)
			if (resp?.data) {
				const { paid } = resp.data
				setActiveProject({
					...activeProject,
					paid: paid,
				})
			}
			hideLoading()
			return resp?.data
		} catch (e) {
			hideLoading()
			showAlertError('There was an error unsubscribing')
		}
	}

	return {
		isLoading,
		payment,
		addPayment,
		removePayment,
		subscribe,
		unsubscribe,
		handleChange,
		setPayment,
	}
}

export default usePayments
