import React, { useState, useContext } from 'react'
import { ApiContext } from 'context'
import { useAlerts, useLoaders, useResource } from 'hooks'

const useViews = ({ id, projectId, ...props }) => {
  
  const { api } = useContext(ApiContext)
	const { showLoading, hideLoading } = useLoaders()

	const {
		resourceId: viewId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: view,
		resources: views,
		findOne: findView,
		findMany: findViews,
		update: updateView,
		create: createView,
		save: saveView,
		destroy: deleteView,
		handleChange,
		handleChangePage,
		reload: reloadView,
		reloadMany: reloadViews,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		setResource: setView,
		setResources: setViews,
	} = useResource({
		id: id,
		url: `/api/v1/projects/${projectId}/views`,
		name: 'view',
	})

	const updateViewPositions = (views) => {		
		try {
      showLoading()
			api.post(`/api/v1/projects/${projectId}/views/update_positions`, {
					ids: views.map((view) => view.id),
					positions: views.map((view) => view.position),
				}
			)			
      return views 
		} catch (e) {
			console.log(e)
		} finally {
      hideLoading()
    }	
	}

  const listUsers = async (viewId) => {		
		try {
      showLoading()
			const resp = await api.get(`/api/v1/projects/${projectId}/views/${viewId}/list_users`)			
      return resp?.data
		} catch (e) {
			console.log(e)
		} finally {
      hideLoading()
    }	
	}

  const addUser = async (viewId, userId) => {		
		try {
      showLoading()
			const resp = await api.post(`/api/v1/projects/${projectId}/views/${viewId}/add_user`, {
        user: {
          id: userId
        }
      })			
      return resp?.data
		} catch (e) {
			console.log(e)
		} finally {
      hideLoading()
    }	
	}

  const removeUser = async (viewId, userId) => {		
		try {
      showLoading()
			const resp = await api.post(`/api/v1/projects/${projectId}/views/${viewId}/remove_user`, {
        user: {
          id: userId
        }
      })			
      return resp?.data
		} catch (e) {
			console.log(e)
		} finally {
      hideLoading()
    }	
	}


	return {
		viewId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		view,
		views,
		findView,
		findViews,
		saveView,
		updateView,
		createView,
		deleteView,
		listUsers,
    addUser,
    removeUser,
		handleChange,
		handleChangePage,
    updateViewPositions,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		reloadView,
		reloadViews,
		sortKey,
		sortDirection,
		handleSort,
		setView,
		setViews,
	}
}

export default useViews
