import React, {
	useContext,
	useEffect,
	useState,
} from 'react'
import { ProjectContext } from 'context'
import { findAddon } from 'lib/helpers/utils'

const useActiveAddon = ({ provider }) => {
	const [addon, setAddon] = useState()
	const [installed, setInstalled] = useState(false)

	const { activeProject } = useContext(ProjectContext)

	useEffect(() => {
		if (provider && activeProject?.id) {
			let installedAddon = findAddon(
				activeProject,
				provider
			)
			setAddon(installedAddon)
			setInstalled(installedAddon ? true : false)
		}
	}, [activeProject?.id, provider])

	return {
		addon,
		setAddon,
		installed,
		setInstalled,
	}
}

export default useActiveAddon
