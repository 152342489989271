import React, {
	useState,
	useEffect,
	useContext,
} from 'react'
import PropTypes from 'prop-types'
import { useAlerts, useCloudinary } from 'hooks'
import { ProjectContext } from 'context'
import { Box } from '@mui/material'
import CloudinaryList from 'containers/apps/cloudinary/CloudinaryList'
import { ProgressLoader } from 'components'
import { findAddon } from 'lib/helpers/utils'

const CloudinaryBrowser = ({
	open,
	handleClick,
	...props
}) => {
	const { showAlertSuccess } = useAlerts()


	const { activeProject } = useContext(ProjectContext)
	const [resourceType, setResourceType] = useState('image')

	const {
		isLoading,
		resources,
		findResources,
		deleteResource,
		reloadResources,
		loadMoreResources,
		nextCursor,
	} = useCloudinary({ projectId: activeProject?.id })

	const handleDeleteClick = async (item) => {
		await deleteResource(
			item?.public_id,
			item?.resource_type
		)
		reloadResources()
		showAlertSuccess('Asset successfully deleted.')
	}

	const handleResourceTypeChange = (type) => {
		setResourceType(type)
	}

	useEffect(() => {
		if (activeProject?.id) {
			findResources({
				resource_type: resourceType,
			})
		}
	}, [activeProject?.id, resourceType])

	const installed = findAddon(activeProject, 'cloudinary')
		? true
		: false

	return (
		<Box sx={ sx.root }>
			{resources && installed ? (
				<CloudinaryList
					isLoading={isLoading}
					open={open}
					project={activeProject}
					resources={resources}
					findResources={findResources}
					cloudName={activeProject.cloudinary_cloud_name}
					handleDeleteClick={handleDeleteClick}
					handleClick={handleClick}
					reloadResources={reloadResources}
					loadMoreResources={loadMoreResources}
					resourceType={resourceType}
					handleResourceTypeChange={
						handleResourceTypeChange
					}
					nextCursor={nextCursor}
				/>
			) : (
				<Box sx={ sx.loader }>
					<ProgressLoader size={300} />
				</Box>
			)}
		</Box>
	)
}

CloudinaryBrowser.propTypes = {
	className: PropTypes.string,
}

export default CloudinaryBrowser

const sx = {
	root: {},
	iconButton: {
		color: 'primary.main'
	},
	loader: {
		height: 500,
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}
