import React from 'react'
import PropTypes from 'prop-types'
import { useMenu } from 'hooks'
import {
	IconButton,
	Menu,
	MenuItem,
	Typography,
	ListItem,
  ListItemIcon,
	ListItemText,
	ListItemSecondaryAction,
} from '@mui/material'
import { DragIndicator, MoreHoriz } from '@mui/icons-material'
import { Trash, Edit } from 'react-feather'
import { truncate } from 'lib/helpers/utils'

const SubmenuItem = ({
  icon: Icon,
	value,
	label,
	selected,
	showMenu,
	handleClick,
	handleEditClick,
  handleDeleteClick,
  draggable=false,
  showDragIcon=false,
	...props
}) => {

  const { 
    open, 
    anchorEl, 
    closeMenu, 
    toggleMenu 
  } = useMenu()

	const renderMenu = () => (
		<Menu
			open={open}
			onClose={toggleMenu}
			anchorEl={anchorEl}
		>
      { handleEditClick && (
			<MenuItem
				onClick={() => {
					handleEditClick()
					closeMenu()
				}}
			>
        <ListItemIcon>
          <Edit size={18} sx={ sx.menuIcon  } />
        </ListItemIcon>
        <ListItemText primary="Edit" />				  
			</MenuItem>
      )}
      { handleDeleteClick && (
			<MenuItem
				onClick={() => {
					handleDeleteClick()
					closeMenu()
				}}
			>
				<ListItemIcon>
          <Trash size={18} sx={ sx.menuIcon  } />
        </ListItemIcon>
        <ListItemText primary="Delete" />
			</MenuItem>
      )}
		</Menu>
	)

	return (
		<ListItem
      disableRipple
			button={ !draggable }
			onClick={ handleClick}
      sx={{ 
          ...sx.listItem, 
          ...(selected == value && sx.activeItem) 
        }}
		>           
      { showDragIcon && (
        <ListItemIcon>      
          <DragIndicator sx={ sx.dragIcon  } />        
        </ListItemIcon>
      )} 
      { Icon && (
        <ListItemIcon>
          <Icon 
            size={18} 
            sx={{
              ...sx.icon,
              ...(selected == value && sx.activeIcon)  
            }} 
          />
        </ListItemIcon>
      )}
			<ListItemText
				primary={
					<Typography
						color="textPrimary"
						variant="body2"
            sx={{ 
                ...sx.text, 
                ...(selected == value && sx.activeText) 
              }}
					>
						{truncate(label, 24)}
					</Typography>
				}
			/>
			{showMenu && (
				<ListItemSecondaryAction>
					<IconButton size="small" onClick={toggleMenu}>
						<MoreHoriz sx={ sx.icon  } />
					</IconButton>
					{ renderMenu() }
				</ListItemSecondaryAction>
			)}      
		</ListItem>
	)
}

SubmenuItem.propTypes = {
	className: PropTypes.string,
	collections: PropTypes.array,
	activeCollection: PropTypes.object,
	handleClick: PropTypes.func,
}

export default SubmenuItem

const sx = {
	listItem: {
    overflowX: 'hidden',
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: 'background.dark',		
		'&:hover': {
      backgroundColor: 'primary.dark',
			'& $text': {
				color: 'common.white',
			},
		},
	},
	text: {
    color: 'common.white',
  },
	activeItem: {
    backgroundColor: 'primary.main',
		color: 'common.white',
	},
	activeText: {
		fontWeight: 500,
		color: 'common.white',
	},
  icon: {
    color: 'common.white',
    opacity: 0.8,
    height: 20,
    width: 20
  },
  activeIcon: {
    color: 'common.white'
  },
  dragIcon: {
    color: 'secondary.main'
  },
  menuIcon: {
    color: 'icon'
  }
}
