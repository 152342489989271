import React from 'react'
import { Drawer } from 'components'
import Auth0Form from 'containers/apps/auth0/Auth0Form'
import { Box, Button } from '@mui/material'


const Auth0Modal = ({
	open,
	user,
	handleChange,
	handleSubmit,
	handleClose,
}) => {


	return (
		<Drawer
			open={open}
			handleClose={handleClose}
			title={user?.id ? 'Edit User' : 'Add User'}
		>
			<Auth0Form user={user} handleChange={handleChange} />
			<Box sx={ sx.actions }>
				<Button
					fullWidth
					onClick={handleSubmit}
					variant="contained"
					color="primary"
				>
					{user?.id ? 'Update User' : 'Save User'}
				</Button>
			</Box>
		</Drawer>
	)
}

export default Auth0Modal

const sx = {
	root: {},
	actions: {
				my: 2,
    mx: 0,
	},
}
