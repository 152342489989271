import React, {
	useEffect,
	useContext,
} from 'react'
import { AppContext, ProjectContext } from 'context'
import {
	useActiveProject,
	useTabs,   
} from 'hooks'
import {
	CenterPanel,
	LeftPanel,
	PanelLayout,
} from 'components'
import { Box } from '@mui/material'
import ViewLeftPanel from 'containers/main/views/ViewLeftPanel'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

const ViewLayout = ({ 
  projectId,
  children,
  ...props 
  }) => {
	useTabs('views')

	const { 
    setOpenMobile, 
    showSideMenu
  } = useContext(AppContext)

	const history = useHistory()


  const {activeView, setActiveView} = useContext(ProjectContext)
	const { activeProject } = useActiveProject(projectId)

	useEffect(() => {
		if (!projectId && activeProject) {
			history.push(`/projects/${activeProject.id}/views`)
		}
	}, [projectId, activeProject])

	useEffect(() => {
		if (activeView?.id) {
			setOpenMobile(true)
		}
	}, [activeView?.id])

  // Switching active projects should reset active view 
  useEffect(() => {
    setOpenMobile(false)
		setActiveView()
	}, [projectId])

	return (
		<Box sx={ sx.root }>
			<PanelLayout>
				{showSideMenu && (
					<LeftPanel>						
            <ViewLeftPanel
              projectId={ projectId }
            />
					</LeftPanel>
				)}
				<CenterPanel>
          { children }
				</CenterPanel>
			</PanelLayout>
		</Box>
	)
}

ViewLayout.propTypes = {
	className: PropTypes.string,
}

export default ViewLayout

const sx = {
	root: {},
	iconButton: {
		color: 'primary.main'
	},
	icon: {
		color: 'secondary.main'
	},
}
