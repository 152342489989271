import React, { useState } from 'react'
import { Button, Grid } from '@mui/material'
import { AuthLayout, AuthScreen, TextInput } from 'components'
import { useAlerts, useAuth } from 'hooks'
import { validatePassword } from 'validations/users'

const ResetPassword = ({ ...props }) => {
	const { token: resetToken } = props.match.params

	const [user, setUser] = useState({
		password: '',
		password_confirmation: '',
	})

	const { showAlertError } = useAlerts()

	const { resetPassword } = useAuth()

	const handleSubmit = async () => {
		let valid = validatePassword(user)
		if (valid.isValid) {
			let resp = await resetPassword({
				password: user.password,
				passwordConfirmation: user.password_confirmation,
				resetToken: resetToken,
			})
			if (resp?.id) {
				window.location.href = '/projects'
			} else {
				showAlertError('The reset password token is no longer valid.')
			}
		} else {
			valid.messages.map((m) => showAlertError(m))
		}
	}

	const handleChange = ({ target }) => {
		const { name, value } = target
		setUser({
			...user,
			[name]: value,
		})
	}

	return (
    <AuthLayout>
		<AuthScreen
			title="Reset Password"
			subtitle="Forgot password reset."
		>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<TextInput
						fullWidth
						value={user.password}
						name="password"
						handleChange={handleChange}
						type="password"
						placeholder="Password"
					/>
				</Grid>
				<Grid item xs={12}>
					<TextInput
						fullWidth
						value={user.password_confirmation}
						name="password_confirmation"
						handleChange={handleChange}
						type="password"
						placeholder="Confirm password"
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						fullWidth
						size="large"
						onClick={handleSubmit}
						variant="contained"
						color="primary"
					>
						Reset your password
					</Button>
				</Grid>
			</Grid>
		</AuthScreen>
    </AuthLayout>
	)
}

export default ResetPassword
