import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from 'context'
import { AdminlyProvider } from 'context'
import { useActiveProject } from 'hooks'
import ViewLayout from 'containers/main/views/ViewLayout'
import AdminlyEditDetails from 'containers/main/adminly/AdminlyEditDetails'
import { useHistory, useLocation  } from 'react-router-dom'
import queryString from 'query-string'

const AdminlyEdit = ({ ...props }) => {
	
  const location = useLocation()
  const [resourceIds, setResourceIds] = useState()

	const { 
    id: projectId, 
    collection_id: collectionId, 
    resource_id: resourceId 
  } = props.match.params
  
  const { setOpenMobile } = useContext(AppContext)
  const { activeProject } = useActiveProject()

  useEffect(() => {
    setOpenMobile(true)
  }, [resourceId])

  useEffect(() => {
    if(location?.search){
      setResourceIds(queryString.parse(location.search)?.ids?.split(','))
    }
  }, [location])
	
	return (
    <ViewLayout projectId={ projectId }>
      { activeProject && (
				<AdminlyProvider
					token={activeProject?.jwt_token}
					url={activeProject?.api_url}
				>		
          <AdminlyEditDetails 
            collectionId={ collectionId } 
            projectId={ projectId }
            resourceId={ resourceId }
            resourceIds={ resourceIds }
          />
        </AdminlyProvider>
      )}
    </ViewLayout>
	)
}

AdminlyEdit.propTypes = {}

export default AdminlyEdit
