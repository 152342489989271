import React from "react";
import { Button, Container } from "@mui/material";
import { ArrowRightAlt } from "@mui/icons-material";
import { LeftImageText, Section } from 'components'
import Image from "assets/images/adminly-media.png";

export default function SectionOne({ handleClick, ...props}) {  

  return (
  <Section bgColor='common.section' styles={ sx.section }>
    <Container maxWidth="lg">
      <LeftImageText 
        image={ Image }
        caption={'Media Uploads'}
        title={'Image and video uploads.'}
        description={
          'Connect Adminly to 3rd party apps from our marketplace to begin uploading \
            image, audio, and video to your Cloudinary or Amazon S3 account.'
        }
        actions={
          <Button
            size="large"
            color="secondary"
            variant="contained"
            sx={ sx.button }
            onClick={handleClick}
            endIcon={<ArrowRightAlt />}
          >
            Learn More
          </Button>
        }
      />
    </Container>
  </Section>
  );
}

const sx = {
  root: {},
};
