import React from 'react'
import { Box } from '@mui/material'
import {
  FieldArray,
	FieldBoolean,
	FieldDate,
	FieldImage,
	FieldJSON,
	FieldLink,
	FieldText,
  FieldParagraph,
	FieldRating,
	FieldReference,
  FieldVideo
} from 'components'

const Field = ({
  handleClick,
  field,
  resource,
  ...params
}) => {

  const value = resource[field?.db_name] || '-'
  
  const {
    name,
    array: isArray,
    friendly_type,
    foreign_db_table,
    foriegn_db_field
  } = field
  
  let displayValue
  if(friendly_type === 'Reference') {   
    if(value && resource[foreign_db_table]){
      displayValue = resource[foreign_db_table][foriegn_db_field]      
    }      
  }

	const RENDER_TYPES = [
		'boolean',
		'date',
		'datetime',
		'rating',
		'reference',
		'image',
		'json',
		'link',
		'paragraph',
    'text',
    'video'
	]



	if ((!value || value === 'null') && friendly_type != 'Boolean'){
    return <Box></Box>
  }

	return (
		<Box sx={ sx.root  }>
			{friendly_type === 'boolean' && (
				<FieldBoolean value={value} />
			)}

			{friendly_type === 'date' && (
				<FieldDate value={value} />
			)}

			{friendly_type === 'datetime' && (
				<FieldDate value={value} />
			)}

			{friendly_type === 'reference' && (
				<FieldReference
					value={value}
					headerName={name}
          displayValue={displayValue}  
          handleClick={ handleClick }        
				/>
			)}

			{friendly_type === 'image' && (
				<FieldImage 
          value={value} 
          handleClick={ handleClick }
        />
			)}

      {friendly_type === 'video' && (
				<FieldVideo 
          value={value} 
          handleClick={ handleClick }
        />
			)}

			{friendly_type === 'json' && (
				<FieldJSON value={value} />
			)}

			{friendly_type === 'link' && (
				<FieldLink
					value={value}
					handleClick={handleClick}
				/>
			)}

			{friendly_type === 'rating' && (
				<FieldRating value={value} />
			)}

			{friendly_type === 'paragraph' && (
				<FieldParagraph
					value={value}
				/>
			)}

      { !isArray ? (
        <>
          {friendly_type === 'text' && (
            <FieldText
              value={value}
              />
          )}
      
          {!RENDER_TYPES.includes(friendly_type) && (
            <FieldText
              value={value}
              />
          )}
        </>
      ):(
        <FieldArray
          value={value}
        />
      )}
		</Box>
	)
}

export default Field

const sx = {
	root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
}
