export const sx = {
  container: {
  },
	inputBase: {
		p: 0,
		width: '100%',
		'& input, & .MuiInputBase-inputMultiline': {
			'WebkitAppearance': 'none',
			'MozAppearance': 'none',
			appearance: 'none',
			p: 1,
			height: '26px',
			borderRadius: theme => theme.shape.borderRadius,
			fontSize: theme => theme.typography.body3.fontSize,
			fontFamily: theme => theme.typography.body3.fontFamily,
			backgroundColor: 'common.white',
      m: '1px',
      transition: theme => theme.transitions.create([
				'border-color',
				'box-shadow',
			]),
      border: theme => `1px solid ${theme.palette.common.inputBorder}`,
      boxShadow: `0 1px 3px 0 rgb(120 120 175 / 20%)`,  
			'&:focus': {
        m: '0px',
        boxShadow: `none`,        
        border: theme => `2px solid ${theme.palette.primary.light}`
			},
		},
	},
  inputError: {
    border: theme => `2px solid ${theme.palette.common.errorBorder}`      
  },
  inputExpand: {
    '& input': {
      width: { 
        xs: 220, 
        sm: 280 
      },                  
      transition: '0.2s',
      '&:focus':{
        width: {
          xs: 220,
          sm: 320
        }
      },        
    },    
  },
	paper: {
		backgroundColor: 'common.white',
		mt: 1,
		color: '#586069',
		fontSize: 15,
	},
	option: {
		minHeight: 'auto',
		alignItems: 'flex-start',
		p: 8,
		'&[aria-selected="true"]': {
			backgroundColor: 'transparent',
		},
		'&[data-focus="true"]': {
			backgroundColor: 'action.hover'
		},
	},
	popperDisablePortal: {
		position: 'relative',
	},
	label: {
		mb: 0,
	},
}
