import React, { useEffect } from 'react'
import { useAuth } from 'hooks'
import { Alert, Loader } from 'components'
import { CssBaseline } from '@mui/material'
import {
	createTheme,
	ThemeProvider,
} from '@mui/material/styles'
import Routes from 'routes'
import { muiTheme } from 'theme'

const theme = createTheme(muiTheme)

const App = (props) => {

	const { authenticateFromToken } = useAuth()  

	useEffect(() => {
		const token = localStorage.getItem('token')
		if (token) authenticateFromToken(token)
	}, [authenticateFromToken])

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Alert />			
			<Routes {...props} />			
			<Loader />
		</ThemeProvider>
	)
}

export default App
