import React from 'react'
import { 
  Avatar, 
  Typography 
} from '@mui/material'
import { getInitials } from 'lib/helpers/adminly'
import { alpha } from '@mui/material'
import { COLORS } from 'lib/constants'

const ProjectIcon = ({
  project,
  size=28,
  textVariant='caption',
  ...props
}) => {

	return (
    project ? 
    <Avatar
      variant={'rounded'}
      sx={{
        height: size,
        width: size,
        color: COLORS[project.color],
        backgroundColor: alpha(COLORS[project.color], 0.2),
      }}
    >
      <Typography variant={textVariant}>
        { getInitials(project?.name) }
      </Typography>
    </Avatar> : null 
	)
}

export default ProjectIcon

const sx = {
	root: {}
}
