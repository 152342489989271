import React from 'react'
import {
	ButtonLoader,
	ProgressLoader,
	Modal,
} from 'components'
import { Box, Button } from '@mui/material'
import ViewForm from 'containers/main/views/ViewForm'
import { useEnterKey } from 'hooks'

const ViewModal = ({
	isLoading,
	open,
	view,
	handleClose,
	handleChange,
	handleSubmit,
}) => {


  useEnterKey(open && handleSubmit)

	return (
		<Modal
			open={open}
			handleClose={handleClose}
			title={'Save view'}
			actions={
				<Button
					color="primary"
					variant="contained"
					onClick={handleSubmit}
					startIcon={isLoading && <ButtonLoader />}
				>
					Save
				</Button>
			}
		>
			<Box sx={ sx.content }>
				{isLoading ? (
					<ProgressLoader />
				) : (
					<ViewForm
						view={view}
						handleChange={handleChange}
					/>
				)}
			</Box>
		</Modal>
	)
}

export default ViewModal

const sx = {
	content: {
		p: 2,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}
