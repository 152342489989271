import React, { useState } from 'react'
import { useAlerts, useLoaders, useResource } from 'hooks'

const useCollections = ({ id, projectId, ...props }) => {
	const { showLoading, hideLoading } = useLoaders()
	const { showAlertError, showAlertSuccess } = useAlerts()

	const [extendedSchema, setExtendedSchema] = useState()

	const {
		resourceId: collectionId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource: collection,
		resources: collections,
		findOne: findCollection,
		findMany: findCollections,
		update: updateCollection,
		create: createCollection,
		save: saveCollection,
		destroy: deleteCollection,
		handleChange,
		handleChangePage,
		uploadFile,
		deleteFile,
		reload: reloadCollection,
		reloadMany: reloadCollections,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		sortKey,
		sortDirection,
		handleSort,
		setResource: setCollection,
		setResources: setCollections,
	} = useResource({
		id: id,
		url: `/api/v1/projects/${projectId}/collections`,
		name: 'collection',
	})

	const getExtendedSchema = async (collectionId) => {
		showLoading()
		hideLoading()
	}

	return {
		collectionId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		collection,
		collections,
		findCollection,
		findCollections,
		saveCollection,
		updateCollection,
		createCollection,
		deleteCollection,
		uploadFile,
		deleteFile,
		handleChange,
		handleChangePage,
		query,
		page,
		numPages,
		perPage,
		totalCount,
		reloadCollection,
		reloadCollections,
		extendedSchema,
		getExtendedSchema,
		sortKey,
		sortDirection,
		handleSort,
		setCollection,
		setCollections,
	}
}

export default useCollections
