import React from 'react'
import {
	Button,
	Card,
  CardMedia,
	CardActions,
  CardActionArea,
	CardContent,
	IconButton,
	Menu,
	MenuItem,
  ListItemIcon,
  ListItemText,
	Typography,
} from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'
import { useMenu } from 'hooks'
import { Settings, Trash } from 'react-feather'
import { Label } from 'components'
import ProjectIcon from 'containers/main/projects/ProjectIcon'

const ProjectItem = ({
	project,
	handleClick,
	handleEditClick,
	handleDeleteClick,
	...props
}) => {

	const { 
    open, 
    anchorEl, 
    toggleMenu, 
    handleClose 
  } = useMenu()

	const renderMenu = () => (
		<Menu
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
		>
			<MenuItem
				onClick={() => handleEditClick(project)}
			>
        <ListItemIcon>
          <Settings size={16} />
        </ListItemIcon>
        <ListItemText primary="Settings" />				
			</MenuItem>
			<MenuItem
				onClick={() => handleDeleteClick(project)}
			>
        <ListItemIcon>
          <Trash size={16} />
        </ListItemIcon>
        <ListItemText primary="Delete" />								
			</MenuItem>
		</Menu>
	)

	return (
		<Card elevation={0} sx={ sx.root }>
      <CardActionArea
        onClick={() => handleClick(project)}
      >
        <CardMedia 
          component='div'
          sx={ sx.media }
        >
          <ProjectIcon 
            size={48}
            textVariant={'h4'}
            project={ project } 
          />
          <Typography sx={ sx.title  } variant="subtitle2">
            {project.name} 
          </Typography>
          <Label label={ project.status.replace('_',' ') } />
        </CardMedia>
      </CardActionArea>
			<CardActions sx={ sx.cardActions  }>
				<Button
          variant='outlined'
					size="small"
					onClick={() => handleClick(project)}
					color="primary"
				>
					SELECT
				</Button>
        { project.current_role === 'admin' && (
          <IconButton
            onClick={toggleMenu}
            size="small"
            color="primary"
          >
            <MoreHoriz />
            {renderMenu()}
          </IconButton>
        )}
			</CardActions>
		</Card>
	)
}

export default ProjectItem

const sx = {
	root: {
		width: '100%',
    borderRadius: theme => theme.shape.borderRadius,
		backgroundColor: 'common.white',
		boxShadow: `0 0 0 1px rgb(35 38 59 / 5%), 0 1px 3px 0 rgb(35 38 59 / 15%)`,
	},
	bullet: {
		display: 'inline-block',
		m: '0 2px',
		transform: 'scale(0.8)',
	},
	cardActions: {
		display: 'flex',
		justifyContent: 'space-between',
	},
  media: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'background.default',
    height: 170,
    width: '100%'
  },
  title: {
    color: 'text.primary',
    mt: 1
  },
}
