import React, { useState, useEffect } from 'react'
import {
	FormControl,
	Popper,
	Typography,
	Autocomplete,
	Chip,
	TextField,
} from '@mui/material'

import { X } from 'react-feather'

const CustomPopper = function (props) {

	return (
		<Popper
			{...props}
			sx={ sx.popper }
			placement="bottom"
		/>
	)
}

const ArrayInput = ({
	label,
	name,
	value,
	placeholder,
	handleChange,
}) => {


	const handleInputChange = (ev, values) => {
		let newValues = values.filter((value) => value != null)
		handleChange({
			target: {
				name,
				value: newValues,
			},
		})
	}


	return (
		<FormControl fullWidth>
			<Typography variant="caption" color="textSecondary">
				{label}
			</Typography>
			<Autocomplete
				multiple
				freeSolo
				hasClearIcon={false}
				defaultValue={Array.isArray(value) && value || undefined }
				onChange={handleInputChange}
				options={['Enter value']}
				getOptionLabel={(option) => option}
				PopperComponent={CustomPopper}
				clearIcon={<X sx={ sx.icon} /> }
				renderTags={(tagValue, getTagProps) =>
					Array.isArray(tagValue) && 
          tagValue.map((option, index) => (
						<Chip
							sx={ sx.chip }
							label={option}
							color="primary"
							variant="outlined"
							deleteIcon={<X sx={ sx.icon} /> }
							{...getTagProps({ index })}
						/>
					))
				}
				renderInput={(params) => (
					<TextField
						{...params}
						color="secondary"
						sx={ sx.textField }
            placeholder={placeholder}
						margin="dense"
						variant="outlined"
					/>
				)}
			/>
		</FormControl>
	)
}

export default ArrayInput

export const sx = {
	root: {},
	textField: {
		'& .MuiOutlinedInput-root': {
			p: '4px',
			fontSize: theme => theme.typography.body3.fontSize,
			fontFamily: theme => theme.typography.body3.fontFamily,
			borderRadius: 4,
			backgroundColor: 'common.white',
			border: theme => `1px solid ${theme.palette.common.inputBorder}`,
			transition: '0.5s',
			boxShadow: `rgb(0 0 0 / 5%) 0px 2px 4px !important`,
			width: '100%',
			'& fieldset': {
				border: `1px solid transparent`,
			},
			'&:hover fieldset': {
				border: `1px solid transparent`,
			},
			'&.Mui-focused fieldset': {
				border: theme => `0px solid ${theme.palette.primary.light}`,
			},
		},
		root: {
			height: 26,
		},
	},
	icon: {
		height: 20,
		width: 20,
		color: '#888',
	},
	popper: {
		fontWeight: theme => theme.typography.body3.fontWeight,
		fontFamily: theme => theme.typography.body3.fontFamily,
	},
}
