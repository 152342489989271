import React, { useState } from 'react'
import {
	FormControl,
	InputBase,
	Typography,
} from '@mui/material'
import { sx } from './sx'

const TextInput = ({
	required,
	label,
	type,
	name,
	margin,
	value,
	multiline,
	handleChange,
	rows,
	placeholder,
	disabled,
	onKeyPress,
	autoComplete = 'off',
	startIcon,
	endIcon,
	isClearable,
	isLoading,
	error,
  styles={},
	...props
}) => {

	return (
		<FormControl
			fullWidth
			sx={sx.root}
		>
			<Typography variant="caption" color="textSecondary">
				{label}
			</Typography>
			<InputBase
				rows={rows}
				sx={{ 
          ...sx.inputBase,           
          ...(error && sx.inputError),
          ...styles 
        }}
				multiline={multiline}
				autoComplete="off"
				fullWidth
				type={type}
				name={name}
				margin={margin}
				disabled={disabled}
				placeholder={placeholder}
				onKeyPress={onKeyPress}
				variant="outlined"
				onChange={handleChange}
				value={value || ''}
			/>
		</FormControl>
	)
}

export default TextInput
