import { UploadCloud, Database } from 'react-feather'
import Auth0Icon from 'assets/icons/auth0.svg'

export const ADMINLY_RAILS_REPO = "https://www.github.com/aminly-co/adminly-rails"

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID

export const HEROKU_AUTH_URL = process.env.REACT_APP_HEROKU_AUTH_URL
export const HEROKU_CLIENT_ID = process.env.REACT_APP_HEROKU_CLIENT_ID
export const HEROKU_SCOPES = 'global'

export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
export const MUI_GRID_PRO_LICENSE = process.env.REACT_APP_MUI_GRID_PRO_LICENSE

export const PROJECT_STEPS = [
  {value: 0, label: 'Start', path: '/step1' },
  {value: 1, label: 'Hosting', path: '/step2' },
  {value: 2, label: 'Plan', path: '/step3' },
  {value: 3, label: 'Deploy', path: '/step4' },
  {value: 4, label: 'Invite', path: '/step5' }
]

export const USER_ROLES = [
	{ value: 'admin', label: 'Admin' },
	{ value: 'member', label: 'Member' },
]

export const COLUMN_TYPES = [
	{
		value: 'boolean',
		db_type: 'boolean',
		label: 'Switch',
    field_type: 'column',
		description: 'Values true or false',
    association: false,
    require_menu: false,
	},
	{
		value: 'date',
		db_type: 'date',
		label: 'Date',
    field_type: 'column',
		description: 'Date',
    association: false,
    require_menu: false
	},
	{
		value: 'datetime',
		db_type: 'datetime',
		label: 'DateTime',
    field_type: 'column',
		description: 'Date and time',
    association: false,
    require_menu: false
	},
  {
		value: 'enumerable',
		label: 'Enumerable',
		db_type: 'enum',
    field_type: 'column',
		description: 'Select menu',
    association: false,
    require_menu: true
	},
  {
		value: 'enumerable',
		label: 'Enumerable',
		db_type: 'string',
    field_type: 'column',
		description: 'Select menu',
    association: false,
    require_menu: true
	},
  {
		value: 'enumerable',
		label: 'Enumerable',
		db_type: 'text',
    field_type: 'column',
		description: 'Select menu',
    association: false,
    require_menu: true
	},
	{
		value: 'float',
		db_type: 'float',
		label: 'Float',
    field_type: 'column',
		description: 'Fractional numbers',
    association: false,
    require_menu: false
	},
	{
		value: 'json',
		db_type: 'jsonb',
		label: 'JSON',
    field_type: 'column',
		description: 'JSON field',
    association: false,
    require_menu: false
	},
	{
		value: 'image',
		db_type: 'string',
		label: 'Image',
    field_type: 'column',
		description: 'Image file uploader',
    association: false,
    require_menu: false
	},
	{
		value: 'image',
		db_type: 'text',
		label: 'Image',
    field_type: 'column',
		description: 'Image file uploader',
    association: false,
    require_menu: false
	},
	{
		value: 'link',
		db_type: 'string',
		label: 'Link',
    field_type: 'column',
		description: 'Website URL',
    association: false,
    require_menu: false
	},
  {
		value: 'link',
		db_type: 'text',
		label: 'Link',
    field_type: 'column',
		description: 'Website URL',
    association: false,
    require_menu: false
	},
	{
		value: 'number',
		db_type: 'integer',
		label: 'Number',
    field_type: 'column',
		description: 'Integer values.',
    association: false
	},
	{
		value: 'rating',
		db_type: 'integer',
		label: 'Rating',
    field_type: 'column',
		description: 'Five-star ratings',
    association: false,
    require_menu: false
	},
	{
		value: 'reference',
		label: 'Reference',
    field_type: 'column',
		db_type: 'integer',
		description: 'Foreign keys',
    association: true,
    require_menu: true
	},
	{
		value: 'text',
		db_type: 'string',
		label: 'Title',
    field_type: 'column',
		description: 'Titles and names',
    association: false,
    require_menu: false
	},
  {
		value: 'video',
		db_type: 'string',
		label: 'Video',
    field_type: 'column',
		description: 'Video file uploader',
    association: false,
    require_menu: false
	},
	{
		value: 'video',
    label: 'Video',
		db_type: 'text',		
    field_type: 'column',
		description: 'Video file uploader',
    association: false,
    require_menu: false
	},
	{
		value: 'paragraph',
		db_type: 'text',
    field_type: 'column',
		label: 'Text',
		description: 'Paragraphs of text',
    association: false,
    require_menu: false
	},
  {
		value: 'html',
		db_type: 'text',
    field_type: 'column',
		label: 'HTML',
		description: 'HTML editor',
    association: false,
    require_menu: false
	}
]

export const REFERENCE_TYPES = [
	{
    value: 'has_many',
		label: 'Has many',
    field_type: 'reference',
		description: 'Has many association',
    association: true,
    require_menu: true
	},
  {    
    value: 'habtm',		
		label: 'Has and belongs to many',
    field_type: 'reference',    
		description: 'Has and belongs to many association',
    association: true,
    require_menu: true
	}
]

export const FIELD_TYPES = [
  ...COLUMN_TYPES,
  ...REFERENCE_TYPES
]

// Ignore the field names that are not editable
export const EXCLUDED_INPUT_FIELDS = [
	'id',
	'created_at',
	'updated_at',
]

export const BOOLEAN_FIELDS= ['boolean']
export const STRING_FIELDS= ['string','text']
export const NUMBER_FIELDS = ['integer','float','bigint']
export const DATE_FIELDS = ['date', 'datetime']
export const JSON_FIELDS = ['json','jsonb']

export const NUMBER_AND_STRING_FIELDS = [
  ...NUMBER_FIELDS, 
  ...STRING_FIELDS
]

export const SORT_DIRECTIONS = [
	{ label: 'increasing', value: 'asc' },
	{ label: 'decreasing', value: 'desc' },
]

export const BOOLEAN_OPTIONS = [
  { label: 'true', value: "true" },
  { label: 'false', value: "false" }
]

export const EQUAL_OPERATOR = [
  { label: '=', value: 'eq' }
]

export const TEXT_FILTER_OPERATORS = [
	{ label: '=', value: 'eq' },
	{ label: '!=', value: 'neq' },
  { label: 'like', value: 'like' }
]

export const NUMBER_FILTER_OPERATORS = [
	{ label: '>', value: 'gt' },
	{ label: '>=', value: 'gte' },
	{ label: '=', value: 'eq' },
	{ label: '<', value: 'lt' },
	{ label: '<=', value: 'lte' },
	{ label: '!=', value: 'neq' },
  { label: 'in', value: 'in' }
]

export const DATE_FILTER_OPERATORS = [
	{ label: '>', value: 'gt' },
	{ label: '<', value: 'lt' },
  { label: 'after', value: 'gte' },
  { label: 'before', value: 'lte' },
]

export const BOOLEAN_FILTER_OPERATORS = [
	{ label: '=', value: 'eq' },
	{ label: '!=', value: 'neq' }
]

export const DATE_RANGE_OPTIONS = [
  { label: '1 day ago', value: '1_day_ago' }, 
  { label: '7 days ago', value: '7_days_ago' },
  { label: '14 days ago', value: '14_days_ago' },
  { label: '30 days ago', value: '30_days_ago' },
  { label: '60 days ago', value: '60_days_ago' },
  { label: '90 days ago', value: '90_days_ago' }
]

export const FILTER_OPERATORS = {  
	integer: NUMBER_FILTER_OPERATORS,
	float: NUMBER_FILTER_OPERATORS,
	string: TEXT_FILTER_OPERATORS,
	text: TEXT_FILTER_OPERATORS,
	date: DATE_FILTER_OPERATORS,
	datetime: DATE_FILTER_OPERATORS,
	boolean: BOOLEAN_FILTER_OPERATORS,
}

export const FILTERABLE_TYPES = [	
  'date',
  'datetime',  
  'float',	
	'number',
	'price',
	'rating',
  'reference',
  'enumerable',
  'text',
  'paragraph'
]

export const SORTABLE_TYPES = [
	'text',  
	'id',
	'number',
	'float',
	'price',
	'rating',
	'email',
	'phone',
  'enumerable'
]

export const PROJECT_PLAN_MAX_USERS = {
  starter: 5,
  standard: 20,
  premium: 50
}

export const HEROKU_PG_ADDONS = [
	{
		icon: Database,
		label: 'PostgreSQL - Hobby Dev',
		price: '$0/month',
		value: 'heroku-postgresql:hobby-dev',
	},
	{
		icon: Database,
		label: 'PostgreSQL - Hobby Basic',
		price: '$9/month',
		value: 'heroku-postgresql:hobby-basic',
	},
	{
		icon: Database,
		label: 'PostgreSQL - Standard 0',
		price: '$50/month',
		value: 'heroku-postgresql:standard-0',
	},
	{
		icon: Database,
		label: 'PostgreSQL - Standard 2',
		price: '$200/month',
		value: 'heroku-postgresql:standard-2',
	},
]

export const HEROKU_CLOUDINARY_ADDONS = [
	{
		icon: UploadCloud,
		label: 'Cloudinary - Starter',
		price: 'Free',
		value: 'cloudinary:starter',
	},
]

export const HEROKU_AUTH0_ADDONS = [
	{
		image: Auth0Icon,
		label: 'Auth0 - Free',
		price: 'Free',
		value: 'auth0:free',
	},
	{
		image: Auth0Icon,
		label: 'Auth0 - $23/month',
		price: 'Developer',
		value: 'auth0:developer',
	},
]

export const HEROKU_ADDONS = [
	...HEROKU_AUTH0_ADDONS,
	...HEROKU_CLOUDINARY_ADDONS,
	...HEROKU_PG_ADDONS,
]

export const STRIPE_PLANS = [
	{
		name: 'Free',
		price: 0,
		interval: 'month',
		id: 'free',
	},
	{
		name: 'Standard',
		price: 99.0,
		interval: 'month',
		id: 'standard',
	},
	{
		name: 'Premium',
		price: 299,
		id: 'premium',
	},
]

// Cloudinary supported image formats
export const IMAGE_FORMATS = [  
	'jpg',
  'jpeg',
	'png',
	'gif',	
	'bmp',
	'webp',
  'heic'
]

export const VIDEO_FORMATS = [
	'mp4',
	'webm',
	'ogg',
	'mov',
	'm4v',
]

export const IMAGE_OR_VIDEO_FORMATS = [
	...IMAGE_FORMATS,
	...VIDEO_FORMATS,
]

export const COLORS = {
  "red": "#f44336",
  "pink": "#e91e63",
  "purple": "#9c27b0",
  "deep_purple": "#673ab7",
  "indigo": "#3f51b5",
  "blue": "#2196f3",
  "light_blue": "#03a9f4",
  "cyan": "#00bcd4",
  "teal": "#009688",
  "green": "#4caf50",
  "light_green": "#8bc34a",
  "amber": "#ffc107",
  "orange": "#ff9800",
  "deep_orange": "#ff5722"
  //  "lime": "#cddc39",
  // "yellow": "#ffeb3b",
}



export const HTML_TOOLBAR_CONTROLS = [
  "title", 
  "italic", 
  "bold", 
  "link", 
  "quote",   
  "underline", 
  "strikethrough", 
  "undo", 
  "redo", 
  "clear"
  //"code"  
]


