import {
	isEmail,
	isEmpty,
	isMinLength,
	isWorkEmail,
} from 'validations'

export const validateUser = (user) => {
	let isValid = true
	let messages = []

	if (isEmpty(user.first_name) || isEmpty(user.last_name)) {
		isValid = false
		messages.push('Full name is required')
	}

	if (isEmpty(user.email)) {
		isValid = false
		messages.push('Email is required')
	}

	if (isEmpty(user.password)) {
		isValid = false
		messages.push('Password is required')
	}

	if (!isMinLength(user.email, 4)) {
		isValid = false
		messages.push('Email must be at least 4 characters')
	}

	if (!isMinLength(user.password, 4)) {
		isValid = false
		messages.push('Password must be at least 4 characters')
	}

	if (!isEmail(user.email)) {
		isValid = false
		messages.push('Email is not a valid email')
	}

	let validation = {
		isValid,
		messages,
	}

	return validation
}

export const validatePassword = (user) => {
	let isValid = true
	let messages = []

	if (
		isEmpty(user.password) ||
		isEmpty(user.password_confirmation)
	) {
		isValid = false
		messages.push(
			'Password and password confirmation are required'
		)
	}

	if (!isMinLength(user.password, 4)) {
		isValid = false
		messages.push('Password must be at least 4 characters')
	}

	if (user.password != user.password_confirmation) {
		isValid = false
		messages.push('Passwords do not match')
	}

	let validation = {
		isValid,
		messages,
	}

	return validation
}

export const validateInviteUser = (user) => {
	let isValid = true
	let messages = []

	if (isEmpty(user.first_name) || isEmpty(user.last_name)) {
		isValid = false
		messages.push('Full name is required')
	}

	if (isEmpty(user.email)) {
		isValid = false
		messages.push('Email is required')
	}

	if (!isMinLength(user.email, 4)) {
		isValid = false
		messages.push('Email must be at least 4 characters')
	}

	if (!isEmail(user.email)) {
		isValid = false
		messages.push('Email is not a valid email')
	}

	if (!user.role) {
		isValid = false
		messages.push('Please specify a role with this user.')
	}

	let validation = {
		isValid,
		messages,
	}

	return validation
}
