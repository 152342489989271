import React, { useState, useEffect } from 'react'
import ProjectContext from 'context/ProjectContext'

const ProjectProvider = ({ children }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [isLoaded, setIsLoaded] = useState(false)

	const [menuOpen, setMenuOpen] = useState(false)
	const [mediaOpen, setCloudinaryOpen] = useState(false) //Display the media modal

  const [activeDefaultQuery, setActiveDefaultQuery] = useState({})

  const [activeView, setActiveView] = useState()
  const [activeViews, setActiveViews] = useState()

  const [tabIndex, setTabIndex] = useState()
  const [activeTabs, setActiveTabs] = useState([])

	const [activeCollection, setActiveCollection] = useState()
	const [activeCollections, setActiveCollections] = useState()
  
  const [projects, setProjects] = useState()
	const [activeProject, setActiveProject] = useState()  
	const [activeFields, setActiveFields] = useState()

  useEffect(() => {
    if(activeView?.id){
      setTabIndex()
    }
  }, [activeView?.id])

  useEffect(() => {
    if(activeProject?.id){
      setActiveTabs([])
    }
  }, [activeProject?.id])

	const value = {
		isLoading,
		isLoaded,

		menuOpen,
		setMenuOpen,

		mediaOpen,
		setCloudinaryOpen,

		setIsLoaded,
		setIsLoading,

    projects,
    setProjects,

		activeProject,
		setActiveProject,

		activeCollection,
		setActiveCollection,

		activeCollections,
		setActiveCollections,

    activeDefaultQuery,
    setActiveDefaultQuery,

		activeFields,
		setActiveFields,

    activeView, 
    setActiveView,    

    activeViews, 
    setActiveViews,    

    tabIndex,
    setTabIndex,
    
    activeTabs,
    setActiveTabs
	}

	return (
		<ProjectContext.Provider value={value}>
			{children}
		</ProjectContext.Provider>
	)
}

export default ProjectProvider
