import React, { useState, useContext } from 'react'
import { useAlerts, useLoaders } from 'hooks'
import { ApiContext } from 'context'

const useChatGPT = ({ projectId }) => {

  const { showAlertError } = useAlerts()

  const { 
    isLoading, 
    showLoading, 
    hideLoading 
  } = useLoaders()

  const { api } = useContext(ApiContext)

  const [prompt, setPrompt] = useState()
  const [sql, setSql] = useState()
  const [query, setQuery] = useState()

  const handleChange = (e) => {
    setPrompt(e.target.value)
  }

  const execute = async ({ prompt, tables }) => {
    try {
      showLoading()
      const resp = await api.post(`/api/v1/projects/${projectId}/chat_gpt`, { 
        query: {
          prompt: prompt,
          tables: tables  
        }
      })
      if(resp?.data){
        setQuery(resp.data?.query )
        setSql(resp.data?.sql )
      }
      return resp?.data?.sql 
    }catch(e){
      showAlertError(e)
      console.log(e)
    }finally{
      hideLoading()
    }
  }

  return {
    isLoading,
    prompt,
    setPrompt,
    sql,
    query,
    handleChange,
    execute
  }
}

export default useChatGPT 