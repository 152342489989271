import React, { useState } from 'react'
import { Box } from '@mui/material'
import { SearchInput } from 'components'

const SearchForm = ({
	handleSearch,
	handleChange,
	value,
	isLoading,
	...props
}) => {
	const handleKeyPress = (ev) => {
		if (ev.key === 'Enter') {
			handleSearch(value)
		}
	}

	return (
		<Box p={2} px={2}>
			<SearchInput
				fullWidth
				name="keywords"
				onKeyPress={handleKeyPress}
				value={value}
				handleChange={handleChange}
			/>
		</Box>
	)
}

export default SearchForm
