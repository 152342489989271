import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';


const AdminlyTable = ({ 
    children, 
    disableEditing,
    showSideMenu,
    styles={},
    ...rest 
  }) => {

    return(
      <TableContainer sx={{ 
        ...sx.table,
        ...(showSideMenu && sx.tableSideMenu ),
        ...(disableEditing && sx.tableDisableEditing),
        ...styles               
      }} 
    >
      <Table stickyHeader>
        { children }
      </Table>
    </TableContainer>
    )
  }

  export default AdminlyTable

  const sx = {
    table: {    
      width: {
        sm: 'calc(100vw - 96px)',
        xs: 'auto',
      },
      height: { 
        xs: 'calc(100vh - 340px)', 
        sm:  'calc(100vh - 250px)' 
      },
      border: 'none',  
      backgroundColor: 'common.white',
      '&::-webkit-scrollbar': {
        display: 'none',
        msOverflowStyle: 'none',  //IE and Edge
        scrollbarWidth: 'none'  // Firefox 
      },		
    },
    tableSideMenu: {
      width: {
        sm: 'calc(100vw - 360px)',
        xs: 'auto'
      }
    },
    tableDisableEditing: {
      width: {
        sm: 'calc(100vw - 40px)',
        xs: 'auto'
      },
      height: { 
        xs: 'calc(100vh - 340px)', 
        sm:  'calc(100vh - 290px)' 
      }
    },
  }

