import axios from 'axios'
import queryString from 'query-string'
import { NUMBER_FIELDS } from 'lib/constants'

export const parseQueryFromURL = (url, fields) => {
  const queryParams = queryString.parse(url)
  
  const {
    keywords,
    page,
    per_page,
    filters,
    belongs_to,
    has_many,
    habtm,
    order,    
  } = queryParams 

  let [sort_by, sort_direction] = order ? order.split(':') : []

  // comma not inside square braces
  let FILTER_REGEX = /,(?![^\[]*\])/ 
  const BRACKET_REGEX = /\[|\]/g

  let filterQuery = []
  if(filters && filters.split(FILTER_REGEX).length > 0) {
    filterQuery = filters?.split(FILTER_REGEX).map(filter => {
      let [field, operator, value] = filter.split(':')
      if(value.split(',').length > 1) {
        value = value.replace(BRACKET_REGEX, '').split(',')
      }
      return { field, operator, value }
    })
  }

  let belongsToQuery = []  
  if(belongs_to && belongs_to.split(',').length > 0) {
    belongsToQuery = belongs_to?.split(',').map(filter => {
      let [name, foreign_table, foreign_key] = filter.split(':')
      return { name, foreign_table, foreign_key }
    })
  }
  
  let hasManyQuery = []
  if(has_many && has_many?.split(',').length > 0) {
    hasManyQuery = has_many?.split(',').map(filter => {
      let [name, foreign_table, foreign_key] = filter.split(':')
      return { name, foreign_table, foreign_key }
    })
  }

  let habtmQuery = []
  if(habtm && habtm?.split(',').length > 0 ){    
    habtmQuery = habtm?.split(',').map(filter => {
      let [name, foreign_table, join_table] = filter.split(':')
      return { name, foreign_table, join_table }
    })
  }

  return {
    keywords,
    page,
    per_page,
    sort_by,
    sort_direction,
    filters: filterQuery,
    belongs_to: belongsToQuery,
    has_many: hasManyQuery,
    habtm: habtmQuery
  }
}


export const buildQuery = (params, fields) => {  
  let associationQuery = buildQueryFromFields(fields)
  let query = buildQueryFromParams({
    ...params,
    ...associationQuery
  })
  return query 
}

export const buildQueryParams = (query) => {
  let queryParams = decodeURIComponent(new URLSearchParams(query).toString())      
  return queryParams 
}

export const buildQueryFromParams = (params) => {
  if (!params) return {}

  const {
    sort_by,
    sort_direction,
    keywords,
    filters,
    page,
    per_page,
    belongs_to,
    has_many,
    habtm
  } = params

  let query = {
    order: 'id:desc',
    page: 1,
    per_page: 20
  }

  if (page) {
    query = {
      ...query,
      page,
    }
  }

  if (per_page) {
    query = {
      ...query,
      per_page,
    }
  }

  if (sort_by && sort_direction) {
    query = {
      ...query,
      order: `${sort_by}:${sort_direction}`,
    }
  }

  if (Array.isArray(filters) && filters.length > 0) {
    let validFilters = filters
      .filter(validFilter)
      .map(({ field, operator, value }) => {
        if(Array.isArray(value)){
          return `${field}:${operator}:[${value.join(',')}]`
        }else{
          return `${field}:${operator}:${value}`
        }        
      })

    if (validFilters.length >= 0) {
      query = {
        ...query,
        filters: validFilters.join(','),
      }
    }
  }

  if (keywords && keywords != '') {
    query = {
      ...query,
      keywords,
    }
  }

  if (Array.isArray(belongs_to) && belongs_to?.length > 0) {  
    let validBelongsTo = belongs_to
      .filter(validateReference)
      .map(({ 
        name, 
        foreign_table, 
        foreign_key 
      }) => [name, foreign_table, foreign_key].join(":"))

    query = {
      ...query,
      belongs_to: validBelongsTo.join(',')
    }
  }

  if (Array.isArray(has_many) && has_many?.length > 0) {  
    let validHasMany = has_many
      .filter(validateHasMany)
      .map(({ 
        name,
        foreign_table,
        foreign_key 
      }) => [name, foreign_table, foreign_key].join(":"))

    query = {
      ...query,
      has_many: validHasMany.join(',')
    }
  }

  if (Array.isArray(habtm) && habtm?.length > 0) {  
    let validHabtm = habtm
      .filter(validateHABTM)
      .map(({
        name, 
        foreign_table,
        join_table 
      }) => [name, foreign_table, join_table].join(":"))

    query = {
      ...query,
      habtm: validHabtm.join(',')
    }
  }  
  return query
}

export const validFilter = (filter) => {
  const { field, operator, value } = filter
  if (
    field != null &&
    field != '' &&
    operator != null &&
    operator != '' &&
    value != null &&
    value != ''
  ) {
    return true
  } else {
    return false
  }
}

const validateReference = (association) => {
  const { foreign_table, foreign_key } = association
  if (foreign_table != null && 
      foreign_table != '' && 
      foreign_key != null && 
      foreign_key != '') {
    return true
  } else {
    return false
  }
}

const validateHasMany = (association) => {
  const { foreign_table, foreign_key } = association
  if (foreign_table != null && 
      foreign_table != '' && 
      foreign_key != null && 
      foreign_key != '') {
    return true
  } else {
    return false
  }
}

const validateHABTM = (association) => {
  const { foreign_table, join_table } = association
  if (foreign_table != null && 
      foreign_table != '' && 
      join_table != null && 
      join_table != '') {
    return true
  } else {
    return false
  }
}

export const buildQueryFromFields = (fields) => {
  if (!fields?.length > 0) return {}

  let belongs_to = []
  let has_many = []
  let habtm = []

  belongs_to = fields
    .filter(field => field.friendly_type === 'reference')
    .map(field => ({
      name: field.db_name + '_ref',
      foreign_key: field?.db_name,
      foreign_table: field?.foreign_collection?.db_name
    }))

  has_many = fields
    .filter(field => field.friendly_type === 'has_many')
    .map(field => ({
      name: field?.foreign_collection?.db_name,
      foreign_key: field?.foreign_field?.db_name,
      foreign_table: field?.foreign_collection?.db_name
    }))

  habtm = fields
    .filter(field => field.friendly_type === 'habtm')
    .map(field => ({        
      name: field?.foreign_collection?.db_name,
      foreign_table: field?.foreign_collection?.db_name,
      join_table: field?.foreign_join_collection?.db_name,        
    }))
  
  return {
    belongs_to: belongs_to,
    habtm: habtm,
    has_many: has_many
  }
}

export const reorder = (items, startIndex, endIndex) => {
  const result = Array.from(items)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result.map((item, index) => ({
    ...item,
    position: index + 1,
  }))
}

export const getInitials = (name) => {
  if(!name) return ''; 
  let initials = name
    .split(' ')
    .splice(0, 2)
    .map((word) => word[0])
    .join('')
  return initials
}

export const displayValueFromField = ({ field, value, row }) => {
  let displayValue = value
  let foreign_db_field = field?.foreign_field?.db_name
  let foreign_db_table = field?.foreign_collection?.db_name

  if(field.friendly_type === 'reference') {  
    if(row[`${field?.db_name}_ref`]){
      displayValue = row[`${field?.db_name}_ref`][foreign_db_field]      
    }      
  }

  if(field.friendly_type === 'has_many') { 
    let totalCount = row[foreign_db_table]?.length || 0
    displayValue = `${totalCount} ${foreign_db_table}`
  }

  if(field.friendly_type === 'habtm') { 
    let totalCount = row[foreign_db_table]?.length || 0
    displayValue = `${totalCount} ${foreign_db_table}`
  }
  return displayValue  
}

export const isSearchFilters = (url) => {
  const queryParams = queryString.parse(url)
  const {
    keywords,
    filters,
    order
  } = queryParams 

  if(keywords || filters?.length > 0 || order){
    return true
  }else{
    return false 
  }
}

export const isClearableParams = (queryParams) => {
  const {    
    keywords,
    filters    
  } = queryParams 

  if(keywords || filters?.length > 0){
    return true
  }else{
    return false 
  }
}


export const buildColumns = ({
  field,
  disableEditing
}) => {
            
  const editable =  !disableEditing && isEditableField(field)

  let column = {
    id: field.id,
    propsField: field,
    editable: editable,        
    label: fieldName(field),
    width: field.width || 150,
    hide: !field.visible,
    filterable: false,
    sortable: false,
    headerAlign: isNumberField(field) ? 'right' : 'left'
  }
  return column
}

// Name of the row attribute for fetching the field value
export const fieldName = (field) => {
  const { db_name, field_type, friendly_type } = field  
  if(field_type === 'column') {
    return db_name 
  }else if(friendly_type == 'has_many' || friendly_type == 'habtm') {
    return field.foreign_collection?.db_name 
  }
}

export const isEditableField = (field) => {
  let isEditable = field?.db_name?.toLowerCase() != 'id' && field?.editable === true  
  return isEditable 
}

export const isNumberField = (field) => {
  return NUMBER_FIELDS.includes(field.db_type)
}

export const adminlyApi = ({ url, name, token }) => {
	const api = axios.create({
		baseURL: url,
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + token,
		},
		timeout: 30000,
	})

	api.interceptors.response.use(
		(resp) => Promise.resolve(resp.data),
		(error) => Promise.reject(error.response)
	)

	return {
		findOne: async (id) => {
			return await api.get(`${url}/${id}`)?.data
		},
		findMany: async (params) => {
			const resp = await api.get(url, {
				params,
			})
			return {
				data: resp.data,
				meta: resp.meta,
			}
		},
		create: async (resource) => {
			const res = await api.post(url, {
				[name]: resource,
			})
			return res.data
		},
		udpate: async (resource) => {
			const res = await api.put(`${url}/${resource.id}`, {
				[name]: resource,
			})
			return res.data
		},
		destroy: async (id) => {
			const res = await api.delete(`${url}/${id}`)
			return res.data
		},
		updateMany: async (ids, resource) => {
			const res = await api.post(`${url}/update_many`, {
				ids: ids,
				[name]: resource,
			})
			return res.data
		},
		deleteMany: async (ids) => {
			const res = await api.post(`${url}/delete_many`, {
				ids: ids,
			})
			return res.data
		},
	}
}
