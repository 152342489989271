import React from 'react'
import {
  LandingLayout    
} from 'components'
import SectionHero from './SectionHero'
import SectionOne from './SectionOne'
import SectionTwo from './SectionTwo'
import SectionThree from './SectionThree'
import SectionFour from './SectionFour'
import { HOME_FEATURES } from 'lib/constants/landing'

const Home = (props) => {

  return(
    <LandingLayout>      
      <SectionHero />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour 
        features={ HOME_FEATURES }
      />
    </LandingLayout>
  )
}

export default Home 

const sx = {
  root: {} 
}
