import React from 'react'
import { Grid } from '@mui/material'

import { Autosuggest, TextInput } from 'components'
import { USER_ROLES } from 'lib/constants'

const AccountForm = ({ user, isEditing, handleChange }) => (
	<Grid container spacing={1}>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={user.first_name}
				label="First name"
				name="first_name"
				placeholder="First name"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				value={user.last_name}
				label="Last name"
				name="last_name"
				placeholder="Last name"
				handleChange={handleChange}
			/>
		</Grid>
		<Grid item xs={12} sm={12}>
			<TextInput
				disabled
				value={user.email}
				label="Email"
				name="email"
				placeholder="Email"
				handleChange={handleChange}
			/>
		</Grid>
	</Grid>
)

export default AccountForm
