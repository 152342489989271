import { useContext } from 'react'
import { ApiContext } from 'context'
import { useAlerts, useLoaders } from 'hooks'

const useHerokuOAuth = () => {
	const { api } = useContext(ApiContext)

	const { showLoading, hideLoading } = useLoaders()
	const { showAlertError } = useAlerts()

	const herokuOAuth = async (code, params) => {
		try {
			showLoading()
			let resp = await api.post(`/api/v1/oauth/heroku`, {
				code: code,
				...params,
			})
			return resp.data
		} catch (e) {
			console.log(e)
			showAlertError(e.message)
		} finally {
			hideLoading()
		}
	}

	const onHerokuComplete = async (message, params) => {
		const { code, provider } = JSON.parse(message)
		if (code && provider == 'heroku') {
			return await herokuOAuth(code, params)
		}
	}

	return {
		herokuOAuth,
		onHerokuComplete,
	}
}

export default useHerokuOAuth
