import React from 'react'
import { useHistory } from 'react-router'
import { VerticalTabs, VerticalTab } from 'components'
import { LEGAL_TABS } from 'lib/constants/navigation'

const LegalTabs = ({ step, ...props }) => {
	const history = useHistory()

	const handleClick = (route) => history.push(route)

	return (
		<VerticalTabs>
			{LEGAL_TABS.map((item, idx) => {
				let Icon = item.icon
				return (
					<VerticalTab
						key={idx}
						step={step}
						icon={<Icon size={20} />}
						value={item.step}
						primary={item.label}
						secondary={item.subtitle}
						handleClick={() => handleClick(item.value)}
					/>
				)
			})}
		</VerticalTabs>
	)
}

export default LegalTabs
