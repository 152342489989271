import React from 'react'
import { useActiveProject } from 'hooks'
import {
  Button,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemSecondaryAction,
} from '@mui/material'
import { UserAvatar } from 'components'
import { Check } from 'react-feather'

const ViewUserItem = ({
	user,
	handleAddClick,
	handleRemoveClick,
	...props
}) => {

  const { activeProject } = useActiveProject()

  return (
		<ListItem>
			<ListItemIcon>
				<UserAvatar src={user.avatar} variant="circle" />
			</ListItemIcon>
			<ListItemText
				primary={user.name}
				secondary={user.email}
			/>
			<ListItemSecondaryAction>				
				{ handleAddClick && (
					<Button 
            size="small"
            variant="outlined"
            startIcon={              
              <Check sx={ sx.icon } size={20 } />
            }
            onClick={() => handleAddClick(user)}
          >
            Share  
					</Button>
				)}
        { handleRemoveClick && (
					<Button 
            variant="outlined"
            size="small"
            onClick={() => handleRemoveClick(user)}>
            Remove 
					</Button>
				)}
			</ListItemSecondaryAction>
		</ListItem>
	)
}

export default ViewUserItem

const sx = {
  icon: {
    color: 'text.secondary'
  }
}