import React from 'react'
import { Grid } from '@mui/material'
import { TextInput } from 'components'

const ProjectSettingsForm = ({
	project,
	handleHerokuClick,
	handleChange,
}) => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={12}>
				<TextInput
					label="Project Name"
					value={project.name}
					name="name"
					placeholder="Project name"
					handleChange={handleChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextInput
					label="API URL"
					value={project.api_url}
					name="api_url"
					placeholder="API URL"
					handleChange={handleChange}
				/>
			</Grid>      
		</Grid>
	)
}

export default ProjectSettingsForm

const sx = {
	inputContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	icon: {
		color: 'text.secondary',
	},
	inputIcon: {
		mt: 2,
		color: 'text.secondary',
	},
}
