import React, { useState, useContext } from 'react'
import { ProjectContext } from 'context'
import {
	PanelHeader,
  ProgressLoader,
  ToggleEditing,
  DeleteWarningModal
} from 'components'
import {
  useAlerts,
	useActiveProject,
  useViews
} from 'hooks'
import PropTypes from 'prop-types'
import { Box, IconButton } from '@mui/material'
import { useLocation, useHistory } from 'react-router-dom'
import { UserPlus } from 'react-feather'
import ViewShareModal from 'containers/main/views/ViewShareModal'
import ViewActionMenu from 'containers/main/views/ViewActionMenu'
import ViewModal from 'containers/main/views/ViewModal'
import { validateView } from 'validations/views'

const CollectionHeader = ({ 
    title, 
    viewId, 
    projectId,
    collectionId,   
    ...props 
  }) => {

	const {     
    activeProject,
  } = useActiveProject(projectId)

  const location = useLocation()
	const history = useHistory()

  const { showAlertError, showAlertSuccess } = useAlerts()



  const [showShareModal, setShowShareModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
	
  const { 
    activeViews, 
    setActiveViews, 
  } = useContext(ProjectContext)

	const { 
    isLoading: isViewLoading,
    view,    
    findView,        
    deleteView,
    updateView,
    handleChange,
  } = useViews({
		projectId: activeProject?.id,
	})

  const handleShareClick = async () => {
    setShowShareModal(true)
    await findView(viewId)
  }

  const handleUpdateView = async () => {
    let validate = validateView(view)
    if(validate.isValid){
      let savedView = await updateView(view)    
      let index = activeViews.findIndex(v => v.id === savedView.id)
      let newViews = [...activeViews]
      newViews[index] = savedView
      setActiveViews(newViews)
      setShowEditModal(false) 
      showAlertSuccess("View successfully updated.")   
    }else{
      validate.messages.map(m => showAlertError(m))
    }
  }

  const handleDeleteView = () => {
    deleteView(view?.id)
    setActiveViews(activeViews.filter(v => v.id != view.id))
    showAlertSuccess("View successfully deleted.")
    setShowDeleteModal(false)
    history.push(`/projects/${activeProject?.id}/collections`)
  }

	return (
		<Box sx={ sx.root }>
      {activeProject && (
        <Box>
          <PanelHeader
            enableBackButton
            title={ title ? title : <ProgressLoader styles={ sx.progressLoader } size={24} /> }
            actions={
              (activeProject?.current_role === 'admin') && (
                <React.Fragment>                  
                  { viewId && (
                    <IconButton
                      onClick={ handleShareClick }
                    >
                      <UserPlus size={20} sx={ sx.icon  } />
                    </IconButton>
                  )}                  
                  <ToggleEditing />
                  { viewId && (
                    <ViewActionMenu 
                      handleEditClick={() => setShowEditModal(true) }
                      handleDeleteClick={() => setShowDeleteModal(true) }
                    />
                  )}
                </React.Fragment>                  
              )    
            }
          />
        </Box>      
      )}          
      <ViewModal
        isLoading={ isViewLoading }
        open={ showEditModal }
        view={ view }
        handleClose={() => setShowEditModal(false)}
        handleChange={ handleChange }
        handleSubmit={ handleUpdateView }        
      />
      <ViewShareModal
        isLoading={isViewLoading} 
        view={ view }
        open={showShareModal}        
        handleClose={() => setShowShareModal(false)}
      />
      <DeleteWarningModal 
        isLoading={isViewLoading}
        open={ showDeleteModal }
        onClose={() => setShowDeleteModal(false)}
        onConfirm={ handleDeleteView }
      />
		</Box>
	)
}

CollectionHeader.propTypes = {
	className: PropTypes.string,
}

export default CollectionHeader

const sx = {
	root: {},
	iconButton: {
		color: 'primary.main'
	},
	icon: {
		color: 'text.secondary'
	},
  progressLoader: {
    p: 0
  }
}
