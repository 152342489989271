import { isEmpty } from 'validations'

export const validateCollection = (collection) => {
	let isValid = true
	let messages = []

	if (isEmpty(collection.name)) {
		isValid = false
		messages.push('Collection API name is required')
	}

	let validation = {
		isValid,
		messages,
	}

	return validation
}
