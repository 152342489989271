import React, { useEffect, useContext } from 'react'
import {
	Box,
	ListItem,
	List,
	SwipeableDrawer,
} from '@mui/material'
import { AppContext } from 'context'
import { AuthContext } from 'context'
import { ProjectContext } from 'context'
import { useResponsive, useAuth } from 'hooks'
import { MenuItem } from 'components'
import Logo from 'assets/logo-regular.svg'
import LogoSquare from 'assets/logo-square.svg'
import { useHistory } from 'react-router-dom'
import { MENU_ITEMS } from 'lib/constants/navigation'

const Drawer = ({ 
    open, 
    closeMenu, 
    ...props 
  }) => {

	const history = useHistory()
	const { isMobile } = useResponsive()
	const { authenticated, currentUser } = useContext(AuthContext)
	const { activeTab } = useContext(AppContext)
	const { activeProject } = useContext(ProjectContext)
	const { fetchMe } = useAuth()	

	const handleClick = (item) => {
		closeMenu()
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})

		if (item.project) {
			history.push(`/projects/${activeProject?.id}/${item.value}`)
		} else {
			history.push(item.value)
		}
	}

  const handleHomeClick = () => {
    if(activeProject){
      history.push(`/projects/${activeProject?.id}/collections`)
    }else{
      history.push(`/projects`)
    }
  }

	useEffect(() => {
		if (authenticated && !currentUser) fetchMe()
	}, [authenticated])

	return (
		<SwipeableDrawer
			open={open}
			variant={isMobile ? 'temporary' : 'permanent'}
			onClose={closeMenu}
		>
			<Box
				display="flex"
				justifyContent="space-between"
				flexDirection="column"
				height="100%"        
			>
				<List 
          component="nav" 
          sx={{
            ...sx.list,
            width: { xs: '320px', sm: '60px' }
          }}>
					<ListItem 
            button 
            onClick={ handleHomeClick }
            sx={ sx.logo }>            
						<img              
							style={{ 
                ...sx.image, 
                width: isMobile ? '140px' : '36px'               
              }}
							src={isMobile ? Logo : LogoSquare}
						/>
					</ListItem>
					{MENU_ITEMS[currentUser? 'authenticated': 'unauthenticated'].map(
						(menu, idx) =>
							((menu.project && activeProject?.id) ||
								!menu.project) && (
								<MenuItem
									key={idx}
									active={activeTab === menu.id}
									value={menu.value}
                  label={menu.label}
									icon={menu.icon}
									image={menu.image}
									handleClick={() => handleClick(menu)}
								/>
							)
					)}
					<Box sx={ sx.divider } />
					{currentUser &&
						activeProject &&
						activeProject.addons.map((addon, idx) => {
							let menu = MENU_ITEMS['addons'][addon?.provider]
							return menu ? (
								<MenuItem
									key={idx}
									active={activeTab === menu.id}
									value={menu.value}
									label={menu.label}
									icon={menu.icon}
									image={menu.image}
									handleClick={() => handleClick(menu)}
								/>
							) : null
						})}
				</List>
			</Box>
		</SwipeableDrawer>
	)
}

export default Drawer

const sx = {
	root: {
    backgroundColor: 'background.dark',		
    '& .MuiPaper-root': {
      backgroundColor: theme => theme.palette.common.darkBorder,
    },
		'& .MuiDrawer-paperAnchorDockedLeft': {
			borderRight: theme => `2px solid ${theme.palette.common.darkBorder}`,
		},
	},
	logo: {
		height: 66,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	list: {
		backgroundColor: 'background.dark',		
		height: '100%',
	},
	icon: {
		color: 'icon'
	},
	divider: {
		m: 2,
		opacity: 0.6,
		borderTop: theme => `1px solid ${theme.palette.common.darkBorder}`
	},
}
