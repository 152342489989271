
import React, { useState, useEffect } from 'react'
import {
	TextInput,
	Autosuggest,
  SearchInput,
  ArrayInput
} from 'components'
import {
  Box,
  Button,
	IconButton,
	Typography,
} from '@mui/material'
import {
	FILTER_OPERATORS,
	FILTERABLE_TYPES,
  SORT_DIRECTIONS,
  BOOLEAN_OPTIONS,
  BOOLEAN_FIELDS,
  DATE_FIELDS,
  STRING_FIELDS,
  NUMBER_FIELDS,
  DATE_RANGE_OPTIONS
} from 'lib/constants'
import { Clear, FilterList } from '@mui/icons-material'
import { Plus, X, Search } from 'react-feather'


const AdminlyFilterForm = ({
  query,
	fields,
	handleChange,
	handleSearch,
  handleClearFilters,
  ...props
}) => {
  
  const [activeFilters, setActiveFilters] = useState([])

	let defaultFilter = {
		field: '',
		operator: '',
		value: '',
	}

	const handleFilterChange = (ev, index) => {
		let filter = activeFilters[index]
		let { name, value } = ev.target
		let updatedFilter = {
			...filter,
			[name]: value,
		}
    
    if(name === 'field') {
      updatedFilter = {
        ...updatedFilter,
        operator: '',
        value: ''
      }
    }

		let updatedFilters = [...activeFilters]
		updatedFilters[index] = updatedFilter
		setActiveFilters(updatedFilters)
	}

	const handleAddFilter = () => {
		let updatedFilters = [...activeFilters, defaultFilter]
		setActiveFilters(updatedFilters)
	}

	const handleRemoveFilter = (index) => {
		let updatedFilters = activeFilters.filter(
			(f, i) => i != index
		)
		setActiveFilters(updatedFilters)
	}

  const handleFilterSearch = () => {
    let searchQuery = {
      ...query,
      filters: activeFilters
    }
    handleSearch(searchQuery)
  }

  useEffect(() => {
    if(query?.filters && Array.isArray(query?.filters)){
      setActiveFilters(query?.filters)
    }
  }, [query])

	return (
		<Box sx={ sx.root }>

      <Box sx={ sx.searchBar }>
        <Box sx={ sx.inputField }>
          <Box sx={ sx.inputLabel }>
            <Typography
              variant="caption"
              color="textSecondary"
            >
              KEYWORDS 
            </Typography>
          </Box>
          <Box sx={ sx.inputKeywords }>
            <SearchInput
              name="keywords"
              value={query.keywords}
              handleChange={handleChange}
            />
          </Box>
        </Box>				
			</Box>

      <Box sx={ sx.searchBar }>
        <Box sx={ sx.inputField }>
          <Box sx={ sx.inputLabel }>
            <Typography
              variant="caption"
              color="textSecondary"
            >
              SORT BY 
            </Typography>
          </Box>
          <Box sx={ sx.inputSortBy }>
            <Autosuggest
              name={'sort_by'}
              options={fields.map((field) => ({
                label: field.db_name,
                value: field.db_name,
              }))}
              placeholder="Sort field"
              value={query?.sort_by || ''}
              handleChange={ handleChange }/>
          </Box>
          <Box sx={ sx.inputSortDirection  }>
            <Autosuggest
              name={'sort_direction'}
              options={ SORT_DIRECTIONS }
              placeholder="Sort direction"
              value={query?.sort_direction || ''}
              handleChange={ handleChange }/>
          </Box>
        </Box>				
			</Box>

			{Array.isArray(activeFilters) && 
       activeFilters?.map((filter, index) => {

				let filterOperators = FILTER_OPERATORS['integer']
        let currentField
				if (filter.field) {
					currentField = fields.find((f) => f.db_name == filter.field)
					filterOperators = FILTER_OPERATORS[currentField?.db_type || 'integer']
				}
				return (
					<Box key={index}>
						<Box sx={ sx.inputField }>
							<Box sx={ sx.inputLabel }>
								<Typography
									variant="caption"
									color="textSecondary"
								>
									{ index > 0 ? "AND" : "WHERE" }
								</Typography>
							</Box>
							<Box sx={ sx.inputFilter }>
								<Autosuggest
									name={'field'}
									options={fields.filter((field) => FILTERABLE_TYPES
                    .includes(field?.friendly_type))
										.map((field) => ({
											label: field.db_name,
											value: field.db_name,
										}))}
									placeholder="field"
									value={filter?.field || ''}
									handleChange={(ev) =>
										handleFilterChange(ev, index)
									}
								/>
							</Box>
							<Box sx={ sx.inputOperator }>
								<Autosuggest
									sx={ sx.operator }
									name={'operator'}
									placeholder="…"
									options={filterOperators}
									value={filter?.operator || ''}
									handleChange={(ev) =>
										handleFilterChange(ev, index)
									}
								/>
							</Box>
							<Box sx={ sx.inputValue }>
                {BOOLEAN_FIELDS.includes(currentField?.db_type) && (
                  <Autosuggest
                    sx={ sx.value }
                    name={'value'}
                    placeholder="true or false"
                    options={BOOLEAN_OPTIONS}
                    value={filter?.value || ''}
                    handleChange={(ev) =>
                      handleFilterChange(ev, index)
                    }
                  />
                )}                

                { DATE_FIELDS.includes(currentField?.db_type) && (
                   <>
                    {['gte','lte'].includes(filter?.operator) ? (                                                             
                      <Autosuggest
                        sx={ sx.value }
                        name={'value'}
                        placeholder="date since"
                        options={DATE_RANGE_OPTIONS}
                        value={filter?.value || ''}
                        handleChange={(ev) =>
                          handleFilterChange(ev, index)
                        }
                      />     
                    ):(
                      <TextInput
                        type={'date'}
                        sx={ sx.value }
                        name={'value'}
                        placeholder="value"
                        value={filter?.value || ''}
                        handleChange={(ev) =>
                          handleFilterChange(ev, index)
                        }
                      />
                    )}
                   </>
                )}

                {(NUMBER_FIELDS.includes(currentField?.db_type)) && (
                  <>
                    { filter?.operator === 'in' ? (
                       <ArrayInput
                        name={'value'}
                        placeholder="values"                        
                        value={filter.value}
                        handleChange={(ev) =>
                          handleFilterChange(ev, index)
                        }
                      />                  
                    ):(                                          
                      <TextInput        
                        type='number'            
                        sx={ sx.value }
                        name={'value'}
                        placeholder="value"
                        value={filter?.value || ''}
                        handleChange={(ev) =>
                          handleFilterChange(ev, index)
                        }
                      />
                    )}              
                  </>
                )}
                
                { (!currentField?.db_type || STRING_FIELDS.includes(currentField?.db_type)) && (
                  <TextInput        
                    sx={ sx.value }
                    name={'value'}
                    placeholder="value"
                    value={filter?.value || ''}
                    handleChange={(ev) =>
                      handleFilterChange(ev, index)
                    }
                  />
                )}

							</Box>
							<Box sx={ sx.inputIcon }>
								<IconButton
									size="small"
									onClick={() => handleRemoveFilter(index)}
									sx={ sx.iconButton }
								>
									<Clear sx={ sx.icon } />
								</IconButton>
							</Box>
						</Box>
					</Box>
				)
			})}

      <Box sx={ sx.inputField }>
				<Box sx={ sx.inputLabel }>
        </Box>
        <Box sx={ sx.input  }>
          <Button            
            variant="outlined"            
            startIcon={<Plus />}
            onClick={handleAddFilter}
            sx={ sx.addFilterButton }
          >
            Filter
          </Button>
        </Box> 
      </Box>
      <Button
				sx={ sx.button }
				startIcon={<Search />}
				onClick={handleFilterSearch}
				fullWidth
				variant="contained"
				color="primary"
			>
				Search
			</Button>

      <Button
				sx={ sx.button }
				startIcon={<FilterList />}
				onClick={handleClearFilters}
				fullWidth
				variant="outlined"
				color="primary"
			>
				Reset filters 
			</Button>
		</Box>
	)
}

export default AdminlyFilterForm

const sx = {
  button: {
		mt: 2,
	},
	inputField: {
		py: 0.5,
    px: 0,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
  inputLabel: {
    minWidth: '100px',
  },
  inputKeywords: {
    width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
    mr: 34,
	},
  inputSortBy: {
		width: '100%',
    flexScale: 1,
		mr: 1,
  },
  inputSortDirection: { 
    width: '100%', 
    flexScale: 1,  
    mr: 4
  },  
	inputFilter: {
		flexScale: 3,
    maxWidth: '160px',
		mr: 1,
	},
	inputOperator: {
		flexScale: 1,
    maxWidth: '60px',
		mr: 1,
	},
	inputValue: {
		flexScale: 1,
    width: '100%'
  },
	addFilterButton: {
    maxWidth: '160px',
    width: { sm: '100%' }
	},
	icon: {
    height: '20px',
    width: '20px',
		color: 'icon'
	},
}
