import React from 'react'
import {
	Button,
	Box,
	Typography,
	List,
} from '@mui/material'

import {
	ButtonLoader,
	Modal,
	ListItemButton,
} from 'components'
import { Folder as FolderIcon } from 'react-feather'
import FolderForm from 'containers/apps/cloudinary/Folders/FolderForm'

const FolderModal = ({
	isLoading,
	open,
	folder,
	handleClose,
	handleChange,
	handleSubmit,
}) => {


	return (
		<Modal
			open={open}
			handleClose={handleClose}
			title={'Create folder'}
			actions={
				<Button
					size="large"
					color="primary"
					variant="contained"
					onClick={handleSubmit}
					startIcon={isLoading && <ButtonLoader />}
				>
					Create Folder
				</Button>
			}
		>
			<Box sx={ sx.content }>
				<List>
					<ListItemButton
						icon={FolderIcon}
						primary={'Cloudinary Folder'}
						secondary={'Add a folder.'}
					/>
				</List>
				<FolderForm
					folder={folder}
					handleChange={handleChange}
				/>
			</Box>
		</Modal>
	)
}

export default FolderModal

const sx = {
	content: {
		p: 2,
	},
}
