import React from 'react'
import { Button, Box, Typography } from '@mui/material'

import {
	ButtonLoader,
	ProgressLoader,
	Modal,
} from 'components'
import UserForm from 'containers/main/users/UserForm'

const UserModal = ({
	isLoading,
	open,
	user,
	handleClose,
	handleChange,
	handleSubmit,
}) => {


	return (
		<Modal
			open={open}
			handleClose={handleClose}
			title={'Project Team'}
			actions={
				<Button
					color="primary"
					variant="contained"
					onClick={handleSubmit}
					startIcon={isLoading && <ButtonLoader />}
				>
					{user?.id ? 'Update User' : 'Invite User'}
				</Button>
			}
		>
			<Box sx={ sx.content }>
				{!isLoading ? (
					<Box>
						<Box py={2}>
							<Typography
								variant="body2"
								color="textSecondary"
							>
								Invite and manage team members for this
								project.
							</Typography>
						</Box>
						<UserForm
							user={user}
							handleChange={handleChange}
						/>
					</Box>
				) : (
					<ProgressLoader />
				)}
			</Box>
		</Modal>
	)
}

export default UserModal

const sx = {
	content: {
		p: 2,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}
