import React from 'react'
import { Chip } from '@mui/material'
import { alpha } from '@mui/material'
import { COLORS } from 'lib/constants'

const Label = ({
	label,
  color='teal',
	className,
	...rest
}) => {

	return (
    <Chip 
      label={label} 
      sx={{ 
        ...sx.chip,
        color: COLORS[color],
        backgroundColor: alpha(COLORS[color], 0.2),
      }} 
      size="small"     
    />
	)
}

export default Label

const sx = {
  chip: {
    mt: 0,
    mr: 0.5,
    mb: 0.5,
    ml: 0,    
    textTransform: 'uppercase',
    characterSpacing: '1em',
    fontSize: 11,
		fontFamily: theme => theme.typography.caption.fontFamily,
		letterSpacing: 0,
	},
}
