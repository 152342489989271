import React, { useContext } from 'react'
import { AppContext } from 'context'
import { IconButton, Hidden } from '@mui/material'
import { LastPage } from '@mui/icons-material'

const ExpandSideMenu = () => {

	const { showSideMenu, toggleSideMenu } = useContext(AppContext)

	return !showSideMenu ? (
    <Hidden smDown>
      <IconButton
        onClick={toggleSideMenu}
        sx={ sx.button }
      >
        <LastPage sx={ sx.icon  } />
      </IconButton>
    </Hidden>
	) : null
}

export default ExpandSideMenu

const sx = {
  button: {
    ml: 2,
    minWidth: '32px',
	},
  icon: {
    color: 'icon'
  }
}
