import { useContext, useEffect, useState } from 'react'
import { ApiContext } from 'context'
import axios from 'axios'
import { useResource, useLoaders } from 'hooks'

const useCloudinary = ({ id, projectId, ...props }) => {
	const { api } = useContext(ApiContext)

	const { showLoading, hideLoading } = useLoaders()
	const [nextCursor, setNextCursor] = useState()
	const [cloudinaryToken, setCloudinaryToken] = useState()

	const {
		resourceId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource,
		resources,
		findOne: findResource,
		findMany: findResources,
		reload: reloadResource,
		reloadMany: reloadResources,
		query,
		meta,
		setResource,
		setResources,
	} = useResource({
		id: id,
		url: `/api/v1/apps/projects/${projectId}/cloudinary`,
		name: 'resource',
	})

	const deleteResource = async (
		publicId,
		resourceType = 'image'
	) => {
		try {
			showLoading()
			const res = await api.post(
				`/api/v1/apps/projects/${projectId}/cloudinary/delete`,
				{
					public_id: publicId,
					resource_type: resourceType,
				}
			)
			hideLoading()
			setResource({ data: {} })
		} catch (e) {
			hideLoading()
		}
	}

	const loadMoreResources = async (nextCursor) => {
		let nextResources = await findResources({
			next_cursor: nextCursor,
		})
		if (nextResources.length > 0) {
			setResources([...resources, ...nextResources])
		}
	}

	const fetchCloudinaryToken = async (source, folder) => {
		const res = await api.get(
			`/api/v1/apps/projects/${projectId}/cloudinary/cloudinary_token`,
			{
				params: {
					source,
					folder: `${folder}`,
				},
			}
		)
		setCloudinaryToken(res?.data?.signature)
		return res?.data
	}

	//https://cloudinary.com/documentation/upload_images#generating_authentication_signatures
	const signedUpload = async (file, path = '') => {
		const publicId = file?.name?.split('.')[0]
		let cloudinary = await fetchCloudinaryToken(
			publicId,
			path
		)
		const {
			api_key,
			timestamp,
			folder,
			public_id,
			cloud_name,
			signature,
		} = cloudinary
		const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${cloud_name}/auto/upload`
		const formData = new FormData()
		formData.append('file', file)
		formData.append('api_key', api_key)
		formData.append('folder', folder)
		formData.append('public_id', public_id)
		formData.append('timestamp', timestamp)
		formData.append('signature', signature)

		// Remove JWT token headers from app
		delete axios.defaults.headers.common['Authorization']
		let resp = await axios.post(cloudinaryUrl, formData, {
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
			},
		})
		return resp
	}

	useEffect(() => {
		setNextCursor(meta?.next_cursor)
	}, [meta])

	return {
		resourceId,
		isLoading,
		isLoaded,
		isEmpty,
		isEditing,
		isValid,
		resource,
		resources,
		findResource,
		findResources,
		deleteResource,
		reloadResource,
		reloadResources,
		loadMoreResources,
		signedUpload,
		fetchCloudinaryToken,
		cloudinaryToken,
		query,
		meta,
		nextCursor,
		setResource,
		setResources,
	}
}

export default useCloudinary
