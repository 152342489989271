import React, { useContext } from 'react'
import { AppContext } from 'context'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import Header from 'containers/main/navigation/Header'

const CenterPanel = ({ children, ...props }) => {
  const { openMobile } = useContext(AppContext)

	return (
    <Box 
      sx={{ 
        ...sx.root, 
        ...(openMobile && sx.open) 
      }}>
      <Header />
      <Box
        sx={sx.content}
      >      
        {children}
      </Box>
    </Box>
	)
}

CenterPanel.propTypes = {
	children: PropTypes.any,
}

export default CenterPanel

const sx = {
  root: {    
    height: '100%',   
    width: '100%',     
    minWidth: { 
      xs: '100%',
      sm: 'auto'
    },
    maxWidth: '100vw',
    transition: 'transform .5s ease',    
    transform: {
      xs: 'translateX(0)',
      sm: 'translateX(0)'      
    }
  },
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		overflowY: 'scroll',
    height: {
      xs: 'calc(100vh - 72px)',
      sm: 'calc(100vh - 48px)'
    },
    py: 0,
    px: 2,    
    backgroundColor: 'background.main',		
	},
	open: {		
		transform: { 
      xs: 'translateX(-100%)',
      sm: 'translateX(0)'
    }
	},
}
