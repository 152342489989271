import React from 'react'
import { Hidden, Button, ListItem } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

const MenuItem = ({
	handleClick,
	active,
	depth = 0,
	open,
	expandable = false,
	label,
	image,
	icon: Icon,
	image: Image,
}) => {

	let paddingLeft = 18

	if (depth > 0) {
		paddingLeft = 42 + 8 * depth
	}

	const style = {
		paddingLeft,
	}

	return (
		<ListItem sx={ sx.itemLeaf } disableGutters>
			<Button
				onClick={handleClick}
				sx={{
          ...sx.button, 
          ...(active && sx.active)
        }}
				style={style}
				startIcon={
					<>
						{Icon && <Icon size={20} sx={ sx.icon} /> }
						{image && (
							<img src={Image} style={ sx.icon } />
						)}
					</>
				}
			>
        <Hidden smUp>
          { label }
        </Hidden>
				{expandable && (
					<>
						{open ? (
							<ExpandLess
								sx={ sx.expandIcon }
								color="inherit"
							/>
						) : (
							<ExpandMore
								sx={ sx.expandIcon }
								color="inherit"
							/>
						)}
					</>
				)}
			</Button>
		</ListItem>
	)
}
export default MenuItem

const sx = {
	item: {
		display: 'block',
	},
	itemLeaf: {
		display: 'flex',
		pt: 0,
		pb: 0,
    borderRadius: '4px',    
    ml: '4px',
    mr: '4px' 
	},
	button: {
    color: 'text.primary',
    width: '100%',
    minWidth: 40,
		borderRadius: '4px',
		p: '12px 8px',
		justifyContent: 'flex-start',
		labelTransform: 'uppercase',
		color: 'common.white',
		letterSpacing: 0,
    mr: '10px'    
	},
	icon: {
		height: 20,
		width: 20,
		color: 'common.white',
		display: 'flex',
		alignItems: 'center',
		mt: 0,
    mr: 1,
    mb: 0,
    ml: 1,
    m: { sm: 0 }
	},
	expandIcon: {
		ml: 'auto',
		height: 20,
		width: 20,
	},
	label: {
		color: 'common.white',
		display: 'flex',
		alignItems: 'center',
		ml: 'auto',
	},
	active: {
		color: 'common.white',
    backgroundColor: 'primary.main',
    boxShadow: `0 0 0 1px rgb(35 38 59 / 5%), 0 1px 3px 0 rgb(35 38 59 / 15%)`,
		fontWeight: theme => theme.typography.fontWeightBold,
		'&:hover': {
			backgroundColor: 'primary.main'
		},
		'& $icon': {
			color: 'common.white',
		},
	},
}
