import React from 'react'
import { useHistory } from 'react-router'
import { 
  Button,
  Box, 
  Container, 
  Typography, 
  Link
} from '@mui/material'
import { Panel } from 'components'
import Logo from 'assets/logo-dark.svg'

const AuthScreen = ({
	title,
	subtitle,
  logo,
	children,
	...props
}) => {
  const history = useHistory()
  const handleClick = () => {
    history.push('/login')
  }

	return (		
		<Box sx={ sx.root }>        
      <Container maxWidth="sm">
        <Panel p={4}>					
          <Box sx={ sx.logo }>
            <Button onClick={ handleClick }>
              <img src={ Logo } style={ styles.logo } />
            </Button> 
          </Box>             
          <Typography variant="h4">{title}</Typography>
          {subtitle && (
            <Typography
              variant="body1"
              color="textSecondary"
            >
              {subtitle}
            </Typography>
          )}
					{children}
				</Panel>
        <center>
          <Box maxWidth={400}>
            <Typography variant="body2" m={3} color='textSecondary'>
              By clicking "Sign In", "Sign In with Google" or "Register"{' '} 
              you agree to our <Link href="/terms_of_service" target="_blank">Terms of Service</Link>
            </Typography>
          </Box>
        </center>
      </Container>
    </Box>
	)
}

export default AuthScreen

const styles = {
  logo: {
    maxWidth: 130
  }
}

const sx = {
  root: {
    py: {
      sm: 12,
      xs: 4
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
	logo: {
		width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: 1,
    caption: {
      maxWidth: 400
    }
	}
}
