import React from 'react'
import { EmptyImage, CloudinaryImage } from 'components'


const CellImageThumbnail = ({ value, size=60, ...props }) => {

	let isCloudinary = /(cloudinary)/i.test(value)

  if(!value) return(<EmptyImage />) 

	return (      
			isCloudinary ? (
				<CloudinaryImage
					src={value}
					height={size}
					width={size}
				/>
			) : (
				<img src={value} style={{ 
          ...styles.image,
          height: `${size}px`,
          width: `${size}px`
        }} />
			)
	)
}

export default CellImageThumbnail

const styles = {
  image: {
		objectFit: 'cover',
	}
}

const sx = {
	avatar: {
		backgroundColor: 'common.white',
	}
}
