import React from 'react'
import { Box } from '@mui/material'
import Header from 'containers/landing/Header' 
import Footer from 'containers/landing/Footer'
import { MENU_ITEMS } from 'lib/constants/navigation'
import Logo from 'assets/logo-dark.svg'

const LandingLayout = ({ children, ...props }) => {

	return (
		<Box sx={ sx.root }>	
      <Header 
        logo={ Logo }
        menuItems={ MENU_ITEMS.public }
      />
      <Box sx={ sx.content }>	
			  {children}
      </Box> 
      <Footer />
		</Box>
	)
}

export default LandingLayout

const sx = {
  root: {    
    height: '100%'
  },
  content: {
    width: '100vw',
    minHeight: 'calc(100vh - 405px)',
  }
}