import { useContext, useEffect, useState } from 'react'
import { ApiContext } from 'context'
import { useAlerts } from 'hooks'

const useProjectUsers = ({ id, projectId, ...props }) => {
	const { api } = useContext(ApiContext)

	const { showAlertError } = useAlerts()

	const [isLoading, setIsLoading] = useState(false)
	const [users, setUsers] = useState([])

	const [user, setUser] = useState({})

	const showLoading = () => setIsLoading(true)
	const hideLoading = () => setIsLoading(false)

	const handleChange = (ev) => {
		const { name, value } = ev.target
		setUser({
			...user,
			[name]: value,
		})
	}

	const listUsers = async (params) => {
		try {
      showLoading()
			const resp = await api.get(
				`/api/v1/projects/${projectId}/list_users`,
				{
					params: params,
				}
			)			
			setUsers(resp?.data)
			return resp.data
		} catch (e) {
			showAlertError('There was an error accessing the config vars.')
		} finally {
      hideLoading()
    }
	}

	const inviteUser = async (user) => {
		try {
      showLoading()
			const resp = await api.post(`/api/v1/projects/${projectId}/invite_user`,{
				  user: user,
				}
			)
			hideLoading()
			return resp.data
		} catch (e) {
			showAlertError(
				'There was an error inviting the user.'
			)
		} finally {
      hideLoading()
    }
	}

	const updateUser = async (user) => {
		try {
      showLoading()
			const resp = await api.post(`/api/v1/projects/${projectId}/update_user`,{
					user: user,
				}
			)			
			return resp.data
		} catch (e) {
			showAlertError(
				'There was an error inviting the user.'
			)
		}finally{
      hideLoading()
    }
	}

	const removeUser = async (user) => {
		try {
      showLoading()
			const resp = await api.post(`/api/v1/projects/${projectId}/remove_user`, {
					user: {
            id: user?.id
          }
				}
			)
			return resp.data
		} catch (e) {
			showAlertError(
				'There was an error removing the user.'
			)
		}finally{
      hideLoading()
    }
	}

	return {
		isLoading,
		user,
		users,
		listUsers,
		updateUser,
		inviteUser,
		removeUser,
		handleChange,
		setUser,
		setUsers,
	}
}

export default useProjectUsers
