import React, { useContext } from 'react'
import {
  Hidden,	
	Button,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { AuthContext } from 'context'
import { useMenu, useAuth, useResponsive } from 'hooks'
import { UserMenu, OnlineStatusAvatar } from 'components'
import { useHistory } from 'react-router-dom'

const OnlineUserMenu = ({ ...props }) => {
	const { isMobile } = useResponsive()
	const history = useHistory()

	const { logoutUser } = useAuth()

	const { currentUser } = useContext(AuthContext)

	const { 
    open, 
    anchorEl, 
    closeMenu, 
    toggleMenu 
  } = useMenu()

	const handleClick = (url) => {
		closeMenu()
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
		history.push(url)
	}

	const handleLogoutClick = () => logoutUser()

	return (
		<>
			{currentUser && (
        <>        
        <Button 
          sx={ sx.button  }
          onClick={toggleMenu}
          startIcon={
            <OnlineStatusAvatar
              online
              size={'20px'}
              user={currentUser}
            />
          }
          endIcon={ <ExpandMore sx={ sx.icon} />  }
        >
          <Hidden smDown>{ currentUser?.name }</Hidden>
        </Button>
        <UserMenu
          open={open}
          anchorEl={anchorEl}
          toggleMenu={toggleMenu}
          handleLogoutClick={handleLogoutClick}
          handleClick={handleClick}
        />
      </>
			)}
		</>
	)
}

export default OnlineUserMenu

const sx = {
  button: {
    color: 'text.primary',
    fontSize: '15px',
    textTransform: 'none'    
  },
	icon: {
		color: 'text.secondary'
	},	
}
